<template>
    <client-only>
        <div ref="trustpilotRef" class="trustpilot-widget" :class="{ 'is-visible': isMounted }" data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="550884280000ff00057e1e9e" data-style-height="20px" data-style-width="100%" data-theme="light">
            <a href="https://www.trustpilot.com/review/lensdirect.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>
    </client-only>
</template>

<script>
export default {
    mounted() {
        this.initComponent();
    },
    data() {
        return {
            isMounted: false
        }
    },
    methods: {
        initComponent(counter = 0) {
            if (counter > 10) {
                return;
            }

            if (typeof window.Trustpilot === 'undefined') {
                counter++;
                window.setTimeout(() => {
                    this.initComponent(counter);
                }, 300);
                return;
            }

            if (!this.$refs.trustpilotRef) {
                counter++;
                window.setTimeout(() => {
                    this.initComponent(counter);
                }, 300);
                return;
            }

            window.Trustpilot.loadFromElement(this.$refs.trustpilotRef);

            this.isMounted = true;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
.trustpilot-widget {
    min-width: 420px;
    height: 20px;
    opacity: 0;
    align-self: center;
    &.is-visible {
        opacity: 1;
    }
}

@media (max-width: $breakpoint2) {
    .trustpilot-widget {
        min-width: auto;
    }
}
</style>
