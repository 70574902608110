<template>
    <b-modal centered :visible="value" @hidden="onClickCancel" title="Are you sure?" size="sm">
        <template v-slot:modal-header-close>
            <img :src="formatAssetUrl('images/times-line.svg')" />
        </template>

        <p class="text-center">
            <slot></slot>
        </p>

        <template v-slot:modal-footer>
            <button class="btn btn-outline" type="button" @click="onClickCancel">
                Cancel
            </button>

            <CustomButton
                class="btn btn-primary"
                type="button"
                @click="onClickConfirm"
                :isLoading="isLoading"
            >
            Remove
            </CustomButton>
        </template>
    </b-modal>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    props: {
        value: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClickCancel() {
            this.$emit('input', false);
        },
        onClickConfirm(e) {
            this.$emit('input', false);
            this.$emit('confirmed');
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep {
        .confirm-modal-wrapper {
            .modal-content {
                border: 0px;
                box-shadow: 0px 0px 20px 0px rgba(0,0,0,.25);
            }
        }
    }
</style>
