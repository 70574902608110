export default {
    STANDARD: {
        id: 1,
        name: 'Standard AR'
    },
    PREMIUM: {
        id: 2,
        name: 'Premium AR'
    },
    BLUE_LIGHT: {
        id: 3,
        name: 'Blue Light'
    },
    ULTRA: {
        id: 4,
        name: 'Aura Ultra AR'
    },
    ANTI_FOG: {
        id: 5,
        name: 'Anti-Fog AR'
    },
    COSMIC: {
        id: 6,
        name: 'Cosmic'
    },
    LIGHT_RESPONSIVE_GRAY: {
        id: 7,
        name: 'Light Responsive Gray'
    }
};
