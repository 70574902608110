const SET_CURRENT_STEP = 'Set Current Step';
const SET_CHOICES = 'Set Choices';
const SET_STEPS = 'Set Steps';
const SET_SELECTED_CHOICES = 'Set Selected Choices';
const SET_ESTIMATED_PRODUCT = 'Set Estimated Product';
const SET_BASE_PRODUCT = 'Set Base Product';
const SET_IS_LOADING_CHOICES = 'Set Is Loading Choices';
const SET_ADDITIONAL_PRICE = 'Set Additional Price';
const SET_EXISTING_ADDON_PRICE = 'Set Existing Addon Price';
const RESET_STATE = 'Reset State';
const SET_SELECTED_ADDONS = 'Set Selected Addons';
const SET_DETERMINING_FIRST_STEP = 'Set Determining First Step';
const SET_LENS_REPLACEMENT_PAGE_DATA = 'Set Lens Replacement Page Data';
const SET_SHOW_MOBILE_SUMMARY = 'Set Show Mobile Summary';

import storage from '@/utils/storage';
import objectFactory from '@/utils/object-factory';

export const state = () => ({
    baseProduct: null,
    currentStep: null,
    choices: [],
    steps: [],
    selectedChoices: [],
    selectedAddons: [],
    estimatedProduct: null,
    isLoadingChoices: false,
    additionalPrice: 0,
    existingAddonPrice: 0,
    determiningFirstStep: true,
    lensReplacementPageData: null,
    showMobileSummary: false
});

export const mutations = {
    [SET_SHOW_MOBILE_SUMMARY](state, payload) {
        state.showMobileSummary = payload;
    },
    [SET_LENS_REPLACEMENT_PAGE_DATA](state, payload) {
        state.lensReplacementPageData = payload;
    },
    [SET_DETERMINING_FIRST_STEP](state, payload) {
        state.determiningFirstStep = payload;
    },
    [SET_SELECTED_ADDONS](state, payload) {
        state.selectedAddons = payload;
    },
    [RESET_STATE](state) {
        state.choices = [];
        state.steps = [];
        state.selectedChoices = [];
        state.selectedAddons = [];
        state.estimatedProduct = null;
        state.isLoadingChoices = false;
        state.additionalPrice = 0;
        state.existingAddonPrice = 0;
    },
    [SET_EXISTING_ADDON_PRICE](state, payload) {
        state.existingAddonPrice = payload;
    },
    [SET_ADDITIONAL_PRICE](state, payload) {
        state.additionalPrice = payload;
    },
    [SET_IS_LOADING_CHOICES](state, payload) {
        state.isLoadingChoices = payload;
    },
    [SET_BASE_PRODUCT](state, payload) {
        state.baseProduct = payload;
    },
    [SET_CURRENT_STEP](state, payload) {
        state.currentStep = payload;
    },
    [SET_CHOICES](state, payload) {
        state.choices = payload;
    },
    [SET_STEPS](state, payload) {
        state.steps = payload;
    },
    [SET_SELECTED_CHOICES](state, payload) {
        state.selectedChoices = payload;
    },
    [SET_ESTIMATED_PRODUCT](state, payload) {
        state.estimatedProduct = payload;
    }
};

export const actions = {
    setShowMobileSummary({ commit }, payload) {
        commit(SET_SHOW_MOBILE_SUMMARY, payload);
        return Promise.resolve();
    },
    async loadLensReplacementPageData({ dispatch }) {
        try {
            let response = await this.$axios.$get('/page/lens-replacement');
            return dispatch('setLensReplacementPageData', response);
        } catch (e) {
            // Prevent issues loading page
            return dispatch('setLensReplacementPageData', null);
        }
    },
    setLensReplacementPageData({ commit }, payload) {
        commit(SET_LENS_REPLACEMENT_PAGE_DATA, payload);
        return Promise.resolve();
    },
    addSelectedAddons({ commit, state }, payload) {
        let addons = [];
        let found = false;
        for (let x = 0; x < state.selectedAddons.length; x++) {
            let addon = state.selectedAddons[x];
            if (!addon?.id) {
                continue;
            }

            if (payload?.id === addon?.id) {
                found = true;
                continue;
            }

            addons.push(addon);
        }

        if (!found) {
            addons.push(payload);
        }

        commit(SET_SELECTED_ADDONS, addons);
        return Promise.resolve();
    },
    removeSelectedAddons({ commit, state }, payload) {
        let addons = [];

        for (let x = 0; x < state.selectedAddons.length; x++) {
            let addon = state.selectedAddons[x];
            if (!addon?.id) {
                continue;
            }

            if (payload?.id === addon?.id) {
                continue;
            }

            addons.push(addon);
        }

        commit(SET_SELECTED_ADDONS, addons);
        return Promise.resolve();
    },
    setDeterminingFirstStep({ commit }, payload) {
        commit(SET_DETERMINING_FIRST_STEP, payload);
        return Promise.resolve();
    },
    setSelectedAddons({ commit }, payload) {
        commit(SET_SELECTED_ADDONS, payload);
        return Promise.resolve();
    },
    setExistingAddonPrice({ commit }, payload) {
        commit(SET_EXISTING_ADDON_PRICE, payload);
        return Promise.resolve();
    },
    setAdditionalPrice({ commit }, payload) {
        commit(SET_ADDITIONAL_PRICE, payload);
        return Promise.resolve();
    },
    resetState({ commit }) {
        commit(RESET_STATE);
        return Promise.resolve();
    },
    resetStorage({ commit }) {
        commit(SET_ADDITIONAL_PRICE, 0);
        storage.set('brandStyle', null);
        storage.set('oversized', null);
        storage.set('largeLenses', null);
        storage.set('wraps', null);
        storage.set('framePhoto', null);
        storage.set('additionalInformation', null);
        storage.set('prescriptionInputType', null);
        storage.set('selectedPatient', null);
        storage.set('newPatient', null);
        storage.set('prescriptionImage', null);
        storage.set('prescriptionId', null);
        storage.set('eyewearFlowCachedProductChoices', null);
        storage.set('selectedAddons', null);
        storage.set('engravingText', '');
        storage.set('engravingFontFamily', '');

        return Promise.resolve();
    },
    setIsLoadingChoices({ commit }, payload) {
        commit(SET_IS_LOADING_CHOICES, payload);
        return Promise.resolve();
    },
    setBaseProduct({ commit }, payload) {
        commit(SET_BASE_PRODUCT, payload);
        return Promise.resolve();
    },
    setCurrentStep({ commit }, payload) {
        commit(SET_CURRENT_STEP, payload);
        return Promise.resolve();
    },
    setChoices({ commit }, payload) {
        commit(SET_CHOICES, payload);
        return Promise.resolve();
    },
    setSteps({ commit }, payload) {
        commit(SET_STEPS, payload);
        return Promise.resolve();
    },
    setSelectedChoices({ commit }, payload) {
        commit(SET_SELECTED_CHOICES, payload);
        return Promise.resolve();
    },
    setEstimatedProduct({ commit }, payload) {
        commit(SET_ESTIMATED_PRODUCT, payload);
        return Promise.resolve();
    },
    async loadNextStep({ dispatch, state }, { queryParams, flowStep }) {
        dispatch('setIsLoadingChoices', true);
        try {

            queryParams.flowStep = flowStep.id;

            if (state.baseProduct) {
                queryParams.baseProduct = state.baseProduct.id;
            }

            await dispatch('setChoices', []);

            let response = await this.$axios.$get(
                '/eyewear-flow', {
                    params: queryParams
                }
            );

            let promises = [];
            promises.push(dispatch('setExistingAddonPrice', response.addonPrice));
            promises.push(dispatch('setAdditionalPrice', response.addonPrice));
            promises.push(dispatch('setCurrentStep', flowStep));
            promises.push(dispatch('setChoices', response.choices));
            promises.push(dispatch('setSteps', response.steps));
            promises.push(dispatch('setSelectedChoices', response.selectedChoices));
            promises.push(dispatch('setEstimatedProduct', response.product));
            promises.push(dispatch('setSelectedAddons', response.selectedAddons));
            dispatch('setIsLoadingChoices', false);
            return Promise.all(promises);
        } catch (e) {
            console.error(e);
        }

        dispatch('setIsLoadingChoices', false);

        return Promise.reject();
    },
    async loadNextSteps({ dispatch, state }, { queryParams, flowStep }) {
        queryParams.flowStep = flowStep.id;

        if (state.baseProduct) {
            queryParams.baseProduct = state.baseProduct.id;
        }

        try {
            let response = await this.$axios.$get(
                '/eyewear-flow', {
                    params: queryParams
                }
            );

            dispatch('setSteps', response.steps);
        } catch (e) {
            // Prevent issues loading steps
        }
    }
};
