export default {
    CR39: {
        id: 1,
        name: 'CR39'
    },
    POLYCARBONATE: {
        id: 2,
        name: 'Polycarbonate'
    },
    TRIVEX: {
        id: 3,
        name: 'Trivex'
    },
    HIGH_INDEX_167: {
        id: 4,
        name: 'High Index 1.67'
    },
    HIGH_INDEX_174: {
        id: 5,
        name: 'High Index 1.74'
    }
};
