<template>
    <div id="algoliaAutocomplete"></div>
</template>

<script>

import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';

import algoliaAutoCompleteItemTemplate from '@/third-party/algolia/algolia-autocomplete-item-template';
import algoliaAutoCompleteFooterTemplate from '@/third-party/algolia/algolia-autocomplete-footer-template';
import { getViewAllEndpoints } from '@/third-party/algolia/algolia-helpers';

export default {
    mounted() {
        document.body.addEventListener('algoliaClickedLink', this.onClickSearchResult);
        window.addEventListener('resize', this.onResize);

        if (window.innerWidth > 1060) {
            this.setupAlgolia();
        }
    },
    destroyed() {
        document.body.removeEventListener('algoliaClickedLink', this.onClickSearchResult);
        window.removeEventListener('resize', this.onResize);
    },
    data() {
        return {
            handleAlgoliaOpenState: null,
            algoliaSearch: null
        }
    },
    methods: {
        onResize() {
            if (!this.algoliaSearch && window.innerWidth > 1060) {
                this.setupAlgolia();
                return;
            }

            if (this.algoliaSearch && window.innerWidth <= 1060) {
                this.algoliaSearch.destroy();
                this.algoliaSearch = null;
            }
        },
        setupAlgolia() {
            if (this.algoliaSearch) {
                this.algoliaSearch.destroy();
            }

            const searchClient = algoliasearch(this.$config.algolia.appId, this.$config.algolia.apiKey);

            this.algoliaSearch = autocomplete({
                container: '#algoliaAutocomplete',
                placeholder: 'I\'m searching for...',
                classNames: {
                    form: 'searchProduct'
                },
                panelPlacement: 'end',
                getSources: ({ query, setIsOpen, setContext }) => {
                    const algoliaIndex = this.$config.algolia.index;
                    this.handleAlgoliaOpenState = setIsOpen;
                    return [
                        {
                            sourceId: 'products',
                            getItems: () => {
                                return getAlgoliaResults({
                                        searchClient,
                                        queries: [
                                            {
                                                indexName: algoliaIndex,
                                                query,
                                                params: {
                                                    hitsPerPage: 5
                                                }
                                            }
                                        ],
                                        transformResponse: ({ results, hits }) => {
                                            setContext({
                                                nbProducts: results[0].nbHits,
                                                viewAllPath: getViewAllEndpoints(results[0].hits)
                                            });

                                            return hits;
                                        }
                                    });
                            },
                            templates: {
                                header({ state }) {
                                    return state.context.nbProducts + ' Results total';
                                },
                                footer: algoliaAutoCompleteFooterTemplate,
                                item: algoliaAutoCompleteItemTemplate
                            }
                        }
                    ];
                }
            });
        },
        onClickSearchResult(e) {
            this.$router.push(e.detail);

            if (this.handleAlgoliaOpenState) {
                this.handleAlgoliaOpenState(false);
            }
        }
    }
}
</script>
<style>
#algoliaAutocomplete {
    --aa-selected-color-rgb: "244,247,249";
    --aa-selected-color-alpha: "1";
}
</style>
<style lang="scss">
@import '~/assets/scss/variables/color.scss';

#algoliaAutocomplete {
    .aa-Form {
        border: 0px;
        width: auto;
        box-shadow: none !important;
    }
    .aa-InputWrapper {
        border-radius: 22px;
    }
    .aa-SubmitButton {
        display: none;
    }
    .aa-InputWrapperSuffix {
        position: absolute;
        right: 0px;
        z-index: 5;
    }
}

.aa-Panel {
    z-index: 1000;
    border: 0px;
    min-width: 500px;
    box-shadow: 0px 33px 26px $box-shadow;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    .aa-Panel--scrollable {
        padding-left: 0px;
        padding-right: 0px;
    }
    .aa-ItemIcon {
        height: 70px;
        width: 70px;
        border: 0px;
        box-shadow: none;
        background-color: transparent;
        img {
            max-width: none;
            max-height: none;
            width: 100%;
            mix-blend-mode: darken;
        }
    }
    .aa-SourceHeader,
    .aa-Item {
        padding-left: 15px;
        padding-right: 15px;
        &:hover {
            text-decoration: underline;
        }
        a {
            color: #000000;
        }
    }
    .aa-Item[aria-selected=true] {
        background-color: #F4F7F9;
    }
    .aa-SourceFooter {
        padding: 15px;
        font-weight: bold;
        text-align: center;
    }
}
</style>
