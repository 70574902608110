<template>
    <div class="free-lens-upgrades-container">
        Free lens upgrades included:
        <div class="free-lens-upgrade">
            <img :src="formatAssetUrl('images/uv.svg')" alt="uv icon" />
            <div>UV Protection</div>
        </div>
        <div class="free-lens-upgrade">
            <img :src="formatAssetUrl('images/antirefl.svg')" alt="anti-reflective icon" />
            <div>Anti-reflective</div>
        </div>
        <div class="free-lens-upgrade">
            <img :src="formatAssetUrl('images/scratchres.svg')" alt="scratch icon" />
            <div>Scratch Resistant</div>
        </div>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
export default {
    mixins: [formatImageMixin]
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

.free-lens-upgrades-container {
    position: fixed;
    background-color: #f4f7f9;
    bottom: 0px;
    right: 0px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 14px;
    color: #515e68;
    height: 50px;
    border-top: solid $color-grey-border 1px;
    box-shadow: 0px -2px 24px #dce3e86e;
    .free-lens-upgrade {
        display: flex;
        gap: 10px;
        color: #000000;
        font-weight: 900;
        align-items: center;
        img {
            width: 36px;
            height: 36px;
        }
    }
}

@media (max-width: $desktopBreakpoint2) {
    .free-lens-upgrades-container {
        font-size: 13px;
        .free-lens-upgrade {
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .free-lens-upgrades-container {
        display: none;
    }
}
</style>
