<template>
    <CustomButton
        type="button"
        class="btn-amazon-login"
        :isLoading="isLoading"
        @click="onClickLogin"
    >
        <font-awesome-icon icon="fa-brands fa-amazon" aria-label="Amazon" alt="Amazon" />
        <div class="button-text">
            <slot>
                Sign in with Amazon
            </slot>
        </div>
    </CustomButton>
</template>

<script>
import storage from '@/utils/storage';

export default {
    props: {
        redirectPath: {
            type: String,
            default: '/profile/account-snapshot'
        },
        failRedirectPath: {
            type: String,
            default: '/user/login'
        },
        connectExisting: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false
        };
    },
    methods: {
        onClickLogin() {
            if (typeof window.amazon === 'undefined') {
                return;
            }

            let options = {
                scope: 'profile',
                scope_data: {
                    profile: {
                        essential: false
                    }
                }
            };

            storage.set('amazonConnectExisting', this.connectExisting ? 1 : 0);
            storage.set('amazonLoginRedirect', this.redirectPath);
            storage.set('amazonLoginFailRedirect', this.failRedirectPath)
            window.amazon.Login.authorize(options, this.$config.frontendURL + '/amazon-login');
        }
    }
}
</script>

<style lang="scss" scoped>
    .btn-amazon-login {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border: 0px;
        padding: 15px 10px;
        width: 100%;
        border-radius: 5px;
        color: #000000;
        border-radius: 5px;
        width: 100%;
        background-color: #fad676;
        font-size: 18px;
        &:hover {
            background-color: #ffce4a;
        }
        .fa-amazon {
            position: absolute;
            font-size: 24px;
            left: 15px;
            top: 18px;
            margin-right: auto;
        }
    }
</style>
