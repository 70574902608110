<template>
    <div class="mobile-summary-wrapper">
        <div class="tab-label" v-if="discountedPrice > 0 && !showSummary">Total: <strong>${{ formatMoney(discountedPrice) }}</strong></div>
        <a href="#" @click.prevent="onClickShowSummary" v-if="!showSummary" class="tab-label">
            <strong>+ Show details</strong>
        </a>

        <MobileSlideUp v-if="showSummary" :hide="hideSummary">
            <template #header>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="tab-label" v-if="discountedPrice > 0">Total: <strong>${{ formatMoney(discountedPrice) }}</strong></div>
                    <a href="#" @click.prevent="onClickCloseSummary" class="tab-label">
                        <strong>- Hide details</strong>
                    </a>
                </div>
            </template>
            <div v-if="product">
                <Sample
                    class="sample-container"
                />
            </div>
        </MobileSlideUp>
    </div>
</template>

<script>
import formatMoneyMixin from '@/mixins/money-formatter';
import MobileSlideUp from '@/components/MobileSlideUp';
import Sample from '@/components/landing-page/select-lenses/Sample';

export default {
    mixins: [formatMoneyMixin],
    components: {
        Sample,
        MobileSlideUp
    },
    props: {
        estimatedProduct: {
            type: Object
        },
        additionalPrice: {
            type: Number,
            default: 0
        },
        product: {
            type: Object
        },
        selectedChoices: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            showSummary: false,
            hideSummary: false
        };
    },
    computed: {
        discountedPrice(){
            return this.totalPrice * .8;
        },
        totalPrice() {
            if (!this.estimatedProduct) {
                return 0;
            }

            return this.estimatedProduct.price + this.additionalPrice;
        }
    },
    methods: {
        onClickShowSummary() {
            this.showSummary = true;
            this.hideSummary = false;
        },
        onClickCloseSummary() {
            this.hideSummary = true;

            window.setTimeout(() => {
                this.showSummary = false;
            }, 250);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

.mobile-summary-wrapper {
    display: none;
}

@media (max-width: $breakpoint2) {
    .mobile-summary-wrapper {
        display: flex;
        z-index: 10000;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 56px;
        font-size: 16px;
        background-color: #ffffff;
        padding: 0px 20px;
        box-shadow: 0px 9px 26px #00000017;
        .tab-label {
            font-size: 16px;
            strong {
                font-size: 18px;
            }
        }
        a {
            color: #000000;
            font-weight: bold;
            font-size: 18px;
        }
        .text-muted {
            color: $color-grey-input !important;
        }

        ::v-deep {
            .sample-container {
                max-height: 80vh;
                overflow: auto;
                overscroll-behavior: contain;

                .prescription-desc {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                }

                .preview-image {
                    margin-bottom: 30px;
                }
            }
        }
    }
}
</style>
