<template>
    <div class="panel-content global-banner-sale">
        <div v-show="!isGlasses && !isSunglasses && !isLensReplacement && !isContactLenses" @click="isModalOpen = true" class="cursor-hand">
            <div class="hidden-mobile"><span class="emoji">⚡</span> New customer? Save up to 20% | <span class="hover-underline">Learn more</span></div>
            <div class="hidden-desktop"><span class="emoji">⚡</span> New customer? Save up to 20% | Learn more</div>
        </div>
        <div v-show="isGlasses || isSunglasses || isLensReplacement || isContactLenses">
            <p>
                <span class="d-none d-sm-inline-block">
                    <span class="emoji">{{ globalBannerEmoji }}</span> {{ globalBannerInitialContent }}
                </span> <span class="d-inline-block d-sm-none"><span class="emoji">{{ globalBannerEmoji }}</span> {{ globalBannerInitialMobileContent }}</span> {{ globalBannerPercent }} <span class="hidden-mobile">{{ globalBannerIncludeFreeShippingContent }}</span> <span class="d-inline-block"><span class="d-inline-block d-sm-none">|</span><span class="d-none d-sm-inline-block">w/ code </span> <span class="highlight">{{ globalBannerCouponCode }}</span></span>
            </p>
        </div>
        <Modal v-model="isModalOpen" additionalDialogClass="global-banner-sale-modal" @close="isModalOpen = false">
            <h2>First order? Save money & get free shipping</h2>
            <p class="font-sm-mobile">
                20% off Contacts w/ <strong class="highlight">CONTACTSNEW20</strong><br />
                20% off Lens Replacement w/ <strong class="highlight">REPLACE20</strong><br />
                20% off Glasses w/ <strong class="highlight">EYE20</strong><br />
                20% off Sunglasses w/ <strong class="highlight">SUN20</strong><br />
                15% off Existing Customers w/ <strong class="highlight">SAVE15</strong>
            </p>
            <p class="font-sm-mobile">
                Did you know we've got a rewards program that lets you earn with each purchase?<br />
                5x points per dollar on contacts<br />
                10x points per dollar on all other purchases<br />
            </p>
            <p class="small">* Discount does not apply to DIY lens replacement, $34 discounted frames, accessories, online vision test. Reward points do apply.</p>
        </Modal>
    </div>
</template>

<script>
import productTypes from '@/constants/product-types';
import Modal from '@/components/modals/Modal';

export default {
    components: {
        Modal
    },
    props: {
        globalBannerProductType: { type: Number, default: 0 }
    },
    data() {
        return { isModalOpen: false };
    },
    computed: {
        isNewCustomer() {
            if (!this.$store.state.auth.currentUser?.customer) {
                return true;
            }
            return this.$store.state.auth.currentUser?.customer?.isNewCustomer;
        },
        isGlobalHomepage() {
            return this.globalBannerProductType === 0;
        },
        isContactLenses() {
            return this.globalBannerProductType === productTypes.CONTACT_LENSES.id;
        },
        isGlasses() {
            return this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.READERS.id;
        },
        isSunglasses() {
            return this.globalBannerProductType === productTypes.SUNGLASSES.id;
        },
        isLensReplacement() {
            return this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id;
        },
        isDiyLensReplacement() {
            return this.globalBannerProductType === productTypes.DIY_LENSES.id;
        },
        isAccessories() {
            return this.globalBannerProductType === productTypes.ACCESSORIES.id;
        },
        globalBannerPercent() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return '20%';
            }

            if (this.globalBannerProductType === productTypes.SUNGLASSES.id) {
                return '20%';
            }

            if (this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id) {
                return '20%';
            }

            if (this.globalBannerProductType === productTypes.CONTACT_LENSES.id) {
                return this.isNewCustomer ? '20%' : '15%';
            }

            return '15%';
        },
        globalBannerCouponCode() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return 'EYE20';
            }

            if (this.globalBannerProductType === productTypes.SUNGLASSES.id) {
                return 'SUN20';
            }

            if (this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id) {
                return 'REPLACE20';
            }

            if (this.globalBannerProductType === productTypes.CONTACT_LENSES.id) {
                return this.isNewCustomer ? 'CONTACTSNEW20' : 'CONTACTS15';
            }

            return 'SAVE15';
        },
        globalBannerSaveOnProduct() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return 'glasses';
            }

            if (this.globalBannerProductType === productTypes.SUNGLASSES.id) {
                return 'sunglasses';
            }

            if (this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id) {
                return 'lens replacement';
            }

            if (this.globalBannerProductType === productTypes.CONTACT_LENSES.id) {
                return 'contacts';
            }

            return 'all orders';
        },
        globalBannerUseCodeContent() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.SUNGLASSES.id ||
                this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id ||
                this.globalBannerProductType === productTypes.CONTACT_LENSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return 'Use code:';
            }

            return '';
        },
        globalBannerInitialMobileContent() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.SUNGLASSES.id ||
                this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id ||
                this.globalBannerProductType === productTypes.CONTACT_LENSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return 'Save';
            }

            return '';
        },
        globalBannerEmoji() {
            if (this.globalBannerProductType === productTypes.CONTACT_LENSES.id) {
                return '👀';
            }

            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return '🤓';
            }

            if (this.globalBannerProductType === productTypes.SUNGLASSES.id) {
                return '😎';
            }

            if (this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id) {
                return '🤩';
            }

            return '';
        },
        globalBannerInitialContent() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.SUNGLASSES.id ||
                this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id ||
                this.globalBannerProductType === productTypes.CONTACT_LENSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return 'Save ';
            }

            return 'New Customer Offer!';
        },
        globalBannerIncludeFreeShippingContent() {
            if (
                this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.SUNGLASSES.id ||
                this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id ||
                this.globalBannerProductType === productTypes.CONTACT_LENSES.id ||
                this.globalBannerProductType === productTypes.READERS.id
            ) {
                return '+ free shipping';
            }

            return '';
        },
    }
};
</script>

<style lang="scss" scoped>
.cursor-hand {
    cursor: pointer;
    color: #fff;
}
.panel-content {
    color: #ffffff;
    font-size: 15px;
    line-height: normal;
}
</style>

<style lang="scss">
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/base-url.scss';

.panel-content.global-banner-sale {
    .highlight {
        color: #ffffff;
        font-weight: bold;
    }
    .emoji {
        font-family: 'Noto Color Emoji', sans-serif;
    }
    &:hover {
        .hover-underline {
            text-decoration: underline;
        }
    }
}

.global-banner-sale-modal {
    text-align: center;
    .emoji {
        font-family: 'Noto Color Emoji', sans-serif;
    }
    h2 {
        font-size: 36px;
        text-align: center;
        line-height: 1.2em;
    }
    p {
        font-size: 24px;
        &.small {
            font-size: 12px;
        }
    }
    .modal-content {
        background-color: #2c5284;
        color: #ffffff;
        .highlight {
            color: #ffe200;
        }
        .close-button {
            font-size: 24px !important;
            color: #ffffff !important;
            &:hover {
                color: #f2f2f2 !important;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .cursor-hand {
        font-size: 14px;
    }
    .global-banner-sale-modal {
        h2 {
            font-size: 28px;
            line-height: 1.2em;
            padding-bottom: 10px;
        }
        p {
            font-size: 18px;
        }
        .modal-content {
            .modal-body {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    .font-sm-mobile {
        font-size: 16px !important;
    }
}
</style>
