export default function() {
    if (typeof window === 'undefined') {
        return;
    }

    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://s.skimresources.com/js/219901X1701083.skimlinks.js";
        js.type = "text/javascript"
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'skimlinks-script'));
}
