<template>
    <!-- Start Partner Area -->
    <div class="filterBrand">
        <div class="partner-slides owl-carousel owl-theme">
            <ssr-carousel
                :loop="overrideLoop"
                :slides-per-page="slidesPerPage"
                :responsive="responsiveConfiguration"
                :gutter="overrideGutter"
            >
                <div class="slide" v-for="(brand, index) in brands" :key="index">
                    <nuxt-link :to="'/' + brand.slug" class="brand" :class="getClass(brand)">
                        <div class="d-flex align-items-center justify-content-center h-100">
                            <img
                                :src="formatAssetUrl('images/' + brand.image)"
                                :alt="brand.name"
                                :class="brand.slug"
                            />
                        </div>
                    </nuxt-link>
                </div>
                <div class="slide">
                    <nuxt-link to="/contact-lenses" class="brand">
                        <div class="d-flex align-items-center justify-content-center h-100">
                            <strong>All brands</strong>
                        </div>
                    </nuxt-link>
                </div>
            </ssr-carousel>
        </div>
    </div>
    <!-- End Partner Area -->
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    name: 'Brand',
    mixins: [formatImageMixin],
    props: {
        overrideResponsiveConfiguration: {
            type: Array,
            default: () => ([])
        },
        overrideLoop: {
            type: Boolean,
            default: true
        },
        overrideSlidesPerPage: {
            type: Number,
            default: 8
        },
        overrideGutter: {
            type: Number,
            default: 10
        }
    },
    computed: {
        slidesPerPage() {
            if (this.overrideSlidesPerPage) {
                return this.overrideSlidesPerPage;
            }

            return null;
        },
        responsiveConfiguration() {
            if (this.overrideResponsiveConfiguration.length) {
                return this.overrideResponsiveConfiguration;
            }

            return [
                {
                    maxWidth: 1880,
                    slidesPerPage: 7
                },
                {
                    maxWidth: 1700,
                    slidesPerPage: 6
                },
                {
                    maxWidth: 1536,
                    slidesPerPage: 4
                },
                {
                    maxWidth: 1366,
                    slidesPerPage: 3
                },
                {
                    maxWidth: 1060,
                    slidesPerPage: 4,
                    peekRight: 20
                }
            ];
        }
    },
    methods: {
        getClass(brand) {
            return brand.slug === this.$route.name ? 'selected-brand' : '';
        }
    },
    data() {
        return {
            brands: [
                { name: 'Acuvue', alt: 'Acuvue', image: 'brand-logos/acuvue-black.png', slug: 'acuvue-contacts' },
                { name: 'Air Optix', alt: 'Air Optix', image: 'brand-logos/air-optix-black.png', slug: 'air-optix' },
                { name: 'Biofinity', alt: 'Biofinity', image: 'brand-logos/biofinity-black.png', slug: 'biofinity' },
                { name: 'Dailies', alt: 'Dailies', image: 'brand-logos/dailies-black.png', slug: 'dailies' },
                {
                    name: 'Bausch & Lomb',
                    alt: 'Bausch & Lomb',
                    image: 'brand-logos/bausch-lomb-black.png',
                    slug: 'bausch-and-lomb'
                },


                { name: 'Clariti', alt: 'Clariti', image: 'brand-logos/clariti-black.png', slug: 'clariti' },
                { name: 'Freshlook', alt: 'Freshlook', image: 'brand-logos/freshlook-black.png', slug: 'freshlook' },
                {
                    name: 'Purevision',
                    alt: 'Purevision',
                    image: 'brand-logos/purevision-black.svg',
                    slug: 'purevision'
                },

                {
                    name: 'Precision1',
                    alt: 'Precision1',
                    image: 'brand-logos/precision1-black.svg',
                    slug: 'precision-1'
                },
                { name: 'Soflens', alt: 'Soflens', image: 'brand-logos/soflens-black.svg', slug: 'soflens' },
                { name: 'Avaira', alt: 'Avaira', image: 'brand-logos/avaira-black.svg', slug: 'avaira' },
                { name: 'Biomedics', alt: 'Biomedics', image: 'brand-logos/biomedics-black.svg', slug: 'biomedics' },
                { name: 'Focus', alt: 'Focus', image: 'brand-logos/focus-black.svg', slug: 'focus' },
                { name: 'Proclear', alt: 'Proclear', image: 'brand-logos/proclear-black.svg', slug: 'proclear' },
                { name: 'Ultra', alt: 'Ultra', image: 'brand-logos/ultra-black.svg', slug: 'ultra' }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.filterBrand {
    width: 60%;
    padding-left: 10px;
    padding-top: 0px;
    .brand {
        min-width: 120px;
        background-color: transparent;
        border: solid $color-grey-border 1px;
        height: 55px;
        border-radius: 100px;
        padding: 0px 10px;
        &:hover {
            background-color: #ffffff;
            border: solid transparent 1px;
        }
        .acuvue-contacts {
            max-height: 17px;
        }
        .air-optix {
            max-height: 25px;
        }
        .dailies {
            max-height: 16px;
        }
        .biofinity {
            margin-top: 5px;
            max-height: 23px;
        }
        .clariti {
            max-height: 18px;
        }
        .freshlook {
            max-height: 25px;
        }
    }
    .selected-brand {
        background-color: #ffffff;
        border: solid $color-blue 1px !important;
    }

    ::v-deep {
        .ssr-carousel-mask {
            margin-right: -10px;
            padding-right: 10px;
        }
    }
}

@media (max-width: 1880px) {
    .filterBrand {
        width: 55%;
        max-width: 1020px;
    }
}

@media (max-width: 1700px) {
    .filterBrand {
        width: 52%;
        max-width: 980px;
    }
}

@media (max-width: $desktopBreakpoint1) {
    .filterBrand {
        width: 50%;
        max-width: 667px;
    }
}

@media (max-width: 1500px) {
    .filterBrand {
        max-width: 600px;
    }
}

@media (max-width: $desktopBreakpoint2) {
    .filterBrand {
        max-width: 593px;
    }
}

@media (max-width: 1400px) {
    .filterBrand {
        max-width: 540px;
    }
}

@media (max-width: $desktopBreakpoint3) {
    .filterBrand {
        max-width: none;
        width: 42%;
    }
}


@media (max-width: $breakpoint3) {
    .filterBrand {
        width: calc(100% + 1rem);
        order: 3;
        padding-left: 0px;
        padding-top: 15px;
        padding-right: 10px;
        margin-right: -1rem;
        .brand {
            min-width: 80px;
            height: 32px;
            font-size: 12px;
            line-height: 1em;
            font-weight: bold;
            img {
                max-width: 100%;
            }
            .acuvue-contacts {
                max-height: none;
            }
        }
    }
}
</style>
