import prescriptionDetailFormatterMixin from '@/mixins/prescription-detail-formatter';
import flowSteps from '@/constants/eyewear-flow-steps';
import storage from '@/utils/storage';

export default {
    mixins: [prescriptionDetailFormatterMixin],
    computed: {
        rxTypeChoiceValueObject() {
            let selectedChoice = this.getSelectedChoice('rxTypeVariation');
            if (!selectedChoice) {
                return this.getSelectedChoice('rxType');
            }

            return {
                value: selectedChoice?.choice?.rxType?.name + ' ' + selectedChoice?.choice?.content?.shortName
            }
        },
        stepDetails() {
            let stepDetails = [];
            for (let x = 0; x < this.steps.length; x++) {
                let step = this.steps[x];
                if (step === 'frameDetails') {
                    continue;
                }

                if (step === 'lensTypeVariation') {
                    // Will use "Lens Color" as the step
                    continue;
                }

                if (step === 'enterRx') {
                    this.appendEnterRxStep(stepDetails);
                    continue;
                }

                if (step === 'lensPower') {
                    this.appendLensPower(stepDetails);
                    continue;
                }

                let selectedChoice = this.getSelectedChoice(step);
                if (step === 'rxType' || step === 'rxTypeVariation') {
                    selectedChoice = this.rxTypeChoiceValueObject;
                }

                if (!selectedChoice && step === 'lensType') {
                    selectedChoice = this.getSelectedChoice('lensTypeVariation');
                }

                if (selectedChoice && step === 'lensCoating') {
                    selectedChoice = this.getUpgradesValueObject(selectedChoice);
                }

                stepDetails.push({
                    name: this.getStepName(step),
                    value: selectedChoice ? selectedChoice.value : ''
                });

                if (step !== 'lensType') {
                    continue;
                }


                let lensTypeVariationObject = this.getLensTypeVariationValueObject();
                if (!lensTypeVariationObject) {
                    continue;
                }

                stepDetails.push(lensTypeVariationObject);
            }

            return stepDetails;
        }
    },
    methods: {
        getSelectedChoice(stepId) {
            for (let x = 0; x < this.selectedChoices.length; x++) {
                let selectedChoice = this.selectedChoices[x];
                if (selectedChoice?.choice.stepTypeProperty !== stepId) {
                    continue;
                }

                return selectedChoice;
            }

            return null;
        },
        appendLensPower(stepDetails) {
            if (!this.currentStep) {
                return;
            }

            if (this.currentStep && this.currentStep.id === 'lensPower') {
                return;
            }

            let lensPower = storage.get('lensPower');
            if (lensPower === null) {
                return;
            }

            let foundLensPowerStep = false;
            for (let x = 0; x < this.steps.length; x++) {
                if (this.steps[x] === this.currentStep.id) {
                    break;
                }

                if (this.steps[x] === 'lensPower') {
                    foundLensPowerStep = true;
                }
            }

            if (!foundLensPowerStep) {
                return;
            }

            stepDetails.push({
                value: this.formatPrescriptionValue('power', lensPower),
                name: 'Lens power'
            });
        },
        getStepName(stepId) {
            for (let x in flowSteps) {
                if (flowSteps[x].id !== stepId) {
                    continue;
                }

                if (flowSteps[x].name === 'Lens coating') {
                    return 'Upgrades';
                }

                return flowSteps[x].name;
            }

            return '';
        },
        getUpgradesValueObject(selectedCoatingChoice) {
            if (!selectedCoatingChoice) {
                return null;
            }

            let selectedAddons = this.$store.state.eyewearFlow.selectedAddons;
            let names = [selectedCoatingChoice.value];
            for (let x = 0; x < selectedAddons.length; x++) {
                let selectedAddon = selectedAddons[x];
                if (selectedAddon.name === 'Prism') {
                    continue;
                }

                names.push(selectedAddon.name);
            }

            return {
                value: names.join(', ')
            }
        },
        appendEnterRxStep(stepDetails) {
            if (!this.currentStep) {
                return;
            }

            if (this.currentStep && this.currentStep.id === 'enterRx') {
                return;
            }

            let rxInputType = storage.get('prescriptionInputType');
            if (!rxInputType) {
                return;
            }

            let foundEnterRxStep = false;
            for (let x = 0; x < this.steps.length; x++) {
                if (this.steps[x] === this.currentStep.id) {
                    break;
                }

                if (this.steps[x] === 'enterRx') {
                    foundEnterRxStep = true;
                }
            }

            if (!foundEnterRxStep) {
                return;
            }

            stepDetails.push({
                value: rxInputType.name,
                name: 'Prescription'
            });
        },
        getLensTypeVariationValueObject() {
            let selectedChoice = this.getSelectedChoice('lensTypeVariation');
            if (!selectedChoice) {
                return null;
            }

            return {
                choice: selectedChoice.choice,
                name: 'Lens color',
                value: selectedChoice.choice?.displayName ? selectedChoice.choice.displayName : selectedChoice.value
            }
        },
    }
}
