export default {
    methods: {
        formatImageUrl(url) {
            return 'https://assets.lensdirect.com/' + url;
        },
        formatAssetUrl(url) {
            return 'https://assets.lensdirect.com/assets/2.0/' + url;
        },
        formatProductImageUrl(url, size = 'full', override = '') {
            // @todo Backend doesn't currently upload to size directories so
            // forcing size to full
            size = size === 'thumbnails' ? size : (override ? override : 'full');
            return 'https://assets.lensdirect.com/uploads/images/' + size + '/' + url;
        },
        formatProductImageUrlResized(url, size) {
            return this.$config.apiBaseURL + '/product/image-resize/' + size + '?image=' + url;
        },
        formatTemporaryPrescriptionImageUrl(url) {
            return this.$config.apiBaseURL + '/customer/prescription/image/' + url;
        },
        formatPrescriptionImageUrl(id) {
            return this.$config.apiBaseURL + '/customer/prescription/' + id + '/image';
        },
        formatCategoryBannerUrl(url) {
            return 'https://assets.lensdirect.com/uploads/images/banners/' + url;
        },
        formatSwatchUrl(url) {
            return 'https://assets.lensdirect.com/uploads/images/swatches/' + url;
        },
        formatCategoryFilterImageUrl(url) {
            return 'https://assets.lensdirect.com/uploads/images/category-filter/' + url;
        },
        websiteLogo() {
            return this.formatImageUrl('assets/images/header/logo-20200522.svg');
        },
        websiteLogoCss() {
            // Include any additional css classes here for the website logo
            return '';
        }
    }
}
