export default function (zipWidgetUrl) {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.quadpay !== 'undefined') {
        // Already loaded
        return;
    }

    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = zipWidgetUrl;
        js.type = "text/javascript"
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'zip-pay-widget-script'));
}
