const SET_MOBILE_MENU_OPEN = 'Set mobile menu open';
const SET_TRUSTPILOT_DATA = 'Set Trustpilot Data';
const SET_GLOBAL_BANNER_PRODUCT_TYPE_ID = 'Set Global Banner Product Type ID';
const SET_GLOBAL_BANNER_PRODUCT_TYPE_TIMEOUT = 'Set Global Banner Product Type Timeout';
const SET_THEME_SOURCE = 'Set Theme Source';
const SET_SHOW_REORDER_LOGIN = 'Set Show Reorder Login';
const SET_GLOBAL_BANNER_MODAL_OPEN = 'Set global banner modal open';

import itemGroupFormatter from '@/utils/item-group-formatter';
import paymentMethodTypes from '@/constants/payment-method-types';
import { pinterestTrackAddToCart } from '@/third-party/pinterest';
import { quoraTrackAddToCart } from '@/third-party/quora';
import { googleTrackAddToCart } from '@/third-party/google';
import storage from '@/utils/storage';

export const state = () => ({
    cart: null,
    totalAmount: 0,
    totalQuantity: 0,
    orders: [],
    categorySlug: "",
    categoryTitle: "",
    categoryData: {},
    categoryProducts: [],
    counter: 0,
    guestPatients: [],
    productDataCancelToken: null,
    mobileMenuOpen: false,
    trustpilotData: null,
    globalBannerProductTypeId: 0,
    globalBannerProductTypeTimeout: 0,
    themeSource: '',
    globalBannerModalOpen: false,
    showReorderLogin: false
});

export const totals = (paylodArr) => {
    const totalAmount = paylodArr.map(cartArr => {
        return cartArr.price * cartArr.quantity
    }).reduce((a, b) => a + b, 0);

    const totalQuantity = paylodArr.map(cartArr => {
        return cartArr.quantity;
    }).reduce((a, b) => a + b, 0);

    return {
        amount: totalAmount.toFixed(2),
        qty: totalQuantity
    }
};

export const mutations = {
    [SET_THEME_SOURCE](state, payload) {
        state.themeSource = payload;
    },
    [SET_GLOBAL_BANNER_MODAL_OPEN](state, payload) {
        state.globalBannerModalOpen = payload;
    },
    [SET_SHOW_REORDER_LOGIN](state, payload) {
        state.showReorderLogin = payload;
    },
    [SET_GLOBAL_BANNER_PRODUCT_TYPE_TIMEOUT](state, payload) {
        state.globalBannerProductTypeTimeout = payload;
    },
    [SET_GLOBAL_BANNER_PRODUCT_TYPE_ID](state, payload) {
        state.globalBannerProductTypeId = payload;
    },
    [SET_TRUSTPILOT_DATA](state, payload) {
        state.trustpilotData = payload;
    },
    [SET_MOBILE_MENU_OPEN](state, status) {
        state.mobileMenuOpen = status;
    },
    increment(state) {
        state.counter++
    },
    'SET_CART'(state, payload) {
        state.cart = payload;
    },
    'GET_ORDER'(state, payload) {
        state.orders = payload
    },
    'GET_CART'(state, payload) {
        state.cart = payload
        state.totalAmount = totals(payload).amount
        state.totalQuantity = totals(payload).qty
    },
    'ADD_TO_CART'(state, payload) {
        state.cart = [...state.cart, ...payload]
        state.totalAmount = totals(state.cart).amount
        state.totalQuantity = totals(state.cart).qty
    },
    'DELETE_CART'(state, id) {
        const currentCartToDelete = state.cart
        const indexToDelete = currentCartToDelete.findIndex(cart => {
            return cart.id == id
        })

        state.cart = [...currentCartToDelete.slice(0, indexToDelete), ...currentCartToDelete.slice(indexToDelete + 1)]
        state.totalAmount = totals(state.cart).amount
        state.totalQuantity = totals(state.cart).qty
    },
    'UPDATE_CART'(state, payload) {
        state.cart = payload

        state.totalAmount = totals(payload).amount
        state.totalQuantity = totals(payload).qty
    },
    'CART_EMPTY'(state) {
        state.cart = []
        state.totalAmount = 0
        state.totalQuantity = 0
    },
    'set_category_slug'(state, payload) {
        state.categorySlug = payload;
    },
    'set_category_title'(state, payload) {
        state.categoryTitle = payload;
    },
    'setCategoryData'(state, payload) {
        state.categoryData = payload;
    },
    'setProductData'(state, payload) {
        state.categoryProducts = payload
    },
    'ADD_GUEST_PATIENT'(state, payload) {
        state.guestPatients.push(payload);
    },
    'SET_GUEST_PATIENTS'(state, payload) {
        state.guestPatients = payload;
    },
    'SET_PRODUCT_DATA_CANCEL_TOKEN'(state, payload) {
        state.productDataCancelToken = payload;
    }
};

export const actions = {
    setThemeSource({ commit }, payload) {
        commit(SET_THEME_SOURCE, payload);
        return Promise.resolve();
    },
    async loadThemeSource({ dispatch }, themeSessionToken = '') {
        let response = null;
        try {
            response = await this.$axios.$get('/split-test/session?token=' + themeSessionToken);
            dispatch('setThemeSource', response?.themeSource ? response.themeSource : '');

            if (typeof window !== 'undefined') {
                storage.set('themeSessionToken', response?.token, 10 * 60 * 1000);
            }
        } catch (e) {
            // Prevent issues setting theme source
        }

        return Promise.resolve(response);
    },
    setGlobalBannerModalOpen({ commit }, payload) {
        commit(SET_GLOBAL_BANNER_MODAL_OPEN, payload);
        return Promise.resolve();
    },
    setShowReorderLogin({ commit }, payload) {
        commit(SET_SHOW_REORDER_LOGIN, payload);
        return Promise.resolve();
    },
    setGlobalBannerProductTypeTimeout({ commit }, payload) {
        commit(SET_GLOBAL_BANNER_PRODUCT_TYPE_TIMEOUT, payload);
        return Promise.resolve();
    },
    setGlobalBannerProductTypeId({ commit, state }, payload) {
        if (state.globalBannerProductTypeTimeout && typeof window !== 'undefined') {
            window.clearTimeout(state.globalBannerProductTypeTimeout);
            commit(SET_GLOBAL_BANNER_PRODUCT_TYPE_TIMEOUT, null);
        }

        commit(SET_GLOBAL_BANNER_PRODUCT_TYPE_ID, payload);
        return Promise.resolve();
    },
    async loadTrustpilotData({ commit, state }) {
        if (state.trustpilotData) {
            return;
        }

        try {
            let response = await this.$axios.$get('/trustpilot-widget-data');
            commit(SET_TRUSTPILOT_DATA, response);
        } catch (e) {
            // Prevent issues loading trustpilot data
        }
    },
    setCart({ commit }, cart) {
        commit('SET_CART', cart);
    },
    setMobileMenuOpen({ commit }, status) {
        commit(SET_MOBILE_MENU_OPEN, status);
        const globalBanner = document.getElementsByClassName('top-panel');

        if (status) {
            globalBanner[0].classList.add('freeze-mobile-top-panel');
            globalBanner[0].classList.remove('unfreeze-mobile-top-panel');
        } else {
            globalBanner[0].classList.add('unfreeze-mobile-top-panel');
            globalBanner[0].classList.remove('freeze-mobile-top-panel');
        }

        return Promise.resolve();
    },
    addGuestPatient({ commit }, payload) {
        commit('ADD_GUEST_PATIENT', payload);
        return Promise.resolve();
    },
    setGuestPatients({ commit }, payload) {
        commit('SET_GUEST_PATIENTS', payload);
        return Promise.resolve();
    },
    async initCart({ commit, dispatch, state }, useCache = false) {
        if (useCache === true && state.cart) {
            return Promise.resolve();
        }
        try {
            const time = new Date().getTime();
            let cart = await this.$axios.$get('/customer/cart?t=' + time);
            commit('SET_CART', cart);

            dispatch('checkout/setAddingNewPaymentMethod', cart.paymentMethod ? false : true);

            dispatch(
                'checkout/setPaymentMethodType',
                cart.paymentMethod
                    ? cart.paymentMethod.paymentMethodType
                    : paymentMethodTypes.CHECKOUT_COM.id
            );

            dispatch(
                'checkout/setPaymentMethodId',
                cart.paymentMethod
                    ? cart.paymentMethod.id
                    : 0
            );
        } catch (e) {
            console.log(e);
        }

        return Promise.resolve();
    },
    async addToCart({ commit, state, dispatch }, payload) {
        if (state.cart === null) {
            await dispatch('initCart');
        }

        let cart = null;
        try {
            cart = await this.$axios.$post('/customer/cart/item', payload);
            commit('SET_CART', cart);
        } catch (e) {
            return Promise.reject(e.response.data);
        }

        try {
            pinterestTrackAddToCart(payload, cart);
            quoraTrackAddToCart();
            googleTrackAddToCart();
        } catch (e) {
            // Prevent issues with tracking
            console.log(e);
        }

        return Promise.resolve();
    },
    deleteCart({ commit }, id) {
        commit('DELETE_CART', id)
    },
    async updateCartItems({ commit }, payload) {
        try {
            let cart = await this.$axios.$patch('/customer/cart/item', payload);
            commit('SET_CART', cart);
        } catch (e) {
            return Promise.reject(e.response.data);
        }

        return Promise.resolve();
    },
    async deleteCartItems({ commit }, payload) {
        try {
            let cart = await this.$axios.$delete('/customer/cart/item', { data: payload });
            commit('SET_CART', cart);
        } catch (e) {
            console.log(e);
            return Promise.reject(e.response.data);
        }

        return Promise.resolve();
    },
    async updateCart({ commit }, payload) {
        try {
            let cart = await this.$axios.$patch('/customer/cart', payload);
            commit('SET_CART', cart);
        } catch (e) {
            console.log(e);
            return Promise.reject(e);
        }

        return Promise.resolve();
    },
    cartEmpty({ commit }) {
        commit('CART_EMPTY')
    },
    setCategorySlug({ commit }, payload) {
        commit('set_category_slug', payload)
    },
    setCategoryTitle({ commit }, payload) {
        commit('set_category_title', payload)
    },
    setCategoryData({ commit }, payload) {
        commit('setCategoryData', payload)
    },
    setCategoryProducts({ commit }, payload) {
        commit('setProductData', payload)
    },
    getCategoryData({ commit }, config) {
        return new Promise((resolve, reject) => {
            this.$axios
                .get(this.$config.apiBaseURL + '/category?slug=' + config.slug)
                .then(res => {
                    commit('setCategoryData', res.data.data[0])
                    return resolve(res.data.data[0])
                })
                .catch(err => {
                    console.error(err);
                    //commit('setProcessing', false)
                    return reject(err.response.data.message)
                })
        })
    },
    cancelProductDataRequest({ state }) {
        try {
            if (state.productDataCancelToken !== null) {
                state.productDataCancelToken.cancel();
            }
        } catch (e) {
            // Prevent issues
            console.log(e);
        }
    },
    async getProductData({ state, commit, dispatch }, config) {
        let query = {};

        if (config.categoryFilters && config.categoryFilters.length > 0) {
            for (let x = 0; x < config.categoryFilters.length; x++) {
                query['category[' + x + ']'] = config.categoryFilters[x];
            }
        }

        if (config.slug) {
            query.categorySlug = config.slug;
        }

        if (config.sortBy) {
            query.sortBy = config.sortBy;
        }

        if (config.productName) {
            query.productName = config.productName;
        }

        if (config.page) {
            query.page = config.page;
        }

        if (config.isPurchasable) {
            query.isPurchasable = 1;
        }

        if (typeof config.variantOf !== 'undefined') {
            query.variantOf = config.variantOf;
        }

        let cancelToken = this.$axios.CancelToken.source();

        try {
            commit('SET_PRODUCT_DATA_CANCEL_TOKEN', cancelToken);
            let response = await this.$axios.$get('/product-categories', {
                params: query,
                cancelToken: cancelToken.token
            });
            commit('SET_PRODUCT_DATA_CANCEL_TOKEN', null);
            return Promise.resolve(response);
        } catch (e) {
            // prevent issues;
            commit('SET_PRODUCT_DATA_CANCEL_TOKEN', null);
        }
    },
};

export const getters = {
    cart(state) {
        return state.cart
    },
    itemGroups(state) {
        let cart = Object.assign({}, state.cart);
        return itemGroupFormatter(cart.items);
    },
    totalAmount(state) {
        return state.totalAmount
    },
    totalQuantity(state) {
        return state.totalQuantity
    },
    getOrders(state) {
        return state.orders
    },
    getCategorySlug(state) {
        return state.categorySlug
    },
    categoryTitle(state) {
        return state.categoryTitle
    },
    categoryData(state) {
        return state.categoryData
    },
    getCategoryProducts(state) {
        return state.categoryProducts
    }
};
