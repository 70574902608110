<template>
    <!-- Start Navbar Area -->
    <div :class="['navbar-area', { 'is-sticky': isSticky }]">
        <Header class="hidden-mobile" :hideAuth="true" />
        <div class="comero-nav">
            <div class="checkout-navbar">
                <div class="container">
                    <nav class="navbar navbar-expand-md navbar-light">
                        <a href="/" class="navbar-mobile-back-button hidden-desktop">
                            <img :src="formatAssetUrl('images/arrow-left.svg')" alt="left arrow" />
                        </a>
                        <a class="navbar-brand" href="/">
                            <img :src="websiteLogo()" alt="logo" class="checkout-logo-img" :class="websiteLogoCss()" />
                        </a>
                        <div class="navbar-text">
                            <span class="secure-separator">|</span>
                            <img src="~@/assets/images/lock-solid.svg" alt="lock icon" class="ml-2">
                            <span class="secure-text">Secure Checkout</span>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <SidebarPanel />
    </div>
    <!-- End Navbar Area -->
</template>

<script>
import SidebarPanel from '@/layouts/SidebarPanel';
import Header from '@/layouts/Header';
import imageUrlFormatMixin from '@/mixins/image-url-formatter';

export default {
    name: 'CheckoutNavbar',
    mixins: [imageUrlFormatMixin],
    components: {
        SidebarPanel,
        Header
    },
    data() {
        return {
            isSticky: false,
            menu: [
                {
                    id: 0,
                    title: 'Secure Checkout'
                }
            ],
            current_number: 0
        };
    },
    mounted() {
        this.current_number = this.$route.path.includes('checkout') ? 3 : 2;
    },
    watch: {
        $route(pathUrl) {
            this.current_number = this.$route.path.includes('checkout') ? 3 : 2;
        }
    },
    computed: {
        cart() {
            return this.$store.getters.cart;
        }
    },
    methods: {
        toggle() {
            this.$store.dispatch('sidebar/toggleNav');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.navbar-area {
    position: relative;
    z-index: 1200;
    background-color: #ffffff;
    .checkout-navbar {
        padding: 0px;
        display: flex;
        align-items: center;
        min-height: 60px;
    }
    .comero-nav {
        width: 100%;
        margin-top: 0px;
        .navbar {
            .navbar-brand {
                .checkout-logo-img {
                    margin-left: -13px;
                }
            }
            .navbar-nav {
                .nav-item {
                    span {
                        &.line {
                            font-weight: normal;
                            letter-spacing: -3px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    ::v-deep {
        .top-panel {
            position: relative;
        }
    }
    .navbar-text {
        font-size: 16px;
        font-weight: bold;
        color: #515E68;
        span {
            &.secure-separator {
                display: inline-block;
                font-weight: normal;
                line-height: 16px;
                vertical-align: bottom;
                padding-bottom: 2px;
            }
            &.secure-text {
                display: inline-block;
                line-height: 16px;
                vertical-align: bottom;
                padding-bottom: 1px;
                padding-left: 4px;
            }
        }

    }
}

.comero-nav {
    background-color: #ffffff;
}

@media (max-width: $breakpoint2) {
    .navbar-area {
        .comero-nav {
            .navbar {
                justify-content: center;
                padding-left: 0px;
                .navbar-mobile-back-button {
                    position: absolute;
                    left: 20px;
                    img {
                        width: 23px;
                    }
                }
                .navbar-brand {
                    .checkout-logo-img {
                        margin-left: 0px;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint5) {
    .navbar-area {
        padding: 0px;
        border-bottom: solid $color-grey-border 1px;
        .comero-nav {
            background-color: #f4f7f9;
            margin-top: 0px;
            .navbar {
                img {
                    max-height: 32px;
                    height: 32px;
                }
            }
        }
        .navbar-text {
            display: none;
        }
    }
}
</style>
