<template>
    <client-only>
        <div style="display: none"></div>
    </client-only>
</template>

<script>
const NAME = 'glasseson';

export default {
    mounted() {
        if (this.isLoaded) {
            return;
        }

        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://web.cdn.glasseson.com/glasseson-2.23.1.js";
            js.type = "text/javascript"
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'glasseson-gofit-script'));

        this.$store.dispatch('thirdParty/setLoadedScript', NAME);
    },
    computed: {
        isLoaded() {
            return this.$store.getters['thirdParty/isLoaded'](NAME);
        }
    }
}
</script>
