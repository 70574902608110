<template>
    <div class="mobile-steps-list-container">
        <div class="mobile-progress-bar" :style="{ width: getWidth() + '%' }"></div>
        <div
            v-for="(pastStep, index) in pastSteps"
            :key="index"
            class="mobile-step-list-item"
            @click.prevent="onClickPastStep(pastStep.id)"
        >
            <span class="step-number"><img :src="formatAssetUrl('images/check-white-thick.svg')" alt="check"/></span>
            {{ pastStep.name }}
            <div class="chosen-option">
                <a v-if="pastStep.value" href="#" @click.prevent>
                    {{ pastStep.value }}
                </a>
                <template v-else>
                    -
                </template>
            </div>
        </div>

        <ConfirmBackButton :show="showConfirmModal" @close="showConfirmModal = false" @confirm="onClickConfirmGoBack" />
    </div>
</template>

<script>
import eyewearFlowMixin from '@/mixins/eyewear-flow';
import eyewearFlowSummaryMixin from '@/mixins/eyewear-flow-summary-mixin';
import eyewearFlowProgressMixin from '@/mixins/eyewear-flow-progress-mixin';
import formatImageUrlMixin from '@/mixins/image-url-formatter';
import ConfirmBackButton from '@/components/landing-page/choose-lenses/ConfirmBackButton';

export default {
    mixins: [eyewearFlowMixin, eyewearFlowSummaryMixin, formatImageUrlMixin, eyewearFlowProgressMixin],
    components: {
        ConfirmBackButton
    },
    data() {
        return {
            showConfirmModal: false,
            selectedStep: null
        };
    },
    computed: {
        pastSteps() {
            if (!this.currentStepNumber) {
                return [];
            }

            let pastSteps = [];
            for (let x = 0; x < this.steps.length; x++) {
                if (x + 1 >= this.currentStepNumber) {
                    continue;
                }

                let stepConstant = this.getStepConstant(this.steps[x]);
                let stepDetail = stepConstant ? this.getStepDetail(stepConstant.name) : null;

                pastSteps.push({
                    id: stepConstant.id,
                    name: stepConstant.name,
                    value: stepDetail?.value
                });
            }

            return pastSteps;
        }
    },
    methods: {
        getWidth() {
            return (this.currentStepNumber / this.steps.length) * 100;
        },
        onClickPastStep(pastStep) {
            this.selectedStep = pastStep;
            this.showConfirmModal = true;
        },
        onClickConfirmGoBack() {
            this.onClickStep(this.selectedStep);
        },
        getStepDetail(stepName) {
            if (stepName === 'Enter prescription') {
                stepName = 'Prescription';
            }

            if (stepName === 'Lens Coating') {
                stepName = 'Upgrades';
            }

            for (let x = 0; x < this.stepDetails.length; x++) {
                if (this.stepDetails[x].name !== stepName) {
                    continue;
                }

                return this.stepDetails[x];
            }

            return null;
        },
        onScroll() {
            const bar = document.getElementsByClassName('mobile-progress-bar');

            // sticky scroll progress bar
            if (window.innerWidth > 1060) {
                // Reset top just in case going from mobile to desktop
                bar[0].style.top = '99px';
                return;
            }

            if (window.top.scrollY === 0 || window.top.scrollY < 38) {
                bar[0].style.top = '99px';
            } else {
                bar[0].style.top = 60 + window.top.scrollY + 'px';
            }
        }
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll, true);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll, true);
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

@media (max-width: $breakpoint2) {
    .mobile-progress-bar {
        z-index: 10000;
        background-color: $color-blue;
        position: absolute;
        top: 99px;
        left: 0;
        width: 100%;
        height: 7px;
    }
}
</style>
