import objectFactory from '@/utils/object-factory';
import flowSteps from '@/constants/eyewear-flow-steps';

export default {
    methods: {
        isSelected(step) {
            if (!this.currentStep) {
                return false;
            }

            if (this.isCurrentStep(step)) {
                return true;
            }

            for (let x = 0; x < this.steps.length; x++) {
                if (this.currentStep.id === this.steps[x]) {
                    break;
                }

                if (this.steps[x] === step) {
                    return true;
                }
            }

            return false;
        },
        isCurrentStep(step) {
            if (!this.currentStep) {
                return false;
            }

            if (this.currentStep.id === step) {
                return true;
            }

            return false;
        },
        onClickStep(step) {
            if (!this.isSelected(step)) {
                return;
            }

            if (this.isCurrentStep(step)) {
                return;
            }

            let link = this.getStepLink(step);
            let queryParams = this.getQueryForStep(step);

            this.$router.push({ path: link, query: queryParams });
        },
        getStepLink(step) {
            let stepConstant = this.getStepConstant(step);
            if (!stepConstant) {
                return '';
            }

            return this.formatStepLink(stepConstant);
        },
        getQueryForStep(step) {
            let queryParams = objectFactory.deepCopy(this.$route.query);

            // Remove step query params
            let foundStep = false;
            for (let x = 0; x < this.steps.length; x++) {
                if (this.steps[x] === step) {
                    foundStep = true;
                }

                if (
                    typeof this.isStepAfterLensColor !== 'undefined' &&
                    !this.isStepAfterLensColor({ id: step }) &&
                    typeof queryParams.lensColor !== 'undefined') {
                        delete queryParams.lensColor;
                }

                if (!foundStep) {
                    continue;
                }

                let stepConstant = this.getStepConstant(this.steps[x]);
                if (!stepConstant) {
                    continue;
                }

                if (typeof queryParams[stepConstant.id] !== 'undefined') {
                    delete queryParams[stepConstant.id];
                }

                if (
                    this.steps[x] === flowSteps.LENS_TYPE_VARIATION.id &&
                    typeof queryParams.lensColor !== 'undefined'
                ) {
                    delete queryParams.lensColor;
                }

                if (
                    this.steps[x] === flowSteps.LENS_TYPE.id &&
                    typeof queryParams.lensTypeVariation !== 'undefined'
                ) {
                    delete queryParams.lensTypeVariation;
                }

                if (
                    this.steps[x] === flowSteps.RX_TYPE.id &&
                    typeof queryParams.rxTypeVariation !== 'undefined'
                ) {
                    delete queryParams.rxTypeVariation;
                }
            }

            return queryParams;
        },
    }
}
