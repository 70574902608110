<template>
    <div class="main-content container text-center">
        <img :src="formatAssetUrl('images/error404.png')" alt="page not found" class="mb-5">
        <h6 class="mb-5">
            We looked high and low, but can't seem to find what you're looking for.
        </h6>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    fetch() {
        this.$nuxt.context.error({
            status: 404,
            message: 'Page not found',
        });
    }
}
</script>
