export default {
    BOTH: {
        id: 0,
        label: 'Both'
    },
    NEW: {
        id: 1,
        label: 'New Customers'
    },
    RETURNING: {
        id: 2,
        label: 'Returning Customers'
    }
}
