
const SET_MENU_ITEM = 'Description';

export const state = () => ({
    descriptionMenuItem: "Description",
});

export const mutations = {
    [SET_MENU_ITEM](state, payload) {
        state.descriptionMenuItem = payload;
    },
};

export const actions = {
    setDescriptionMenuItem({ commit }, payload) {
        commit(SET_MENU_ITEM, payload);
        return Promise.resolve();
    },
};

export const getters = {
    descriptionMenuItem(state) {
        return state.descriptionMenuItem
    }
};