<template>
    <div class="zip-plugin-container" v-if="zipPluginLoaded">
        <div id="zipPluginAmount">{{ amount }}</div>
        <quadpay-widget-v3
            :alignment="alignment"
            :merchantId="zipMerchantId"
            amountSelector="#zipPluginAmount"
            :amount="amount"
            :breakpointAlignment="breakpointAlignment"
        ></quadpay-widget-v3>
    </div>
</template>

<script>
export default {
    props: {
        amount: {
            type: Number,
            default: 0
        },
        alignment: {
            type: String,
            default: "center"
        },
        breakpointAlignment: {
            type: String,
            default: ""
        }
    },
    mounted() {
        let interval = window.setInterval(() => {
            if (typeof window.quadpay !== 'undefined') {
                this.zipPluginLoaded = true;
                window.clearInterval(interval);
            }
        });
    },
    data() {
        return {
            zipPluginLoaded: false
        }
    },
    computed: {
        zipMerchantId() {
            return this.$config.zipMerchantId;
        }
    }
}
</script>

<style scoped>
    #zipPluginAmount {
        display: none;
    }

    .zip-plugin-container {
        min-height: 20px;
        font-size: 1em;
        font-weight: normal;
    }
</style>
