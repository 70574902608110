export const determineProductType = (route, state) => {
    if (route?.meta?.productType) {
        return route.meta.productType;
    }

    if (state?.globalBannerProductTypeId) {
        return state.globalBannerProductTypeId;
    }

    return 0;
};
