export function loadQuoraTracking() {
    if (typeof window === 'undefined') {
        return;
    }

    !function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
    qp('init', 'b80342f5957d4b5f834a3078858ccd7e');
}

export function quoraTrackPageView() {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.qp === 'undefined') {
        loadQuoraTracking();
    }

    qp('track', 'ViewContent');
}

export function quoraConversionTracking() {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.qp === 'undefined') {
        loadQuoraTracking();
    }

    qp('track', 'Purchase');
}

export function quoraTrackAddToCart() {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.qp === 'undefined') {
        loadQuoraTracking();
    }

    qp('track', 'Add to Cart');
}
