import objectFactory from '@/utils/object-factory';
import {
    addFlowSelection,
    removeFlowSelection,
    getSteps,
    getParentTreeForSelections,
    buildFlowTreeObject,
    getLowestCostFlowTreeFinalChild
} from '@/utils/eyewear-flow-v2/flow-tree-utils';

const SET_FRAME_TYPES = 'Set Frame Types';
const SET_RX_TYPES = 'Set Rx Types';
const SET_RX_TYPE_VARIATIONS = 'Set Rx Type Variations';
const SET_LENS_TYPES = 'Set Lens Types';
const SET_LENS_TYPE_VARIATIONS = 'Set Lens Type Variations';
const SET_LENS_TYPE_VARIATION_GROUPS = 'Set Lens Type Variation Groups';
const SET_LENS_MATERIALS = 'Set Lens Materials';
const SET_LENS_COATINGS = 'Set Lens Coatings';
const SET_ADDONS = 'Set Addons';
const SET_FLOW_TREE = 'Set Flow Tree';
const SET_FLOW_SELECTIONS = 'Set Flow Selections';
const SET_CURRENT_STEP = 'Set Current Step';
const SET_CURRENT_SUB_STEP = 'Set Current Sub Step';
const SET_FLOW_TREE_PRODUCT_ID = 'Set Flow Tree Product Id';
const SET_IS_STEP_CONTENT_LOADED = 'Set Is Step Content Loaded';
const SET_CURRENT_SELECTION = 'Set Current Selection';
const SET_SELECTED_ADDONS = 'Set Selected Addons';
const SET_COUPON_DATA = 'Set Coupon Data';
const SET_PRISM_PRODUCT = 'Set prism product';
const SET_IS_PRISM_SELECTED = 'Set is prism selected';
const SET_LENS_COLOR_FOR_SUMMARY = 'Set lens color for summary';
const SET_PREVIOUS_SELECTIONS = 'Set Previous Selections';
const SET_CUSTOMER_PRESCRIPTIONS = 'Set Customer Prescriptions';

export const state = () => ({
    lensColorForSummary: '',
    prismProduct: null,
    isPrismSelected: false,
    couponData: null,
    frameTypes: [],
    rxTypes: [],
    rxTypeVariations: [],
    lensTypes: [],
    lensTypeVariations: [],
    lensTypeVariationGroups: [],
    lensMaterials: [],
    lensCoatings: [],
    addons: [],
    flowTree: null,
    flowTreeProductId : null,
    isStepContentLoaded: false,
    flowSelections: [],
    currentStep: null,
    currentSubStep: null,
    currentSelection: null,
    selectedAddons: [],
    previousSelections: [],
    customerPrescriptions: []
});

export const mutations = {
    [SET_CUSTOMER_PRESCRIPTIONS](state, payload) {
        state.customerPrescriptions = payload;
    },
    [SET_PREVIOUS_SELECTIONS](state, payload) {
        state.previousSelections = payload;
    },
    [SET_LENS_COLOR_FOR_SUMMARY](state, payload) {
        state.lensColorForSummary = payload;
    },
    [SET_IS_PRISM_SELECTED](state, payload) {
        state.isPrismSelected = payload;
    },
    [SET_PRISM_PRODUCT](state, payload) {
        state.prismProduct = payload;
    },
    [SET_COUPON_DATA](state, payload) {
        state.couponData = payload;
    },
    [SET_SELECTED_ADDONS](state, payload) {
        state.selectedAddons = payload;
    },
    [SET_CURRENT_SELECTION](state, payload) {
        state.currentSelection = payload;
    },
    [SET_IS_STEP_CONTENT_LOADED](state, payload) {
        state.isStepContentLoaded = payload;
    },
    [SET_FLOW_TREE_PRODUCT_ID](state, payload) {
        state.flowTreeProductId = payload;
    },
    [SET_CURRENT_STEP](state, payload) {
        state.currentStep = payload;
    },
    [SET_CURRENT_SUB_STEP](state, payload) {
        state.currentSubStep = payload;
    },
    [SET_FLOW_SELECTIONS](state, payload) {
        state.flowSelections = payload;
    },
    [SET_FLOW_TREE](state, payload) {
        state.flowTree = payload;
    },
    [SET_FRAME_TYPES](state, payload) {
        state.frameTypes = payload;
    },
    [SET_RX_TYPES](state, payload) {
        state.rxTypes = payload;
    },
    [SET_RX_TYPE_VARIATIONS](state, payload) {
        state.rxTypeVariations = payload;
    },
    [SET_LENS_TYPES](state, payload) {
        state.lensTypes = payload;
    },
    [SET_LENS_TYPE_VARIATIONS](state, payload) {
        state.lensTypeVariations = payload;
    },
    [SET_LENS_TYPE_VARIATION_GROUPS](state, payload) {
        state.lensTypeVariationGroups = payload;
    },
    [SET_LENS_MATERIALS](state, payload) {
        state.lensMaterials = payload;
    },
    [SET_LENS_COATINGS](state, payload) {
        state.lensCoatings = payload;
    },
    [SET_ADDONS](state, payload) {
        state.addons = payload;
    }
};

export const actions = {
    setCustomerPrescriptions({ commit }, payload) {
        commit(SET_CUSTOMER_PRESCRIPTIONS, payload);
        return Promise.resolve();
    },
    removePreviousSelection({ dispatch, state }, stepTypeProperty) {
        if (state.previousSelections.length === 0) {
            return Promise.resolve();
        }

        let previousSelections = objectFactory.deepCopy(state.previousSelections);
        const foundIndex = previousSelections.findIndex((e) => {
            return e?.stepEntity?.stepTypeProperty === stepTypeProperty;
        });

        if (foundIndex === -1) {
            return Promise.resolve();
        }

        previousSelections.splice(foundIndex, 1);

        return dispatch('setPreviousSelections', previousSelections);
    },
    setPreviousSelection({ dispatch, state }, choice) {
        if (!choice?.stepEntity?.stepTypeProperty) {
            return;
        }

        let previousSelections = objectFactory.deepCopy(state.previousSelections);
        const foundIndex = previousSelections.findIndex((e) => {
            return e?.stepEntity?.stepTypeProperty === choice.stepEntity.stepTypeProperty;
        });

        if (foundIndex === -1) {
            previousSelections.push(objectFactory.deepCopy(choice));
        } else {
            previousSelections[foundIndex] = objectFactory.deepCopy(choice);
        }

        return dispatch('setPreviousSelections', previousSelections);
    },
    setPreviousSelections({ commit }, payload) {
        commit(SET_PREVIOUS_SELECTIONS, payload);
        return Promise.resolve();
    },
    setLensColorForSummary({ commit }, payload) {
        commit(SET_LENS_COLOR_FOR_SUMMARY, payload);
        return Promise.resolve();
    },
    setIsPrismSelected({ commit }, payload) {
        commit(SET_IS_PRISM_SELECTED, payload);
        return Promise.resolve();
    },
    setPrismProduct({ commit }, payload) {
        commit(SET_PRISM_PRODUCT, payload);
        return Promise.resolve();
    },
    setCouponData({ commit }, payload) {
        commit(SET_COUPON_DATA, payload);
        return Promise.resolve();
    },
    setSelectedAddons({ commit }, payload) {
        commit(SET_SELECTED_ADDONS, payload);
        return Promise.resolve();
    },
    addSelectedAddons({ dispatch, state }, payload) {
        let addonsCopy = objectFactory.deepCopy(state.selectedAddons);
        addonsCopy.push(payload);

        return dispatch('setSelectedAddons', addonsCopy);
    },
    removeSelectedAddons({ dispatch, state }, payload) {
        let addonsCopy = objectFactory.deepCopy(state.selectedAddons);
        let filteredAddons = addonsCopy.filter(el => {
            return el.id !== payload.id;
        });

        return dispatch('setSelectedAddons', filteredAddons);
    },
    setCurrentSelection({ commit }, payload) {
        commit(SET_CURRENT_SELECTION, payload);
        return Promise.resolve();
    },
    setIsStepContentLoaded({ commit }, payload) {
        commit(SET_IS_STEP_CONTENT_LOADED, payload);
        return Promise.resolve();
    },
    setFlowTreeProductId({ commit }, payload) {
        commit(SET_FLOW_TREE_PRODUCT_ID, payload);
        return Promise.resolve();
    },
    setCurrentStep({ commit }, payload) {
        commit(SET_CURRENT_STEP, payload);
        return Promise.resolve();
    },
    setCurrentSubStep({ commit }, payload) {
        commit(SET_CURRENT_SUB_STEP, payload);
        return Promise.resolve();
    },
    removeFlowSelection({ commit, state }, payload) {
        let newFlowSelections = removeFlowSelection(state.flowSelections, payload);
        if (newFlowSelections.length === state.flowSelections.length) {
            return Promise.resolve();
        }

        commit(SET_FLOW_SELECTIONS, newFlowSelections);
        return Promise.resolve();
    },
    addFlowSelection({ commit, state }, payload) {
        let filteredFlowSelections = addFlowSelection(state.flowSelections, payload);

        commit(SET_FLOW_SELECTIONS, filteredFlowSelections);
        return Promise.resolve();
    },
    setFlowSelections({ commit }, payload) {
        commit(SET_FLOW_SELECTIONS, payload);
        return Promise.resolve();
    },
    setFlowTree({ commit }, payload) {
        commit(SET_FLOW_TREE, payload);
        return Promise.resolve();
    },
    setFrameTypes({ commit }, payload) {
        commit(SET_FRAME_TYPES, payload);
        return Promise.resolve();
    },
    setRxTypes({ commit }, payload) {
        commit(SET_RX_TYPES, payload);
        return Promise.resolve();
    },
    setRxTypeVariations({ commit }, payload) {
        commit(SET_RX_TYPE_VARIATIONS, payload);
        return Promise.resolve();
    },
    setLensTypes({ commit }, payload) {
        commit(SET_LENS_TYPES, payload);
        return Promise.resolve();
    },
    setLensTypeVariations({ commit }, payload) {
        commit(SET_LENS_TYPE_VARIATIONS, payload);
        return Promise.resolve();
    },
    setLensTypeVariationGroups({ commit }, payload) {
        commit(SET_LENS_TYPE_VARIATION_GROUPS, payload);
        return Promise.resolve();
    },
    setLensMaterials({ commit }, payload) {
        commit(SET_LENS_MATERIALS, payload);
        return Promise.resolve();
    },
    setLensCoatings({ commit }, payload) {
        // @todo Temporary while anti fog is unavailable
        // Created ticket LEN-1087 to fix
        let coatings = payload.filter((el) => {
            return el.name !== 'Anti-Fog AR'
        });

        commit(SET_LENS_COATINGS, coatings);
        return Promise.resolve();
    },
    setAddons({ commit }, payload) {
        commit(SET_ADDONS, payload);
        return Promise.resolve();
    },
    initHasPrism({ state, commit }) {
        const prismSelection = state.flowSelections.find(el => el.name === 'Prism');
        if (typeof prismSelection === 'undefined' && !state.isPrismSelected) {
            return Promise.resolve();
        }

        if (typeof prismSelection === 'undefined') {
            commit(SET_IS_PRISM_SELECTED, false);
            return Promise.resolve();
        }

        commit(SET_IS_PRISM_SELECTED, prismSelection.value ? true : false);
        return Promise.resolve();
    }
};

export const getters = {
    steps(state, getters, rootState) {
        if (!state.flowTree) {
            return [];
        }

        if (!rootState.eyewearFlow.baseProduct) {
            return [];
        }

        const isLensReplacement = rootState.eyewearFlow.baseProduct.lensReplacement;

        return getSteps(getters.builtFlowTree, isLensReplacement, state.flowSelections);
    },
    builtFlowTree(state) {
        if (!state.flowTree) {
            return null;
        }

        return buildFlowTreeObject(state.flowTree);
    },
    parentTree(state, getters) {
        if (!state.flowTree) {
            return null;
        }

        return getParentTreeForSelections(
            state.flowSelections,
            state.currentStep,
            getters.builtFlowTree,
            state.currentSubStep
        );
    },
    finalChild(state, getters) {
        if (!state.flowTree) {
            return null;
        }

        return getLowestCostFlowTreeFinalChild(getters.parentTree, state.flowSelections);
    },
    estimatedPrice(state, getters, rootState) {
        if (!state.flowTree) {
            return 0;
        }

        let totalPrice = rootState.eyewearFlow.baseProduct.price;

        if (state.currentSelection) {
            totalPrice += state.currentSelection.lensPrice;
        } else if(getters.finalChild) {
            totalPrice += getters.finalChild.lensPrice;
        }

        if (state.isPrismSelected) {
            totalPrice += state.prismProduct?.price;
        }

        const selectedAddons = state.selectedAddons;
        if (selectedAddons.length === 0) {
            return totalPrice;
        }

        for (let selectedAddon of selectedAddons) {
            totalPrice += selectedAddon.price;
        }

        return totalPrice;
    }
};
