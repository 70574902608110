<template>
    <div style="display: none"></div>
</template>

<script>
const NAME = 'facebookLogin';

export default {
    mounted() {
        if (this.isLoaded) {
            return;
        }

        window.fbAsyncInit = () => {
            FB.init({
                appId: this.$config.facebook.appId, // Defined in the view
                cookie: true, // enable cookies to allow the server to access the session
                xfbml: true,
                version: this.$config.facebook.appVersion // or v2.0, v2.1, v2.0 - defined in the view
            });
        };

        (function(d, s, id) {                      // Load the SDK asynchronously
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        this.$store.dispatch('thirdParty/setLoadedScript', NAME);
    },
    computed: {
        isLoaded() {
            return this.$store.getters['thirdParty/isLoaded'](NAME);
        }
    }
}
</script>
