export default {
    head() {
        if (!this.meta) {
            return {
                title: 'Contacts For Less | Discount Contact Lenses Online | LensDirect'
            };
        }

        let metaFields = [];

        if (this.meta.description) {
            metaFields.push({
                hid: 'description',
                name: 'description',
                content: this.meta.description
            });
        }

        if (this.meta.keywords) {
            metaFields.push({
                hid: 'keywords',
                name: 'keywords',
                content: this.meta.keywords
            });
        }

        if (this.meta.robots) {
            metaFields.push({
                hid: 'robots',
                name: 'robots',
                content: this.meta.robots
            });
        }


        if (this.meta.html) {
            metaFields.push({
                hid: 'lensdirect',
                name: 'lensdirect',
                content: '" />' + this.meta.html + '<meta name="lensdirect'
            });
        }

        let metaData = {
            title: this.meta ? this.meta.title : 'Contacts For Less | Discount Contact Lenses Online | LensDirect',
            meta: metaFields
        };

        if (this.meta.html) {
            metaData.__dangerouslyDisableSanitizersByTagID = {
                lensdirect: ['content']
            };
        }

        return metaData;
    },
    computed: {
        meta() {
            if (typeof this.getMetaObjectToUse !== 'undefined' && this.getMetaObjectToUse) {
                return this.getMetaObjectToUse();
            }

            if (!this.$route?.meta?.data) {
                return null;
            }

            return this.$route.meta.data;
        }
    }
}
