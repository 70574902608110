export default {
    methods: {
        onClickLiveChat() {
            if (typeof Gladly === undefined) {
                return;
            }

            Gladly.show();
        }
    }
}
