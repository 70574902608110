<template>
    <div>
        <div class="modal-backdrop" v-if="isPanelOpen"></div>
        <!-- Start Shopping Cart Modal -->
        <transition name="slide-fade">
            <div
                v-if="isPanelOpen"
                class="modal right shoppingCartModal text-left"
                id="shoppingCartModal"
                tabindex="-1"
                role="dialog"
                @click="closeSidebarPanel()"
            >
                <div class="modal-dialog" role="document" @click.stop="">
                    <div class="modal-content">
                        <div class="modal-body" v-if="itemGroups.length > 0">
                            <div class="text-center shopping-header">
                                <button type="button" class="close" @click="closeSidebarPanel">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h6>Your Cart</h6>
                                <nuxt-link to="/cart" class="shopping-cart" @click.native="onClickCartLink">
                                    <span class="count">{{ itemGroups.length }}</span>
                                    <img :src="formatAssetUrl('images/cart.svg')" alt="cart" />
                                </nuxt-link>
                            </div>
                            <div class="product-cart-content">
                                <CartItem
                                    v-for="(itemGroup, index) in itemGroups"
                                    :key="index"
                                    :itemGroup="itemGroup"
                                />
                            </div>
                            <div class="people-bought" v-if="false">
                                <h6>People also bought</h6>
                                <div class="suggestion-product" v-for="item in products" :key="'product' + item.id">
                                    <img :src="formatAssetUrl('images/' + item.image)" :alt="item.name" />
                                    <p>{{ item.name }}</p>
                                    <span>${{ item.price }}</span>
                                    <!-- <button class="btn" @click="addToCart()">Add</button> -->
                                    <button class="btn">Add</button>
                                </div>
                            </div>
                            <footer>
                                <div class="product-cart-subtotal">
                                    <h6>Subtotal</h6>
                                    <h6 class="subtotal">${{ formatMoney(cart.subtotal) }}</h6>
                                </div>
                                <div class="product-cart-shipping">
                                    <h6>Shipping</h6>
                                    <h6 :class="cart.subtotal > freeShippingMinimum ? 'free' : 'calculated'">
                                        {{ cart.subtotal > freeShippingMinimum ? 'FREE' : 'Calculated at Checkout' }}
                                    </h6>
                                </div>
                                <div class="product-cart-btn">
                                    <nuxt-link
                                        to="/cart"
                                        class="btn btn-outline outline-dark"
                                        @click.native="onClickCartLink"
                                    >
                                        Cart
                                    </nuxt-link>
                                    <a href="/checkout" class="btn btn-cta"
                                        ><i class="fas fa-lock mr-2"></i> Continue to checkout
                                    </a>
                                </div>
                            </footer>
                        </div>
                        <div class="modal-body" v-else>
                            <div class="text-center shopping-header">
                                <button type="button" class="close" @click="closeSidebarPanel">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h6>Your Cart</h6>
                                <a class="shopping-cart">
                                    <span class="count">{{ itemGroups.length }}</span>
                                    <img :src="formatAssetUrl('images/cart.svg')" alt="cart" />
                                </a>
                            </div>
                            <div class="product-cart-content inline-links-section">
                                <img
                                    :src="formatAssetUrl('images/empty-cart.jpg')"
                                    alt="empty cart"
                                    class="mt-4 mb-4"
                                />
                                <p class="mb-4 pb-4 text-center">
                                    Something missing?
                                    <br />
                                    <a
                                        href="/user/login"
                                        @click.prevent="onClickSidebarLink('/user/login')"
                                        v-if="!user"
                                        >Sign in</a
                                    >
                                    <a
                                        href="/profile/order-history"
                                        @click.prevent="onClickSidebarLink('/profile/order-history')"
                                        v-else
                                        >View your order history</a
                                    >
                                    to see items you've previously ordered.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <!-- End Shopping Cart Modal -->
    </div>
</template>

<script>
import formatMoneyMixin from '@/mixins/money-formatter';
import formatImageUrlMixin from '@/mixins/image-url-formatter';

import CartItem from '@/components/sidebar/CartItem';

export default {
    name: 'SidebarPanel',
    components: {
        CartItem
    },
    mixins: [formatMoneyMixin, formatImageUrlMixin],
    data() {
        return {
            products: [
                {
                    id: 1,
                    name: 'Nerdwax Slimeline Single',
                    price: 10,
                    image: 'suggestion1.png'
                },
                {
                    id: 2,
                    name: 'Croakies Black Sunglasses Strap',
                    price: 12,
                    image: 'suggestion2.png'
                },
                {
                    id: 3,
                    name: 'Nerdwax Slimeline Single',
                    price: 10,
                    image: 'suggestion1.png'
                },
                {
                    id: 4,
                    name: 'The Nerdwax Fogblock Anti-Fog Cloth',
                    price: 15,
                    image: 'suggestion3.png'
                }
            ]
        };
    },
    methods: {
        onClickCartLink() {
            if (this.$route?.name === 'cart') {
                this.closeSidebarPanel();
            }
        },
        onClickSidebarLink(link) {
            this.$router.push(link);
            this.closeSidebarPanel();
        },
        closeSidebarPanel() {
            this.$store.dispatch('sidebar/toggleNav');
        },
        addToCart() {
            const product = [
                {
                    id: this.id,
                    name: this.name,
                    price: this.price,
                    image: this.image,
                    quantity: this.quantity
                }
            ];

            if (this.cart.length > 0) {
                let id = this.id;
                let cartIndex = this.cart.findIndex(cart => {
                    return cart.id == id;
                });

                if (cartIndex == -1) {
                    this.$store.dispatch('addToCart', product);
                    this.$toast('Added to cart', {
                        icon: 'fas fa-cart-plus'
                    });
                } else {
                    this.$store.dispatch('updateCart', {
                        id,
                        unit: 1,
                        cart: this.cart
                    });
                    this.getExistPId = true;
                    this.$toast.info('Already added to the cart');
                }
            } else {
                this.$store.dispatch('addToCart', product);
                this.$toast('Added to cart', {
                    icon: 'fas fa-cart-plus'
                });
            }
        }
    },
    computed: {
        freeShippingMinimum() {
            return parseFloat(this.$config.freeShippingMinimum);
        },
        itemGroups() {
            return this.$store.getters.itemGroups;
        },
        isPanelOpen() {
            return this.$store.state.sidebar.isNavOpen;
        },
        cart() {
            return this.$store.getters.cart;
        },
        user() {
            return this.$store.state.auth?.currentUser?.customer;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/transitions.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.shoppingCartModal .modal-content .modal-body {
    overscroll-behavior: contain;
    max-height: calc(100vh - 189px);
}

@media (max-width: $breakpoint2) {
    .shoppingCartModal .modal-content .modal-body {
        max-height: calc(100vh - 253px);
    }
}
</style>
