export default function (phone) {
    if (!phone.trim()) {
        return phone;
    }

    let filteredPhone = phone.trim();
    filteredPhone = filteredPhone.replace(/[^0-9]/g, '');

    let firstChar = filteredPhone[0];
    if (firstChar === '1') {
        filteredPhone = filteredPhone.substring(1);
    }

    return filteredPhone;
}
