import { getLastOccurrenceOfCartItem } from '@/third-party/tracking-helper';

const PINTEREST_ID = 2620262096256;

export function loadPinterestTracking() {
    if (typeof window === 'undefined') {
        return;
    }

    !function(e){if(!window.pintrk){window.pintrk=function(){window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var n=window.pintrk;n.queue=[],n.version="3.0";var t=document.createElement("script");t.async=!0,t.src=e;var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");

    pintrk('load', PINTEREST_ID);
    pintrk('page');
}

export function pinterestPageVisit(route, product = null) {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.pintrk === 'undefined') {
        loadPinterestTracking();
    }

    if (route.meta?.type === 'product' && !product) {
        return;
    }

    if (route.meta?.type === 'category') {
        return;
    }

    let metaData = route.meta?.data;
    let pageData = {};

    if (metaData?.title) {
        pageData.page_title = metaData.title;
    }

    if (product) {
        pageData.product_id = product.id;
        pageData.product_name = product?.name;
    }

    pintrk('track', 'pagevisit', pageData);
}

export function pinterestTrackAddToCart(addToCartPayload, resultCart) {
    if (typeof window === 'undefined') {
        return;
    }

    // Find the last occurrence of the items
    if (!Array.isArray(addToCartPayload)) {
        return;
    }

    if (typeof window.pintrk === 'undefined') {
        loadPinterestTracking();
    }

    let lineItems = [];
    for (let x = 0; x < addToCartPayload.length; x++) {
        let item = addToCartPayload[x];

        let cartItem = getLastOccurrenceOfCartItem(resultCart, item);
        if (!cartItem) {
            continue;
        }

        lineItems.push({
            product_name: cartItem.product?.variantName,
            product_id: cartItem.product?.variantId,
            product_variant_id: cartItem.product?.id,
            product_price: cartItem.product?.price,
            product_quantity: cartItem.quantity
        });
    }

    pintrk('track', 'addtocart', { line_items: lineItems });
}

export function pinterestConversionTracking(order) {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.pintrk === 'undefined') {
        loadPinterestTracking();
    }

    let lineItems = [];
    let totalQuantity = 0;
    for (let x = 0; x < order.items.length; x++) {
        let orderItem = order.items[x];
        lineItems.push({
            product_name: orderItem.product?.variantName,
            product_id: orderItem.product?.variantId,
            product_variant_id: orderItem.product?.id,
            product_price: orderItem.product?.price,
            product_quantity: orderItem.quantity
        });

        totalQuantity += orderItem.quantity;
    }

    pintrk(
        'track',
        'checkout',
        {
            line_items: lineItems,
            order_quantity: totalQuantity,
            value: order.total,
            order_id: order.id,
            currency: 'USD',
            promo_code: order.coupon ? order.coupon.code : ''
        }
    );
}

export function pinterestCategoryTracking(categoryName) {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.pintrk === 'undefined') {
        loadPinterestTracking();
    }

    pintrk('track', 'viewcategory', { category_name: categoryName });
}

export function pinterestSignupTracking() {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.pintrk === 'undefined') {
        loadPinterestTracking();
    }

    pintrk('track', 'signup');
}
