<template>
    <button type="button" class="btn-hamburger" :class="{ toggled }" @click="onClick">
        <div class="hamburger-line"></div>
    </button>
</template>

<script>
export default {
    props: {
        toggled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick() {
            this.$emit('click');
        }
    }
}
</script>

<style lang="scss" scoped>
    .btn-hamburger {
        position: relative;
        display: block;
        border: 0px;
        margin-left: 5px;
        background-color: transparent;
        outline: none;
        width: 25px;
        height: 26px;
        padding: 0px;

        .hamburger-line {
            position: absolute;
            top: 50%;
            left: 0px;
            margin-top: -1px;
            height: 2px;
            background-color: #000000;
            width: 90%;
            border-radius: 100px;
            transition: transform .4s;
            &::before {
                position: absolute;
                content: "";
                top: -8px;
                left: 0px;
                width: 100%;
                height: 2px;
                border-radius: 20px;
                background-color: #000000;
                transition: transform .4s, top .4s;
            }
            &::after {
                position: absolute;
                content: "";
                bottom: -8px;
                left: 0px;
                width: 100%;
                height: 2px;
                border-radius: 20px;
                background-color: #000000;
                transition: transform .4s, opacity .1s, bottom .4s;
            }
        }
        &.toggled {
            width: 30px;
            .hamburger-line {
                width: 100%;
                transform: rotate(45deg);
                &::before {
                    width: 100%;
                    transform: rotate(-90deg);
                    top: 0px;
                }
                &::after {
                    transform: rotate(-45deg);
                    bottom: 4px;
                    width: 100%;
                    opacity: 0;
                }
            }
        }
    }
</style>
