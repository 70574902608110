const LINKED_IN_PARTNER_ID = 91375;

export default function() {
    if (typeof window === 'undefined') {
        return;
    }

    window._linkedin_data_partner_id = LINKED_IN_PARTNER_ID;
    (function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})();
}
