export default {
    CONTACT_LENSES: {
        id: 1,
        name: 'Contact Lenses'
    },
    SUNGLASSES: {
        id: 2,
        name: 'Sunglasses'
    },
    GLASSES: {
        id: 3,
        name: 'Glasses'
    },
    ACCESSORIES: {
        id: 4,
        name: 'Accessories'
    },
    LENSES: {
        id: 5,
        name: 'Lenses'
    },
    FRAMES: {
        id: 6,
        name: 'Frames'
    },
    VISION_TEST: {
        id: 7,
        name: 'Vision Test'
    },
    LENS_REPLACEMENT: {
        id: 8,
        name: 'Lens Replacement'
    },
    DIY_LENSES: {
        id: 9,
        name: 'DIY Lenses'
    },
    HANDLING_INSURANCE: {
        id: 10,
        name: 'Handling & Insurance'
    },
    EYEWEAR_ADDON: {
        id: 11,
        name: 'Eyewear Addon'
    },
    CONTACT_LENSES_ADDON: {
        id: 12,
        name: 'Contact Lenses Addon'
    },
    GIFT_CARD: {
        id: 13,
        name: 'Gift Card'
    },
    READERS: {
        id: 14,
        name: 'Readers'
    }
}
