const state = () => ({    
    flow: {},
    flowParams: {},
    selectedChoice: [],
    prevStep: ""
});

const getters = {
    getFlow(state){
        return state.flow
    },
    getFlowParams(state){
        return state.flowParams
    },
    getSeletedChoice(state){
        return state.selectedChoice
    },
    getPrevStep(state){
        return state.prevStep
    }
};

const mutations = {
    mutateFlowStatus: function (state, payload) {
       state.flow = payload.data;
    },
    mutateFlowParams: function (state, payload) {
        state.flowParams = {
            productType: payload.productType,
            flowStep: payload.flowStep,
            frameProduct: payload.frameProduct,
            rxType: payload.rxType,
            rxTypeVariation: payload.rxTypeVariation,
            lensType: payload.lensType,
            lensTypeVariation: payload.lensTypeVariation,
        }
    },
    mutateSelectedChoice: function (state, payload) {
        state.selectedChoice = payload;
    },
    mutatePrevStep: function (state, payload) {
        state.prevStep = payload;
    },
}

const actions = {
    updateFlow: function ({commit}, config) {
        return new Promise((resolve, reject) => {
            var productType = config.productType ? "productType=" + config.productType : "";
            var flowStep = config.flowStep ? "&flowStep=" + config.flowStep : "";
            var frameProduct = config.frameProduct ? "&frameProduct=" + config.frameProduct : "";
            var rxType = config.rxType ? "&rxType=" + config.rxType : "";
            var rxTypeVariation = config.rxTypeVariation ? '&rxTypeVariation=' + config.rxTypeVariation : "";
            var lensType = config.lensType ? "&lensType=" + config.lensType : "";
            var lensTypeVariation = config.lensTypeVariation ? '&lensTypeVariation=' + config.lensTypeVariation : "";
            this.$axios
            .get(this.$config.apiBaseURL + '/eyewear-flow?' + productType + flowStep + frameProduct + rxType + rxTypeVariation + lensType + lensTypeVariation)
            .then(res => {
                commit('mutateFlowStatus', res);
                commit('mutatePrevStep', config.prev)
                return resolve(res.data);
            })
            .catch(err => {
                return reject(err.response.data.message)
            })
        })        
    },
    updateFlowParams: function ({commit}, config) {
        commit('mutateFlowParams', config);        
    },
    updateSelectedChoice:  function ({commit}, config) {
        return new Promise((resolve, reject) => {
            var productType = config.productType ? "productType=" + config.productType : "";
            var flowStep = config.flowStep ? "&flowStep=" + config.flowStep : "";
            var frameProduct = config.frameProduct ? "&frameProduct=" + config.frameProduct : "";
            var rxType = config.rxType ? "&rxType=" + config.rxType : "";
            var rxTypeVariation = config.rxTypeVariation ? '&rxTypeVariation=' + config.rxTypeVariation : "";
            var lensType = config.lensType ? "&lensType=" + config.lensType : "";
            var lensTypeVariation = config.lensTypeVariation ? '&lensTypeVariation=' + config.lensTypeVariation : "";
            this.$axios
            .get(this.$config.apiBaseURL + '/eyewear-flow?' + productType + flowStep + frameProduct + rxType + rxTypeVariation + lensType + lensTypeVariation)
            .then(res => {
                commit('mutateSelectedChoice', res.data.selectedChoices);
                return resolve(res.data.selectedChoices);
            })
            .catch(err => {
                return reject(err.response.data.message)
            })
        })   
    }
}

export default {
    state, getters, mutations, actions
};