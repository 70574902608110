export const state = ({
    prescription: null,
    process: false
})

export default {
    getters: {
        process: state => state.process,
    },
    mutations: {
        setPrescriptions (state, payload) {
            state.prescription = payload
            state.process = false
        },
        setprocess (state, payload) {
            state.process = payload
        },
    },
    actions: {
        getPrescriptions ({ commit }, config) {
            return new Promise((resolve, reject) => {
                commit('setprocess', true)
                this.$axios
                .get(this.$config.apiBaseURL + '/customer/prescription', config)
                .then(res => {
                    commit('setPrescriptions', res.data)
                    commit('setprocess', false)
                    return resolve(res.data)
                })
                .catch(err => {
                    commit('setprocess', false)
                    return reject(err.response.data.message)
                })
            })
        },
        getPrescriptionDetail ({ commit }, params) {
            return new Promise((resolve, reject) => {
                commit('setprocess', true)
                this.$axios
                .get(this.$config.apiBaseURL + '/customer/prescription/' + params.id, params.config)
                .then(res => {
                    commit('setprocess', false)
                    return resolve(res.data)
                })
                .catch(err => {
                    return reject(err.response.data.message)
                })
            })
        },
        deletePrescription ({ commit }, params) {
            return new Promise((resolve, reject) => {
                this.$axios
                .delete(this.$config.apiBaseURL + '/customer/prescription/' + params.id, params.config)
                .then(res => {
                    commit('setProcessing', false)
                    return resolve(res.data)
                })
                .catch(err => {
                    commit('setProcessing', false)
                    return reject(err.data.message)
                })
            })
        },
    }
}
