<template>
    <div class="lens-package-attributes-list-component">
        <div
            v-for="(attributeListItem, index) in attributeList"
            :key="index"
            class="d-flex mb-1"
        >
            <div class="label">
                {{ attributeListItem.name }}:
            </div>
            <div class="text-secondary">
                {{ attributeListItem.value }}
            </div>
        </div>
        <div
            v-if="addons.length > 0"
            class="d-flex"
        >
            <div class="label">
                Addons:
            </div>
            <div class="text-secondary">
                <div
                    v-for="(addon, index) in addons"
                    :key="index"
                >
                    {{ addon.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import eyewearTypes from '@/constants/eyewear-types';

export default {
    props: {
        lensPackageAttributes: {
            type: Object
        },
        lensColor: {
            type: String,
            default: ''
        },
        addons: {
            type: Array,
            default: () => ([])
        }
    },
    computed: {
        attributeList() {
            let attributeList = [];

            let typesToCheck = [
                {
                    values: eyewearTypes.materialTypes,
                    name: 'Material',
                    key: 'lensMaterial'
                },
                {
                    values: eyewearTypes.lensTypes,
                    name: 'Type',
                    key: 'lensType'
                },
                {
                    values: eyewearTypes.lensCoatingTypes,
                    name: 'Coating',
                    key: 'lensCoating'
                },
                {
                    values: eyewearTypes.lensTypeVariationTypes,
                    name: 'Color',
                    key: 'lensTypeVariation'
                }
            ];

            let rxTypeAttribute = this.lensPackageAttributes.rxTypeVariation
                ? this.getAttribute(this.lensPackageAttributes.rxTypeVariation, eyewearTypes.rxTypeVariationTypes)
                : this.getAttribute(this.lensPackageAttributes.rxType, eyewearTypes.rxTypes);

            if (rxTypeAttribute) {
                attributeList.push({
                    name: 'Rx Type',
                    value: rxTypeAttribute.name
                });
            }

            for (let x = 0; x < typesToCheck.length; x++) {
                let typeToCheck = typesToCheck[x];
                let attribute = this.getAttribute(this.lensPackageAttributes[typeToCheck.key], typeToCheck.values);

                if (!attribute) {
                    continue;
                }

                let attributeValue = attribute.name;

                if (typeToCheck.name === 'Color' && this.lensColor) {
                    attributeValue += ' - ' + this.lensColor;
                } else if (typeToCheck.name === 'Color' && this.lensColor) {
                    attributeValue = this.lensColor;
                }

                if (attribute) {
                    attributeList.push({
                        name: typeToCheck.name,
                        value: attributeValue
                    });
                }
            }

            return attributeList;
        }
    },
    methods: {
        getAttribute(id, types) {
            if (!id) {
                return null;
            }

            for (let x in types) {
                let type = types[x];
                if (!type || !type.id) {
                    continue;
                }

                if (type.id === id) {
                    return type;
                }
            }

            return null;
        }
    }
}
</script>

<style lang="scss" scoped>
    .lens-package-attributes-list-component {
        font-size: .875em;
        .label {
            font-weight: 600;
            min-width: 90px;
        }
    }
</style>
