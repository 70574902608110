const LOAD_TIME = Date.now();

export default async function (context) {
    if (typeof window === 'undefined') {
        return;
    }

    if (context?.route?.path === '/refresh') {
        return;
    }

    if (!context?.route?.fullPath) {
        return;
    }

    // Refresh page if 12 hours has passed
    const REFRESH_TIME = 1000 * 60 * 60 * 12;

    if (Date.now() - LOAD_TIME < REFRESH_TIME) {
        return;
    }

    window.location = context.route.fullPath;

    // Pause so the page reloads before the route moves
    const pauseBeforeContinue = new Promise((resolve, reject) => {
        window.setTimeout(() => {
            resolve();
        }, 10000);
    });

    await pauseBeforeContinue;
}
