const SET_NAV_OPEN = 'Set Nav Open';
const SET_QUICK_VIEW_OPEN = 'Set Quick View Open';

import checkMobile from '@/utils/check-mobile';

export const state = () => ({
    isNavOpen: false,
    isQuickViewOpen: false
});

export const mutations = {
    [SET_NAV_OPEN](state, payload) {
        state.isNavOpen = payload;
    },
    [SET_QUICK_VIEW_OPEN](state, payload) {
        state.isQuickViewOpen = payload;
    }
};

export const actions = {
    toggleNav({ state, commit }) {

        if (checkMobile()) {
            this.$router.push('/cart');
            window.scrollTo(0, 0);
            return Promise.resolve();
        }

        let isOpen = !state.isNavOpen;
        commit(SET_NAV_OPEN, !state.isNavOpen);

        if (isOpen) {
            document.body.classList.add('hide-chat-window');
        } else {
            document.body.classList.remove('hide-chat-window');
        }

        return Promise.resolve();
    },
    toggleQuickView({ state, commit }) {
        commit(SET_QUICK_VIEW_OPEN, !state.isQuickViewOpen);
        return Promise.resolve();
    }
};
