export default {
    AUTHORIZE: {
        id: 1,
        name: 'Credit Card'
    },
    PAYPAL: {
        id: 2,
        name: 'PayPal'
    },
    AFTERPAY: {
        id: 3,
        name: 'Afterpay'
    },
    APPLEPAY: {
        id: 4,
        name: 'Apple Pay'
    },
    ZIP: {
        id: 5,
        name: 'Zip'
    },
    AMAZONPAY: {
        id: 6,
        name: 'Amazon Pay'
    },
    ACCOUNTCREDIT: {
        id: 7,
        name: 'Account Credit'
    },
    GIFTCARD: {
        id: 8,
        name: 'Gift Card'
    },
    CHECKOUT_COM: {
        id: 9,
        name: 'Credit Card'
    },
    PAYPAL_DIRECT: {
        id: 10,
        name: 'PayPal'
    }
}
