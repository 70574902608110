const EXP_TIME = 28800000; // 8 hours

class LDStorage {
    constructor()
    {
        this.namespace = "lensdirect_";
        this.storage = typeof window !== 'undefined' ? window.localStorage : null;
    }

    set(key, value, expTime = null)
    {
        if (this.storage === null) {
            return null;
        }

        let item = {
            value,
            exp: Date.now() + (expTime !== null ? expTime : EXP_TIME)
        }

        let json = JSON.stringify(item);
        json = json.replace(/\</g, "&lt;");
        json = json.replace(/\>/g, "&gt;");


        this.storage.setItem(this.namespace + key, json);
    }

    get(key)
    {
        if (this.storage === null) {
            return null;
        }

        let value = this.storage.getItem(this.namespace + key);
        try
        {
            const item = JSON.parse(value);

            if(item && item.exp > Date.now())
            {
                // Not yet expired
                value = item.value;
            }
            else if(item)
            {
                // expired
                this.delete(this.namespace + key);
                return null;
            }
        }
        catch(e)
        {
            console.error(e);
            value = null;
        }

        return value;
    }

    delete(key) {
        if (this.storage === null) {
            return null;
        }

        if (key.indexOf(this.namespace) === -1) {
            key = this.namespace + key;
        }

        this.storage.removeItem(key);
    }

    clear()
    {
        if (this.storage === null) {
            return null;
        }

        this.storage.clear();
    }
}

export default new LDStorage();
