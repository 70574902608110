<template>
    <button
        class="custom-radio-component"
        :class="{ active: value }"
        @click="onClick"
    >
        <div class="radio-container"><slot name="radioIcon"></slot></div>
        <div class="radio-label"><slot></slot></div>
    </button>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        preventUncheck: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick() {
            if (this.value && this.preventUncheck) {
                return;
            }

            this.$emit('input', !this.value);
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/color.scss';
    @import '~/assets/scss/variables/font.scss';
    @import '~/assets/scss/variables/breakpoints.scss';

    .custom-radio-component {
        border: 0px;
        background: transparent;
        display: flex;
        box-shadow: none;
        padding: 0px;
        width: 100%;
        .radio-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border: solid #D1DAE1 1px;
            background-color: #ffffff;
            border-radius: 100px;
            flex-shrink: 0;
        }
        .radio-label {
            &:empty {
                display: none;
            }
            max-width: 90%;
            text-align: left;
            margin-left: 10px;
            color: #000000;
        }
        &:hover {
            .radio-label {
                text-decoration: underline;
            }
            .radio-container {
                box-shadow: 0px 0px 4px 0px $box-shadow-blue;
            }
        }
        &.active {
            font-weight: bold;
            .radio-container {
                &::before {
                    display: block;
                    content: "";
                    width: 14px;
                    height: 14px;
                    background-color: $bg-blue;
                    border-radius: 100px;
                }
            }
        }
    }

    @media (max-width: $breakpoint5) {
        .custom-radio-component {
            .radio-label {
                max-width: 85%;
            }
        }
    }
</style>
