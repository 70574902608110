<template>
    <button
        v-if="previousStep || overrideOnClick"
        class="btn-back-button"
        @click.prevent="onClick"
    >
        <img :src="formatAssetUrl(arrowImage)" class="blue-arrow" alt="left arrow" />
        <img :src="formatAssetUrl(arrowImageHover)" class="black-arrow" alt="left arrow" />
        <span class="back-button-text"><slot>Back</slot></span>
    </button>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
import { getPreviousStepsForStepName, formatStepName } from '@/utils/eyewear-flow-v2/progress-utils';
import { getNextStepLink, buildQueryStringObjectFromSelections } from '@/utils/eyewear-flow-v2/flow-tree-utils';
import objectFactory from '@/utils/object-factory';

export default {
    mixins: [formatImageMixin],
    props: {
        overrideOnClick: {
            type: Function
        },
        arrowImage: {
            type: String,
            default: 'images/arrow-left.svg'
        },
        arrowImageHover: {
            type: String,
            default: 'images/arrow-left-b.svg'
        },
        backLinkPathPrefix: {
            type: String,
            default: '/lens-replacement/'
        }
    },
    computed: {
        baseProduct() {
            return this.$store.state.eyewearFlow.baseProduct;
        },
        flowSelections() {
            return this.$store.state.eyewearFlowV2.flowSelections;
        },
        currentStepName() {
            return formatStepName(this.$store.state.eyewearFlowV2.currentStep + '');
        },
        steps() {
            return this.$store.getters['eyewearFlowV2/steps'];
        },
        previousSteps() {
            return getPreviousStepsForStepName(this.steps, this.currentStepName);
        },
        previousStep() {
            if (!this.previousSteps?.length) {
                return null;
            }

            return this.previousSteps[this.previousSteps.length - 1];
        }
    },
    methods: {
        getQueryStringKeyForFlowSelection(flowSelection) {
            if (flowSelection.queryStringKey === 'rxTypeVariation') {
                return 'rxType';
            }

            if (flowSelection.queryStringKey === 'lensTypeVariation' || flowSelection.queryStringKey === 'lensColor') {
                return 'lensType';
            }

            return flowSelection.queryStringKey;
        },
        async onClick() {
            if (this.overrideOnClick) {
                this.overrideOnClick();
                return Promise.resolve();
            }

            // Build query string for the step we're going to
            const previousSteps = this.previousSteps;
            let newflowSelections = [];
            let flowSelectionCopy = objectFactory.deepCopy(this.flowSelections);
            for (let flowSelection of flowSelectionCopy) {
                let foundSelectionStep = previousSteps.find(el => el.id === this.getQueryStringKeyForFlowSelection(flowSelection));
                if (typeof foundSelectionStep === 'undefined') {
                    continue;
                }

                newflowSelections.push(flowSelection);
            }

            const queryString = buildQueryStringObjectFromSelections(newflowSelections);

            const link = getNextStepLink(
                previousSteps.length > 1 ? this.previousStep.page : '',
                this.baseProduct.slug,
                this.backLinkPathPrefix
            );

            this.$router.push({ path: link, query: queryString });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.btn-back-button {
    display: flex;
    align-items: center;
    padding: 0px;
    border: 0px;
    background-color: transparent;
    color: $color-blue;
    gap: 10px;
    margin-right: auto;
    .black-arrow {
        display: none;
    }
    &:hover {
        color: #000000;
        .blue-arrow {
            display: none;
        }
        .black-arrow {
            display: block;
        }
    }
}

@media (max-width: $breakpoint2) {
    .btn-back-button {
        border: solid $color-grey-input 1px !important;
        color: #91A5B4 !important;
        font-size: 20px;
        border-radius: 5px;
        padding: 20px 25px;
        line-height: 1.2em;
        img {
            display: none !important;
        }
    }
}
</style>
