<template>
    <div class="footer--version2">
        <div class="container--version2 mb-4 pt-4">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="communication-container">
                        <h6 class="text-center">Stay tuned!</h6>
                        <div class="text-center">Exclusive promotions, special offers and more</div>
                        <form class="marketing-email-form-container" @submit.prevent="onSubscribe">
                                <input
                                    type="email"
                                    class="form-control"
                                    placeholder="Your email address"
                                    id="email"
                                    v-model="subscribeEmail"
                                    autocomplete="off"
                                >
                                <CustomButton
                                    type="submit"
                                    :isLoading="isLoadingSubscribe"
                                >
                                    {{ isMobile ? '' : 'Subscribe'}}
                                    <img v-if="isMobile" :src="formatAssetUrl('images/angle-right-white.svg')" class="angle-right" alt="Subscribe">
                                </CustomButton>
                            </form>
                        </div>
                    </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0">
                    <div class="communication-container">
                        <div class="app-container d-flex flex-column align-items-center">
                            <h6>Get the app</h6>
                            <div class="text-center">Exclusive promotions, special offers and more</div>
                            <a class="app-store-button mt-3" href="https://apps.apple.com/us/app/lensdirect/id1627023741" target="_blank">
                                <img :src="formatAssetUrl('images/mobile-app/app-store-button.png')" alt="Download on the App Store">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-section--version2 bg-dark-blue">
            <div class="container--version2 noskim">
                <div class="d-flex contact-section flex-row justify-space-around ">
                        <div class="d-flex flex-column flex-md-row contact-box align-items-center justify-content-between justify-content-md-start">
                            <img class="contact-image" :src="formatAssetUrl('images/footer/01-call.webp')" alt="call" />
                            <div class="contact-text d-flex flex-column ml-md-2 mt-2 mt-md-0">
                                <div class="contact-action">Call <span class="hidden-mobile">us!</span></div>
                                <div class="contact-details hidden-mobile">Mon - Fri from 9AM to 6PM ET</div>
                            </div>
                            <a href="tel:18005367111"></a>
                        </div>

                    <div class="d-flex flex-column flex-md-row contact-box align-items-center justify-content-between justify-content-md-start">
                        <img :src="formatAssetUrl('images/footer/02-email.webp')" alt="email" class="email-icon pt-2 pt-md-0" />
                        <div class="contact-text d-flex flex-column ml-md-2 mt-2 mt-md-0">
                            <div class="contact-action">Email</div>
                            <div class="contact-details hidden-mobile">info@lensdirect.com</div>
                        </div>
                        <a href="mailto:info@lensdirect.com"></a>
                    </div>
                    <div class="d-flex flex-column flex-md-row contact-box align-items-center justify-content-between justify-content-md-start">
                        <img :src="formatAssetUrl('images/footer/03-chat.webp')" alt="chat" />
                        <div class="contact-text d-flex flex-column ml-md-2 mt-2 mt-md-0">
                            <div class="recommended">Best Option</div>
                            <div class="contact-action">Chat</div>
                            <div class="contact-details hidden-mobile">Mon - Fri from 9AM to 6PM ET</div>
                        </div>
                        <a @click.prevent="onClickLiveChat" href="#"></a>
                    </div>
                    <div class="d-flex flex-column flex-md-row  contact-box align-items-center justify-content-between justify-content-md-start">
                        <img :src="formatAssetUrl('images/footer/04-chat.webp')" alt="sms" class="sms-icon" />
                        <div class="contact-text d-flex flex-column ml-md-2 mt-2 mt-md-0">
                            <div class="contact-action">Text</div>
                            <div class="contact-details hidden-mobile">Send us a text!</div>
                        </div>
                        <a href="sms:+15164004534"></a>
                    </div>
                </div>
            </div>
            <div class="container--version2">
                <div class="footer-links d-flex flex-column flex-md-row justify-content-between">
                    <div class="footer-links-container">
                        <FooterSection>
                            <template #header>Shop</template>
                            <li><nuxt-link to="/contact-lenses">Contact Lenses</nuxt-link></li>
                            <li><nuxt-link to="/glasses">Glasses</nuxt-link></li>
                            <li><nuxt-link to="/sunglasses">Sunglasses</nuxt-link></li>
                            <li><a href="/diy-lens-replacement/choose-your-brand">DIY Lens Replacement</a></li>
                            <li><nuxt-link to="/accessories">Accessories</nuxt-link></li>
                            <li><nuxt-link to="/vision-test">Online Vision Test</nuxt-link></li>
                            <li><nuxt-link to="/giftcards">Gift Cards</nuxt-link></li>
                        </FooterSection>

                        <FooterSection>
                            <template #header>Lens Replacement</template>
                            <li><nuxt-link to="/lens-replacement/prescription-lens-replacement">Prescription Glasses</nuxt-link></li>
                            <li><nuxt-link to="/lens-replacement/prescription-sunglasses-lens-replacement">Prescription Sunglasses</nuxt-link></li>
                            <li><nuxt-link to="/lens-replacement/non-prescription-sunglass-lens-replacement">Non-Prescription Sunglasses</nuxt-link></li>
                        </FooterSection>
                    </div>
                    <div class="footer-links-container">
                        <FooterSection>
                            <template #header>Guides & Resources</template>
                            <li><a href="https://www.lensdirect.com/blog ">Blog</a></li>
                            <li><a href="https://www.lensdirect.com/app ">LensDirect App</a></li>
                            <li> <a href="https://api.lensdirect.com/download-pd-ruler" target="_blank">Download PD Ruler</a></li>
                            <li><nuxt-link to="/face-shape-guide">Face Shape Guide</nuxt-link></li>
                            <li><nuxt-link to="/how-lens-replacement-works">How Lens Replacement Works</nuxt-link></li>
                            <li><nuxt-link to="/pd-measurement">How to Measure Your Pupillary Distance</nuxt-link></li>
                            <li><nuxt-link to="/dotthepupils">How to Dot the Pupils on Your Lenses</nuxt-link></li>
                            <li><nuxt-link to="/how-to-install-sunglass-lenses">How to Install Sunglass Lenses</nuxt-link></li>
                            <li><nuxt-link to="/how-to-identify-sunglasses">How to Identify your Sunglasses</nuxt-link></li>
                            <li><nuxt-link to="/insurance">Get Reimbursed By Your Vision Insurance</nuxt-link></li>
                        </FooterSection>
                    </div>
                    <div class="footer-links-container">
                        <FooterSection>
                            <template #header>Support</template>
                            <li><a href="/faq">Frequently Asked Questions</a></li>
                            <li><a href="#" @click.prevent="onClickEnableAccessibilityOptions">Enable Accessibility Options</a></li>
                            <li><nuxt-link to="/profile/account-snapshot">My Account</nuxt-link></li>
                            <li><nuxt-link to="/return-policy">Return Policy</nuxt-link></li>
                            <li><nuxt-link to="/warranty">Warranty</nuxt-link></li>
                            <li><nuxt-link to="/guest/check-order-status">Check Order Status</nuxt-link></li>
                            <li><nuxt-link to="/guest/check-autorefill-status">Check Subscription Status</nuxt-link></li>
                            <li><nuxt-link to="/help">Contact Us</nuxt-link></li>
                        </FooterSection>
                        <FooterSection>
                            <template #header>About</template>
                            <li><nuxt-link to="/the-company">The Lens Direct Story</nuxt-link></li>
                            <li><nuxt-link to="/reviews">Reviews</nuxt-link></li>
                            <li><nuxt-link to="/california-consumer-privacy-act">CCPA</nuxt-link></li>
                        </FooterSection>
                    </div>
                    <div class="footer-links-container">
                        <FooterSection>
                            <template #header>Programs</template>
                            <li><nuxt-link to="/the-company">Rewards Program</nuxt-link></li>
                            <li>
                                <a
                                    href="https://signup.cj.com/member/signup/publisher/?cid=3117602#/branded"
                                    target="_blank"
                                    rel="noopener"
                                    >Affiliate Program</a
                                >
                            </li>
                            <li>
                                <a
                                    href="https://lensdirect.grin.live/influencer-program-apply"
                                    target="_blank"
                                    rel="noopener"
                                    >Influencer Program</a
                                >
                            </li>
                            <li><nuxt-link to="/subscribe-and-save">Subscribe and Save</nuxt-link></li>
                            <li><nuxt-link to="/rebate">Rebates</nuxt-link></li>
                            <li><nuxt-link to="/coupon-codes">Coupon Codes</nuxt-link></li>
                            <li><nuxt-link to="/heroes-offer">Heroes Offer</nuxt-link></li>
                        </FooterSection>
                        <FooterSection>
                            <template #header>Brands & Technology</template>
                            <li><nuxt-link to="/bludefend">Blu Defend</nuxt-link></li>
                            <li><nuxt-link to="/diy-lens-replacement/sports?color=Volt%20Pro%20-%20Polarized%20-%20Gray">Volt Pro</nuxt-link></li>
                            <li>  <a
                                    href="https://islandoptics.com/"
                                    target="_blank"
                                    rel="noopener"
                                    >Island Optics</a
                                ></li>
                        </FooterSection>
                    </div>
                </div>
            </div>
            <div class="container--version2 lower-section hidden-mobile">
                <div class="footer-links d-flex flex-column flex-md-row justify-content-between">
                    <div class="footer-links-container">
                        <FooterSection>
                            <template #header>Shop by Categories</template>
                            <li><nuxt-link to="/mens-prescription-glasses">Men's Glasses</nuxt-link></li>
                            <li><nuxt-link to="/womens-designer-eyeglasses">Women's Glasses</nuxt-link></li>
                            <li><nuxt-link to="/childrens-eyewear">Children's Glasses</nuxt-link></li>
                            <li><a href="/blue-light-glasses">Blue Light Glasses</a></li>
                            <li><nuxt-link to="/readers">Readers</nuxt-link></li>
                            <li><nuxt-link to="/progressives">Progressives</nuxt-link></li>
                        </FooterSection>
                    </div>
                    <div class="footer-links-container">
                        <FooterSection>
                            <template #header>Trending Products</template>
                            <li><nuxt-link to="/glasses-new-arrivals">New Glasses</nuxt-link></li>
                            <li><nuxt-link to="/sunglasses-new-arrivals">New Sunglasses</nuxt-link></li>
                            <li><nuxt-link to="/glasses-best-sellers">Best Selling Glasses</nuxt-link></li>
                            <li><nuxt-link to="/sunglasses-best-sellers">Best Selling Sunglasses</nuxt-link></li>
                        </FooterSection>
                    </div>
                    <div class="footer-links-container">
                        <FooterSection>
                            <template #header>Shop by Deal</template>
                            <li><nuxt-link to="/glasses-under-35">Glasses Under $35</nuxt-link></li>
                            <li><nuxt-link to="/bogo">BOGO Frames</nuxt-link></li>
                        </FooterSection>
                    </div>
                    <div class="footer-links-container">
                        <FooterSection>
                            <template #header>Shop by Collection</template>
                            <li><nuxt-link to="/hyannis-sunglasses">Hyannis Sunglasses</nuxt-link></li>
                            <li><nuxt-link to="/holly-sunglasses">Holly Sunglasses</nuxt-link></li>
                            <li><nuxt-link to="/brian-mazza-collection">Brian Mazza Collection</nuxt-link></li>
                            <li><nuxt-link to="/clipon-glasses">Clip-On Glasses</nuxt-link></li>
                            <li><nuxt-link to="/delancey-street">Delancey Street</nuxt-link></li>
                        </FooterSection>
                    </div>
                </div>
            </div>

            <div class="container--version2 review-payment-section">
                <div class="footer-links d-flex flex-row justify-content-between">
                    <div class="d-flex flex-row align-items-center justify-content-between reviews">
                        <img :src="formatAssetUrl('images/footer/bbb.svg')" alt="better business bureau" class="hidden-mobile" />
                        <img class="google" :src="formatAssetUrl('images/footer/01-google.svg')" alt="google reviews" />
                        <img class="tp-reviews" :src="formatAssetUrl('images/footer/02-tp.svg')" alt="trustpilot reviews" />
                        <img class="newsweek" :src="formatAssetUrl('images/footer/03-newsweek.svg')" alt="newsweek reviews" />
                    </div>
                    <div class="ml-5 d-flex flex-row justify-content-between align-items-center payments hidden-mobile">
                        <img :src="formatAssetUrl('images/footer/visa.svg')" alt="visa" />
                        <img class="mastercard" :src="formatAssetUrl('images/footer/mcard.svg')" alt="mastercard" />
                        <img :src="formatAssetUrl('images/footer/amex.svg')" alt="american express" />
                        <img :src="formatAssetUrl('images/footer/disc.svg')" alt="discover" />
                        <img :src="formatAssetUrl('images/footer/pp.svg')" alt="paypal" />
                        <img :src="formatAssetUrl('images/footer/amazon.svg')" alt="amazon pay" />
                        <img :src="formatAssetUrl('images/footer/zip.webp')" alt="zip" />
                        <img :src="formatAssetUrl('images/footer/applepay.svg')" alt="apple pay" />
                    </div>
                </div>
            </div>
            <div class="container--version2 lower-footer">
                <div class="footer-terms-container">
                    <nuxt-link to="/privacy-policy">Privacy Policy</nuxt-link>
                    <span>|</span>
                    <nuxt-link to="/terms-and-conditions">Terms & Conditions</nuxt-link>
                </div>
                <div class="footer-logo-container">
                    <img src="~assets/images/logo-white.svg" />
                </div>
                <div class="footer-social-media-container">
                    <a href="https://www.facebook.com/shoplensdirect" target="_blank" rel="noopener">
                        <img
                            :src="formatAssetUrl('images/footer/facebook.webp')"
                            aria-label="Facebook"
                            alt="Facebook"
                        />
                    </a>
                    <a href="https://instagram.com/lensdirect" target="_blank" rel="noopener">
                        <img
                            :src="formatAssetUrl('images/footer/instagram.webp')"
                            aria-label="Instagram"
                            alt="Instagram"
                        />
                    </a>
                    <a href="https://twitter.com/lensdirect" target="_blank" rel="noopener">
                        <img
                            :src="formatAssetUrl('images/footer/twitter.webp')"
                            aria-label="Twitter"
                            alt="Twitter"
                        />
                    </a>
                    <a href="https://www.pinterest.com/lensdirect/" target="_blank" rel="noopener">
                        <img
                            :src="formatAssetUrl('images/footer/pinterest.webp')"
                            aria-label="Pinterest"
                            alt="Pinterest"
                        />
                    </a>
                    <a href="https://www.tiktok.com/@lensdirect" target="_blank" rel="noopener">
                        <img
                            :src="formatAssetUrl('images/footer/tiktok.webp')"
                            aria-label="tiktok"
                            alt="tiktok"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import TrustpilotMicroTrustScore from '@/components/third-party/TrustpilotMicroTrustScore';
import CustomTrustpilotWidget from '@/components/landing-page/CustomTrustpilotWidget';
import FooterSection from '@/components/FooterSection';
import ContactOptions from '@/components/ContactOptions';
import formatImageMixin from '@/mixins/image-url-formatter';
import accessibilityOptionsMixin from '@/mixins/accessibility-options-mixin';
import checkMobile from '@/utils/check-mobile';

export default {
    mixins: [formatImageMixin, accessibilityOptionsMixin],
    components: {
        CustomTrustpilotWidget,
        FooterSection,
        ContactOptions
    },
    data() {
        return {
            subscribeEmail: '',
            isLoadingSubscribe: false
        }
    },
    computed: {
        isMobile(){
            return checkMobile();
        },
    },
    methods: {
        onClickLiveChat() {
            if (typeof Gladly === undefined) {
                return;
            }

            Gladly.show();
        },
        async onSubscribe() {
            if (this.subscribeEmail === '' || !this.validateEmail(this.subscribeEmail)) {
                this.$toast.error('Email address is required.', {
                    icon: 'fas fa-times'
                });
                return;
            }

            this.isLoadingSubscribe = true;
            let payload = {
                email: this.subscribeEmail
            };

            try {
                await this.$axios.$post('/subscribe-email', payload);

                this.$toast.success(`Subscribed successfully!`, {
                    icon: 'fas fa-user-check'
                });
                this.subscribeEmail = ''
            } catch (e) {
                this.$toast.error('We were unable to subscribe you at this time.  Please try again later.', {
                    icon: 'fas fa-times'
                });
            }

            this.isLoadingSubscribe = false;
        },
        validateEmail(email) {
            return /^\S+@\S+\.\S+$/.test(email);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';




.footer--version2 {
    .app-store-button {
            width: 175px;
        }
    .communication-container {
        background-color: #F1F6FD;
        border-radius: 10px;
        padding: 40px 60px;
        height: 100%;
        .marketing-email-form-container {
            display: flex;
            padding-top: 20px;
            input {
                font-size: 1em;
                border-radius: 0px;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
            button {
                color: #ffffff;
                border-radius: 0px;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                background-color: #11b681;
                border: 0px;
                font-size: 1.125em;
                font-weight: bold;
                padding: 10px 20px;
                &:hover {
                    background-color: #0e996c;
                }
                &:active,&:focus {
                    background-color: #0a8f64;
                }
            }
        }
    }
    font-size: 16px;
    .footer-section--version2 {
        padding: 20px;
        &.bg-dark-blue {
            color: #ffffff;
            padding-top: 30px;
            padding-bottom: 30px;
            .container--version2 {
                &.lower-section{
                    margin-top: 50px;
                    padding-top: 20px;
                    border-top: 1px solid rgba(226, 226, 226, .10);
                }
                &.review-payment-section{
                    margin-top: 50px;
                    padding-top: 40px;
                    border-top: 1px solid rgba(226, 226, 226, .10);
                    .reviews{
                        flex-grow: 1;
                        gap: 5px;
                        img{
                            flex-basis: 100%;
                        }
                        .google{
                            height: 42px;
                        }
                        .tp-reviews{
                            height: 44px
                        }
                        .newsweek{
                            margin-top: 4px;
                            height: 37px
                        }
                    }
                    .payments{
                        flex-grow: 1;
                        gap: 8px;
                        .mastercard{
                            height: 29px;
                        }
                        img{
                            height: 16px;
                        }
                    }
                }
                .contact-section{
                    border-bottom: solid rgba(112,112,112,.24) 1px;
                    flex-grow: 1;
                    padding: 0 0 40px;
                    gap: 45px;
                    .contact-box{
                        position: relative;
                        padding: 15px;
                        flex-basis: 100%;
                        box-shadow: 0px 12px 36px #00000014;
                        border: 1px solid rgba(226, 226, 226, .10);
                        border-radius: 10px;
                        a{
                            position: absolute;
                            z-index: 10;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                        .contact-image{
                            height: 25px;
                        }
                        .contact-text{
                            .contact-action{
                                color: #FFFFFF;
                                font-size: 18px;
                            }
                            .contact-details{
                                color: #FFFFFF;
                                opacity: 0.7;
                                font-size: 14px;
                            }
                            .recommended{
                                position: absolute;
                                top: -5px;
                                right: -5px;
                                border-radius: 100px;
                                padding: 5px 10px;
                                height: auto;
                                line-height: 12px;
                                font-size: 12px;
                                background-color: #13a676;
                                color: #ffffff;
                                font-weight: 500;
                            }
                        }
                    }
                }
                display: flex;
                gap: 6.67%;
                &.lower-footer {
                    font-size: .8125em;
                    gap: 0px;
                    justify-content: space-between;
                    margin-top: 40px;
                    padding-top: 30px;
                    border-top: solid rgba(112,112,112,.24) 1px;
                    .footer-terms-container {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        a {
                            color: #ffffff;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                    .footer-logo-container {
                        img {
                            height: 30px;
                        }
                    }
                    .footer-social-media-container {
                        display: flex;
                        gap: 12px;
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: rgba(255,255,255,.06);
                            border-radius: 100px;
                            width: 50px;
                            height: 50px;
                            transition: background-color .1s;
                            &:hover {
                                background-color: rgba(255,255,255,.12);
                            }
                        }
                    }
                }
            }
            ::v-deep {
                h6 {
                    color: #ffffff;
                    font-size: 1.625em;
                    line-height: 1.2em;
                    font-weight: bold;
                }
                .single-footer-widget {
                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        list-style: none;
                        margin: 0px;
                        padding: 0px;
                        padding-top: 15px;
                        li {
                            padding: 0px;
                            margin: 0px;
                            a {
                                color: #ffffff;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
            .footer-links{
                width: 100%;
                flex-grow: 1;
                gap: 45px;
                .footer-links-container {
                    flex-basis: 100%;
                    width: 16.64%;
                }
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .footer--version2 {
        .app-store-button {
            width: 135px;
        }
        .communication-container {
            width: 100%;
            padding: 40px 20px;
            .marketing-email-form-container {
                margin-bottom: 20px;
            }
        }
        .footer-section--version2 {
            &.bg-white {
                .container--version2 {
                    justify-content: center;
                    .payment-methods-container {
                        display: none;
                    }
                }
            }
            &.bg-dark-blue {
                padding-top: 30px;
                ::v-deep {
                    .single-footer-widget {
                        h6 {
                            font-size: 1em;
                        }
                    }
                }
                .container--version2 {
                    flex-direction: column;
                    &.lower-footer {
                        gap: 15px;
                        flex-direction: column-reverse;
                        justify-content: center;
                        align-items: center;
                    }
                    .contact-section{
                        border-bottom: solid rgba(112,112,112,.24) 1px;
                        flex-grow: 1;
                        padding: 0 0 40px;
                        margin-bottom: 20px;
                        gap: 15px;
                        .contact-box{
                            padding: 10px 0;
                            flex-basis: 100%;
                        }
                        .contact-text{
                            .contact-action{
                                color: #FFFFFF;
                                font-size: 14px;
                            }
                            .recommended{
                                display: none;
                            }
                        }
                    }
                    &.review-payment-section{
                        margin-top: 0px;
                        padding-top: 20px;
                        border-top: none;
                    }
                    &.lower-section{
                        margin-top: 0px;
                        padding-top: 0px;
                        border-top: none;
                    }
                }
                .footer-links{
                    width: 100%;
                    flex-grow: 0;
                    gap: 0px;
                    .footer-links-container {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
