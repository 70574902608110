export default {
    methods: {
        formatMoney(value, decimals = 2) {
            return parseFloat(value).toFixed(decimals);
        },
        formatDisplayPrice(price) {
            const formattedPrice = parseFloat(this.formatMoney(price, 0));
            let decimals = 0;
            if (price - formattedPrice != 0) {
                 decimals = 2;
            }

            return this.formatMoney(price, decimals);
        },
    }
}
