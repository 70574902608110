<template>
    <div class="eyewear-flow-sticky-wrapper">
        <div class="eyewear-flow-side-bar-container">
            <Summary :isLoading="isLoading" />

            <div class="eyewear-flow-side-bar-section trustpilot-widget-section" >
                <div class="eyewear-flow-side-bar-section free-lens-upgrades-section">
                    <div class="section-title"><strong>Lenses include:</strong></div>

                    <div class="free-lens-upgrades-container">
                        <div class="free-lens-upgrade">
                            <div class="img-container">
                                <div class="img-backgrond"></div>
                                <img :src="formatAssetUrl('images/uv-prot.svg')" alt="sun behind cloud icon" />
                            </div>
                            <div class="free-lens-upgrade-text">
                                UV Protection
                            </div>
                        </div>
                        <div class="free-lens-upgrade">
                            <div class="img-container">
                                <div class="img-backgrond"></div>

                                <img :src="formatAssetUrl('images/anti-refl.svg')" alt="sun reflection icon" />
                            </div>
                            <div class="free-lens-upgrade-text">
                                Anti-Reflective
                            </div>
                        </div>
                        <div class="free-lens-upgrade">
                            <div class="img-container">
                                <div class="img-backgrond"></div>
                                <img :src="formatAssetUrl('images/scratch-res.svg')" alt="scratch icon" />
                            </div>
                            <div class="free-lens-upgrade-text">
                                Scratch Resistant
                            </div>
                        </div>
                    </div>
                </div>
                <CustomTrustpilotWidget v-if="trustpilotData" />
            </div>
        </div>
    </div>
</template>

<script>
import formatImageUrlMixin from '@/mixins/image-url-formatter';
import CustomTrustpilotWidget from '@/components/landing-page/CustomTrustpilotWidget';
import Summary from '@/components/landing-page/lens-replacement/steps/Summary';

export default {
    mixins: [formatImageUrlMixin],
    components: {
        Summary,
        CustomTrustpilotWidget
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        trustpilotData() {
            return this.$store.state.trustpilotData;
        }
    }
};
</script>

<style lang="scss" scoped>
    .trustpilot-widget {
        margin-bottom: 24px;
    }
</style>
