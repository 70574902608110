import eyewearFlowSteps from '@/constants/eyewear-flow-steps';
import objectFactory from '@/utils/object-factory';
import eyewearRxTypes from '@/constants/eyewear-rx-types';
import FlowTree from '@/utils/eyewear-flow-v2/FlowTree';

const STEP_NAMES = {
    FRAME_TYPE: 'Frame Type',
    LENS_COATING: 'Lens Coating',
    LENS_MATERIAL: 'Lens Material',
    LENS_TYPE: 'Lens Type',
    RX_TYPE: 'Rx Type',
    FRAME_DETAILS: 'Frame Details',
    ENTER_RX: 'Enter prescription',
    ADDONS: 'Addons',
    LENS_POWER: 'Lens Power',
    RX_TYPE_VARIATION: 'Rx Type Variation',
    LENS_TYPE_VARIATION: 'Variation',
    LENS_COLOR: 'Lens Color',
    PRISM: 'Prism'
};

const NON_API_STEPS = [
    STEP_NAMES.ENTER_RX,
    STEP_NAMES.LENS_POWER,
    STEP_NAMES.ADDONS,
    STEP_NAMES.FRAME_DETAILS,
    STEP_NAMES.LENS_COLOR,
    STEP_NAMES.PRISM
];

const doesChildHavePreviousSelection = (childTree, previousSelection, stepName = '', subStepName = '') => {
    for (let previousChildSelection of childTree.previousSelections) {
        if (previousChildSelection.name !== previousSelection.name) {
            continue;
        }

        if (previousChildSelection.value !== previousSelection.value) {
            return false;
        }

        return true;
    }

    if (childTree?.parentTree?.children?.length === 1) {
        return true;
    }

    if (childTree?.parentTree && childTree?.children?.length > 0) {
        return false;
    }

    if (childTree?.attributeName !== stepName && childTree?.attributeName !== subStepName) {
        return false;
    }

    return childTree?.attributeValue === previousSelection.value;
}

const doesChildTreeMatchSelections = (childTree, previousSelections, stepName, subStepName) => {
    let filteredSelections = previousSelections.filter((el) => {
        if (NON_API_STEPS.indexOf(el.name) !== -1) {
            return false;
        }

        return true;
    });

    if (childTree.previousSelections.length === 0 && filteredSelections.length === 0) {
        return true;
    }

    for (let previousSelection of filteredSelections) {
        if (!doesChildHavePreviousSelection(childTree, previousSelection, stepName, subStepName)) {
            return false;
        }
    }

    return true;
};

export const getParentTreeForSelections = (previousSelections, stepName, tree, subStepName = null) => {
    if (!previousSelections || previousSelections.length === 0) {
        return tree;
    }

    let previousSelectionsCopy = objectFactory.deepCopy(previousSelections);
    let filteredFlowSelections = previousSelectionsCopy.filter((el) => {
        return el.name !== stepName && el.name !== subStepName;
    });

    if (stepName === STEP_NAMES.ENTER_RX || stepName === STEP_NAMES.LENS_POWER) {
        return getParentTreeForSelections(filteredFlowSelections, 'Lens Material', tree);
    }

    if (stepName === STEP_NAMES.FRAME_DETAILS) {
        return getParentTreeForSelections(filteredFlowSelections, 'Lens Coating', tree);
    }

    for (let childTree of tree.children) {
        if (childTree.attributeName !== stepName) {
            continue;
        }

        // Check if this child matches previous selections
        if (doesChildTreeMatchSelections(childTree, filteredFlowSelections, stepName, subStepName)) {
            return tree;
        }

        // Check deeper in childTree
        let deeperTree = getParentTreeForSelections(filteredFlowSelections, stepName, childTree, subStepName);
        if (deeperTree) {
            return deeperTree;
        }
    }

    // Go through the children and see if theres a match
    for (let childTree of tree.children) {
        if (childTree.attributeName === stepName) {
            continue;
        }

        let result = getParentTreeForSelections(filteredFlowSelections, stepName, childTree, subStepName);
        if (result) {
            return result;
        }
    }

    return null;
};

export const getChoices = (flowSelections, flowTree, stepName, subStepName = null) => {
    // Find the flow tree branch with the same selections as the customer
    if (!flowTree || !flowSelections) {
        return [];
    }

    const parentTree = getParentTreeForSelections(flowSelections, stepName, flowTree, subStepName);
    return parentTree?.children ? parentTree.children : [];
}

const getStepEntityForChoice = (choice, stepEntities, stepEntityFilterFn = null) => {
    if (!stepEntityFilterFn) {
        stepEntityFilterFn = (stepEntity, choice) => (stepEntity.stepTypeValue === choice.attributeValue);
    }

    for (let stepEntity of stepEntities) {
        if (stepEntityFilterFn(stepEntity, choice)) {
            return stepEntity;
        }
    }

    return null;
}

export const getSubChoices = (choice, subChoiceAttributeName) => {
    // Check to see if the choice's children have the matching subChoiceAttributeName
    if (!choice?.children?.length) {
        return [];
    }

    for (let child of choice.children) {
        if (child.attributeName !== subChoiceAttributeName) {
            return [];
        }
    }

    return choice.children;
};

export const getChoicesWithContent = (choices, stepEntities, subChoiceAttributeName = null, stepEntityFilterFn = null) => {
    let formattedChoices = [];
    let lowestPrice = null;

    for (let choice of choices) {
        let stepEntity = getStepEntityForChoice(choice, stepEntities, stepEntityFilterFn);

        if (!stepEntity) {
            continue;
        }

        if (lowestPrice === null) {
            lowestPrice = choice.lensPrice;
        }

        if (lowestPrice > choice.lensPrice) {
            lowestPrice = choice.lensPrice;
        }

        let subChoices = [];
        if (subChoiceAttributeName) {
            subChoices = getSubChoices(choice, subChoiceAttributeName);
        }

        formattedChoices.push({
            priceDifference: 0,
            lensPrice: choice.lensPrice,
            templateId: choice.templateId,
            stepEntity: stepEntity,
            subChoices: subChoices
        });
    }

    // Calculate price differences based on lowest price
    for (let formattedChoice of formattedChoices) {
        formattedChoice.priceDifference = formattedChoice.lensPrice - lowestPrice > 0
            ? formattedChoice.lensPrice - lowestPrice
            : 0;
    }

    // Sort choices
    formattedChoices.sort((a, b) => {
        if (a.stepEntity.sortOrder < b.stepEntity.sortOrder) {
            return -1;
        }

        if (a.stepEntity.sortOrder > b.stepEntity.sortOrder) {
            return 1;
        }

        return 0;
    });

    return formattedChoices;
}

export const getLowestCostFlowTreeFinalChild = (flowTree, flowSelections) => {
    if (!flowTree) {
        return null;
    }

    if (flowTree.children.length === 0) {
        return flowTree;
    }

    let lowestCostChild = null;

    for (let childTree of flowTree.children) {
        let finalChild = getLowestCostFlowTreeFinalChild(childTree, flowSelections);
        if (!finalChild) {
            continue;
        }

        // Check if child matches selections
        if (!doesChildTreeMatchSelections(finalChild, flowSelections, 'Lens Coating')) {
            continue;
        }

        if (lowestCostChild === null) {
            lowestCostChild = finalChild;
            continue;
        }

        if (lowestCostChild.price > finalChild.price) {
            lowestCostChild = finalChild;
        }
    }

    return lowestCostChild;
}

function doesRequireEnterRxStep(flowSelections) {
    const rxTypeSelection = flowSelections.find(el => el.name === STEP_NAMES.RX_TYPE);
    if (typeof rxTypeSelection === 'undefined') {
        return false;
    }

    return rxTypeSelection.value !== eyewearRxTypes.NON_PRESCRIPTION.id &&
        rxTypeSelection.value !== eyewearRxTypes.READERS.id;
}

function traverseUpToParentTreeForStep(childTree, stepName) {
    if (!childTree.parentTree) {
        return null;
    }

    if (stepName === 'Prescription type') {
        stepName = 'Rx Type';
    }

    if (childTree.attributeName?.toLowerCase() === stepName.toLowerCase()) {
        return childTree.parentTree;
    }

    return traverseUpToParentTreeForStep(childTree.parentTree, stepName);
}

function shouldSkipStep(parentTreeForStep, isForLensReplacement = true) {
    if (parentTreeForStep.children.length !== 1) {
        return false;
    }

    if (parentTreeForStep.children[0].children.length > 1 && isForLensReplacement) {
        return false;
    }

    for (let child of parentTreeForStep.children[0].children) {
        if (child.variations.length > 0) {
            return false;
        }
    }

    return true;
}

function doesRequireLensPowerStep(flowSelections) {
    const rxType = flowSelections.find(el => el.name === STEP_NAMES.RX_TYPE);
    if (typeof rxType === 'undefined') {
        return false;
    }

    return rxType?.value === eyewearRxTypes.READERS.id;
}



export const getSteps = (flowTree, isForLensReplacement = true, flowSelections = []) => {
    if (!flowTree) {
        return [];
    }

    // Find the first lowest priced endpoint
    let finalChild = getLowestCostFlowTreeFinalChild(flowTree, flowSelections);
    if (!finalChild) {
        return [];
    }

    let stepNames = finalChild.steps ? finalChild.steps : [];
    if (stepNames.length === 0) {
        return stepNames;
    }

    // Go through the steps and then add any non-lens component step

    let stepNameMapping = {
        'Frame Type': eyewearFlowSteps.FRAME_TYPE,
        'Rx Type': eyewearFlowSteps.RX_TYPE,
        'Lens Type': eyewearFlowSteps.LENS_TYPE,
        'Lens Material': eyewearFlowSteps.LENS_MATERIAL,
        'Lens Coating': eyewearFlowSteps.LENS_COATING
    };

    let formattedSteps = [];
    let lastStep = null;
    for (let stepName of stepNames) {
        if (typeof stepNameMapping[stepName] === 'undefined') {
            continue;
        }

        const parentTreeForStep = traverseUpToParentTreeForStep(finalChild, stepNameMapping[stepName].name);
        if (parentTreeForStep && shouldSkipStep(parentTreeForStep, isForLensReplacement)) {
            continue;
        }

        if (!isForLensReplacement && stepName === 'Frame Type') {
            continue;
        }

        formattedSteps.push(stepNameMapping[stepName]);

        lastStep = stepName;

        if (lastStep === 'Lens Type' && stepNames.indexOf('Rx Type') !== -1 && doesRequireEnterRxStep(flowSelections)) {
            formattedSteps.push(eyewearFlowSteps.ENTER_RX);
        }

        if (lastStep === 'Lens Type' && doesRequireLensPowerStep(flowSelections)) {
            formattedSteps.push(eyewearFlowSteps.LENS_POWER);
        }
    }

    if (isForLensReplacement) {
        formattedSteps.push(eyewearFlowSteps.FRAME_DETAILS);
    }

    return formattedSteps;
}

function getNextStepFromNonApiStep(currentStep, steps) {
    let foundCurrentStep = false;
    for (let step of steps) {
        if (step.name.toLowerCase() === currentStep.toLowerCase()) {
            foundCurrentStep = true;
            continue;
        }

        if (!foundCurrentStep) {
            continue;
        }

        return step.name;
    }

    return null;
}

function getPotentialNonApiStep(currentStep, determinedNextStep, steps) {
    let currentPath = getStepPath(currentStep);
    let determinedNextStepPath = getStepPath(determinedNextStep);

    let foundCurrent = false;
    for (let step of steps) {
        if (currentPath === step.page) {
            foundCurrent = true;
            continue;
        }

        if (!foundCurrent) {
            continue;
        }

        if (step.page === determinedNextStepPath) {
            return null;
        }

        return step;
    }

    return null;
}

function isNonApiStep(step) {
    return NON_API_STEPS.indexOf(step) !== -1;
}

export const determineNextStep = (
    flowTreeData,
    currentStep,
    previousSelections,
    currentSelection,
    currentSubStep = null,
    isForLensReplacement = true
) => {
    if (isForLensReplacement && currentStep === STEP_NAMES.LENS_COATING) {
        return STEP_NAMES.FRAME_DETAILS;
    }

    const flowTree = flowTreeData instanceof FlowTree ? flowTreeData : buildFlowTreeObject(flowTreeData);

    let parentTree = getParentTreeForSelections(previousSelections, currentStep, flowTree, currentSubStep);
    if (!parentTree) {
        return null;
    }

    const steps = getSteps(parentTree, isForLensReplacement, previousSelections);
    if (isNonApiStep(currentStep)) {
        return getNextStepFromNonApiStep(currentStep, steps);
    }

    let determinedNextStep = null;

    // Find the child with the current selection
    for (let childTree of parentTree.children) {
        if (childTree.attributeValue !== currentSelection) {
            continue;
        }

        if (childTree.children.length === 0) {
            continue;
        }

        determinedNextStep = childTree.children[0].attributeName;
        break;
    }

    const nonApiStepNext = getPotentialNonApiStep(currentStep, determinedNextStep, steps);
    if (nonApiStepNext) {
        return nonApiStepNext.name;
    }

    // Check to see if next step is
    return determinedNextStep;
}



export const buildQueryStringObjectFromSelections = (flowSelections) => {
    let queryStringObject = {};

    for (let flowSelection of flowSelections) {
        queryStringObject[flowSelection.queryStringKey] = flowSelection.value;
    }

    return queryStringObject;
};

export const getStepPath = (stepName) => {
    let formattedStepName = stepName.toLowerCase();

    const stepMapping = {
        'frame type': eyewearFlowSteps.FRAME_TYPE.page,
        'rx type': eyewearFlowSteps.RX_TYPE.page,
        'lens type': eyewearFlowSteps.LENS_TYPE.page,
        'enter prescription': eyewearFlowSteps.ENTER_RX.page,
        'lens material': eyewearFlowSteps.LENS_MATERIAL.page,
        'lens coating': eyewearFlowSteps.LENS_COATING.page,
        'frame details': eyewearFlowSteps.FRAME_DETAILS.page,
        'addons': eyewearFlowSteps.ADDONS.page,
        'lens power': eyewearFlowSteps.LENS_POWER.page,

        // Use rx type step for rx type variation
        'rx type variation': eyewearFlowSteps.RX_TYPE.page,

        // Use lens type for lens type variation
        'variation': eyewearFlowSteps.LENS_TYPE.page
    };

    return typeof stepMapping[formattedStepName] !== 'undefined' ? stepMapping[formattedStepName] : null;
};

export const getNextStepLink = (stepPath, productSlug, pathPrefix = '/lens-replacement/') => {
    let link = pathPrefix + productSlug;
    if (!stepPath) {
        return link;
    }

    return link + '/' + stepPath;
};

export const goToNextStep = (nextStep, productSlug, queryString, router, isForLensReplacement = true) => {
    const stepPath = getStepPath(nextStep);

    const pathPrefix = isForLensReplacement ? '/lens-replacement/' : '/choose-lenses/';
    const link = getNextStepLink(stepPath, productSlug, pathPrefix);
    router.push({ path: link, query: queryString });
};

function parseQueryStringValue(queryStringValue) {
    if (!Array.isArray(queryStringValue)) {
        return queryStringValue;
    }

    return queryStringValue.map(el => {
        if (isNaN(el)) {
            return el;
        }

        return parseInt(el);
    })
}

function getStepAttributeKeyMapping() {
    return {
        frameType: STEP_NAMES.FRAME_TYPE,
        rxType: STEP_NAMES.RX_TYPE,
        lensType: STEP_NAMES.LENS_TYPE,
        enterRx: STEP_NAMES.ENTER_RX,
        lensMaterial: STEP_NAMES.LENS_MATERIAL,
        lensCoating: STEP_NAMES.LENS_COATING,
        frameDetails: STEP_NAMES.FRAME_DETAILS,
        'addons[]': STEP_NAMES.ADDONS,
        lensPower: STEP_NAMES.LENS_POWER,
        rxTypeVariation: STEP_NAMES.RX_TYPE_VARIATION,
        lensTypeVariation: STEP_NAMES.LENS_TYPE_VARIATION,
        lensColor: STEP_NAMES.LENS_COLOR,
        hasPrism: STEP_NAMES.PRISM
    };
}

export const buildFlowSelectionsFromQueryString = (route) => {
    if (!route?.query) {
        return [];
    }

    let flowSelections = [];

    const stepMapping = getStepAttributeKeyMapping();

    for (let queryKey in route.query) {
        if (typeof stepMapping[queryKey] === 'undefined') {
            continue;
        }

        const queryValue = isNaN(route.query[queryKey]) || queryKey === eyewearFlowSteps.LENS_POWER.id
            ? route.query[queryKey]
            : parseInt(route.query[queryKey]);
        const parsedQueryValue = parseQueryStringValue(queryValue);

        flowSelections.push({
            name: stepMapping[queryKey],
            value: parsedQueryValue,
            queryStringKey: queryKey
        });
    }

    return flowSelections;
};

export const buildSelectedAddonsFromFlowSelections = (flowSelections, addons) => {
    const flowSelectionValue = flowSelections.find(el => el.name === 'Addons');
    if (typeof flowSelectionValue === 'undefined') {
        return [];
    }

    const addonIds = Array.isArray(flowSelectionValue.value) ? flowSelectionValue.value : [flowSelectionValue.value];

    // id, name, price

    let formattedAddons = [];
    for (let addon of addons) {
        if (addonIds.indexOf(addon.stepEntity?.id) === -1) {
            continue;
        }

        formattedAddons.push({
            id: addon.stepEntity.id,
            name: addon.stepEntity?.name,
            price: addon.stepEntity?.price
        });
    }

    return formattedAddons;
};

export const getSelectedValueForStep = (step, flowSelections) => {
    let flowSelectionValue = flowSelections.find(el => {
        return step.id === el?.queryStringKey;
    });

    return typeof flowSelectionValue === 'undefined' ? '' : flowSelectionValue.value;
};

export const getSelectedChoiceFromFlowSelections = (choices, flowSelections, stepName) => {
    let flowSelection = flowSelections.find(el => el.name === stepName);
    if (typeof flowSelection === 'undefined') {
        return null;
    }

    let selectedChoice = choices.find(el => el?.stepEntity?.stepTypeValue === flowSelection.value);

    return typeof selectedChoice === 'undefined' ? null : selectedChoice;
};

export const getSelectedSubChoiceFromFlowSelections = (choice, flowSelections, stepName) => {
    let flowSelection = flowSelections.find(el => el.name === stepName);
    if (typeof flowSelection === 'undefined') {
        return null;
    }

    let selectedSubChoice = choice.subChoices.find(el => el?.stepEntity?.stepTypeValue === flowSelection.value);

    return typeof selectedSubChoice === 'undefined' ? null : selectedSubChoice;
};

export const getFirstVisibleStep = (flowTree, parentTree = null) => {
    if (!flowTree) {
        return null;
    }

    if (flowTree.children.length === 0) {
        return parentTree.attributeName;
    }

    if (flowTree.children.length > 1) {
        return flowTree.children[0].attributeName;
    }

    return getFirstVisibleStep(flowTree.children[0], flowTree);
};

function getAttributeKeyForName(name) {
    const keyMapping = getStepAttributeKeyMapping();

    for (let key in keyMapping) {
        if (keyMapping[key] === name) {
            return key;
        }
    }

    return null;
}

export const buildFlowFirstStepFlowSelections = (flowTree, flowSelections = []) => {
    if (flowTree.children.length === 0 || flowTree.children.length > 1) {
        return flowSelections;
    }

    const key = getAttributeKeyForName(flowTree.children[0].attributeName);

    let additionalSelections = [{
        name: flowTree.children[0].attributeName,
        queryStringKey: key,
        value: flowTree.children[0].attributeValue
    }];

    const newSelections = flowSelections.concat(additionalSelections);

    return buildFlowFirstStepFlowSelections(flowTree.children[0], newSelections);
};

export const buildFlowTreeObject = (flowTreeData, parentTree = null) => {
    const flowTreeCopy = !parentTree ? objectFactory.deepCopy(flowTreeData) : flowTreeData;

    const baseTree = new FlowTree(
        flowTreeCopy.attributeName,
        flowTreeCopy.attributeValue,
        flowTreeCopy.lensPrice,
        flowTreeCopy.steps,
        flowTreeCopy.previousSelections,
        flowTreeCopy.variations,
        flowTreeCopy.templateId,
        parentTree
    );

    for (let child of flowTreeCopy.children) {
        if (child.children.length > 0) {
            const childTree = buildFlowTreeObject(child, baseTree);
            baseTree.addChild(childTree);
            continue;
        }

        baseTree.addChild(new FlowTree(
            child.attributeName,
            child.attributeValue,
            child.lensPrice,
            child.steps,
            child.previousSelections,
            child.variations,
            child.templateId,
            baseTree
        ));
    }

    return baseTree;
}

export const addFlowSelection = (currentSelections, newSelection) => {
    let currentSelectionCopy = objectFactory.deepCopy(currentSelections);

    let filteredFlowSelections = currentSelectionCopy.filter(el => {
        return el.name !== newSelection.name;
    });

    filteredFlowSelections.push(newSelection);

    return filteredFlowSelections;
};

export const removeFlowSelection = (currentSelections, removeSelection) => {
    let currentSelectionsCopy = objectFactory.deepCopy(currentSelections);

    let filteredFlowSelections = currentSelectionsCopy.filter(el => {
        return el.name !== removeSelection;
    });

    return filteredFlowSelections;
};
