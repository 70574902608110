import objectFactory from '@/utils/object-factory';
import storage from '@/utils/storage';
import moment from 'moment';
import thirdPartyTracking from '@/third-party';

const SET_LOADED_SCRIPT = 'Set loaded script';

export const state = () => ({
    loadedScript: {}
});

export const mutations = {
    [SET_LOADED_SCRIPT](state, scriptName) {
        let stateCopy = objectFactory.deepCopy(state.loadedScript);

        stateCopy[scriptName] = true;
        state.loadedScript = stateCopy;
    }
};

export const getters = {
    isLoaded: (state) => {
        return (scriptName) => {
            return typeof state.loadedScript[scriptName] !== 'undefined' && state.loadedScript[scriptName];
        }
    }
};

export const actions = {
    setLoadedScript({ commit }, scriptName) {
        commit(SET_LOADED_SCRIPT, scriptName);
        return Promise.resolve();
    },
    triggerTracking({ dispatch, rootState }, order) {
        if (storage.get('conversionCodesShown') === order?.id) {
            return;
        }

        if (!order?.createdAt) {
            return;
        }

        let orderDate = order?.createdAt ? moment(order.createdAt) : null;
        if (!orderDate || (orderDate && !orderDate.isValid())) {
            return;
        }

        let yesterday = moment().subtract(1, 'days');
        if (!orderDate.isAfter(yesterday)) {
            return;
        }

        // Google Enhanced Tracking Object
        thirdPartyTracking.googleEnhancedConversionTrackingObject(order);

        // Attentive
        thirdPartyTracking.attentiveConversionTracking(order);

        // Google
        thirdPartyTracking.googleAdwordsRemarketingPurchaseTracking(order, 'purchase');

        thirdPartyTracking.googleAnalyticsUniversalConversion(order);

        thirdPartyTracking.googleAdsConversionTracking(order);

        // Bing
        thirdPartyTracking.bingConversionTracking(order);

        // CJ
        thirdPartyTracking.cjConversionTracking(order, rootState?.auth?.currentUser);

        // Pinterest
        thirdPartyTracking.pinterestConversionTracking(order);

        // Quora
        thirdPartyTracking.quoraConversionTracking();

        // Google Enhanced Tracking
        thirdPartyTracking.googleEnhancedConversionTracking(order);

        // SafeOpt
        thirdPartyTracking.safeOptConversionTracking(order);

        // Yotpo
        thirdPartyTracking.yotpoConversionTracking(order);

        // Grin
        thirdPartyTracking.grinConversionTracking(order);

        storage.set('conversionCodesShown', order?.id);
    },
};
