import prescriptionStatusType from "@/constants/prescription-status-type";
import productTypes from "~/constants/product-types";

const SET_PATIENTS = 'Set Patients';
const SET_LOADING_PATIENTS = 'Set Loading Patients';
const SET_PRIMARY_ADDRESS = 'Set Primary Address';
const SET_LOADING_ADDRESS = 'Set Loading Address';
const SET_PRESCRIPTIONS = 'Set Prescriptions';
const SET_LOADING_PRESCRIPTIONS = 'Set Loading Prescriptions';
const SET_PRODUCTS = 'Set Products';
const SET_LOADING_PRODUCTS = 'Set Loading Products';
const SET_ELIGIBLE_ERROR = 'Set Eligible Error';
const SET_VISION_TEST_PRODUCT = 'Set Vision Test Product';

const loadProducts = async (page, axios) => {
    let products = [];
    try {
        let response = await axios.$get('/product', {
            params: {
                productType: productTypes.CONTACT_LENSES.id,
                serializerGroups: 'customerPrescription:get',
                page,
                isPurchasable: 1,
                maxResults: 100
            }
        });

        products = response.data;
        if (response.meta.nextPage) {
            products = products.concat(await loadProducts(response.meta.nextPage, axios));
        }
    } catch (e) {
        // Prevent issues loading products
        console.error(e);
    }

    return products;
};

export const state = () => ({
    patients: [],
    address: null,
    prescriptions: [],
    products: [],
    eligibleError: '',
    visionTestProduct: null,
    isLoadingAddress: false,
    isLoadingPatients: false,
    isLoadingPrescriptions: false,
    isLoadingProducts: false
});

export const mutations = {
    [SET_VISION_TEST_PRODUCT](state, payload) {
        state.visionTestProduct = payload;
    },
    [SET_ELIGIBLE_ERROR](state, payload) {
        state.eligibleError = payload;
    },
    [SET_PRODUCTS](state, payload) {
        state.products = payload;
    },
    [SET_LOADING_PRODUCTS](state, payload) {
        state.isLoadingProducts = payload;
    },
    [SET_PATIENTS](state, payload) {
        state.patients = payload;
    },
    [SET_LOADING_PATIENTS](state, payload) {
        state.isLoadingPatients = payload;
    },
    [SET_PRIMARY_ADDRESS](state, payload) {
        state.address = payload;
    },
    [SET_LOADING_ADDRESS](state, payload) {
        state.isLoadingAddress = payload;
    },
    [SET_PRESCRIPTIONS](state, payload) {
        state.prescriptions = payload;
    },
    [SET_LOADING_PRESCRIPTIONS](state, payload) {
        state.isLoadingPrescriptions = payload;
    }
};

export const actions = {
    setVisionTestProduct({ commit }, payload) {
        commit(SET_VISION_TEST_PRODUCT, payload);
        return Promise.resolve();
    },
    async loadVisionTestProduct({ dispatch }) {
        try {
            let response = await this.$axios.$get('/product/eye-exam');
            await dispatch('setVisionTestProduct', response);
        } catch (e) {
            // Prevent issues loading the vision test product
        }

        return Promise.resolve();
    },
    setEligibleError({ commit }, payload) {
        commit(SET_ELIGIBLE_ERROR, payload);
        return Promise.resolve();
    },
    setProducts({ commit }, payload) {
        commit(SET_PRODUCTS, payload);
        return Promise.resolve();
    },
    async loadProducts({ dispatch, commit }) {
        commit(SET_LOADING_PRODUCTS, true);

        await dispatch('setProducts', await loadProducts(1, this.$axios));

        commit(SET_LOADING_PRODUCTS, false);
    },
    setPatients({ commit }, payload) {
        commit(SET_PATIENTS, payload);
        return Promise.resolve();
    },
    async loadPatients({ dispatch, commit }) {
        commit(SET_LOADING_PATIENTS, true);

        try {
            let response = await this.$axios.$get('/customer/patient');
            await dispatch('setPatients', response.data);
        } catch (e) {
            // Prevent issues loading patients
        }

        commit(SET_LOADING_PATIENTS, false);
        return Promise.resolve();
    },
    setPrimaryAddress({ commit }, payload) {
        commit(SET_PRIMARY_ADDRESS, payload);
        return Promise.resolve();
    },
    async loadPrimaryAddress({ dispatch, commit }) {
        commit(SET_LOADING_ADDRESS, true);

        commit(SET_LOADING_ADDRESS, true);
        try {
            let response = await this.$axios.$get('/customer/address/primary');
            await dispatch('setPrimaryAddress', response);
        } catch (e) {
            // Prevent issues loading primary address
        }

        commit(SET_LOADING_ADDRESS, false);
        return Promise.resolve();
    },
    setPrescriptions({ commit }, payload) {
        commit(SET_PRESCRIPTIONS, payload);
        return Promise.resolve();
    },
    async loadPrescriptions({ dispatch, commit }, patientId = null) {
        commit(SET_LOADING_PRESCRIPTIONS, true);
        try {
            let response = await this.$axios.$get('/customer/prescription', {
                params: {
                    maxResults: 100,
                    status: [prescriptionStatusType.VERIFIED, prescriptionStatusType.EXPIRED]
                }
            });

            await dispatch('setPrescriptions', response.data);
        } catch (e) {
            // Prevent issues loading prescriptions
        }
        commit(SET_LOADING_PRESCRIPTIONS, false);

        return Promise.resolve();
    }
};
