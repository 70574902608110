import handleLinkClick from '@/third-party/algolia/algolia-link-click-event';

export default function ({ item, components, html}) {
    const url = '/' + item.slug;

    return html`
    <div class="aa-ItemWrapper" onClick="${(e) => handleLinkClick(e, url)}">
        <div class="aa-ItemContent">
            <div class="aa-ItemIcon aa-ItemIcon--alignTop">
                <a href="${url}" onClick="${(e) => handleLinkClick(e, url)}">
                    <img
                    src="${item.image}"
                    alt="${item.name}"
                    width="40"
                    height="40"
                    />
                </a>
            </div>
            <div class="aa-ItemContentBody">
                <div class="aa-ItemContentTitle">
                    <a href="${url}" onClick="${(e) => handleLinkClick(e, url)}">
                        ${components.Highlight({
                            hit: item,
                            attribute: 'name',
                        })}
                    </a>
                </div>
                <div class="aa-ItemContentDescription">
                    <a href="${url}" onClick="${(e) => handleLinkClick(e, url)}">
                        ${components.Snippet({
                            hit: item,
                            attribute: 'description',
                        })}
                    </a>
                </div>
            </div>
        </div>
    </div>
    `
};
