export const state = () => ({
    all: [
        {
            id: 1,
            name: '1 Day Acuvue Moist 30 pack',
            price: 191.00,
            offer: true,
            offerPrice: 3,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: require('../assets/images/product1.png'),
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
        {
            id: 2,
            name: '1 Day Acuvue Moist 30 pack',
            price: 300.00,
            offer: false,
            offerPrice: 3,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: require('../assets/images/product2.png'),
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
        {
            id: 3,
            name: '1 Day Acuvue Moist 30 pack',
            price: 166.00,
            offer: true,
            offerPrice: 5,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: require('../assets/images/product3.png'),
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
        {
            id: 4,
            name: '1 Day Acuvue Moist 30 pack',
            price: 200.00,
            offer: false,
            offerPrice: 7,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: require('../assets/images/product1.png'),
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
        {
            id: 5,
            name: '1 Day Acuvue Moist 30 pack',
            price: 191.00,
            offer: true,
            offerPrice: 3,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: require('../assets/images/product2.png'),
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
        {
            id: 6,
            name: '1 Day Acuvue Moist 30 pack',
            price: 300.00,
            offer: false,
            offerPrice: 3,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: require('../assets/images/product3.png'),
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
    ],
    sunglasses: [
        {
            id: 1,
            name: 'Astor',
            price: 191.00,
            offer: true,
            offerPrice: 3,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: "",
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
        {
            id: 2,
            name: 'Emory wide',
            price: 300.00,
            offer: false,
            offerPrice: 3,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: require('../assets/images/sunglass01.png'),
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
        {
            id: 3,
            name: 'Essex',
            price: 166.00,
            offer: true,
            offerPrice: 5,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: require('../assets/images/prescription.png'),
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
        {
            id: 4,
            name: 'Astor',
            price: 200.00,
            offer: false,
            offerPrice: 7,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: "",
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
        {
            id: 5,
            name: 'Emory wide',
            price: 191.00,
            offer: true,
            offerPrice: 3,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: require('../assets/images/sunglass01.png'),
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
        {
            id: 6,
            name: 'Essex',
            price: 300.00,
            offer: false,
            offerPrice: 3,
            latest: true,
            bestSellers: false,
            trending: false,
            image: {
                url: require('../assets/images/prescription.png'),
                alt: ""
            },
            timePeriod: false,
            autoRefill: 26.09,
            dateTime: new Date("December 19, 2020 17:00:00 PDT")
        },
    ]
});
