import phoneFilter from '@/utils/phone-filter';
import paymentMethodTypes from '@/constants/payment-method-types';
import objectFactory from '@/utils/object-factory';

const ADD_GUEST_PRESCRIPTION = 'Add Guest Prescription';
const SET_GUEST_PRESCRIPTIONS = 'Set Guest Prescriptions'
const SET_PAYMENT_METHOD = 'Set Payment Method';
const SET_EMAIL = 'Set Email';
const SET_SHIPPING_ADDRESS = 'Set Shipping Address';
const SET_BILLING_ADDRESS = 'Set Billing Address';
const SET_ADDING_NEW_PAYMENT_METHOD = 'Set Adding New Payment Method';
const SET_ADDING_NEW_ADDRESS = 'Set Adding New Address';
const SET_IS_LOADING_PAYMENT_METHODS = 'Set Is Loading Payment Methods';
const SET_PAYPAL_EMAIL = 'Set Paypal Email';
const SET_PAYPAL_EXPRESS_CHECKOUT_ADDRESS = 'Set PayPal Express Checkout Address';
const SET_PAYMENT_METHOD_ID = 'Set Payment Method ID';
const SET_PAYMENT_METHOD_TYPE = 'Set Payment Method Type';
const SET_NEW_PAYMENT_PAYLOAD = 'Set New Payment Payload';
const SET_CHECKOUT_ERROR = 'Set Checkout Error';
const SET_SUBMITTING_CHECK_OUT = 'Set submitting checkout';
const SET_IS_APPLEPAY_QUICK_CHECKOUT = 'Set is ApplePay quick checkout';
const SET_SHIPPING_METHOD_OPTIONS = 'Set shipping method options';
const SET_PAYMENT_OPTION_CONFIGURATION = 'Set Payment Option configuration'
const SET_BILLING_SAME_AS_SHIPPING = 'Set Billing Same as Shipping';
const SET_EXPRESS_CHECKOUT_FROM_CART = 'Set Express Checkout from Cart';
const SET_CHECKOUT_ERROR_DETAIL = 'Set Checkout Error Detail';
const SET_SELECTED_EXISTING_ADDRESS = 'Set Selected Existing Address';
const SET_PAYPAL_DIRECT_RESPONSE = 'Set PayPalDirect Response';

export const state = () => ({
    guestPrescriptions: [],
    email: '',
    addingNewAddress: true,
    addingNewPaymentMethod: true,
    shippingAddress: {
        firstName: '',
        lastName: '',
        line1: '',
        line2: '',
        city: '',
        stateCode: '',
        zipCode: '',
        countryCode: '',
        phone: ''
    },
    billingAddress: {
        firstName: '',
        lastName: '',
        line1: '',
        line2: '',
        city: '',
        stateCode: '',
        zipCode: '',
        countryCode: '',
        phone: ''
    },
    billingSameAsShipping: true,
    isLoadingPaymentMethods: false,
    paypalEmail: '',
    paypalExpressCheckoutAddress: null,
    selectedPaymentMethodType: 0,
    selectedPaymentMethodId: 0,
    newPaymentPayload: {
        cardNumber: '',
        expMonth: '',
        expYear: '',
        cvc: ''
    },
    checkoutError: '',
    checkoutErrorDetail: null,
    isSubmittingCheckout: false,
    isApplePayQuickCheckout: false,
    shippingMethodOptions: [],
    paymentOptionConfiguration: [],
    expressCheckoutFromCart: false,
    selectedExistingAddress: null,
    paypalDirectResponse: null
});

export const mutations = {
    [SET_PAYPAL_DIRECT_RESPONSE](state, payload) {
        state.paypalDirectResponse = payload;
    },
    [SET_SELECTED_EXISTING_ADDRESS](state, payload) {
        state.selectedExistingAddress = payload;
    },
    [SET_EXPRESS_CHECKOUT_FROM_CART](state, payload) {
        state.expressCheckoutFromCart = payload;
    },
    [SET_CHECKOUT_ERROR_DETAIL](state, payload) {
        state.checkoutErrorDetail = payload;
    },
    [SET_BILLING_SAME_AS_SHIPPING](state, payload) {
        state.billingSameAsShipping = payload;
    },
    [SET_CHECKOUT_ERROR](state, payload) {
        state.checkoutError = payload;
    },
    [SET_PAYMENT_OPTION_CONFIGURATION](state, payload) {
        state.paymentOptionConfiguration = payload;
    },
    [SET_SHIPPING_METHOD_OPTIONS](state, payload) {
        state.shippingMethodOptions = payload;
    },
    [SET_IS_APPLEPAY_QUICK_CHECKOUT](state, payload) {
        state.isApplePayQuickCheckout = payload;
    },
    [SET_SUBMITTING_CHECK_OUT](state, payload) {
        state.isSubmittingCheckout = payload;
    },
    [SET_NEW_PAYMENT_PAYLOAD](state, payload) {
        state.newPaymentPayload = payload;
    },
    [SET_PAYMENT_METHOD_TYPE](state, payload) {
        state.selectedPaymentMethodType = payload;
    },
    [SET_PAYMENT_METHOD_ID](state, payload) {
        state.selectedPaymentMethodId = payload;
    },
    [SET_PAYPAL_EXPRESS_CHECKOUT_ADDRESS](state, payload) {
        state.paypalExpressCheckoutAddress = payload;
    },
    [SET_PAYPAL_EMAIL](state, payload) {
        state.paypalEmail = payload;
    },
    [SET_IS_LOADING_PAYMENT_METHODS](state, payload) {
        state.isLoadingPaymentMethods = payload;
    },
    [SET_ADDING_NEW_ADDRESS](state, payload) {
        state.addingNewAddress = payload;
    },
    [SET_ADDING_NEW_PAYMENT_METHOD](state, payload) {
        state.addingNewPaymentMethod = payload;
    },
    [SET_EMAIL](state, payload) {
        state.email = payload;
    },
    [SET_SHIPPING_ADDRESS](state, payload) {
        payload.phone = phoneFilter(payload.phone);
        state.shippingAddress = payload;
    },
    [SET_BILLING_ADDRESS](state, payload) {
        payload.phone = phoneFilter(payload.phone);
        state.billingAddress = payload;
    },
    [ADD_GUEST_PRESCRIPTION](state, payload) {
        let guestPrescriptions = state.guestPrescriptions.slice();

        for (let x = 0; x < guestPrescriptions.length; x++) {
            let guestPrescription = guestPrescriptions[x];
            if (guestPrescription.cartItemGroup === payload.cartItemGroup) {
                guestPrescriptions.splice(x, 1);
                break;
            }
        }

        guestPrescriptions.push(payload);

        state.guestPrescriptions = guestPrescriptions;
    },
    [SET_GUEST_PRESCRIPTIONS](state, payload) {
        state.guestPrescriptions = payload;
    }
};

export const actions = {
    setPaypalDirectResponse({ commit }, payload) {
        commit(SET_PAYPAL_DIRECT_RESPONSE, payload);
        commit(SET_PAYPAL_EMAIL, payload?.paymentTokenResponse?.email ? payload.paymentTokenResponse.email : '');
        commit(
            SET_NEW_PAYMENT_PAYLOAD,
            { token: payload?.paymentTokenResponse?.token ? payload.paymentTokenResponse.token : '' }
        );
        return Promise.resolve();
    },
    setSelectedExistingAddress({ commit }, payload) {
        commit(SET_SELECTED_EXISTING_ADDRESS, payload);
        return Promise.resolve();
    },
    setExpressCheckoutFromCart({ commit }, payload) {
        commit(SET_EXPRESS_CHECKOUT_FROM_CART, payload);
        return Promise.resolve();
    },
    removeCheckoutErrorDetailPath({ commit, state }, path) {
        let checkoutErrorDetail = objectFactory.deepCopy(state.checkoutErrorDetail);
        if (!checkoutErrorDetail) {
            return;
        }

        let newCheckoutErrorDetail = [];
        for (let checkoutErrorDetailElement of checkoutErrorDetail) {
            if (path === checkoutErrorDetailElement?.path) {
                continue;
            }

            newCheckoutErrorDetail.push(checkoutErrorDetailElement);
        }

        commit(SET_CHECKOUT_ERROR_DETAIL, newCheckoutErrorDetail);
        return Promise.resolve();
    },
    setCheckoutErrorDetail({ commit }, payload) {
        commit(SET_CHECKOUT_ERROR_DETAIL, payload);
        return Promise.resolve();
    },
    setBillingSameAsShipping({ commit }, payload) {
        commit(SET_BILLING_SAME_AS_SHIPPING, payload);
        return Promise.resolve();
    },
    setCheckoutError({ commit }, payload) {
        commit(SET_CHECKOUT_ERROR, payload);
        return Promise.resolve();
    },

    setPaymentOptionConfiguration({ commit }, payload) {
        commit(SET_PAYMENT_OPTION_CONFIGURATION, payload);
        return Promise.resolve();
    },
    setShippingMethodOptions({ commit }, payload) {
        commit(SET_SHIPPING_METHOD_OPTIONS, payload);
        return Promise.resolve();
    },
    setIsApplePayQuickCheckout({ commit }, payload) {
        commit(SET_IS_APPLEPAY_QUICK_CHECKOUT, payload);
        return Promise.resolve();
    },
    setIsSubmittingCheckout({ commit }, payload) {
        commit(SET_SUBMITTING_CHECK_OUT, payload);
        return Promise.resolve();
    },
    setNewPaymentPayload({ commit }, payload) {
        commit(SET_NEW_PAYMENT_PAYLOAD, payload);
        return Promise.resolve();
    },
    setPaymentMethodType({ commit }, payload) {
        commit(SET_PAYMENT_METHOD_TYPE, payload);
        return Promise.resolve();
    },
    setPaymentMethodId({ commit }, payload) {
        commit(SET_PAYMENT_METHOD_ID, payload);
        return Promise.resolve();
    },
    setPaypalExpressCheckoutAddress({ commit }, payload) {
        commit(SET_PAYPAL_EXPRESS_CHECKOUT_ADDRESS, payload);
        return Promise.resolve();
    },
    setPaypalEmail({ commit }, payload) {
        commit(SET_PAYPAL_EMAIL, payload);
        return Promise.resolve();
    },
    setIsLoadingPaymentMethods({ commit }, payload) {
        commit(SET_IS_LOADING_PAYMENT_METHODS, payload);
        return Promise.resolve();
    },
    setAddingNewAddress({ commit }, payload) {
        commit(SET_ADDING_NEW_ADDRESS, payload);
        return Promise.resolve();
    },
    setAddingNewPaymentMethod({ commit }, payload) {
        commit(SET_ADDING_NEW_PAYMENT_METHOD, payload);
        return Promise.resolve();
    },
    setEmail({ commit }, payload) {
        commit(SET_EMAIL, payload);
        return Promise.resolve();
    },
    setShippingAddress({ commit }, payload) {
        commit(SET_SHIPPING_ADDRESS, payload);
        return Promise.resolve();
    },
    setBillingAddress({ commit }, payload) {
        commit(SET_BILLING_ADDRESS, payload);
        return Promise.resolve();
    },
    addGuestPrescription({ commit }, payload) {
        commit(ADD_GUEST_PRESCRIPTION, payload);
        return Promise.resolve();
    },
    setGuestPrescriptions({ commit }, payload) {
        commit(SET_GUEST_PRESCRIPTIONS, payload);
        return Promise.resolve();
    }
};

export const getters = {
    isApplePayAvailable(state) {
        return () => {
            if (!window.ApplePaySession) {
                return false;
            }

            if (!ApplePaySession.canMakePayments()) {
                return false;
            }

            return true;
        };
    }
};
