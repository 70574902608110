<template>
    <button :disabled="isLoading || isDisabled" @click="onClick">
        <template v-if="!isLoading">
            <slot></slot>
        </template>
        <template v-else>
            <font-awesome-icon icon="fa-solid fa-spinner" spin />
        </template>
    </button>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick(e) {
            this.$emit('click', e);
        }
    }
}
</script>
