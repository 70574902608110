<template>
    <div class="custom-date-of-birth-field-component">
        <div class="form-label-group month-wrapper">
            <select
                class="form-control"
                v-model="dateOfBirth.month"
                @change="onChangeDateOfBirth"
            >
                <option
                    v-for="(month, index) in months"
                    :value="(index + 1 + '').padStart(2, '0')"
                    :key="index"
                >
                    {{ month }}
                </option>
            </select>
            <label>Month</label>
        </div>
        <div class="form-label-group day-wrapper">
            <select
                class="form-control"
                v-model="dateOfBirth.day"
                @change="onChangeDateOfBirth"
            >
                <option
                    v-for="n in 31"
                    :value="(n + '').padStart(2, '0')"
                    :key="n"
                >
                    {{ n }}
                </option>
            </select>
            <label>Day</label>
        </div>
        <div class="form-label-group year-wrapper">
            <select
                class="form-control"
                v-model="dateOfBirth.year"
                @change="onChangeDateOfBirth"
            >
                <option
                    v-for="(year, index) in years"
                    :value="year"
                    :key="index"
                >
                    {{ year }}
                </option>
            </select>
            <label>Year</label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object
        },
        initialValue: {
            type: Object
        },
        futureDatesOnly: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        if (!this.initialValue) {
            let today = new Date();
            this.dateOfBirth.year = today.getFullYear();
            if (this.futureDatesOnly) {
                this.dateOfBirth.month = today.getMonth() + 1;
                this.dateOfBirth.day = today.getDate();
            }

            this.onChangeDateOfBirth();
        } else {
            this.dateOfBirth = this.initialValue;
        }
    },
    data() {
        return {
            dateOfBirth: {
                month: '01',
                day: '01',
                year: 2022
            }
        };
    },
    computed: {
        years() {
            let currentYear = (new Date()).getFullYear();

            let years = [currentYear];
            for (let x = 1; x < 100; x++) {
                years.push(!this.futureDatesOnly ? (currentYear - x) : (currentYear + x));
            }

            return years;
        },
        months() {
            return [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ];
        }
    },
    methods: {
        onChangeDateOfBirth() {
            this.$emit('input', this.dateOfBirth);
        }
    }
}
</script>

<style lang="scss" scoped>
    .custom-date-of-birth-field-component {
        display: flex;
        .month-wrapper {
            width: 50%;
            padding-right: 15px;
        }
        .day-wrapper {
            width: 25%;
            padding-right: 15px;
        }
        .year-wrapper {
            width: 25%;
        }
    }
</style>
