<template>
    <div class="panel-content global-banner-sale global-banner-sale-alt">
        <div v-show="!isGlasses && !isSunglasses && !isLensReplacement && !isContactLenses" @click="toggleModalOpen(true)" class="cursor-hand">
            <div class="hidden-mobile"><span class="emoji">🦸‍♂️</span> Father's Day Sale | <span class="hover-underline">Learn more</span></div>
            <div class="hidden-desktop"><span class="emoji">🦸‍♂️</span> Father's Day Sale | Learn More</div>
        </div>
        <div v-show="isContactLenses" @click="toggleModalOpen(true)" class="cursor-hand">
            <div class="hidden-mobile"><span class="emoji">🦸‍♂️</span> Father's Day Sale | 20% off Contacts | <span class="highlight">DAD24</span> | Ends 6/20</div>
            <div class="hidden-desktop"><span class="emoji">🦸‍♂️</span> Father's Day Sale | Save 20% | <span class="highlight">DAD24</span></div>
        </div>
        <div v-show="isGlasses" @click="toggleModalOpen(true)" class="cursor-hand">
            <div class="hidden-mobile"><span class="emoji">🦸‍♂️</span> Father's Day Sale | 40% off Glasses | <span class="highlight">DAD24</span> | Ends 6/20</div>
            <div class="hidden-desktop"><span class="emoji">🦸‍♂️</span> Father's Day Sale | Save 40% | <span class="highlight">DAD24</span></div>
        </div>
        <div v-show="isSunglasses" @click="toggleModalOpen(true)" class="cursor-hand">
            <div class="hidden-mobile"><span class="emoji">🦸‍♂️</span> Father's Day Sale | 40% off Sunglasses | <span class="highlight">DAD24</span> | Ends 6/20</div>
            <div class="hidden-desktop"><span class="emoji">🦸‍♂️</span> Father's Day Sale | Save 40% | <span class="highlight">DAD24</span></div>
        </div>
        <div v-show="isLensReplacement" @click="toggleModalOpen(true)" class="cursor-hand">
            <div class="hidden-mobile"><span class="emoji">🦸‍♂️</span> Father's Day Sale | 40% off Lenses | <span class="highlight">DAD24</span> | Ends 6/20</div>
            <div class="hidden-desktop"><span class="emoji">🦸‍♂️</span> Father's Day Sale | Save 40% | <span class="highlight">DAD24</span></div>
        </div>

        <Modal :value="globalBannerModalOpen" additionalDialogClass="global-banner-sale-modal global-banner-sale-modal-alt" @close="toggleModalOpen(false)">
            <div>
                <h2><span class="emoji">🦸‍♂️</span> Father's Day Sale</h2>
                <p>
                    Heroic savings all week long!
                </p>
                <p class="font-sm-mobile">
                    Earn 5x points per dollar on contacts<br />
                    Earn 10x points per dollar on all other purchases<br />
                    Check out how to maximize your savings with our <a href="/rewards">FREE rewards program!</a>
                </p>
                <p class="font-sm-mobile">
                    20% off Contacts | <strong class="highlight">DAD24</strong><br />
                    40% off Lens Replacement | <strong class="highlight">DAD24</strong><br />
                    40% off Glasses | <strong class="highlight">DAD24</strong><br />
                    40% off Sunglasses | <strong class="highlight">DAD24</strong><br />
                    Expires June 20
                </p>
                <p class="small">* Discount exclusions on DIY lens replacement, discounted frames, accessories, online vision test, reward points apply.</p>
            </div>
        </Modal>
    </div>
</template>

<script>
import productTypes from '@/constants/product-types';
import Modal from '@/components/modals/Modal';

export default {
    components: {
        Modal
    },
    props: {
        globalBannerProductType: { type: Number, default: 0 }
    },
    data() {
        return { isModalOpen: false };
    },
    computed: {
        globalBannerModalOpen() {
            return this.$store.state.globalBannerModalOpen;
        },
        isGlobalHomepage() {
            return this.globalBannerProductType === 0;
        },
        isContactLenses() {
            return this.globalBannerProductType === productTypes.CONTACT_LENSES.id;
        },
        isGlasses() {
            return this.globalBannerProductType === productTypes.GLASSES.id ||
                this.globalBannerProductType === productTypes.READERS.id;
        },
        isSunglasses() {
            return this.globalBannerProductType === productTypes.SUNGLASSES.id;
        },
        isLensReplacement() {
            return this.globalBannerProductType === productTypes.LENS_REPLACEMENT.id;
        },
        isDiyLensReplacement() {
            return this.globalBannerProductType === productTypes.DIY_LENSES.id;
        },
        isAccessories() {
            return this.globalBannerProductType === productTypes.ACCESSORIES.id;
        }
    },
    methods: {
        toggleModalOpen(status) {
            this.$store.dispatch('setGlobalBannerModalOpen', status);
        }
    }
};
</script>

<style lang="scss" scoped>
.cursor-hand {
    cursor: pointer;
    color: #ffffff;
}
.panel-content {
    color: #ffffff;
    font-size: 15px;
    line-height: normal;
}
</style>

<style lang="scss">
@import '~/assets/scss/variables/breakpoints.scss';
.top-panel.global-banner-sale.light-bg {
    .account {
        color: #000000;
        .reorder-header-link {
            color: #000000;
            img {
                filter: invert(1);
            }
        }
        .dropdown-toggle {
            color: #000000;
            .logged-in-text {
                color: #000000;
            }
        }
        .separator {
            color: #000000;
        }
    }
    .panel-content {
        color: #1E2D42;
    }
    .cursor-hand {
        color: #1E2D42;
    }
}

.panel-content.global-banner-sale.global-banner-sale-alt {
    .highlight {
        color: #1066df;
        font-weight: bold;
    }
    .emoji {
        position: relative;
        top: -1px;
        font-family: 'Noto Color Emoji', sans-serif;
        text-shadow: 0px 0px 2px #000000;
    }
}

.global-banner-sale-modal.global-banner-sale-modal-alt {
    text-align: center;
    .emoji {
        font-family: 'Noto Color Emoji', sans-serif;
        text-shadow: 0px 0px 1px #000000;
    }
    h2 {
        font-size: 36px;
        text-align: center;
        line-height: 1.2em;
    }
    p {
        font-size: 24px;
        &.small {
            font-size: 12px;
        }
    }
    .modal-content {
        background-color: #D5E3FD;
        color: #1E2D42;
        .highlight {
            color: #1066df; /** #A5F8B8 */
            font-weight: bold;
        }
        .close-button {
            font-size: 24px !important;
            color: #ffffff !important;
            &:hover {
                opacity: .5;
                color: #ffffff !important;
            }
        }
        a {
            display: inline;
            color: #1066df;
            font-weight: bold;
            &:hover {
                text-decoration: underline;
            }
        }
        .exclusive-text {
            display: inline-block;
            font-weight: bold;
            background-color: #234e85;
            border: solid #ffffff 1px;
            padding: 10px 30px;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: $breakpoint2) {
    .cursor-hand {
        font-size: 14px;
    }
    .font-sm-mobile {
        font-size: 16px !important;
    }
}

@media (max-width: 414px) {
    .exclusive-text {
        .font-sm-mobile {
            font-size: 14px !important;
        }
    }
}
</style>
