<template>
    <span>{{ installmentPayments }}</span>
</template>

<script>
import formatMoneyMixin from '@/mixins/money-formatter';
export default {
    mixins: [formatMoneyMixin],
    props: {
        amount: {
            type: Number,
            default: 0
        },
        useMoneyFormatter: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        installmentPayments() {
            if(process.browser && typeof window.quadpay === 'undefined')
            {
                return this.formatAmount(this.amount / 4);
            }

            let installments = process.browser ? window.quadpay.calculateInstallments(this.amount) : null;
            if(installments && installments.length > 0)
            {
                let maxAmount = 0;
                installments.forEach(installment => {
                    if(maxAmount < installment.amount)
                    {
                        maxAmount = installment.amount;
                    }
                });

                return this.formatAmount(maxAmount);
            }

            return this.formatAmount(this.amount / 4);
        }
    },
    methods: {
        formatAmount(amount) {
            if (this.useMoneyFormatter) {
                return this.formatMoney(amount);
            }

            return amount;
        }
    }
}
</script>
