<template>
    <div class="steps-wrapper" v-if="!isLoading">
        <div
            v-for="(headerStep, index) in headerSteps"
            :key="index"
            class="step-container"
            :class="{ active: currentStepNumber >= (index + 1), 'current-step': isCurrentStep(headerStep.step.id) }"
            @click="onClickHeaderStep(headerStep, index + 1)"
        >
            <div class="step-number">
                {{ (index + 1) }}
            </div>
            <div class="step-name">
                {{ headerStep.name }}
            </div>
        </div>

        <ConfirmBackButton
            :show="showConfirmModal"
            @close="showConfirmModal = false"
            @confirm="onClickConfirmGoBack"
        />
    </div>
    <div class="steps-wrapper is-loading" v-else>

    </div>
</template>

<script>
import eyewearFlowMixin from '@/mixins/eyewear-flow';
import eyewearFlowProgressMixin from '@/mixins/eyewear-flow-progress-mixin';
import formatImageUrlMixin from '@/mixins/image-url-formatter';
import ConfirmBackButton from '@/components/landing-page/choose-lenses/ConfirmBackButton';

export default {
    mixins: [eyewearFlowMixin, eyewearFlowProgressMixin, formatImageUrlMixin],
    components: {
        ConfirmBackButton
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showConfirmModal: false,
            selectedHeaderStep: null
        };
    },
    computed: {
        headerSteps() {
            let headerSteps = [];
            for (let x = 0; x < this.steps.length; x++) {
                if (this.steps[x] === 'addons') {
                    continue;
                }

                let stepConstant = this.getStepConstant(this.steps[x]);

                let stepName = stepConstant.name;

                if (stepConstant.id === 'enterRx') {
                    stepName = 'Rx';
                }

                if (stepConstant.id === 'rxType') {
                    stepName = 'Rx Type';
                }

                if (stepConstant.id === 'lensCoating') {
                    stepName = 'Upgrades';
                }

                if (stepConstant.id === 'frameDetails') {
                    stepName = 'Details';
                }

                headerSteps.push({
                    name: stepName,
                    step: stepConstant
                });
            }

            return headerSteps;
        }
    },
    methods: {
        onClickHeaderStep(headerStep, stepNumberClicked) {
            if (stepNumberClicked >= this.currentStepNumber) {
                return;
            }

            this.selectedHeaderStep = headerStep;
            this.showConfirmModal = true;
        },
        onClickConfirmGoBack() {
            this.onClickStep(this.selectedHeaderStep.step.id);
            this.selectedHeaderStep = null;
            this.showConfirmModal = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

.steps-wrapper {
    display: flex;
    gap: 10px;
    text-transform: uppercase;
    &.is-loading {
        min-height: 68px;
    }
    .step-container {
        display: flex;
        align-items: center;
        color: #91A5B4;
        &::after {
            content: "";
            background-color: #DCE3E8;
            width: 50px;
            height: 1px;
            margin-left: 10px;
            cursor: default;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        .step-number {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #91A5B4;
            font-size: 15px;
            border: solid #91A5B4 1px;
            background-color: transparent;
            width: 26px;
            height: 26px;
            border-radius: 100px;
            margin-right: 5px;
        }
        &.active {
            color: #000000;
            .step-number {
                color: #ffffff;
                border-color: $color-blue;
                background-color: $color-blue;
            }
            &:not(.current-step) {
                .step-name {
                    &:hover {
                        cursor: pointer;;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media (max-width: $desktopBreakpoint1) {
    .steps-wrapper {
        .step-container {
            .step-number {
                font-size: 12px;
                width: 22px;
                height: 22px;
            }
            &::after {
                width: 30px;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint3) {
    .steps-wrapper {
        gap: 5px;
        .step-container {
            .step-number {
                font-size: 12px;
                width: 20px;
                height: 20px;
            }
            &::after {
                margin-left: 5px;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint4) {
    .steps-wrapper {
        gap: 5px;
        .step-container {
            .step-name {
                font-size: 11px;
            }
            .step-number {
                font-size: 11px;
                width: 20px;
                height: 20px;
            }
            &::after {
                width: 20px;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .steps-wrapper {
        display: none;
    }
}
</style>
