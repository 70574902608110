<template>
    <DefaultNotFoundPage />
</template>

<script>
import DefaultNotFoundPage from '@/pages/_.vue';

export default {
    components: {
        DefaultNotFoundPage
    }
}
</script>
