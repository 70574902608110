<template>
    <div class="glasses-flow-header">
        <div class="container">
            <div class="logo-wrapper">
                <a href="/" @click.prevent="onClickLogo"><img :src="websiteLogo()" alt="lensdirect logo" :class="websiteLogoCss()" /></a>
            </div>
            <DesktopProgress :isLoading="isLoading" />
            <div class="live-chat-wrapper">
                <img :src="formatAssetUrl('images/chat-icon.svg')" alt="chat bubble icon">
                <div>
                    Need help?
                </div>
                <a href="#" @click.prevent="onClickStartLiveChat">
                    Start live chat
                </a>
            </div>
        </div>

        <ConfirmExitModal
            :show="showConfirmExit"
            @close="showConfirmExit = false"
            @confirm="onConfirmExit"
        />
    </div>
</template>

<script>
import formatImageUrlMixin from '@/mixins/image-url-formatter';
import liveChatMixin from '@/mixins/live-chat-mixin';
import DesktopProgress from '@/components/landing-page/lens-replacement/steps/DesktopProgress';
import ConfirmExitModal from '@/components/landing-page/choose-lenses/ConfirmExitModal';

export default {
    mixins: [formatImageUrlMixin, liveChatMixin],
    components: {
        DesktopProgress,
        ConfirmExitModal
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showConfirmExit: false
        };
    },
    methods: {
        onClickStartLiveChat() {
            this.$emit('showLiveChat');
            this.onClickLiveChat();
        },
        onClickLogo() {
            this.showConfirmExit = true;
        },
        onConfirmExit() {
            window.location = '/';
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

.glasses-flow-header {
    position: relative;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 24px #DCE3E86E;
    background-color: #ffffff;
    z-index: 10;
    padding: 12px;
    height: 60px;
    font-size: 14px;
    .container {
        max-width: none;
        width: 98%;
        display: flex;
        justify-content: space-between;
    }
    .logo-wrapper {
        display: flex;
        align-items: center;
        img {
            width: 100%;
            max-height: 30px;
            max-width: 167px;
            min-width: 185px;
            &.xmas-logo {
                min-width: auto;
            }
        }
    }

    .live-chat-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
        color: #000000;
        min-width: 250px;
        font-size: 16px;
        img {
            margin-top: 5px;
        }
        a {
            text-decoration: underline;
        }
    }
}

@media (max-width: $desktopBreakpoint1) {
    .glasses-flow-header {
        font-size: 12px;
        .live-chat-wrapper {
            font-size: 14px;
        }
    }
}

@media (max-width: $desktopBreakpoint3) {
    .glasses-flow-header {
        .container {
            width: 100%;
        }
        .live-chat-wrapper {
            min-width: 200px;
        }
    }
}

@media (max-width: $breakpoint2) {
    .glasses-flow-header {
        display: none;
    }
}
</style>
