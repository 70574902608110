<template>
    <CustomButton
        class="btn-facebook"
        type="button"
        :isLoading="isLoading"
        @click="onClick"
    >
        <font-awesome-icon icon="fa-brands fa-facebook" aria-label="Facebook" alt="Facebook" />
        <div class="button-text">
            <slot>
                Continue with Facebook
            </slot>
        </div>
    </CustomButton>
</template>

<script>
const DEFAULT_FB_ERROR = "Unable to login with Facebook at this time.  Please try again or refresh the page.";

import storage from '@/utils/storage';
import notificationsMixin from '@/mixins/notifications';

export default {
    mixins: [notificationsMixin],
    props: {
        connectExisting: {
            type: Boolean,
            default: false
        },
        redirectPath: {
            type: String,
            default: '/profile/account-snapshot'
        }
    },
    data() {
        return {
            isLoading: false
        };
    },
    methods: {
        async onClick() {
            if (typeof FB === 'undefined') {
                this.$emit('error', DEFAULT_FB_ERROR);
                return;
            }

            this.isLoading = true;
            let fbPromise = new Promise(async (resolve, reject) => {
                FB.login(async response => {
                    if (!response.authResponse) {
                        return reject({ message: DEFAULT_FB_ERROR });
                    }

                    let payload = {
                        fbAccessToken: response.authResponse.accessToken
                    };

                    try {
                        let endpoint = this.connectExisting ? '/customer/facebook' : '/customer/login/facebook';
                        await this.$axios.$post(endpoint, payload);
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                }, { scope: 'public_profile,email' });
            });

            try {
                await fbPromise;
                this.$store.dispatch('auth/setLoginRedirect', '/facebook-redirect');
                storage.set('fbLoginRedirect', this.redirectPath, 60000);
                this.$emit('loggedIn');
            } catch (e) {
                this.$emit('error', e);
                this.isLoading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/breakpoints.scss';

    .btn-facebook {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: #ffffff;
        border: 0px;
        padding: 15px 10px;
        width: 100%;
        border-radius: 5px;
        background-color: #3F5AA9;
        font-size: 18px;
        &:hover {
            background-color: #2c3e73;
        }
        .fa-facebook {
            position: absolute;
            font-size: 24px;
            left: 15px;
            top: 18px;
            margin-right: auto;
        }
    }

    @media (max-width: $breakpoint4) {
        .btn-facebook {
            .button-text {
                margin-left: 25px;
            }
        }
    }
</style>
