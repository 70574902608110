<template>
    <form class="login-form-container" @submit.prevent="onSubmit">
        <h1>
            Forgot Password
        </h1>
        <div class="alert alert-danger" v-if="error.message">
            {{ error.message }}
        </div>
        <CustomInput
            class="w-full"
            label="Email Address"
            type="email"
            :errorMessage="error.emailAddress"
            v-model="emailAddress"
            @ref="emailInputRef = $event"
            @input="error.emailAddress = ''"
        />
        <CustomButton
            class="btn btn-outline w-full outline-gray"
            type="submit"
            :isLoading="isLoading"
        >
            Submit
        </CustomButton>
        <div class="create-account-link-container">
            <a @click.prevent="onClickSignIn">Return to Sign In</a>
        </div>
    </form>
</template>

<script>
import notificationsMixin from '@/mixins/notifications';

export default {
    mixins: [notificationsMixin],
    mounted() {
        if (this.$store.state.auth.authFormEmailAddress) {
            this.emailAddress = this.$store.state.auth.authFormEmailAddress;
            this.$store.dispatch('auth/setAuthFormEmail', '');
        }

        this.initFocus();
    },
    data() {
        return {
            emailInputRef: null,
            emailAddress: '',
            error: {
                emailAddress: '',
                message: ''
            },
            isLoading: false
        };
    },
    methods: {
        initFocus() {
            if (this.emailInputRef) {
                this.emailInputRef.focus();
            }
        },
        async onSubmit() {
            this.error = {
                emailAddress: '',
                message: ''
            };

            if (!this.emailAddress) {
                this.error.emailAddress = 'Email address is required.';
                return;
            }

            this.isLoading = true;
            try {
                await this.$store.dispatch('auth/forgotPassword', this.emailAddress);
                this.successMessage('Check your email for a password reset link!', 5000);
                this.$emit('resetLinkSent');
            } catch (e) {
                this.error.message = 'Unable to reset password at this time.  Please contact customer service for more help.';
            }
            this.isLoading = false;
        },
        onClickSignIn() {
            this.$store.dispatch('auth/setAuthFormEmail', this.emailAddress);
            this.$emit('signIn');
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/font.scss';
    @import '~/assets/scss/variables/color.scss';

    .login-form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 400px;
        width: 100%;
        gap: 10px;

        h1 {
            font-family: $font-primary;
            font-weight: 900;
            font-size: 32px;
            padding-bottom: 0px;
            margin-bottom: 0px;
        }

        .btn-outline {
            border-width: 1px;
            min-height: 57px;
        }

        .btn-link {
            font-size: 16px;
            color: #000000;
            &:hover {
                color: $color-blue;
            }
        }

        .create-account-link-container {
            margin-top: 25px;
            font-size: 16px;
            a {
                display: inline;
                font-weight: bold;
                color: $color-blue;
                &:hover {
                    color: $bg-blue-hover;
                    text-decoration: underline;
                }
            }
        }

        ::v-deep {
            .form-label-group {
                margin-bottom: 5px;
            }
        }
    }
</style>
