import { getLastOccurrenceOfCartItem } from '@/third-party/tracking-helper';

function formatAttentiveItems(order) {
    if (!order?.items) {
        return [];
    }

    if (!Array.isArray(order.items)) {
        return [];
    }

    if (order.items.length === 0) {
        return [];
    }

    let items = [];
    for (let x = 0; x < order.items.length; x++) {
        let item = order.items[x];

        items.push({
            name: item?.product?.variantName,
            sku: item?.product?.id,
            quantity: item?.quantity,
            price: item?.subtotal - item?.totalDiscounts
        });
    }

    return items;
}

export function loadAttentiveTracking() {
    if (typeof window === 'undefined') {
        return;
    }

    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://cdn.attn.tv/lensdirect/dtag.js";
        js.type = "text/javascript"
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'attentive-tv-script'));
}

export function attentiveConversionTracking(order) {
    if (typeof window === 'undefined') {
        return;
    }

    window['_LensDirect_Attentive'] = {
        orderId: order?.id,
        phone: order?.shippingAddress?.phone,
        email: order?.customer?.email,
        cartTotal: order?.total,
        couponCode: order?.coupon?.code,
        items: formatAttentiveItems(order)
    }
}

export function attentiveProductPageTracking(product) {
    if (typeof window === 'undefined') {
        return;
    }

    window.attentiveProductSku = product?.id;
    window.attentiveProductName = product?.name;
    window.attentiveProductPrice = product?.price;
}
