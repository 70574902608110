export default {
    computed: {
        hasMoreDetails() {
            if (this.itemGroup.product.contactLenses) {
                return true;
            }

            if (!this.itemGroup.product.eyewear) {
                return false;
            }

            return this.itemGroup.product.lensProduct && this.itemGroup.product.frameProduct;
        }
    }
}
