<template>
    <div class="footer-contact-options-component">
        <p>
            Our friendly customer service team is happy to help you make the right decision and answer any questions!
        </p>
        <ul class="footer-contact">
            <li>
                <a href="tel:18005367111">
                    <img :src="formatAssetUrl('images/footer/01-call.webp')" alt="call" />
                    <h6>Call</h6>
                </a>
            </li>
            <li>
                <a href="mailto:info@lensdirect.com">
                    <img :src="formatAssetUrl('images/footer/02-email.webp')" alt="email" class="email-icon" />
                    <h6>Email</h6>
                </a>
            </li>
            <li>
                <a @click.prevent="onClickLiveChat" href="#">
                    <img :src="formatAssetUrl('images/footer/03-chat.webp')" alt="chat" />
                    <h6>Chat</h6>
                </a>
            </li>
            <li>
                <a href="sms:+15164004534">
                    <img :src="formatAssetUrl('images/footer/04-chat.webp')" alt="sms" class="sms-icon" />
                    <h6>Text</h6>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
export default {
    mixins: [formatImageMixin],
    methods: {
        onClickLiveChat() {
            if (typeof Gladly === undefined) {
                return;
            }

            Gladly.show();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.footer-contact-options-component {
    padding-top: 80px;
    p {
        font-size: 1em;
    }
    .footer-contact {
        display: flex;
        flex-direction: row !important;
        gap: 8px !important;
        padding-top: 15px;
        li {
            border: solid #354155 1px;
            padding: 0px;
            background-color: transparent;
            width: 75px;
            height: 75px;
            h6 {
                font-size: 13px !important;
            }
            &:hover {
                box-shadow: 0px 0px 2px 0px #ffffff25;
            }
        }

        a {
            display: flex;
            flex-direction: column;
            color: #000000;
            height: 100% !important;
            width: 100%;
            justify-content: center;
            gap: 10px;
        }

        h6 {
            margin-top: 0px;
            margin-bottom: 0px !important;
            line-height: 1.1em;
        }

        img {
            align-self: center;
            max-height: 32px;
            max-width: 33px;
        }
    }
}

@media (max-width: $breakpoint2) {
    .footer-contact-options-component {
        padding-top: 30px;
        text-align: center;
        .footer-contact {
            justify-content: center;
            margin-bottom: 0px;
        }
    }
}
</style>
