<template>
    <div class="mobile-profile-menu">
        <button type="button" class="btn btn-profile-menu" @click="onClickToggleMobileMenu">
            <slot name="menuTitleContent"></slot>
            <div class="icon-wrapper" :class="{ 'menu-open': show }">
                <font-awesome-icon icon="fa-solid fa-sort" v-if="!show" />
                <font-awesome-icon icon="fa-solid fa-times" v-else />
            </div>
        </button>
        <div class="mobile-profile-menu-items" :class="{ 'menu-open': show }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import hideChatWindow from '@/utils/hide-chat-window';
import freezeMobileBackdrop from '@/utils/freeze-mobile-backdrop';

export default {
    data() {
        return {
            show: false
        };
    },
    methods: {
        onClickToggleMobileMenu() {
            this.show = !this.show;

            hideChatWindow(this.show);
            freezeMobileBackdrop(this.show);
        }
    },
    watch: {
        $route(newRoute) {
            if (this.show) {
                this.onClickToggleMobileMenu();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

.mobile-profile-menu {
    display: none;
}

@media (max-width: $breakpoint5) {
    .mobile-profile-menu {
        position: relative;
        display: block;
        width: 100%;

        .btn-profile-menu {
            position: relative;
            display: flex;
            align-items: center;
            font-weight: bold;
            width: 100%;
            background-color: #ffffff;
            border: 0px;
            text-align: left;
            border-radius: 0px;
            box-shadow: 0px 0px 10px #00000024;
            min-height: 63px;
            z-index: 10;
            img {
                margin-right: 12px;
                filter: invert(10%) sepia(90%) saturate(5268%) hue-rotate(200deg) brightness(65%) contrast(155%);
            }
            .icon-wrapper {
                margin-left: auto;
                font-size: 14px;
                color: $color-grey-input;
                &.menu-open {
                    font-size: 16px;
                    color: #000000;
                }
            }
        }

        .mobile-profile-menu-items {
            display: none;
            &.menu-open {
                position: absolute;
                top: 63px;
                display: block;
                width: 100%;
                height: calc(100vh - 63px);
                overflow: auto;
                background-color: #ffffff;
                padding: 30px 0px;
                padding-bottom: 150px;
            }

            a {
                font-size: 18px;
                color: $color-grey-text;
                padding: 15px 25px;
                &.active {
                    color: $color-blue;
                }
            }
        }
    }
}
</style>
