export default {
    CLEAR_LENSES: {
        id: 1,
        name: 'Clear Lenses'
    },
    BLUE_LIGHT: {
        id: 2,
        name: 'Blue Light'
    },
    SUNGLASSES: {
        id: 3,
        name: 'Sunglasses'
    },
    TRANSITION: {
        id: 4,
        name: 'Transition'
    }
}
