import { render, staticRenderFns } from "./MobileFutureSteps.vue?vue&type=template&id=68bfebd2&scoped=true&"
import script from "./MobileFutureSteps.vue?vue&type=script&lang=js&"
export * from "./MobileFutureSteps.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68bfebd2",
  null
  
)

export default component.exports