<template>
    <div class="vision-header">
        <div class="d-flex align-items-center">
            <nuxt-link class="navbar-brand" to="/">
                <img :src="websiteLogo()" alt="logo" :class="websiteLogoCss()" />
            </nuxt-link>
            <h6 class="mb-0 ml-5">Quick Vision Test</h6>
        </div>
        <CloseButton>
            <img :src="formatAssetUrl('images/close.png')" alt="close" @click="goHome" class="close-vision" />
        </CloseButton>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    methods: {
        goHome() {
            this.$router.push('/vision-test');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

@media (max-width: $breakpoint2) {
    .vision-header {
        .navbar-brand {
            display: none;
        }
    }
}
</style>
