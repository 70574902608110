

export default {
    data() {
        return {
            isLoadingStepContent: false,
            isLoadingFlowTree: false,
            isLoadingCouponData: false,
            isLoadingPrismProduct: false,
            isLoading: false
        }
    },
    computed: {
        flowTree() {
            return this.$store.state.eyewearFlowV2.flowTree;
        },
        baseProduct() {
            return this.$store.state.eyewearFlow.baseProduct;
        },
        flowSelections() {
            return this.$store.state.eyewearFlowV2.flowSelections;
        }
    },
    methods: {
        async loadFlowData() {
            let promises = [
                this.loadStepContent(),
                this.loadFlowTree(),
                this.loadCouponData(),
                this.loadPrismProduct()
            ];

            await Promise.all(promises);

            return Promise.resolve();
        },
        async loadFlowTree() {
            if (
                this.$store.state.eyewearFlowV2.flowTree &&
                this.$store.state.eyewearFlowV2.flowTreeProductId === this.baseProduct.id
            ) {
                // Don't reload the flow tree
                return Promise.resolve();
            }

            this.isLoadingFlowTree = true;

            try {
                let response = await this.$axios.$get('/eyewear-flow/v2/tree/' + this.baseProduct.id);
                this.$store.dispatch('eyewearFlowV2/setFlowTreeProductId', this.baseProduct.id);
                this.$store.dispatch('eyewearFlowV2/setFlowTree', response);
            } catch (e) {
                // Prevent issues loading
                this.$store.dispatch('eyewearFlowV2/setFlowTree', null);
            }

            this.isLoadingFlowTree = false;

            return Promise.resolve();
        },
        async loadPrismProduct() {
            if (this.$store.state.eyewearFlowV2.prismProduct) {
                return Promise.resolve();
            }

            this.isLoadingPrismProduct = true;
            try {
                let prismProduct = await this.$axios.$get('/eyewear-flow/prism');
                this.$store.dispatch('eyewearFlowV2/setPrismProduct', prismProduct);
            } catch (e) {
                // Prevent issues pulling prism product
            }
            this.isLoadingPrismProduct = false;
        },
        async loadCouponData() {
            if (this.$store.state.eyewearFlowV2.couponData) {
                return Promise.resolve();
            }

            this.isLoadingCouponData = true;

            let couponData = null;
            try {
                couponData = await this.$axios.$get('/eyewear-flow/v2/coupon/' + this.baseProduct.productType);
            } catch (e) {
                // Prevent issues loading the coupon data
            }

            this.isLoadingCouponData = false;
            if (!couponData) {
                return Promise.resolve();
            }

            let formattedCouponData = {
                code: '',
                percentOff: 0
            };

            for (let couponDetail of couponData) {
                let key = couponDetail.name === 'couponCode' ? 'code' : 'percentOff';
                formattedCouponData[key] = couponDetail.value;
            }

            return this.$store.dispatch('eyewearFlowV2/setCouponData', formattedCouponData);
        },
        async loadStepContent() {
            if (this.$store.state.eyewearFlowV2.isStepContentLoaded) {
                return Promise.resolve();
            }

            this.isLoadingStepContent = true;

            let promises = [];

            let steps = [
                {
                    endpoint: 'frame-types',
                    setter: 'setFrameTypes'
                },
                {
                    endpoint: 'rx-types',
                    setter: 'setRxTypes'
                },
                {
                    endpoint: 'rx-type-variations',
                    setter: 'setRxTypeVariations'
                },
                {
                    endpoint: 'lens-types',
                    setter: 'setLensTypes'
                },
                {
                    endpoint: 'lens-type-variations',
                    setter: 'setLensTypeVariations'
                },
                {
                    endpoint: 'lens-type-variation-groups',
                    setter: 'setLensTypeVariationGroups'
                },
                {
                    endpoint: 'lens-materials',
                    setter: 'setLensMaterials'
                },
                {
                    endpoint: 'lens-coatings',
                    setter: 'setLensCoatings'
                },
                {
                    endpoint: 'addons',
                    setter: 'setAddons'
                }
            ];

            for (let step of steps) {
                promises.push((new Promise(async (resolve) => {
                    try {
                        let response = await this.$axios.$get('/eyewear-flow/v2/' + step.endpoint);
                        this.$store.dispatch('eyewearFlowV2/' + step.setter, response.data);
                    } catch (e) {
                        // Prevent issues loading frame types
                        console.log(e);
                    }

                    return resolve();
                })));
            }

            await Promise.all(promises);

            this.$store.dispatch('eyewearFlowV2/setIsStepContentLoaded', true);

            this.isLoadingStepContent = false;

            return Promise.resolve();
        }
    }
}
