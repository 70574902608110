export const formatStepName = (stepName) => {
    const mapping = {
        'prescription type': 'Rx Type',
        'lens coating': 'Upgrades',
        'frame details': 'details',
        'enter prescription': 'Rx'
    }

    if (typeof mapping[stepName.toLowerCase()] !== 'undefined') {
        return mapping[stepName.toLowerCase()];
    }

    return stepName;
};

export const isCurrentStep = (step, currentStepName) => {
    return formatStepName(step.name).toLowerCase() === currentStepName.toLowerCase();
};

export const getCurrentStepNumber = (steps, currentStepName) => {
    let stepCounter = 0;

    for (let step of steps) {
        stepCounter++;
        if (isCurrentStep(step, currentStepName)) {
            break;
        }
    }

    return stepCounter;
};

export const getPreviousStepsForStepName = (steps, stepName) => {
    let previousSteps = [];
    for (let step of steps) {
        if (formatStepName(step.name).toLowerCase() === formatStepName(stepName).toLowerCase()) {
            break;
        }
        previousSteps.push(step);
    }

    return previousSteps;
};
