<template>
    <div>
        <div class="eyewear-flow-container">
            <div class="eyewear-flow-step-container">
                <h3>
                    <b-skeleton />
                </h3>
                <div class="eyewear-flow-choice-container">
                    <b-skeleton />
                    <b-skeleton />
                    <b-skeleton />
                </div>
            </div>
            <Sidebar :isLoading="true" />
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/landing-page/lens-replacement/steps/Sidebar';

export default {
    components: {
        Sidebar
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.eyewear-flow-container {
    .eyewear-flow-step-container {
        .eyewear-flow-choice-container {
            .b-skeleton {
                width: 100%;
                height: 114px;
                border-radius: 10px;
            }
        }
        h3 {
            display: flex;
            align-items: center;
            height: 56px;
            .b-skeleton {
                width: 40%;
                height: 15px;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .eyewear-flow-container {
        .eyewear-flow-step-container {
            .eyewear-flow-choice-container {
                .b-skeleton {
                    height: 80px;
                }
            }
        }
    }
}
</style>
