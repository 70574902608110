const YOTPO_APP_KEY = 'qMsHPROU1fjnlTupCEHIMbpMRTvLkvAsURoYlRbC';

export function yotpoConversionTracking(order) {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.LensDirectYotpoConversionLoaded === 'undefined') {
        (function e(){var e=document.createElement("script");e.type="text/javascript",e.async=true,e.src="//staticw2.yotpo.com/" + YOTPO_APP_KEY + "/widget.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)})();
        window.LensDirectYotpoConversionLoaded = true;
    }

    window.yotpoTrackConversionData = {
        orderId: order?.id + '',
        orderAmount: order?.total + '',
        orderCurrency: 'USD'
    };
};
