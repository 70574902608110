import { determineNextStep, buildFlowTreeObject } from '@/utils/eyewear-flow-v2/flow-tree-utils';

export default {
    computed: {
        flowTree() {
            return this.$store.getters['eyewearFlowV2/builtFlowTree'];
        },
        flowSelections() {
            return this.$store.state.eyewearFlowV2.flowSelections;
        },
        baseProduct() {
            return this.$store.state.eyewearFlow.baseProduct;
        },
        previousSelections() {
            return this.$store.state.eyewearFlowV2.previousSelections;
        }
    },
    methods: {
        isChoicePreviouslySelected(choice, overrideStepProperty = null) {
            overrideStepProperty = overrideStepProperty
                ? overrideStepProperty
                : choice.stepEntity.stepTypeProperty;

            const foundIndex = this.previousSelections.findIndex(e => {
                if (e?.stepEntity?.stepTypeProperty !== overrideStepProperty) {
                    return false;
                }

                return e?.stepEntity?.stepTypeValue === choice.stepEntity.stepTypeValue;
            });

            return foundIndex !== -1;
        },
        scrollToMobileStartAnchor() {
            if (typeof this.$refs['mobile-start-anchor'] === 'undefined') {
                return;
            }

            if (!this.$refs['mobile-start-anchor']) {
                return;
            }

            if (this.$refs['mobile-start-anchor'].$el.offsetParent === null) {
                return;
            }

            this.$refs['mobile-start-anchor'].$el.scrollIntoView();
        },
        getNextStep(stepName, subStepName = null, bypassChoiceCheck = false, flowSelections = null) {
            if (!this.selectedChoice && !bypassChoiceCheck) {
                return null;
            }

            return determineNextStep(
                this.flowTree,
                stepName,
                flowSelections ? flowSelections : this.flowSelections,
                this.selectedChoice?.stepEntity?.stepTypeValue,
                subStepName,
                this.baseProduct.lensReplacement
            );
        }
    }
}
