<template>
    <button type="button" @click="onClick">
        <slot>
            <font-awesome-icon
                icon="fa-solid fa-xmark"
                :aria-label="alt"
                :alt="alt"
            />
        </slot>
    </button>
</template>

<script>
export default {
    props: {
        alt: {
            type: String,
            default: 'Close'
        }
    },
    methods: {
        onClick() {
            this.$emit('click');
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/color.scss';

    button {
        padding: 0px;
        background-color: transparent;
        border: 0px;
        font-size: 1em;
        color: $color-grey-text;
        margin-left: 10px;
        margin-top: 1px;
        line-height: 1em;
        &:hover {
            color: $color-black;
        }
    }
</style>
