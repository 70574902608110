<template>
    <div class="product-cart">
        <div class="product-image">
            <img :src="itemGroup.product.image ? formatProductImageUrl(itemGroup.product.image.url, 'large') : ''" :alt="itemGroup.product.name" />
        </div>
        <div class="product-content">
            <div>
                <p class="p16"><a href="#">{{ itemGroup.product.variantName }}</a></p>
                <span>Qty: {{ itemGroup.quantity }}</span>
                <a @click="onToggleDetails" v-if="hasMoreDetails">
                    <template v-if="showDetails"><span class="text-blue">-</span> Hide details</template>
                    <template v-else><span class="text-blue">+</span> View details</template>
                </a>
                <TransitionExpand>
                    <CartItemDetail
                        v-if="showDetails"
                        :itemGroup="itemGroup"
                    />
                </TransitionExpand>
            </div>
            <div class="product-price">
                <span class="price">${{ formatMoney(itemGroup.total) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import formatMoneyMixin from '@/mixins/money-formatter';
import formatImageUrlMixin from '@/mixins/image-url-formatter';

import TransitionExpand from '@/components/TransitionExpand';
import CartItemDetail from '@/components/cart/CartItemDetail';
import hasMoreDetailsMixin from '@/mixins/has-more-details-mixin';

export default {
    mixins: [formatMoneyMixin, formatImageUrlMixin, hasMoreDetailsMixin],
    components: {
        CartItemDetail,
        TransitionExpand
    },
    props: {
        itemGroup: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showDetails: false
        };
    },
    computed: {

    },
    methods: {
        onToggleDetails() {
            this.showDetails = !this.showDetails;
        }
    }
}
</script>

<style lang="scss" scoped>
    .product-content::v-deep {
        span {
            display: inline !important;
        }
    }
</style>
