const PROJECT_ID = 10000;
const PIXEL_ID = 10019827;

export default function() {
    if (typeof window === 'undefined') {
        return;
    }

    (function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':PROJECT_ID,'properties':{'pixelId':PIXEL_ID}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");
}
