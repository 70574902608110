import eyewearTypes from '@/constants/eyewear-types';
import enterRxStepEntities from '@/constants/rx-input-types';
import eyewearFlowSteps from '@/constants/eyewear-flow-steps';

export const getAttributeObjectById = (id) => {
    switch (id) {
        case 'frameType':
            return eyewearTypes.frameTypes;
        case 'rxType':
            return eyewearTypes.rxTypes;
        case 'lensType':
            return eyewearTypes.lensTypes;
        case 'enterRx':
            return enterRxStepEntities;
        case 'lensMaterial':
            return eyewearTypes.materialTypes;
        case 'lensCoating':
            return eyewearTypes.lensCoatingTypes;
        case 'rxTypeVariation':
            return eyewearTypes.rxTypeVariationTypes;
        case 'lensTypeVariation':
            return eyewearTypes.lensTypeVariationTypes;
    }

    return null;
};

function getAttributeDisplayValueUsingStepEntities(stepEntities, attributeValue) {
    let stepEntity = stepEntities.find(el => {
        return el.stepTypeValue === attributeValue;
    });

    if (typeof stepEntity === 'undefined') {
        return null;
    }

    return stepEntity?.name;
}

function formatLensPower(lensPower) {
    if (parseFloat(lensPower) === 0.0) {
        return '0.00';
    }

    let formattedPower = parseFloat(lensPower).toFixed(2);

    return '+' + formattedPower;
}

export const getAttributeDisplayValue = (attributeId, attributeValue, stepEntities = []) => {
    if (attributeId === eyewearFlowSteps.LENS_POWER.id) {
        return formatLensPower(attributeValue);
    }

    const attributeObject = getAttributeObjectById(attributeId);
    if (!attributeObject) {
        return null;
    }

    for (let key in attributeObject) {
        if (attributeObject[key].id !== attributeValue) {
            continue;
        }

        let stepEntityName = stepEntities.length > 0
            ? getAttributeDisplayValueUsingStepEntities(stepEntities, attributeValue)
            : null;

        if (stepEntityName) {
            return stepEntityName;
        }

        return attributeObject[key].name;
    }

    return null;
};


export const applyAddonsToUpgradeSummary = (selectedAddons, stepDetails) => {
    let upgradeIndex = stepDetails.findIndex(el => el.name === 'Upgrades');
    if (upgradeIndex === -1) {
        return;
    }

    let upgradeValues = []
    upgradeValues.push(stepDetails[upgradeIndex].value);
    for (let selectedAddon of selectedAddons) {
        upgradeValues.push(selectedAddon.name);
    }

    stepDetails[upgradeIndex].value = upgradeValues.join(', ');
};

export const getLensColorDisplayName = (lensTypeVariations, selectedLensTypeVariationType, selectedLensColor) => {
    const selectedLensTypeVariation = lensTypeVariations.find(
        el => el.lensTypeVariation === selectedLensTypeVariationType && el.name === selectedLensColor
    );

    if (typeof selectedLensTypeVariation === 'undefined') {
        return null;
    }

    return selectedLensTypeVariation?.displayName
        ? selectedLensTypeVariation.displayName
        : selectedLensTypeVariation?.name;
}

export const getLensColorShortDisplayName = (lensTypeVariations, selectedLensTypeVariationType, selectedLensColor) => {
    const selectedLensTypeVariation = lensTypeVariations.find(
        el => el.lensTypeVariation === selectedLensTypeVariationType && el.name === selectedLensColor
    );

    if (typeof selectedLensTypeVariation === 'undefined') {
        return null;
    }

    return selectedLensTypeVariation?.content?.displayName
        ? selectedLensTypeVariation.content.displayName
        : selectedLensTypeVariation?.name;
}

export const getLensColorCss = (lensTypeVariations, selectedLensTypeVariationType, selectedLensColor) => {
    const selectedLensTypeVariation = lensTypeVariations.find(
        el => el.lensTypeVariation === selectedLensTypeVariationType && el.name === selectedLensColor
    );

    if (typeof selectedLensTypeVariation === 'undefined') {
        return null;
    }

    return selectedLensTypeVariation?.content?.cssStyle
        ? selectedLensTypeVariation.content.cssStyle
        : '';
}

export const applyLensColorToSummary = (stepDetails, flowSelections, lensTypeVariations) => {
    const selectedColor = flowSelections.find(el => el.queryStringKey === 'lensColor');
    if (typeof selectedColor === 'undefined') {
        return false;
    }

    const selectedLensTypeVariationType = flowSelections.find(el => el.queryStringKey === 'lensTypeVariation');
    if (typeof selectedLensTypeVariationType === 'undefined') {
        return false;
    }

    const lensColorForSummary = getLensColorDisplayName(lensTypeVariations, selectedLensTypeVariationType.value, selectedColor.value);
    if (!lensColorForSummary) {
        return false;
    }

    // Add the lens color directly after the lens type
    const lensTypeIndex = stepDetails.findIndex(el => el.name === 'Lens type');
    if (typeof lensTypeIndex === 'undefined') {
        return false;
    }

    stepDetails.splice(lensTypeIndex + 1, 0, {
        name: 'Lens color',
        value: lensColorForSummary
    });

    return true;
}

export const applyLensColorToSummaryFromCurrentSelection = (stepDetails, lensColorForSummary) => {
    if (!lensColorForSummary) {
        return;
    }

    const lensTypeIndex = stepDetails.findIndex(el => el.name === 'Lens type');
    if (typeof lensTypeIndex === 'undefined') {
        return false;
    }

    stepDetails.splice(lensTypeIndex + 1, 0, {
        name: 'Lens color',
        value: lensColorForSummary
    });

    return true;
};

export const applyRxTypeVariationToSummary = (stepDetails, flowSelections, rxTypeVariations) => {
    const rxTypeSelection = flowSelections.find(el => el.name === 'Rx Type Variation');
    if (typeof rxTypeSelection === 'undefined') {
        return;
    }

    const stepDetailIndex = stepDetails.findIndex(el => el.name === 'Prescription type');
    if (typeof stepDetailIndex === 'undefined') {
        return;
    }

    const rxTypeVariation = rxTypeVariations.find(el => el.rxTypeVariation === rxTypeSelection.value);
    if (typeof rxTypeVariation === 'undefined') {
        return;
    }

    if (typeof stepDetails[stepDetailIndex] === 'undefined') {
        return;
    }

    if (stepDetails[stepDetailIndex].value.indexOf(rxTypeVariation?.content?.shortName) !== -1) {
        return;
    }

    stepDetails[stepDetailIndex].value += ' ' + rxTypeVariation?.content?.shortName;
};
