class FlowTree {
    constructor(attributeName, attributeValue, lensPrice, steps, previousSelections, variations, templateId, parentTree = null) {
        this.attributeName = attributeName;
        this.attributeValue = attributeValue;
        this.lensPrice = lensPrice;
        this.steps = steps;
        this.previousSelections = previousSelections;
        this.variations = variations;
        this.templateId = templateId;
        this.parentTree = parentTree;
        this.children = [];
    }

    addChild(childTree) {
        this.children.push(childTree);
    }
}

export default FlowTree;
