<template>
    <div class="form-label-group" :class="{ 'has-value': value ? true : false }">
        <select ref="customInput" class="form-control" :class="combinedInputClass" :value="value" @change="onInput">
            <slot></slot>
        </select>
        <label>{{ label }}</label>
        <div class="invalid-feedback" v-if="errorMessage">
            {{ errorMessage }}
        </div>
        <div v-if="errorMessage" class="error-icon"></div>
    </div>
</template>

<script>
export default {
    props: {
        inputClass: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: [String, Number],
            default: ''
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.$emit('ref', this.$refs.customInput);
    },
    computed: {
        combinedInputClass() {
            let inputClass = { 'is-invalid': this.errorMessage ? true : false };
            if (this.inputClass) {
                inputClass[this.inputClass] = true;
            }

            return inputClass;
        }
    },
    methods: {
        onInput(e) {
            this.$emit('input', e.target.value);
        }
    }
};
</script>

<style lang="scss" scoped>
.form-label-group {
    .error-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
        padding-right: calc(1.5em + 0.75rem) !important;
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        height: 15px;
        position: absolute;
        top: 18px;
        right: 15px;
    }
    select {
        min-height: 50px;
    }
    label {
        position: absolute;
        font-size: 16px;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        margin-bottom: 0;
        line-height: 1.5;
        color: #91a5b4;
        pointer-events: none;
        cursor: text;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        transition: all 0.1s ease-in-out;
        padding: 0.75rem;
    }
    &.has-value {
        label {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            font-size: 12px;
            color: #777;
        }
    }
}
</style>
