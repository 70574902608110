<template>
    <nuxt />
</template>

<script>
import AppWrapper from '@/components/AppWrapper';

export default {
    components: {
        AppWrapper
    }
}
</script>
