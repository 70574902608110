import {
    getCustomerFavoriteToken,
    fetchCustomerFavoriteToken,
    deleteCustomerFavoriteToken
} from '@/utils/customer-favorites';

const SET_CUSTOMER_FAVORITE_TOKEN = 'Set Customer Favorite Token';
const SET_CUSTOMER_FAVORITES = 'Set Customer Favorites';
const SET_IS_LOADING_FAVORITES = 'Set Is Loading Favorites';

export const state = () => ({
    customerFavoriteToken: null,
    customerFavorites: null,
    isLoadingFavorites: true
});

export const mutations = {
    [SET_CUSTOMER_FAVORITE_TOKEN](state, payload) {
        state.customerFavoriteToken = payload;
    },
    [SET_CUSTOMER_FAVORITES](state, payload) {
        state.customerFavorites = payload;
    },
    [SET_IS_LOADING_FAVORITES](state, payload) {
        state.isLoadingFavorites = payload;
    }
};

export const actions = {
    setCustomerFavoriteToken({ commit }, payload) {
        commit(SET_CUSTOMER_FAVORITE_TOKEN, payload);
        return Promise.resolve();
    },
    setCustomerFavorites({ commit }, payload) {
        commit(SET_CUSTOMER_FAVORITES, payload);
        return Promise.resolve();
    },
    async clearCustomerFavorites({ dispatch }) {
        //deleteCustomerFavoriteToken();
        await dispatch('setCustomerFavorites', []);
        await dispatch('setCustomerFavoriteToken', '');

        return Promise.resolve();
    },
    async resetCustomerFavoriteToken({ dispatch }) {
        await dispatch('clearCustomerFavorites');
        return dispatch('loadCustomerFavoriteToken');
    },
    async loadCustomerFavoriteToken({ commit }) {
        let token = getCustomerFavoriteToken();
        if (token) {
            commit(SET_CUSTOMER_FAVORITE_TOKEN, token);
            return Promise.resolve();
        }

        token = await fetchCustomerFavoriteToken(this.$axios);
        commit(SET_CUSTOMER_FAVORITE_TOKEN, token);

        return Promise.resolve();
    },
    async loadCustomerFavorites({ dispatch, commit }) {
        await dispatch('loadCustomerFavoriteToken');

        commit(SET_IS_LOADING_FAVORITES, true);
        try {
            let response = await this.$axios.$get('/customer/favorite');
            await dispatch('setCustomerFavorites', response);
        } catch (e) {
            // Prevent issues loading favorites
            await dispatch('setCustomerFavorites', []);
            deleteCustomerFavoriteToken();
            await dispatch('loadCustomerFavoriteToken');
        }

        commit(SET_IS_LOADING_FAVORITES, false);

        return Promise.resolve();
    },
    async addFavorite({ dispatch }, product) {
        try {
            let response = await this.$axios.$post('/customer/favorite/product/' + product.id);
            await dispatch('setCustomerFavorites', response);
        } catch (e) {
            // Prevent issues saving a favorite
        }

        return Promise.resolve();
    },
    async removeFavorite({ dispatch }, product) {
        try {
            let response = await this.$axios.$delete('/customer/favorite/product/' + product.id);
            await dispatch('setCustomerFavorites', response);
        } catch (e) {
            // Prevent issues saving a favorite
        }

        return Promise.resolve();
    },
};
