<template>
    <div
        class="custom-file-upload-component"
        :class="{ hovered: draggedOver, 'has-file': hasFile }"
        ref="fileUploadComponent"
    >
        <input type="file" @change="onChange" v-if="!hasFile">
        <div class="text-center h3 mx-auto" v-if="isUploading">
            Uploading...
            <br>
            <font-awesome-icon icon="fa-solid fa-spinner" spin />
        </div>
        <div class="text-center" v-if="!isUploading && !hasFile">
            <slot>
                <strong class="hidden-mobile">
                    Drop your image here, or <span class="text-blue">browse</span>
                </strong>
                <strong class="hidden-desktop">
                    <img :src="formatAssetUrl('images/camera.svg')" class="mb-2 block ml-auto mr-auto">
                    Choose a photo
                </strong>
                <br>
                <small class="text-muted">Supports: JPG, PNG, PDF, GIF.  File size limit: 5MB.</small>
            </slot>
        </div>
        <div class="uploaded-file-container font-18 d-flex w-100 align-items-center" v-if="!isUploading && hasFile">
            <div class="w-75 d-flex align-items-center">
                <template v-if="preview">
                    <img :src="preview" alt="preview image" class="preview-image">
                </template>
                <div class="file-uploaded-container">
                    <strong>File uploaded!</strong>
                    <br>
                    <button
                        class="btn btn-link pt-1"
                        type="button"
                        @click="onClickRemove"
                    >
                        Remove
                    </button>
                </div>
            </div>
            <div class="w-25 text-right h4">
                <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    class="text-blue mr-2"
                />
            </div>
        </div>
    </div>
</template>

<script>
import notificationsMixin from '@/mixins/notifications';
import imageUrlFormatterMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [notificationsMixin, imageUrlFormatterMixin],
    props: {
        value: {
            default: null
        },
        uploadUrl: {
            type: String,
            default: ''
        },
        preview: {
            type: String,
            default: ''
        },
        handleErrors: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            draggedOver: false,
            isUploading: false
        };
    },
    mounted() {
        if (typeof this.$refs.fileUploadComponent === 'undefined') {
            return;
        }

        this.$refs.fileUploadComponent.addEventListener('drop', this.onDropImage);
        this.$refs.fileUploadComponent.addEventListener('dragover', this.onDraggedOver);
        this.$refs.fileUploadComponent.addEventListener('dragleave', this.onDraggedOut);
    },
    destroyed() {
        if (typeof this.$refs.fileUploadComponent === 'undefined') {
            return;
        }

        this.$refs.fileUploadComponent.removeEventListener('drop', this.onDropImage);
        this.$refs.fileUploadComponent.removeEventListener('dragover', this.onDraggedOver);
        this.$refs.fileUploadComponent.removeEventListener('dragleave', this.onDraggedOut);
    },
    computed: {
        hasFile() {
            return this.value ? true : false;
        }
    },
    methods: {
        onDraggedOver() {
            this.draggedOver = true;
        },
        onDraggedOut() {
            this.draggedOver = false;
        },
        onDropImage(e) {
            e.preventDefault();

            this.onChange({
                target: {
                    files: e.dataTransfer.files
                }
            });
        },
        onClickRemove() {
            this.$emit('input', '');
        },
        onChange(e) {
            let file = typeof e.target.files[0] !== 'undefined' ? e.target.files[0] : null;

            if (!file) {
                return;
            }

            if (this.uploadUrl) {
                this.uploadFile(file);
            } else {
                this.$emit('input', file);
            }
        },
        async uploadFile(file) {
            this.isUploading = true;
            try {
                let response = await this.$axios.$post(this.uploadUrl, { image: file }, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                this.$emit('input', response.file);
            } catch (e) {

                if (this.handleErrors) {
                    this.processError(e);
                }

                this.$emit('errors', this.formatError(e));
            }

            this.isUploading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/color.scss';
    @import '~/assets/scss/variables/font.scss';
    @import '~/assets/scss/variables/breakpoints.scss';

    .custom-file-upload-component {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        border: dashed $color-grey-border 2px;
        border-radius: 10px;
        padding: 15px;
        font-family: $font-primary;
        transition: .1s;
        .file-uploaded-container {
            font-size: 1.125em;
            .btn-link {
                font-size: .889em;
            }
        }
        small {
            font-size: .875em;
        }
        &:hover, &.hovered {
            border: dashed $color-grey-input 2px;
        }
        &.is-invalid {
            border-color: $color-red !important;
        }
        &.has-file {
            border: solid $color-blue 2px !important;
            box-shadow: 0px 0px 12px 0px $box-shadow1;
        }
        input {
            opacity: 0;
            cursor: pointer;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            height: 100%;
            width: 100%;
        }
        .btn-remove {
            font-size: .778em;
            padding: 8px 16px;
        }
        .preview-image {
            max-width: 100px;
            max-height: 100px;
            margin-right: 20px;
        }
    }

    @media (max-width: $breakpoint5) {
        .custom-file-upload-component {
            .h3 {
                font-size: 24px;
                .fa-spinner {
                    margin-top: 10px;
                }
            }
        }
    }
</style>
