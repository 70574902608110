<template>
    <client-only>
        <div class="full-page-loader" :class="{ block }">
            <font-awesome-icon v-show="show" icon="fa-solid fa-spinner" spin />
        </div>
    </client-only>
</template>

<script>
export default {
    props: {
        block: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        window.setTimeout(() => {
            this.show = true;
        }, 500);
    },
    data() {
        return {
            show: false
        };
    }
}
</script>

<style lang="scss" scoped>
    .full-page-loader {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 200000;
        background-color: rgba(0,0,0,.15);
        color: #000000;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        &.block {
            position: relative;
            height: 50vh;
            width: 100%;
            background-color: transparent;
        }
    }
</style>
