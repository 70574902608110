import { attentiveConversionTracking } from '@/third-party/attentive';
import {
    googleAdwordsRemarketingPurchaseTracking,
    googleEnhancedConversionTracking,
    googleAdwordsConversionTracking,
    googleAnalyticsUniversalConversion,
    googleAdsConversionTracking,
    googleEnhancedConversionTrackingObject
} from '@/third-party/google';
import { bingConversionTracking } from '@/third-party/bing';
import { cjConversionTracking } from '@/third-party/cj';
import { pinterestConversionTracking } from '@/third-party/pinterest';
import { quoraConversionTracking } from '@/third-party/quora';
import { safeOptConversionTracking } from '@/third-party/safe-opt';
import { yotpoConversionTracking } from '@/third-party/yotpo';
import { grinConversionTracking } from '@/third-party/grin';

export default {
    attentiveConversionTracking,
    googleAdwordsRemarketingPurchaseTracking,
    googleEnhancedConversionTracking,
    googleEnhancedConversionTrackingObject,
    bingConversionTracking,
    cjConversionTracking,
    pinterestConversionTracking,
    quoraConversionTracking,
    googleAdwordsConversionTracking,
    googleAnalyticsUniversalConversion,
    googleAdsConversionTracking,
    safeOptConversionTracking,
    yotpoConversionTracking,
    grinConversionTracking
}
