export function getLastOccurrenceOfCartItem(cart, cartItemPayload) {
    if (!Array.isArray(cart.items)) {
        return null;
    }

    let foundItem = null;
    for (let x = 0; x < cart.items.length; x++) {
        let cartItem = cart.items[x];

        if (
            cartItem?.product?.id !== cartItemPayload?.product.id &&
            cartItem?.product?.variantid !== cartItemPayload?.product?.id
        ) {
            continue;
        }

        if (cartItemPayload?.eye && cartItemPayload?.eye !== cartItem?.eye) {
            continue;
        }

        foundItem = cartItem;
    }

    return foundItem;
}
