const ADD_SEARCH_HISTORY = 'Add search history';
const REMOVE_SEARCH_HISTORY = 'Remove search history';
const SET_SEARCH_HISTORY = 'Set search history';
const SET_IS_VISIBLE = 'Set search is visible';

const SET_OPENED_FROM = 'Set opened from';
const OPENED_FROM_NAVBAR = 'navbar';
const OPENED_FROM_MENU = 'menu';

export const state = () => ({
    isVisible: false,
    openedFrom: '',
    searchHistory: []
});

export const mutations = {
    [SET_OPENED_FROM](state, openedFrom) {
        state.openedFrom = openedFrom;
    },
    [SET_IS_VISIBLE](state, status) {
        state.isVisible = status;
    },
    [SET_SEARCH_HISTORY](state, searchHistory) {
        state.searchHistory = searchHistory;
    },
    [ADD_SEARCH_HISTORY](state, searchTerm) {
        let searchHistory = state.searchHistory.slice();
        searchHistory.unshift(searchTerm);

        let removeIndex = null;
        for (let x = 1; x < searchHistory.length; x++) {
            if (searchHistory[x] === searchTerm) {
                removeIndex = x;
                break;
            }
        }

        if (removeIndex !== null) {
            searchHistory.splice(removeIndex, 1);
        }

        searchHistory = searchHistory.slice(0, 5);

        state.searchHistory = searchHistory;
    },
    [REMOVE_SEARCH_HISTORY](state, index) {
        let searchHistory = state.searchHistory.slice();
        searchHistory.splice(index, 1);
        state.searchHistory = searchHistory;
    }
};

export const actions = {
    openedFromNavbar({ commit }) {
        commit(SET_OPENED_FROM, OPENED_FROM_NAVBAR);
        return Promise.resolve();
    },
    openedFromMenu({ commit }) {
        commit(SET_OPENED_FROM, OPENED_FROM_MENU);
        return Promise.resolve();
    },
    clearSearchHistory({ commit }) {
        commit(SET_SEARCH_HISTORY, []);
        return Promise.resolve();
    },
    addSearchHistory({ commit }, searchTerm) {
        commit(ADD_SEARCH_HISTORY, searchTerm);
        return Promise.resolve();
    },
    removeSearchHistory({ commit }, index) {
        commit(REMOVE_SEARCH_HISTORY, index);
        return Promise.resolve();
    },
    setIsVisibleStatus({ commit }, status) {
        commit(SET_IS_VISIBLE, status);
        return Promise.resolve();
    }
};


export const getters = {
    openedFromMenu: (state) => {
        return state.openedFrom === OPENED_FROM_MENU;
    },
    openedFromNavbar: (state) => {
        return state.openedFrom === OPENED_FROM_NAVBAR;
    }
};
