<template>
    <div class="yotpo-rewards-component" style="display: none"></div>
</template>

<script>
export default {
    props: {
        scriptId: {
            type: String,
            default: 'yotpo-rewards-widget-script'
        }
    },
    mounted() {
        this.loadYotpo();
    },
    computed: {
        cart() {
            return this.$store.state.cart;
        }
    },
    methods: {
        loadYotpo() {
            if (typeof window === 'undefined') {
                return;
            }

            if (!this.$config?.yotpo?.loyalty?.widgetScript) {
                return;
            }

            if (document.getElementById(this.scriptId)) {
                // Don't reload the script
                return;
            }

            const widgetScript = this.$config.yotpo.loyalty.widgetScript;

            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = widgetScript;
                js.type = "text/javascript"
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', this.scriptId));
        }
    },
    watch: {
        cart(newCart, oldCart) {
            if (!newCart || !newCart?.customer) {
                return;
            }

            if (newCart?.customer?.guest) {
                return;
            }

            this.loadYotpo();
        }
    }
}
</script>
