<template>
    <div id="algoliaMobileAutoComplete" class="search-form"></div>
</template>

<script>
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';

export default {
    data() {
        return {
            algoliaSearch: null
        }
    },
    mounted() {
        const searchClient = algoliasearch(this.$config.algolia.appId, this.$config.algolia.apiKey);

        this.algoliaSearch = autocomplete({
            container: '#algoliaMobileAutoComplete',
            placeholder: 'I\'m searching for...',
            detachedMediaQuery: 'none',
            autoFocus: false,
            onStateChange: ({ state }) => {
                if (!state.query) {
                    this.$emit('results', {
                        hits: [],
                        nbHits: null,
                        query: ''
                    });
                }
            },
            getSources: ({ query }) => {
                return [
                    {
                        sourceId: 'products',
                        getItems: () => {
                            const algoliaIndex = this.$config.algolia.index;
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: algoliaIndex,
                                        query,
                                        params: {
                                            hitsPerPage: 5
                                        }
                                    }
                                ],
                                transformResponse: ({ results, hits }) => {
                                    this.$emit('results', results[0]);

                                    return hits;
                                }
                            });
                        },
                        templates: {
                            item() {
                                return '';
                            }
                        }
                    }
                ];
            }
        });
    },
    destroyed() {
        if (this.algoliaSearch) {
            this.algoliaSearch.destroy();
        }
    }
}
</script>

<style lang="scss">
#algoliaMobileAutoComplete {
    .aa-InputWrapperPrefix {
        display: none;
    }
    .aa-Input {
        padding-left: 50px;
        &:focus {
            outline-color: rgba(0,0,0,.1);
        }
    }
    .aa-Form {
        border: 0px;
        border-bottom: solid #DCE3E8 1px;
        border-radius: 0px;
        &:focus-within {
            box-shadow: none;
        }
    }
}
</style>
