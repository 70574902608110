export default {
    COPY_LENSES: {
        id: 1,
        name: 'Copy my lenses'
    },
    UPLOAD: {
        id: 2,
        name: 'Upload a photo'
    },
    ENTER_ONLINE: {
        id: 3,
        name: 'Enter Online'
    },
    PROVIDE_LATER: {
        id: 4,
        name: 'Provide Later'
    },
    USE_EXISTING: {
        id: 5,
        name: 'Use Existing Rx'
    }
}
