<template>
    <div class="mobile-slide-up" @click.prevent="onClickClose">
        <TransitionExpand :speed=".3">
            <div class="slide-up-inner-container" v-if="show && !hide" @click.stop>
                <div class="slide-up-header">
                    <slot name="header"></slot>
                </div>
                <div class="slide-up-content">
                    <slot></slot>
                </div>
            </div>
        </TransitionExpand>
    </div>
</template>

<script>
import TransitionExpand from '@/components/TransitionExpand';

export default {
    components: {
        TransitionExpand,
    },
    props: {
        hide: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        window.setTimeout(() => {
            this.show = true;
        }, 100);
    },
    data() {
        return {
            show: false
        };
    },
    methods: {
        onClickClose() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/color.scss';
        .mobile-slide-up {
            position: fixed;
            overscroll-behavior: none;
            overflow-y: hidden;
            z-index: 100000;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0,0,0,.7);
            .slide-up-inner-container {
                position: fixed;
                bottom: 0px;
                left: 0px;
                right: 0px;
                background-color: #ffffff;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                z-index: 100;
                .slide-up-header {
                    padding: 10px 15px;
                    border-bottom: solid $color-grey-border 1px;
                }
                .slide-up-content {
                    padding: 10px 15px;
                    padding-bottom: 30px;
                }
            }
        }

</style>
