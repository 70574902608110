<template>
    <div id="amazon-root"></div>
</template>

<script>
const NAME = 'amazonLOGIN';

export default {
    mounted() {
        if (this.isLoaded) {
            return;
        }

        window.onAmazonLoginReady = () => {
            amazon.Login.setClientId(this.$config.amazonLogin.clientId);
        };

        (function(d) {
            var a = d.createElement('script'); a.type = 'text/javascript';
            a.async = true; a.id = 'amazon-login-sdk';
            a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
            d.getElementById('amazon-root').appendChild(a);
        })(document);

        this.$store.dispatch('thirdParty/setLoadedScript', NAME);
    },
    computed: {
        isLoaded() {
            return this.$store.getters['thirdParty/isLoaded'](NAME);
        }
    }
}
</script>
