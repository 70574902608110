<template>
    <div class="meet lens-replace" v-if="faq.length > 0">
        <div class="container">
            <h6>Frequently Asked Questions</h6>
            <div>
                <div class="meet-text" v-for="(item, index) in faq" :key="index">
                    <p class="meet-title" v-b-toggle="'collapse-' + index">
                        {{ item.question.content }}<i class="fas fa-angle-up"></i>
                    </p>
                    <b-collapse :id="'collapse-' + index">
                        <div>
                            <div class="meet-description">
                                <p>{{ item.answer.content }}</p>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pageData: {
            type: Object
        }
    },
    computed: {
        faq() {
            if (!this.pageData?.content?.content?.faq) {
                return [];
            }

            return this.pageData.content.content.faq;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.meet {
    background-color: #ffffff;
    .meet-text {
        padding-top: 15px;
        border-bottom: dotted $color-grey-border 1px;
        &:first-child {
            border-top: dotted $color-grey-border 1px;
        }
    }
    .meet-description {
        p {
            background-color: transparent;
            padding: 0px;
            font-size: 16px;
        }
    }
}

@media (max-width: $breakpoint5) {
    .meet {
        .meet-title {
            padding-right: 30px;
        }
        .meet-text {
            display: block;
        }
    }
}
</style>
