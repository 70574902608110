<template>
    <client-only>
        <div style="display: none"></div>
    </client-only>
</template>

<script>
const NAME = 'yotpo';

export default {
    mounted() {
        if (this.isLoaded) {
            return;
        }

        var e=document.createElement("script");e.type="text/javascript",e.async=true,e.src="//staticw2.yotpo.com/" + this.appKey + "/widget.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t);

        this.$store.dispatch('thirdParty/setLoadedScript', NAME);
    },
    computed: {
        isLoaded() {
            return this.$store.getters['thirdParty/isLoaded'](NAME);
        },
        appKey() {
            return this.$config.yotpo.appKey;
        }
    }
}
</script>
