<template>
    <footer class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-12 footer-block">
                    <FooterSection>
                        <template #header>Shop with us</template>
                        <li><nuxt-link to="/contact-lenses">Contact Lenses</nuxt-link></li>
                        <li><nuxt-link to="/glasses">Glasses</nuxt-link></li>
                        <li><nuxt-link to="/sunglasses">Sunglasses</nuxt-link></li>
                        <li><a href="/lens-replacement">Lens Replacement</a></li>
                        <li><nuxt-link to="/accessories">Accessories</nuxt-link></li>
                        <li><nuxt-link to="/vision-test">Online Vision Test</nuxt-link></li>
                        <li><nuxt-link to="/all-brands">All Brands</nuxt-link></li>
                        <li><nuxt-link to="/bludefend">BluDefend Blue Light Blocking</nuxt-link></li>
                        <li><a href="https://islandoptics.com/" target="_blank">Island Optics</a></li>
                        <li><nuxt-link to="/rebate">Rebates</nuxt-link></li>
                    </FooterSection>
                </div>
                <div class="col-lg-3 col-md-12 footer-block">
                    <FooterSection>
                        <template #header>We're here to help</template>
                        <li><nuxt-link to="/help">Contact Information</nuxt-link></li>
                        <li><a href="https://www.lensdirect.com/blog ">Blog</a></li>
                        <li><nuxt-link to="/profile/account-snapshot">My Account</nuxt-link></li>
                        <li><a href="/faq">Frequently Asked Questions</a></li>
                        <li><nuxt-link to="/face-shape-guide">Face Shape Guide</nuxt-link></li>
                        <li><nuxt-link to="/subscribe-and-save">Subscribe and save</nuxt-link></li>
                        <li>
                            <a href="https://api.lensdirect.com/download-pd-ruler" target="_blank">Download PD Ruler</a>
                        </li>
                        <li><nuxt-link to="/how-to-install-sunglass-lenses">How to Install Sunglass Lenses</nuxt-link></li>
                        <li><nuxt-link to="/how-to-identify-sunglasses">How to Identify your Sunglasses</nuxt-link></li>
                        <li><nuxt-link to="/how-lens-replacement-works">How Lens Replacement Works</nuxt-link></li>
                        <li><a href="#" @click.prevent="onClickEnableAccessibilityOptions">Enable Accessibility Options</a></li>
                    </FooterSection>
                </div>
                <div class="col-lg-3 col-md-12 footer-block">
                    <FooterSection>
                        <template #header>Corporate information</template>
                        <li><nuxt-link to="/the-company">The Lens Direct Story</nuxt-link></li>
                        <li>
                            <a
                                href="https://signup.cj.com/member/signup/publisher/?cid=3117602#/branded"
                                target="_blank"
                                >Affiliate program</a
                            >
                        </li>
                        <li><nuxt-link to="/rewards">LD Rewards</nuxt-link></li>
                        <!-- <li><nuxt-link to="/refer">Refer a friend</nuxt-link></li> -->
                        <li><nuxt-link to="/reviews">Reviews</nuxt-link></li>
                        <li><nuxt-link to="/return-policy">Return policy</nuxt-link></li>
                        <li><nuxt-link to="/warranty">Warranty</nuxt-link></li>
                        <li><nuxt-link to="/heroes-offer">Heroes Offer</nuxt-link></li>
                        <li><nuxt-link to="/california-consumer-privacy-act">CCPA</nuxt-link></li>
                        <li><nuxt-link to="/guest/check-order-status">Check Order Status</nuxt-link></li>
                        <li><nuxt-link to="/guest/check-autorefill-status">Check Subscription Status</nuxt-link></li>
                    </FooterSection>
                </div>
                <div class="col-lg-3 col-md-12 footer-block">
                    <div class="single-footer-widget">
                        <h6 class="footer-heading">Speak to somebody</h6>
                        <p>
                            <span
                                >Our friendly (and award-winning) customer service team is happy to help!</span
                            >
                        </p>
                        <ContactOptions />
                        <h6 class="mb-0 footer-heading">Let’s get social</h6>
                        <ul class="footer-social">
                            <li>
                                <a href="https://www.facebook.com/shoplensdirect" target="_blank">
                                    <img
                                        :src="formatAssetUrl('images/facebook.svg')"
                                        aria-label="Facebook"
                                        alt="Facebook"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/lensdirect" target="_blank">
                                    <font-awesome-icon
                                        icon="fa-brands fa-instagram"
                                        aria-label="Instagram"
                                        alt="Instagram"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/lensdirect" target="_blank">
                                    <font-awesome-icon icon="fa-brands fa-twitter" aria-label="Twitter" alt="Twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.pinterest.com/lensdirect/" target="_blank">
                                    <img
                                        :src="formatAssetUrl('images/pinterest.svg')"
                                        aria-label="Pinterest"
                                        alt="Pinterest"
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tiktok.com/@lensdirect" target="_blank">
                                    <font-awesome-icon icon="fa-brands fa-tiktok" aria-label="TikTok" alt="TikTok" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="copyright-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 copyright-block">
                        <client-only>
                            <!-- TrustBox widget - Micro Combo -->
                            <div
                                id="trustpilotFooterWidget"
                                class="trustpilot-widget"
                                data-locale="en-US"
                                data-template-id="5419b6ffb0d04a076446a9af"
                                data-businessunit-id="550884280000ff00057e1e9e"
                                data-style-height="20px"
                                data-style-width="70%"
                                data-theme="light"
                            >
                                <a
                                    href="https://www.trustpilot.com/review/lensdirect.com"
                                    target="_blank"
                                    rel="noopener"
                                    >Trustpilot</a
                                >
                            </div>
                            <!-- End TrustBox widget -->
                        </client-only>
                    </div>
                    <div class="col-lg-6 col-md-6 copyright-block">
                        <ul class="payment-card">
                            <li>
                                <img :src="formatAssetUrl('images/visa.svg')" alt="visa" />
                            </li>
                            <li>
                                <img :src="formatAssetUrl('images/mastercard.svg')" alt="mastercard" />
                            </li>
                            <li>
                                <img :src="formatAssetUrl('images/amex.svg')" alt="amex" />
                            </li>
                            <li>
                                <img :src="formatAssetUrl('images/discover.svg')" alt="discover" />
                            </li>
                            <li>
                                <img :src="formatAssetUrl('images/paypal.svg')" alt="paypal" />
                            </li>
                            <li>
                                <img :src="formatAssetUrl('images/amazon-pay-logo.svg')" alt="amazon-pay" />
                            </li>
                            <li>
                                <img :src="formatAssetUrl('images/zip.svg')" alt="zip" />
                            </li>
                            <li>
                                <img :src="formatAssetUrl('images/apple-pay.svg')" alt="apple-pay" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="privacy-policy inline-links-section">
                <p><nuxt-link to="/privacy-policy">Privacy Policy</nuxt-link> | <nuxt-link to="/terms-and-conditions">Terms & Conditions of Use</nuxt-link></p>
                <p>
                    The material provided on this site is for informational purposes only. Please make sure you get your
                    eyes examined regularly and always follow your eye care professional's instructions for the proper
                    use and care of your contact lenses. It's important to note that if you experience any pain or
                    discomfort from your contact lens, discontinue use immediately and consult your eye care
                    professional. All discounts and promotions are applied to future purchases only and cannot be
                    applied to past purchases. © LensDirect.com All Rights Reserved
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import FooterSection from '@/components/FooterSection';
import ContactOptions from '@/components/ContactOptions';
import formatImageMixin from '@/mixins/image-url-formatter';
import accessibilityOptionsMixin from '@/mixins/accessibility-options-mixin';
import trustpilotMixin from '@/mixins/trustpilot-mixin';

export default {
    name: 'AppFooter',
    mixins: [formatImageMixin, accessibilityOptionsMixin, trustpilotMixin],
    components: {
        FooterSection,
        ContactOptions
    },
    mounted() {
        this.loadTrustpilotWidget('trustpilotFooterWidget');
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/font.scss';
.footer-heading {
    font-size: 20px;
    font-family: $font-primary;
    font-weight: 700;
}
.footer-contact {
    a {
        display: flex;
        flex-direction: column;
        color: #000000;
        height: 100%;
    }

    h6 {
        margin-top: auto;
        margin-bottom: 0px;
        line-height: 1.1em;
    }

    img {
        align-self: center;
        max-height: 20px;
        max-width: 24px;
    }
}

@media (max-width: $breakpoint3) {
    .footer-area .footer-block .single-footer-widget .quick-links li {
        width: 50%;
        text-align: left;
    }
    #tp-widget-wrapper {
        text-align: left !important;
    }
}
</style>
