export const state = () => ({
    address: null,
    processing: null
});

export const mutations = {
    setAddresses (state, payload) {
        state.address = payload
        state.processing = false
        state.loginError = null
    },
    setProcessing (state, payload) {
        state.processing = payload
        state.loginError = null
    }
};

export const actions = {
    async getAddresses ({ commit }, params = {}) {
        try {
            let response = await this.$axios.$get(this.$config.apiBaseURL + '/customer/address', { params });
            commit('setAddresses', response.data);
            return response;
        } catch (e) {
            console.error(e);

            return null;
        }
    },
    getAddressDetail ({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('setProcessing', true)
            this.$axios
            .get(this.$config.apiBaseURL + '/customer/address/' + params.id, params.config)
            .then(res => {
                commit('setProcessing', false)
                return resolve(res.data)
            })
            .catch(err => {
                commit('setProcessing', false)
                return reject(err.response.data.message)
            })
        })
    },
    addAddress ({ commit }, params) {
        return new Promise((resolve, reject) => {
            this.$axios
            .post(this.$config.apiBaseURL + '/customer/address', params.requestBody, params.config)
            .then(res => {
                commit('setProcessing', false)
                return resolve(res.data)
            })
            .catch(err => {
                commit('setProcessing', false)
                return reject(err.data.message)
            })
        })
    },
    editAddress ({ commit }, params) {
        return new Promise((resolve, reject) => {
            this.$axios
            .patch(this.$config.apiBaseURL + '/customer/address/' + params.id, params.requestBody, params.config)
            .then(res => {
                commit('setProcessing', false)
                return resolve(res.data)
            })
            .catch(err => {
                commit('setProcessing', false)
                return reject(err.data.message)
            })
        })
    },
    deleteAddress ({ commit }, params) {
        return new Promise((resolve, reject) => {
            this.$axios
            .delete(this.$config.apiBaseURL + '/customer/address/' + params.id, params.config)
            .then(res => {
                commit('setProcessing', false)
                return resolve(res.data)
            })
            .catch(err => {
                commit('setProcessing', false)
                return reject(err.data.message)
            })
        })
    }
};
