<template>
    <client-only>
        <div style="display: none"></div>
    </client-only>
</template>

<script>
const NAME = 'clarityMS';

export default {
    mounted() {
        if (this.$config.isDev) {
            return;
        }

        if (this.isLoaded) {
            return;
        }

        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "i8gcsegxcs");

        this.$store.dispatch('thirdParty/setLoadedScript', NAME);
    },
    computed: {
        isLoaded() {
            return this.$store.getters['thirdParty/isLoaded'](NAME);
        }
    }
}
</script>
