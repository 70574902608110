<template>
    <div class="mobile-steps-list-container">
        <div
            v-for="(futureStep, index) in futureSteps"
            :key="index"
            class="mobile-step-list-item"
        >
            <span class="step-number">{{ currentStepNumber + index + 1 }}</span> {{ futureStep.name }}
        </div>
    </div>
</template>

<script>
import eyewearFlowMixin from '@/mixins/eyewear-flow';

export default {
    mixins: [eyewearFlowMixin],
    computed: {
        futureSteps() {
            if (!this.currentStepNumber) {
                return [];
            }

            let futureSteps = [];
            for (let x = 0; x < this.steps.length; x++) {
                if ((x + 1) <= this.currentStepNumber) {
                    continue;
                }

                futureSteps.push(this.getStepConstant(this.steps[x]));
            }

            return futureSteps;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
