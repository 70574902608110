export default {
    FRAME_TYPE: {
        id: 'frameType',
        name: 'Frame type',
        page: 'frame-type'
    },
    RX_TYPE: {
        id: 'rxType',
        name: 'Prescription type',
        page: 'rx-type'
    },
    LENS_TYPE: {
        id: 'lensType',
        name: 'Lens type',
        page: 'lens-type'
    },
    ENTER_RX: {
        id: 'enterRx',
        name: 'Enter prescription',
        page: 'enter-rx'
    },
    LENS_MATERIAL: {
        id: 'lensMaterial',
        name: 'Lens material',
        page: 'lens-material'
    },
    LENS_COATING: {
        id: 'lensCoating',
        name: 'Lens coating',
        page: 'lens-coating'
    },
    FRAME_DETAILS: {
        id: 'frameDetails',
        name: 'Frame details',
        page: 'frame-details'
    },
    ADDONS: {
        id: 'addons',
        name: 'Addons',
        page: 'addons'
    },
    LENS_POWER: {
        id: 'lensPower',
        name: 'Lens power',
        page: 'lens-power'
    },
    RX_TYPE_VARIATION: {
        id: 'rxTypeVariation',
        name: 'Prescription Type Variation',
        page: 'rx-type-variation'
    },
    LENS_TYPE_VARIATION: {
        id: 'lensTypeVariation',
        name: 'Lens Type Variation',
        page: 'lens-type-variation'
    },
    ADD_TO_CART: {
        id: 'addToCart',
        name: 'Add to Cart',
        page: 'add-to-cart'
    }
}
