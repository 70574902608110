<template>
    <div class="checkout-process-layout">
        <div class="loading-container">
            <img :src="formatImageUrl('assets/images/header/logo-20200522.svg')" alt="lensdirect logo">
            <div class="mt-3">
                Processing payment...
            </div>
            <div class="mt-3 h3">
                <font-awesome-icon icon="fa-solid fa-spinner" spin />
            </div>
        </div>
        <nuxt />
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin]
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/color.scss';

    .checkout-process-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        .loading-container {
            border: solid $color-grey-border 1px;
            padding: 40px;
            text-align: center;
            img {
                max-width: 185px;
            }
        }
    }
</style>
