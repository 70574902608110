<template>
    <b-modal
        :visible="show"
        centered
        title="Exit the order process"
        size="sm"
        @hidden="onClose"
        modal-class="confirm-exit-eyewear-modal"
    >
        <template v-slot:modal-header-close>
            <img :src="formatAssetUrl('images/times-line.svg')" />
        </template>

        <p class="text-center">
            Are you sure you want to exit the order process and start over?
        </p>

        <template v-slot:modal-footer>
            <CustomButton
                class="btn btn-primary"
                type="button"
                @click="onClickConfirmExit"
            >
                Yes
            </CustomButton>

            <button class="btn btn-outline" type="button" @click="onClose">
                Don't exit
            </button>
        </template>
    </b-modal>
</template>

<script>
import formatImageUrlMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageUrlMixin],
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClickConfirmExit() {
            this.$emit('confirm');
        },
        onClose() {
            this.$emit('close');
        }
    }
}
</script>
