<template>
    <div>
        <LoginModal
            :show="showLogin"
            redirectPath="/profile/order-history"
            @closeModal="onClose"
            @loggedIn="onLoggedIn"
            @close="onClose"
        >
            <template #logInTitle>
                <div class="text-center">
                    Sign In
                </div>
            </template>
        </LoginModal>
    </div>
</template>

<script>
import LoginModal from '@/components/modals/LoginModal';

export default {
    components: {
        LoginModal
    },
    mounted() {
        this.initReorderLensesFunction();
    },
    destroyed() {
        this.removeReorderLensesFunction();
    },
    data() {
        return {
            initedReorderLenses: false
        }
    },
    computed: {
        user() {
            return this.$store.state.auth.currentUser;
        },
        showLogin() {
            return this.$store.state.showReorderLogin;
        }
    },
    methods: {
        onClose() {
            this.$store.dispatch('setShowReorderLogin', false);
        },
        onClickReOrderLenses(e) {
            e.preventDefault();

            if (this.user?.customer) {
                this.$router.push('/profile/order-history');
                return;
            }

            this.$store.dispatch('setShowReorderLogin', true);
        },
        initReorderLensesFunction() {
            if (this.initedReorderLenses) {
                return;
            }

            if (document.getElementById('LDReorderLensesLink')) {
                this.initedReorderLenses = true;
                document.getElementById('LDReorderLensesLink').addEventListener('click', this.onClickReOrderLenses);
            }
        },
        removeReorderLensesFunction() {
            if (document.getElementById('LDReorderLensesLink') && this.initedReorderLenses) {
                this.initedReorderLenses = false;
                document.getElementById('LDReorderLensesLink').removeEventListener('click', this.onClickReOrderLenses);
            }
        },
        onLoggedIn() {
            this.onClose();
            this.$router.push('/profile/order-history');
        }
    }
}
</script>
