import storage from '@/utils/storage';

const getUserTimeZone = () => {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
        return null;
    }
};

const handleMaintenanceMode = (response, app) => {
    try {
        if (parseInt(response.headers['ld-maintenance-mode']) !== 1) {
            return false;
        }
    } catch (e) {
        // Prevent issues
        return false;
    }


    if (app?.context?.route?.name === 'maintenance') {
        return false;
    }

    window.location = '/maintenance';

    throw new Error('Redirecting for maintenance mode...');
};

export default function ({ $axios, redirect, app }) {
    $axios.onRequest(config => {
        config.credentials = true;

        if (typeof window === 'undefined') {
            return;
        }

        if (typeof window.localStorage === 'undefined') {
            return;
        }

        if (app.$config.stagingAuthUser && app.$config.stagingAuthPass) {
            config.auth = {
                username: app.$config.stagingAuthUser,
                password: app.$config.stagingAuthPass
            };
        }

        const timeZone = getUserTimeZone();
        if (timeZone) {
            config.headers['USER-TIMEZONE'] = timeZone;
        }

        const favoriteToken = storage.get('customerFavoriteToken');
        if (favoriteToken) {
            config.headers.common['FAVORITE-TOKEN'] = favoriteToken;
        }

        const idMeIdentity = storage.get('idMeIdentity');
        const idMeIdentityService = storage.get('idMeIdentityService');
        if (!idMeIdentity || !idMeIdentityService) {
            return;
        }

        config.headers.common['X-CUSTOMER-IDENTITY'] = idMeIdentity;
        config.headers.common['X-CUSTOMER-IDENTITY-SERVICE-ID'] = idMeIdentityService;
    });

    $axios.onResponse(response => {
        if (typeof window === 'undefined') {
            return;
        }

        handleMaintenanceMode(response, app);

        if (typeof window.localStorage === 'undefined') {
            return;
        }

        const lastBackendVersionFound = storage.get('lastBackendVersionFound');

        try {
            let backendVersion = parseFloat(response.headers['ld-version']);

            // Prevent the refresh message showing multiple times
            if (lastBackendVersionFound === backendVersion) {
                return;
            }

            storage.set('lastBackendVersionFound', backendVersion);

            let frontendVersion = parseFloat(app?.$config.frontendVersion);
            if (frontendVersion !== backendVersion) {
                let reloadAppEvent = new CustomEvent('reloadApp');
                document.body.dispatchEvent(reloadAppEvent);
            }

        } catch (e) {
            // Prevent issues checking version
            console.log(e);
        }
    });
}
