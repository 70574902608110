import storage from '@/utils/storage';

const CUSTOMER_FAVORITE_STORAGE_KEY = 'customerFavoriteToken';

export const getCustomerFavoriteToken = () => {
    return storage.get(CUSTOMER_FAVORITE_STORAGE_KEY);
};

export const deleteCustomerFavoriteToken = () => {
    storage.delete(CUSTOMER_FAVORITE_STORAGE_KEY);
};

export const fetchCustomerFavoriteToken = async (axios) => {
    let token = null;
    try {
        let response = await axios.get('/customer/favorite/token');
        token = response.data.token;
        storage.set(CUSTOMER_FAVORITE_STORAGE_KEY, token, (60 * 60 * 24 * 30 * 1000));
    } catch (e) {
        // Prevent issues getting token
    }

    return Promise.resolve(token);
};
