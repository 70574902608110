<template>
    <AppWrapper>
        <Header class="hidden-desktop" :hideAuth="true" />

        <CheckoutNavbar></CheckoutNavbar>
        <nuxt />
        <CheckoutFooter></CheckoutFooter>
    </AppWrapper>
</template>

<script>
import AppWrapper from '@/components/AppWrapper';
import Header from '@/layouts/Header';
import CheckoutNavbar from '@/layouts/CheckoutNavbar.vue';
import CheckoutFooter from '@/layouts/CheckoutFooter.vue';

export default {
    components: {
        AppWrapper,
        Header,
        CheckoutNavbar,
        CheckoutFooter
    }
};
</script>
