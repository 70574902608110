<template>
    <div class="pre-loader"></div>
</template>

<script>
export default {
    name: 'AppSiteLoader'
}
</script>

<style lang="scss" scoped>
    .pre-loader {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        /*background-color: rgba(255,255,255,1);*/
        z-index: 999999;

        img {
            position: absolute;
            left: 50%;
            top: 48%;
            transform: translate(-50%, -48%);
        }
    }
</style>
