<template>
    <div class="preloaded-images-eyewear-flow">
        <img
            v-for="(lensType, index) in lensTypes"
            :key="index + '_lens_type_images'"
            :src="formatImageUrl(lensType.image.url)"
            alt="preloaded image"
            aria-hidden="true"
        >
        <img
            v-for="(enterRxImage, index) in enterRxImages"
            :key="index + '_rx_images'"
            :src="formatImageUrl(enterRxImage)"
            alt="preloaded image"
            aria-hidden="true"
        >
        <img :src="formatAssetUrl('images/check-blue-thick.svg')" alt="check" aria-hidden="true" />
        <img :src="formatAssetUrl('images/check-white-thick.svg')" alt="check" aria-hidden="true" />
        <img :src="formatAssetUrl('images/question.svg')" alt="question mark icon" aria-hidden="true" />
    </div>
</template>

<script>
import formatImageUrlMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageUrlMixin],
    data() {
        return {
            enterRxImages: [
                'assets/2.0/images/lens-replacement/01-upload-rx.svg',
                'assets/2.0/images/lens-replacement/02-saved-rx.svg',
                'assets/2.0/images/lens-replacement/copy-icon.svg',
                'assets/2.0/images/lens-replacement/03-enter-online.svg',
                'assets/2.0/images/lens-replacement/04-provide-later.svg'
            ]
        };
    },
    computed: {
        lensTypes() {
            return this.$store.state.eyewearFlowV2.lensTypes;
        }
    }
}
</script>

<style scoped>
.preloaded-images-eyewear-flow {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    top: 0px;
    left: 0px;
    overflow: hidden;
    width: 1px;
    height: 1px;
}
</style>
