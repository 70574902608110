import { determineProductType } from '@/utils/product-type-utils';

const SET_GLOBAL_BANNERS = 'Set global banners';
const SET_GLOBAL_POPUPS = 'Set global popups';
const SET_IS_ADMIN_PREVIEW = 'Set Is Admin Preview';

export const state = () => ({
    globalBanners: [],
    globalPopups: [],
    isAdminPreview: false
});

export const mutations = {
    [SET_GLOBAL_BANNERS](state, payload) {
        state.globalBanners = payload;
    },
    [SET_GLOBAL_POPUPS](state, payload) {
        state.globalPopups = payload;
    },
    [SET_IS_ADMIN_PREVIEW](state, payload) {
        state.isAdminPreview = payload;
    }
};

export const actions = {
    setIsAdminPreview({ commit }, payload) {
        commit(SET_IS_ADMIN_PREVIEW, payload);
        return Promise.resolve();
    },
    setGlobalPopups({ commit }, payload) {
        commit(SET_GLOBAL_POPUPS, payload);
        return Promise.resolve();
    },
    setGlobalBanners({ commit }, payload) {
        commit(SET_GLOBAL_BANNERS, payload);
        return Promise.resolve();
    },
    async loadPreviewUiComponents({ dispatch }) {
        let response = null;
        const search = {
            id: this.$router?.currentRoute?.query?.previewComponents,
            serializerGroups: 'ui-component:get'
        }

        try {
            response = await this.$axios.$get('/admin/ui-component', { params: search });
        } catch (e) {
            // Prevent issues loading previewed components
            response = null;
        }

        if (!response) {
            return Promise.resolve();
        }

        let promises = [dispatch('setIsAdminPreview', true), dispatch('handleUiComponentResponse', response.data)];

        return await Promise.all(promises);
    },
    async handleUiComponentResponse({ dispatch }, response) {
        const actionMapping = {
            'global_banner': 'setGlobalBanners',
            'global_popup': 'setGlobalPopups'
        };

        let sortedComponents = {};

        for (let data of response) {
            if (typeof data.componentType === 'undefined') {
                continue;
            }

            if (typeof actionMapping[data.componentType] === 'undefined') {
                continue;
            }

            if (typeof sortedComponents[data.componentType] === 'undefined') {
                sortedComponents[data.componentType] = [];
            }

            sortedComponents[data.componentType].push(data);
        }

        for (let action in sortedComponents) {
            await dispatch(actionMapping[action], sortedComponents[action]);
        }

        return Promise.resolve();
    },
    async loadUiComponents({ dispatch }) {
        if (this.$router?.currentRoute?.query?.previewComponents) {
            await dispatch('loadPreviewUiComponents');
            return Promise.resolve();
        }

        let response = null;
        try {
            response = await this.$axios.$get('/ui-component');
        } catch (e) {
            // Prevent issues loading ui components
            response = null;
        }

        if (!response) {
            return Promise.resolve();
        }

        return await dispatch('handleUiComponentResponse', response);
    }
};

const checkGlobalBannerUrl = (globalBanner, route) => {
    for (let url of globalBanner.urls) {
        if (route.fullPath.indexOf(url) !== -1) {
            return true;
        }
    }

    return false;
};

export const getters = {
    currentGlobalBanner(state, getters, rootState) {
        return (route) => {
            const currentProductType = determineProductType(route, rootState);
            let filteredBanners = [];
            for (let globalBanner of state.globalBanners) {
                if (state.isAdminPreview) {
                    filteredBanners.push(globalBanner);
                    continue;
                }

                if (
                    globalBanner.productTypes.length > 0 &&
                    globalBanner.productTypes.indexOf(currentProductType) === -1
                ) {
                    continue;
                }

                if (globalBanner.urls.length > 0 && !checkGlobalBannerUrl(globalBanner, route)) {
                    continue;
                }

                filteredBanners.push(globalBanner);
            }

            if (filteredBanners.length === 0) {
                return null;
            }

            if (filteredBanners.length === 1) {
                return filteredBanners[0];
            }

            // Need to figure out which banner to actually show
            let bannerToUse = null;
            let defaultBanner = null;
            let hasPromoBanner = false;
            for (let filteredBanner of filteredBanners) {
                const specificProductBanner = filteredBanner.productTypes.length > 0 || filteredBanner.urls.length > 0;
                if (filteredBanner.promotionStartDate || filteredBanner.promotionEndDate) {
                    bannerToUse = !bannerToUse || !hasPromoBanner || specificProductBanner ? filteredBanner : bannerToUse;
                    hasPromoBanner = true;
                    continue;
                }

                if (specificProductBanner) {
                    bannerToUse = !hasPromoBanner ? filteredBanner : bannerToUse;
                    continue;
                }

                if (!bannerToUse) {
                    bannerToUse = filteredBanner;
                }

                if (filteredBanner.isDefault) {
                    defaultBanner = filteredBanner;
                }
            }

            if (bannerToUse) {
                return bannerToUse;
            }

            if (defaultBanner) {
                return defaultBanner;
            }

            return null;
        }
    }
};
