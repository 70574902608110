const SET_SELECTED_PRODUCT = 'Set Selected Product';
const SET_SELECTED_COLOR = 'Set Selected Color';
const SET_SELECTED_COLOR_TYPE = 'Set Selected Color Type';
const SET_SELECTED_LENS_TYPE = 'Set Selected Lens Type';
const SET_SELECTED_ELITE_INSTALLATION = 'Set Selected Elite Installation';
const SET_ELITE_INSTALLATION_PRODUCT = 'Set Elite Installation Product';
const SET_LOADING_LENS_SHAPE = 'Set Loading Lens Shape';

export const state = () => ({
    diyLensStartingPrice: 21,
    selectedProduct: null,
    selectedColor: '',
    selectedColorType: 'mirrored',
    selectedLensType: '',
    selectedEliteInstallation: false,
    eliteInstallationProduct: null,
    loadingLensShape: ''
});

export const mutations = {
    [SET_LOADING_LENS_SHAPE](state, payload) {
        state.loadingLensShape = payload;
    },
    [SET_SELECTED_PRODUCT](state, payload) {
        state.selectedProduct = payload;
    },
    [SET_SELECTED_COLOR](state, payload) {
        state.selectedColor = payload;
    },
    [SET_SELECTED_COLOR_TYPE](state, payload) {
        state.selectedColorType = payload;
    },
    [SET_SELECTED_LENS_TYPE](state, payload) {
        state.selectedLensType = payload;
    },
    [SET_SELECTED_ELITE_INSTALLATION](state, payload) {
        state.selectedEliteInstallation = payload;
    },
    [SET_ELITE_INSTALLATION_PRODUCT](state, payload) {
        state.eliteInstallationProduct = payload;
    }
};

export const actions = {
    setLoadingLensShape({ commit }, payload) {
        commit(SET_LOADING_LENS_SHAPE, payload);
        return Promise.resolve();
    },
    setSelectedProduct({ commit }, payload) {
        commit(SET_SELECTED_PRODUCT, payload);
        return Promise.resolve();
    },
    setSelectedColor({ commit }, payload) {
        commit(SET_SELECTED_COLOR, payload);
        return Promise.resolve();
    },
    setSelectedColorType({ commit }, payload) {
        commit(SET_SELECTED_COLOR_TYPE, payload);
        return Promise.resolve();
    },
    setSelectedLensType({ commit }, payload) {
        commit(SET_SELECTED_LENS_TYPE, payload);
        return Promise.resolve();
    },
    setSelectedEliteInstallation({ commit }, payload) {
        commit(SET_SELECTED_ELITE_INSTALLATION, payload);
        return Promise.resolve();
    },
    async loadEliteInstallationProduct({ commit }) {
        try {
            let response = await this.$axios.$get('/product/elite-installation');
            commit(SET_ELITE_INSTALLATION_PRODUCT, response);
        } catch (e) {
            // Prevent issues
            commit(SET_ELITE_INSTALLATION_PRODUCT, null);
        }

        return Promise.resolve();
    },
    async addToCart({ state, dispatch }, frameDetails = '') {
        let payload = [];

        payload.push({
            product: state.selectedProduct.id,
            quantity: 1,
            customerFeedback: {
                prescriptionDetails: {
                    od: {
                        power: 0,
                        cylinder: 0
                    },
                    os: {
                        power: 0,
                        cylinder: 0
                    }
                },
                additionalInformation: frameDetails
            }
        });

        if (state.selectedEliteInstallation) {
            payload.push({
                product: state.eliteInstallationProduct.id,
                quantity: 1
            });
        }

        return await dispatch('addToCart', payload, { root: true });
    }
};

export const getters = {
    totalPrice(state) {
        if (!state.selectedProduct) {
            return 0;
        }

        let price = state.selectedProduct.price;
        if (state.eliteInstallationProduct && state.selectedEliteInstallation) {
            price += state.eliteInstallationProduct.price;
        }

        return price
    }
};
