export default function (shouldFreeze) {
    if (typeof document === 'undefined') {
        return;
    }

    if (shouldFreeze) {
        document.documentElement.classList.add('frozen-backdrop-open');
    } else {
        document.documentElement.classList.remove('frozen-backdrop-open');
    }
}
