<template>
    <client-only>
        <back-to-top bottom="50px" right="50px">
            <div class="go-top"><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i></div>
        </back-to-top>
    </client-only>
</template>

<script>
export default {
    name: 'AppBackToTop'
}
</script>
