export const state = () => ({
    autorefill: null
});

export const mutations = {
    setAutorefills (state, payload) {
        state.autorefill = payload
        state.processing = false
        state.loginError = null
    }
};

export const actions = {
    getAutorefills ({ commit }) {
        return new Promise((resolve, reject) => {
            this.$axios
            .get(this.$config.apiBaseURL + '/customer/autorefill')
            .then(res => {
                commit('setAutorefills', res.data)
                return resolve(res.data)
            })
            .catch(err => {
                commit('setProcessing', false)
                return reject(err.response.data.message)
            })
        })
    },
    getDetail ({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('setProcessing', true)
            this.$axios
            .get(this.$config.apiBaseURL + '/customer/autorefill/' + params.id, params.config)
            .then(res => {
                commit('setProcessing', false)
                return resolve(res.data)
            })
            .catch(err => {
                commit('setProcessing', false)
                return reject(err.response.data.message)
            })
        })
    }
};
