export default function (items) {
    let itemGroups = [];
    let itemGroupMapping = {};
    let itemGroupCounter = 0;

    if (!items) {
        return [];
    }

    for (let x = 0; x < items.length; x++) {
        let item = items[x];
        if (typeof item === 'undefined') {
            continue;
        }

        let itemGroupId = item.itemGroupId;
        let itemGroupIndex = 0;

        if (typeof itemGroupMapping[itemGroupId] === 'undefined') {
            itemGroups[itemGroupCounter] = {
                id: itemGroupId,
                total: 0,
                autoRefillTotal: 0,
                autoRefillFrequency: 0,
                product: item.product,
                items: [],
                addons: [],
                quantity: 0,
                couponDiscount: 0,
                addonQuantity: 0
            };
            itemGroupMapping[itemGroupId] = itemGroupCounter;
            itemGroupIndex = itemGroupCounter;
            itemGroupCounter++;
        } else {
            itemGroupIndex = itemGroupMapping[itemGroupId];
        }

        itemGroups[itemGroupIndex].total += item.subtotal;
        itemGroups[itemGroupIndex].couponDiscount += item.couponDiscount;
        itemGroups[itemGroupIndex].autoRefillFrequency = item.autoRefillFrequency;

        if (typeof item.autoRefillSubtotal !== 'undefined' && item.autoRefillSubtotal > 0) {
            itemGroups[itemGroupIndex].autoRefillTotal += item.autoRefillSubtotal;
        }

        if (itemGroups[itemGroupIndex].product.eyewearAddon) {
            // Prevent eyewear addon being the main item group product
            itemGroups[itemGroupIndex].product = item.product;
            itemGroups[itemGroupIndex].price = item.price;
        }

        if (item.product.eyewearAddon) {
            itemGroups[itemGroupIndex].addons.push(item.product);
            itemGroups[itemGroupIndex].addonQuantity += item.quantity;
        } else {
            itemGroups[itemGroupIndex].quantity += item.quantity;
        }

        itemGroups[itemGroupIndex].items.push(item);
    }

    for (let x = 0; x < itemGroups.length; x++) {
        if (itemGroups[x].quantity === 0 && itemGroups[x].addonQuantity > 0) {
            itemGroups[x].quantity = itemGroups[x].addonQuantity;
        }
    }

    return itemGroups;
}
