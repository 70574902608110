export function loadSafeOptTracking() {
    if (typeof window === 'undefined') {
        return;
    }

    window.AddShoppersWidgetOptions = { 'loadCss': false, 'pushResponse': false };
    (!function(){
        var t=document.createElement("script");
        t.type="text/javascript",
        t.async=!0,
        t.id="AddShoppers",
        t.src="https://shop.pe/widget/widget_async.js#654e6569a4b8bb0ce9e5de20",
        document.getElementsByTagName("head")[0].appendChild(t)
    }());
}

export function safeOptConversionTracking(order) {
    if (typeof window === 'undefined') {
        return;
    }

    window.AddShoppersWidgetOptions = { 'loadCss': false, 'pushResponse': false };

    window.AddShoppersConversion = {
        order_id: order?.id,
        value: order?.total,
        currency: "USD",
        email: order?.customer?.email,
        offer_code: order?.coupon?.code
    };

    (!function(){
        var t=document.createElement("script");
        t.type="text/javascript",
        t.async=!0,
        t.id="AddShoppers",
        t.src="https://shop.pe/widget/widget_async.js#654e6569a4b8bb0ce9e5de20",
        document.getElementsByTagName("head")[0].appendChild(t)
    }());
}
