import {
    getSteps,
    getSelectedValueForStep,
} from '@/utils/eyewear-flow-v2/flow-tree-utils';

import {
    getAttributeDisplayValue,
    applyAddonsToUpgradeSummary,
    applyLensColorToSummary,
    applyRxTypeVariationToSummary,
    applyLensColorToSummaryFromCurrentSelection
} from '@/utils/eyewear-flow-v2/flow-tree-summary-utils';

export default {
    props: {
        isForLensReplacement: {
            type: Boolean,
            default: true
        },
        couponDiscount: {
            type: Number,
            default: .2
        },
        couponCode: {
            type: String,
            default: 'REPLACE20'
        }
    },
    computed: {
        estimatedPriceWithDiscount() {
            return this.estimatedPrice * (1 - this.couponDiscount);
        },
        estimatedPrice() {
            return this.$store.getters['eyewearFlowV2/estimatedPrice'];
        },
        flowSelections() {
            return this.$store.state.eyewearFlowV2.flowSelections;
        },
        flowTree() {
            return this.$store.getters['eyewearFlowV2/builtFlowTree'];
        },
        finalChild() {
            return this.$store.getters['eyewearFlowV2/finalChild'];
        },
        currentStep() {
            return this.$store.state.eyewearFlowV2.currentStep;
        },
        currentSubStep() {
            return this.$store.state.eyewearFlowV2.currentSubStep;
        },
        parentTree() {
            return this.$store.getters['eyewearFlowV2/parentTree'];
        },
        steps() {
            return getSteps(this.parentTree, this.isForLensReplacement, this.flowSelections);
        },
        stepDetails() {
            let stepDetails = [];

            const alternateNameMap = {
                'Enter prescription': 'Prescription',
                'Lens coating': 'Upgrades'
            };

            const stepEntityMap = {
                frameType: this.$store.state.eyewearFlowV2.frameTypes,
                rxType: this.$store.state.eyewearFlowV2.rxTypes,
                rxTypeVariation: this.$store.state.eyewearFlowV2.rxTypeVariations,
                lensType: this.$store.state.eyewearFlowV2.lensTypes,
                lensMaterial: this.$store.state.eyewearFlowV2.lensMaterials,
                lensCoating: this.$store.state.eyewearFlowV2.lensCoatings
            };

            for (let step of this.steps) {
                if (step.id === 'frameDetails') {
                    continue;
                }

                let stepName = typeof alternateNameMap[step.name] === 'undefined'
                    ? step.name
                    : alternateNameMap[step.name];

                if (this.$store.state.eyewearFlowV2.currentSelection?.stepEntity?.stepTypeProperty === step.id) {
                    stepDetails.push({
                        name: stepName,
                        value: this.$store.state.eyewearFlowV2.currentSelection?.stepEntity?.name
                    });
                    continue;
                }

                let stepValue = getSelectedValueForStep(step, this.flowSelections);
                let stepEntities = typeof stepEntityMap[step.id] !== 'undefined' ? stepEntityMap[step.id] : [];
                stepDetails.push({
                    name: stepName,
                    value: stepValue ? getAttributeDisplayValue(step.id, stepValue, stepEntities) : ''
                });
            }

            if (this.$store.state.eyewearFlowV2.selectedAddons) {
                applyAddonsToUpgradeSummary(this.$store.state.eyewearFlowV2.selectedAddons, stepDetails);
            }

            const appliedLensColor = applyLensColorToSummary(
                stepDetails,
                this.flowSelections,
                this.$store.state.eyewearFlowV2.lensTypeVariations
            );

            if (!appliedLensColor) {
                applyLensColorToSummaryFromCurrentSelection(
                    stepDetails,
                    this.$store.state.eyewearFlowV2.lensColorForSummary
                );
            }

            applyRxTypeVariationToSummary(
                stepDetails,
                this.flowSelections,
                this.$store.state.eyewearFlowV2.rxTypeVariations
            );

            return stepDetails;
        },
    }
}
