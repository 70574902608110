<template>
    <div class="container" v-if="baseProduct">
        <div class="back-to-frame-button-container">
            <button class="btn btn-link" type="button" @click="showConfirmExit = true">
                <img :src="formatAssetUrl('images/arrow-left-gray.svg')" alt="back" /> <span>Back to Frame</span>
            </button>
        </div>
        <img class="preview-image" :src="formatProductImageUrl(productImage.url)" :alt="productImage.alt ? productImage.alt : 'frame preview image'" />
        <div class="glasses-summary-container">
            <div class="step-details-wrapper">
                <div class="prescription-desc">
                    <p>Frame:</p>
                    <p class="text-capitalize">
                        {{ frameName }}<template v-if="baseProduct.frameProduct"> - {{ baseProduct.frameProduct.attributes.frameColor }}</template>
                    </p>
                </div>
                <div v-for="(selectedChoice, index) in stepDetails" :key="index">
                    <div  class="prescription-desc" v-if="selectedChoice.value != ''">
                        <p class="text-left">{{ selectedChoice.name }}:</p>
                        <p class="text-capitalize step-value-detail">{{ selectedChoice.value }}</p>
                    </div>
                </div>
            </div>
            <hr />
            <client-only>
                <div class="prescription-desc" v-if="discountedPrice">
                    <div class="d-flex flex-column align-items-start">
                        <p class="total bold">Estimated Total:</p>
                        <div v-if="!excludeCoupons">with {{ couponCode }} coupon</div>
                    </div>
                    <div class="d-flex flew-row align-items-baseline">
                        <div class="text-capitalize discount-price bold total text-danger mr-1">${{ formatMoney(discountedPrice) }}</div>
                        <div class="text-capitalize text-line-through" v-if="!excludeCoupons">${{ formatMoney(estimatedPrice) }}</div>
                    </div>
                </div>
                <div class="pt-2">
                    <ZipWidget
                        :amount="discountedPrice"
                        alignment=""
                        breakpointAlignment="1060, center"
                    />
                </div>
            </client-only>
        </div>

        <ConfirmExitModal
            :show="showConfirmExit"
            @close="showConfirmExit = false"
            @confirm="onConfirmExit"
        />
    </div>
</template>

<script>
import formatImageUrlMixin from '@/mixins/image-url-formatter';
import formatMoneyMixin from '@/mixins/money-formatter';
import eyewearFlowMixin from '@/mixins/eyewear-flow';
import eyewearFlowSummaryMixin from '@/mixins/eyewear-flow-summary-mixin';
import ZipWidget from '@/components/cart/payment-methods/ZipWidget';
import ConfirmExitModal from '@/components/landing-page/choose-lenses/ConfirmExitModal';

export default {
    mixins: [formatImageUrlMixin, formatMoneyMixin, eyewearFlowMixin, eyewearFlowSummaryMixin],
    components: {
        ZipWidget,
        ConfirmExitModal
    },
    data() {
        return {
            showConfirmExit: false,
        }
    },
    computed: {
        excludeCoupons() {
            if (this.baseProduct?.excludeCoupons) {
                return true;
            }

            return this.baseProduct?.frameProduct?.excludeCoupons ? true : false;
        },
        couponCode() {
            if (this.baseProduct?.sunglasses) {
                return 'SUN20';
            }

            return 'EYE20';
        },
        productImage() {
            let availableImages = [this.baseProduct.image];
            if (!this.baseProduct?.customData?.productImages) {
                return availableImages[0];
            }

            for (let x = 0; x < this.baseProduct.customData.productImages.length; x++) {
                availableImages.push(this.baseProduct.customData.productImages[x]);
            }

            let totalImages = availableImages.length;
            let imageIndex = (this.currentStepNumber - 1) % totalImages;

            if (typeof availableImages[imageIndex] !== 'undefined') {
                return availableImages[imageIndex];
            }

            return availableImages[0];
        },
        estimatedPrice() {
            return this.$store.state.eyewearFlow?.estimatedProduct?.price + this.$store.state.eyewearFlow?.additionalPrice;
        },
        discountedPrice(){
            if (this.excludeCoupons) {
                return this.estimatedPrice;
            }

            return this.estimatedPrice * .8;
        },
        frameName() {
            if (this.baseProduct?.customData?.displayName) {
                return this.baseProduct.customData.displayName;
            }

            return this.baseProduct.name;
        }
    },
    methods: {
        onConfirmExit() {
            this.$router.push({ path: '/' + this.baseProduct.slug });
        },
        getChoiceValue(selectedChoice) {
            if (
                selectedChoice.name !== 'Rx Type' &&
                selectedChoice.stepTypeProperty !== 'rxTypeVariation'
            ) {
                return selectedChoice.value;
            }

            let rxTypeName = selectedChoice?.choice?.rxType?.name;
            if (selectedChoice?.choice?.content?.shortName) {
                return rxTypeName + ' ' + selectedChoice?.choice?.content?.shortName;
            }

            return selectedChoice.value;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.glasses-summary-container {
    width: 100%;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
    p {
        font-size: 16px;
    }
    .prescription-desc {
        align-items: flex-start;
        p {
            margin-bottom: 0px;
        }
        .discount-price{
            font-size: 22px;
        }
        .step-value-detail {
            text-align: right;
            max-width: 50%;
        }
    }
    .step-details-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.back-to-frame-button-container {
    position: absolute;
    top: 30px;
    left: 45px;
    img {
        width: auto;
        margin-bottom: 0px;
    }
    button {
        &.btn-link {
            display: flex;
            align-items: center;
            gap: 5px;
            color: #91A5B4;
            font-size: 16px;
            padding-top: 13px;
            min-width: 140px;
        }
    }
}

.preview-image {
    mix-blend-mode: darken;
}

hr {
    margin-top: 0px;
}

@media (max-width: $breakpoint2) {
    .back-to-frame-button-container {
        display: none;
    }
}
</style>
