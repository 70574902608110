<template>
    <!-- Start Subscribe Area -->
    <section class="subscribe-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 p-0">
                    <div class="newsletter-content">
                        <h6>Join LensDirect Insider!</h6>
                        <p class="p18">Get exclusive promotions, special offers and more</p>
                    </div>
                </div>

                <div class="col-lg-6 p-0">
                    <form class="newsletter-form" @submit.prevent="subscribe">
                        <input
                            type="email"
                            class="form-control"
                            placeholder="Your email address"
                            id="email"
                            v-model="subscriptions.email"
                            autocomplete="off"
                        >
                        <CustomButton
                            type="submit"
                            :isLoading="isLoading"
                        >
                            Submit
                        </CustomButton>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- End Subscribe Area -->
</template>
<script>
export default {
    data() {
        return {
            isLoading: false,
            subscriptions: {
                email: '',
                createdAt: new Date()
            }
        }
    },
    methods: {
        async subscribe() {
            if (this.subscriptions.email === '' || !this.validateEmail(this.subscriptions.email)) {
                this.$toast.error('Email address is required.', {
                    icon: 'fas fa-times'
                });
                return;
            }

            this.isLoading = true;
            let payload = {
                email: this.subscriptions.email
            };

            try {
                await this.$axios.$post('/subscribe-email', payload);

                this.$toast.success(`Subscribed successfully!`, {
                    icon: 'fas fa-user-check'
                });
                this.subscriptions.email = ''
            } catch (e) {
                this.$toast.error('We were unable to subscribe you at this time.  Please try again later.', {
                    icon: 'fas fa-times'
                });
            }

            this.isLoading = false;
        },
        validateEmail(email) {
            return /^\S+@\S+\.\S+$/.test(email);
        }
    }
}
</script>
