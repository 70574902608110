<template>
    <!-- Start Top Panel Area -->
    <transition name="fade">
        <div class="top-panel">
            <div class="panel-content">
                <p><span class="d-none d-sm-inline-block">Limited time offer! Take </span>15% off on all orders + Free shipping <span class="d-none d-sm-inline-block"> | Use code: SAVE15</span><a href="#">Learn More</a></p>
            </div>
            <div class="account">
                <ul class="auth">
                    <li><nuxt-link to="/help">Help</nuxt-link></li>
                    <li><nuxt-link to="/login" class="auth-link">Sign In</nuxt-link></li>
                </ul>
                <div class="account-list" v-if="isLoggedIn">
                    <i class="fas fa-user-circle"></i>
                    <select>
                        <option value="">My Account</option>
                        <option>My Profile</option>
                    </select>
                </div>
            </div>
        </div>
    </transition>
    <!-- End Top Panel Area -->
</template>

<script>
export default {
    data() {
        return {
            isLoggedIn: false
        }
    },
}
</script>
