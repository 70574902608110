import lensCoatingTypes from '@/constants/eyewear-coating-types';
import frameTypes from '@/constants/eyewear-frame-types';
import lensTypeVariationTypes from '@/constants/eyewear-lens-type-variation-types';
import lensTypes from '@/constants/eyewear-lens-types';
import materialTypes from '@/constants/eyewear-material-types';
import rxTypes from '@/constants/eyewear-rx-types';
import rxTypeVariationTypes from '@/constants/eyewear-rx-type-variation-types';

export default {
    lensCoatingTypes,
    frameTypes,
    lensTypeVariationTypes,
    lensTypes,
    materialTypes,
    rxTypes,
    rxTypeVariationTypes
}
