<template>
    <div class="cart-item-detail-component">
        <div v-if="itemGroup.product.contactLenses || forceContacts" class="d-flex cart-item-detail-wrapper">
            <ul class="cart-item-eye-detail" v-if="rightEye.length > 0">
                <li>
                    <strong>Right Eye (OD)</strong>
                </li>
                <li
                    v-for="(rightEyeLine, index) in rightEye"
                    :key="index"
                >
                    <strong>
                        {{ rightEyeLine.text }}:
                    </strong>
                    <span>
                        {{ rightEyeLine.value }}
                    </span>
                </li>
            </ul>
            <ul class="cart-item-eye-detail" v-if="leftEye.length > 0">
                <li>
                    <strong>Left Eye (OS)</strong>
                </li>
                <li
                    v-for="(leftEyeLine, index) in leftEye"
                    :key="index"
                >
                    <strong>
                        {{ leftEyeLine.text }}:
                    </strong>
                    <span>
                        {{ leftEyeLine.value }}
                    </span>
                </li>
            </ul>
        </div>
        <div v-if="itemGroup.product.eyewear">
            <LensPackageAttributesList
                :lensPackageAttributes="itemGroup.product.attributes.lensPackageAttributes"
                :lensColor="itemGroup.product.attributes.lensColor"
                :addons="itemGroup.addons"
            />
        </div>
    </div>
</template>

<script>
import prescriptionDetailFormattedMixin from '@/mixins/prescription-detail-formatter';
import LensPackageAttributesList from '@/components/cart/LensPackageAttributesList';

export default {
    mixins: [prescriptionDetailFormattedMixin],
    components: {
        LensPackageAttributesList
    },
    props: {
        itemGroup: {
            type: Object,
            required: true
        },
        forceContacts: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getEye(eye) {
            for (let x = 0; x < this.itemGroup.items.length; x++) {
                let item = this.itemGroup.items[x];
                if (item.eye === eye) {
                    return item;
                }
            }

            return null;
        },
        getPrescriptionDetails(eyeItem) {
            try {
                return eyeItem.product.attributes.prescriptionDetails;
            } catch (e) {
                return null;
            }
        },
        getPrescriptionDetailList(eye) {
            let eyeItem = this.getEye(eye);
            if (!eyeItem) {
                return null;
            }

            let prescriptionDetails = this.getPrescriptionDetails(eyeItem);

            if (!prescriptionDetails) {
                return null;
            }

            let prescriptionDetailValues = [];
            for (let rxDetailName in prescriptionDetails) {
                let rxValue = prescriptionDetails[rxDetailName];

                if (rxValue === null || rxValue === '') {
                    continue;
                }

                let formattedValue = this.formatPrescriptionValue(rxDetailName, rxValue);
                if (formattedValue === '') {
                    continue;
                }

                prescriptionDetailValues.push({
                    text: this.getRxDetailDisplayName(rxDetailName),
                    value: formattedValue
                });
            }

            let eyeColor = this.getEyeColor(eye);
            if (eyeColor) {
                prescriptionDetailValues.push({
                    text: 'Color',
                    value: eyeColor
                });
            }

            prescriptionDetailValues.push({
                text: 'Boxes',
                value: eyeItem.quantity
            });

            return prescriptionDetailValues;
        },
        getEyeColor(eye) {
            let eyeItem = this.getEye(eye);
            if (!eyeItem) {
                return '';
            }

            return eyeItem?.product?.attributes?.lensColor;
        }
    },
    computed: {
        leftEye() {
            let eye = this.getPrescriptionDetailList('Left');
            return eye ? eye : [];
        },
        rightEye() {
            let eye = this.getPrescriptionDetailList('Right');
            return eye ? eye : [];
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/color.scss';

    .cart-item-detail-component {
        display: flex;
        width: 100%;
        transition: height .4s;
        .cart-item-eye-detail {
            padding-left: 0px;
            padding-right: 20px;
            li {
                width: 100%;
                display: flex;
                color: $color-black !important;
                font-size: .875em !important;
                margin-bottom: 0px !important;
                span {
                    padding-left: 10px;
                    margin-left: auto;
                    font-weight: normal !important;
                }
                &:first-child {
                    padding-bottom: 10px;
                }
            }
        }

        .addons {
            font-size: 14px;
        }
    }
</style>
