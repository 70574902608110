export default function (status) {
    if (typeof document === 'undefined') {
        return;
    }

    if (!status) {
        document.body.classList.remove('hide-chat-window');
    } else {
        document.body.classList.add('hide-chat-window');
    }
}
