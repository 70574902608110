<template>
    <AppWrapper :hideChatMobile="true">
        <Header :hideAuth="true" />
        <DesktopHeader class="lens-replacement-header" />
        <div class="mobile-header">
            <client-only>
                <BackButton v-if="baseProduct && !isLoading && !isLoadingChoices" />
            </client-only>
            <a href="/" @click.prevent="onClickLogo"><img :src="websiteLogo()" alt="lensdirect logo" :class="websiteLogoCss()" /></a>
        </div>
        <div class="main-content" :class="{ 'show-mobile-next-step-button': showNextStepButton }">
            <div v-if="isLoading || isLoadingChoices">
                <StepsSkeleton />
            </div>
            <div style="width: 100%" v-if="baseProduct && !isLoading">
                <div v-show="!isLoadingChoices" class="eyewear-flow-container progress-bar-stick-wrapper">
                    <ProgressBar />
                </div>
                <div v-show="!isLoadingChoices" class="eyewear-flow-container">
                    <client-only>
                        <nuxt />
                    </client-only>
                    <MobileFutureSteps />
                    <Sidebar />
                </div>
            </div>
            <Faq v-if="!isLoadingChoices" :pageData="lensReplacementPageData" />
        </div>

        <ConfirmExitModal
            :show="showConfirmExit"
            @close="showConfirmExit = false"
            @confirm="onConfirmExit"
        />
        <GlassesonScript />
    </AppWrapper>
</template>

<script>
import AppWrapper from '@/components/AppWrapper';
import metaMixin from '@/mixins/meta-mixin';
import Sidebar from '@/components/landing-page/lens-replacement/steps/Sidebar';
import ProgressBar from '@/components/landing-page/lens-replacement/steps/ProgressBar';
import loadZipPayWidgetJs from '@/third-party/zippay';
import MobileFutureSteps from '@/components/landing-page/lens-replacement/steps/MobileFutureSteps';
import MobilePastSteps from '@/components/landing-page/lens-replacement/steps/MobilePastSteps';
import BackButton from '@/components/landing-page/lens-replacement/steps/BackButton';
import Faq from '@/components/landing-page/lens-replacement/Faq';
import DesktopHeader from '@/components/landing-page/choose-lenses/DesktopHeader';
import formatImageUrlMixin from '@/mixins/image-url-formatter';
import lensReplacementLoadProductMixin from '@/mixins/eyewear-flow-load-lens-replacement-product';
import ConfirmExitModal from '@/components/landing-page/choose-lenses/ConfirmExitModal';
import StepsSkeleton from '@/components/landing-page/lens-replacement/steps/StepsSkeleton';
import GlassesonScript from '@/components/third-party/Glasseson';
import Header from '@/layouts/Header';
import { attentiveProductPageTracking } from '@/third-party/attentive';

export default {
    name: 'LensReplacementLayout',
    mixins: [metaMixin, formatImageUrlMixin, lensReplacementLoadProductMixin],
    components: {
        AppWrapper,
        Header,
        Sidebar,
        ProgressBar,
        MobileFutureSteps,
        MobilePastSteps,
        Faq,
        DesktopHeader,
        BackButton,
        ConfirmExitModal,
        StepsSkeleton,
        GlassesonScript
    },
    async fetch() {
        await this.loadProduct();

        if (!this.lensReplacementPageData) {
            await this.$store.dispatch('eyewearFlow/loadLensReplacementPageData');
        }
    },
    mounted() {
        loadZipPayWidgetJs(this.$config.zipWidgetJsUrl);

        if (this.estimatedProduct?.id) {
            attentiveProductPageTracking(this.estimatedProduct);
        } else if (this.baseProduct?.id) {
            attentiveProductPageTracking(this.baseProduct);
        }
    },
    data() {
        return {
            isLoading: true,
            showConfirmExit: false,
            showNextStepButton: false
        };
    },
    computed: {
        lensReplacementPageData() {
            return this.$store.state.eyewearFlow.lensReplacementPageData;
        },
        determiningFirstStep() {
            return this.$store.state.eyewearFlow.determiningFirstStep;
        },
        baseProduct() {
            return this.$store.state.eyewearFlow.baseProduct;
        },
        estimatedProduct() {
            return this.$store.state.eyewearFlow.estimatedProduct;
        },
        isLoadingChoices() {
            if (this.determiningFirstStep) {
                return true;
            }

            return this.$store.state.eyewearFlow.isLoadingChoices;
        }
    },
    methods: {
        onScroll(e) {
            const bar = document.getElementsByClassName('mobile-header');

            // sticky scroll navbar on mobile
            if (window.innerWidth > 1060) {
                // Reset top just in case going from mobile to desktop
                bar[0].style.top = '';
                return;
            }


            if (window.top.scrollY > 40) {
                bar[0].style.top = '0px';
                document.body.classList.add('hidden-top-panel');
            } else {
                bar[0].style.top = 40 - window.top.scrollY + `px`;
                document.body.classList.remove('hidden-top-panel');
            }
        },
        onClickLogo() {
            this.showConfirmExit = true;
        },
        onConfirmExit() {
            window.location = '/';
        },
        getMetaObjectToUse() {
            return this.baseProduct ? this.baseProduct.metaData : null;
        }
    },
    watch: {
        estimatedProduct(newEstimatedProduct) {
            if (!newEstimatedProduct?.id) {
                return;
            }

            attentiveProductPageTracking(newEstimatedProduct);
        }
    }
};
</script>
<style lang="scss">
@import '~/assets/scss/components/landing-page/eyewear-flow.scss';
</style>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

.app-wrapper {
    position: relative;
    min-height: 100vh;
    background-color: $bg-light-blue;
    .main-content {
        background-color: $bg-light-blue;
        margin-top: 100px;
        .progress-bar-stick-wrapper {
            display: none;
        }
    }
}

.mobile-header {
    display: none;
}

::v-deep {
    .trustpilot-bottom {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }

    .glasses-flow-header {
        &.lens-replacement-header {
            position: fixed;
            top: 40px;
            width: 100%;
            .steps-wrapper {
                .step-container {
                    &::after {
                        width: 30px;
                    }
                }
            }
        }
    }

    .eyewear-flow-container {
        padding-top: 20px;
    }
}

@media (max-width: $desktopBreakpoint1) {
    ::v-deep {
        .glasses-flow-header {
            &.lens-replacement-header {
                .steps-wrapper {
                    .step-container {
                        &::after {
                            width: 25px;
                        }
                    }
                }
                .live-chat-wrapper {
                    min-width: 200px;
                }
            }
        }
    }
}

@media (max-width: $desktopBreakpoint2) {
    ::v-deep {
        .glasses-flow-header {
            &.lens-replacement-header {
                .container {
                    width: 100%;
                }
                .steps-wrapper {
                    .step-container {
                        &::after {
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $desktopBreakpoint4) {
    ::v-deep {
        .glasses-flow-header {
            &.lens-replacement-header {
                .steps-wrapper {
                    .step-name {
                        font-size: 10px;
                    }
                    .step-container {
                        &::after {
                            width: 15px;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: $breakpoint2) {
    .app-wrapper {
        .main-content {
            margin-top: 0px;
        }
    }

    .progress-bar-stick-wrapper {
        position: sticky;
        display: flex;
        top: 55px;
        z-index: 10;
    }

    .mobile-header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 60px;
        width: 100%;
        background-color: #ffffff;
        z-index: 10;
        border-bottom: solid $color-grey-border 1px;
        img {
            height: 30px;
        }
        ::v-deep {
            .btn-back-button {
                position: absolute;
                display: block;
                background-color: transparent;
                top: 30px;
                margin-top: -17px;
                width: 30px;
                height: 30px;
                left: 10px;
                border: 0px !important;
                padding: 0px;
                img {
                    &.black-arrow {
                        display: block !important;
                        width: 21px;
                    }
                }
                .back-button-text {
                    display: none;
                }
                .blue-arrow {
                    display: none;
                }
                .black-arrow {
                    display: block;
                }
            }
        }
    }

    .eyewear-flow-container {
        padding-top: 0px;
    }

    .show-mobile-next-step-button {
        ::v-deep {
            .eyewear-flow-container {
                .eyewear-flow-step-container {
                    .next-step-button-container {
                        &.is-sticky-mobile-buttons {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint5) {
    .app-wrapper {
        padding-bottom: 80px;
    }
}
</style>
