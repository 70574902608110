export default {
    PLASTIC_ACETATE: {
        id: 1,
        name: 'Plastic/Acetate'
    },
    METAL: {
        id: 2,
        name: 'Metal'
    },
    SEMI_RIMLESS: {
        id: 3,
        name: 'Semi-Rimless'
    },
    RIMLESS: {
        id: 4,
        name: 'Rimless'
    }
};
