<template>
    <div>
        <!-- Start Navbar Area -->
        <div :class="['navbar-area', { 'is-sticky': isSticky }]">
            <div class="comero-nav">
                <div class="custom-container">
                    <nav class="navbar navbar-expand-md navbar-light">
                        <nuxt-link class="navbar-brand" to="/">
                            <img :src="formatAssetUrl('images/logo.png')" alt="logo" />
                        </nuxt-link>

                        <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>

                        <nuxt-link to="/cart" class="mobile-shopping-cart">
                            <span class="count">{{ itemGroups.length }}</span> <i class="fas fa-shopping-cart"></i>
                        </nuxt-link>

                        <b-collapse class="collapse navbar-collapse" id="navbarSupportedContent" is-nav>
                            <ul class="navbar-nav">
                                <li class="nav-item p-relative" v-for="category in categories" :key="category.id">
                                    <span class="nav-link" @click="getCategory(category.slug)" :slug="category.slug">{{
                                        category.name
                                    }}</span>
                                </li>
                                <!-- Sample list
                                <li class="nav-item p-relative"><a href="#" class="nav-link">Eyeglasses <i class="fas fa-chevron-down"></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><nuxt-link to="/products" class="nav-link">Products</nuxt-link></li>
                                        <li class="nav-item"><nuxt-link to="/products-details/1" class="nav-link">Products Details</nuxt-link></li>
                                    </ul>
                                </li>-->
                            </ul>

                            <div class="others-option">
                                <div class="option-item">
                                    <form class="search_product">
                                        <input type="search" placeholder="I'm searching for..." />
                                    </form>
                                    <a @click.prevent="toggle" class="shopping-cart">
                                        <span class="count">{{ itemGroups.length }}</span>
                                        <i class="fas fa-shopping-cart"></i>
                                    </a>
                                </div>
                            </div>
                        </b-collapse>
                    </nav>
                    <div class="search_vision">
                        <input type="search" placeholder="Search my vision care" />
                    </div>
                </div>
            </div>
        </div>

        <!-- End Navbar Area -->

        <SidebarPanel></SidebarPanel>
    </div>
</template>

<script>
import SidebarPanel from '@/layouts/SidebarPanel';
export default {
    components: {
        SidebarPanel
    },
    data() {
        return {
            isSticky: false,
            categories: []
        };
    },
    created() {
        this.getCategories();
    },
    mounted() {
        const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            if (scrollPos >= 100) {
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        });
    },
    computed: {
        cart() {
            return this.$store.getters.cart;
        },
        itemGroups() {
            return this.$store.getters.itemGroups;
        }
    },
    methods: {
        toggle() {
            this.$store.dispatch('sidebar/toggleNav');
        },
        async getCategories() {
            var result = await this.$axios.$get(this.$store.getters.getURL + '/category');
            this.categories = result.data;
        },
        async getCategory(key) {
            //... Get Hero Banner Title, Text and Background image
            var category_result = await this.$axios.$get(this.$store.getters.getURL + '/category?slug=' + key);
            this.$store.dispatch('setCategoryTitle', category_result.data[0].name);
            this.$store.dispatch('setCategoryData', category_result.data[0].customData);

            //... Fetch products on choosen category
            var product_result = await this.$axios.$get(
                this.$store.getters.getURL + '/product-categories?categorySlug=' + key
            );
            this.$store.dispatch('setCategoryProducts', product_result.data);
        }
    }
};
</script>
<style lang="scss" scoped>
// @import "../assets/scss/components/general.scss";
.custom-container {
    padding: 0 60px;
}
</style>
