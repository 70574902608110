function shouldHave2Decimals(rxDetailName, rxValue) {
    const twoDecimalFields = ['sphere', 'power', 'cylinder', 'addition', 'prismVertical', 'prismHorizontal'];
    if (isNaN(rxValue)) {
        return false;
    }

    return twoDecimalFields.indexOf(rxDetailName) !== -1;
}

function shouldHave2Decimal(rxDetailName, rxValue) {
    return rxDetailName === 'baseCurve';
}

function shouldAddPlusSign(rxDetailName, rxValue) {
    const dontAddPlusSign = ['pd', 'axis', 'prismHorizontal', 'baseCurve', 'diameter', 'prismVertical'];
    if (isNaN(rxValue)) {
        return false;
    }

    if (parseFloat(rxValue) <= 0) {
        return false;
    }

    return dontAddPlusSign.indexOf(rxDetailName) === -1;
}

export default {
    computed: {
        prescriptionDetailKeys() {
            return [
                'power',
                'baseCurve',
                'diameter',
                'cylinder',
                'axis',
                'addition',
                'dn',
                'color'
            ];
        },
        eyewearPrescriptionDetailKeys() {
            return [
                'power',
                'baseCurve',
                'diameter',
                'cylinder',
                'axis',
                'addition',
                'pd',
                'prismHorizontal',
                'prismHorizontalDirection',
                'prismVertical',
                'prismVerticalDirection'
            ];
        }
    },
    methods: {
        getRxDetailDisplayName(rxDetailName) {
            if (rxDetailName === 'power') {
                return 'Power';
            }

            if (rxDetailName === 'baseCurve') {
                return 'BC';
            }

            if (rxDetailName === 'diameter') {
                return 'DIA';
            }

            if (rxDetailName === 'cylinder') {
                return 'CYL';
            }

            if (rxDetailName === 'axis') {
                return 'Axis';
            }

            if (rxDetailName === 'addition') {
                return 'ADD';
            }

            if (rxDetailName === 'dn') {
                return 'D/N';
            }

            if (rxDetailName === 'color') {
                return 'Color';
            }

            if (rxDetailName === 'pd') {
                return 'PD';
            }

            if (rxDetailName === 'prismHorizontal') {
                return 'Prism Horizontal';
            }

            if (rxDetailName === 'prismHorizontalDirection') {
                return 'Prism Horizontal Direction';
            }

            if (rxDetailName === 'prismVertical') {
                return 'Prism Vertical';
            }

            if (rxDetailName === 'prismVerticalDirection') {
                return 'Prism Vertical Direction';
            }

            return '';
        },
        formatPrescriptionValue(rxDetailName, rxValue) {
            let formattedValue = rxValue;

            const skipIfZeroFields = ['pd'];
            if (!rxValue && skipIfZeroFields.indexOf(rxDetailName) !== -1) {
                return '';
            }

            if (shouldHave2Decimals(rxDetailName, rxValue)) {
                formattedValue = parseFloat(formattedValue).toFixed(2);
            }

            if (shouldHave2Decimal(rxDetailName, rxValue)) {
                formattedValue = parseFloat(formattedValue).toFixed(1);
            }

            if (shouldAddPlusSign(rxDetailName, rxValue)) {
                formattedValue = '+' + formattedValue;
            } else if(isNaN(rxValue)) {
                formattedValue = (formattedValue + '').toUpperCase();
            }

            if (rxDetailName === 'axis') {
                formattedValue = (formattedValue + '').padStart(3, '0');
            }

            return formattedValue;
        }
    }
}
