import objectFactory from '@/utils/object-factory';
import { pinterestSignupTracking } from '@/third-party/pinterest';
import { deleteCustomerFavoriteToken } from '@/utils/customer-favorites';

const SET_PROCESSING = 'Set Processing';
const SET_CONFIG = 'Set Config';
const SET_USER = 'Set User';
const SET_AUTH_FORM_EMAIL = 'Set Auth Form Email'
const SET_LOGIN_REDIRECT = 'Set Login Redirect';
const SET_SHOW_ACCOUNT_DEACTIVATED_MESSAGE = 'Set Show Account Deactivated Message'

export const state = () => ({
    config: null,
    currentUser: null,
    processing: false,
    loginError: null,
    authFormEmailAddress: '',
    loginRedirect: '',
    showAccountDeactivatedMessage: false
});

export const getters = {
    config: state => state.config,
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
    isNewCustomer: (state) => {
        const customer = state.currentUser?.customer;
        if (!customer) {
            return true;
        }

        return customer?.isNewCustomer ? true : false;
    }
}

export const mutations = {
    [SET_SHOW_ACCOUNT_DEACTIVATED_MESSAGE](state, payload) {
        state.showAccountDeactivatedMessage = payload;
    },
    [SET_LOGIN_REDIRECT](state, payload) {
        state.loginRedirect = payload;
    },
    [SET_AUTH_FORM_EMAIL](state, payload) {
        state.authFormEmailAddress = payload;
    },
    [SET_CONFIG](state, payload) {
        state.config = payload
        state.processing = false
        state.loginError = null
    },
    [SET_USER](state, payload) {
        state.currentUser = payload
        state.processing = false
        state.loginError = null
    },
    [SET_PROCESSING](state, payload) {
        state.processing = payload
        state.loginError = null
    }
};

export const actions = {
    setShowAccountDeactivatedMessage({ commit }, payload) {
        commit(SET_SHOW_ACCOUNT_DEACTIVATED_MESSAGE, payload);
        return Promise.resolve();
    },
    triggerLoginRedirect({ commit, state }, router) {
        let loginRedirect = state.loginRedirect ? state.loginRedirect : '/profile/account-snapshot';

        // Clear out redirect
        commit(SET_LOGIN_REDIRECT, '');

        // Redirect to path
        router.push({ path: loginRedirect });
    },
    setLoginRedirect({ commit }, payload) {
        commit(SET_LOGIN_REDIRECT, payload);
        return Promise.resolve();
    },
    setCustomer({ commit, state }, payload) {
        let copyCurrentUser = objectFactory.deepCopy(state.currentUser);
        copyCurrentUser.customer = payload;
        commit(SET_USER, copyCurrentUser);

        return Promise.resolve();
    },
    setAuthFormEmail({ commit }, payload) {
        commit(SET_AUTH_FORM_EMAIL, payload);
        return Promise.resolve();
    },
    async forgotPassword({ commit }, emailAddress) {
        let payload = {
            email: emailAddress,
            userType: 1
        };

        try {
            let response = this.$axios.$post('/forgot-password', payload);
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(e.response.data);
        }
    },
    async signUp ({ commit }, payload) {
        let result = null;
        try {
            let response = await this.$axios.$post('/customer', payload);
            result = Promise.resolve(response);
        } catch (e) {
            return Promise.reject(e.response.data);
        }

        try {
            pinterestSignupTracking();
        } catch (e) {
            // Prevent issues running tracking code
        }

        return result;
    },
    async login ({ dispatch }, payload) {
        try {
            let response = await this.$axios.$post('/customer/login', payload);
            await dispatch('favorites/resetCustomerFavoriteToken', null, { root: true });
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(e.response?.data?.message ? e.response.data.message : 'Unable to login, please try again.');
        }
    },
    async getUser ({ commit, state }, useCache = false) {
        if (useCache && state.currentUser) {
            return Promise.resolve(state.currentUser);
        }

        commit(SET_PROCESSING, true);
        try {
            let response = await this.$axios.$get('/customer/me');
            commit(SET_USER, response);
            return Promise.resolve(response);
        } catch (e) {
            // Prevent errors getting the customer
            console.error(e);
        }

        commit(SET_PROCESSING, false);

        return Promise.resolve(null);
    },
    async logout({ commit }) {
        try {
            await this.$axios.$get('/customer/logout');
        } catch (e) {
            // Prevent issues logging out
        }

        commit(SET_USER, null);

        return Promise.resolve();
    },
    async guestLogin({ commit }, { email, orderId, graceful = false }) {
        try {
            await this.$axios.$post('/guest/login', {
                orderId,
                email
            });

            return Promise.resolve();
        } catch (e) {
            return graceful ? Promise.resolve(e) : Promise.reject(e);
        }
    }
}
