export default {
    FIT: {
        id: 1,
        label: 'Fit'
    },
    MATERIAL: {
        id: 2,
        label: 'Material'
    },
    FRAME_SHAPE: {
        id: 3,
        label: 'Frame Shape'
    },
    COLOR: {
        id: 4,
        label: 'Color'
    },
    GENDER: {
        id: 5,
        label: 'Gender'
    },
    RIM: {
        id: 6,
        label: 'Rim'
    },
    FACE_SHAPE: {
        id: 7,
        label: 'Face Shape'
    },
    LENS_TYPE: {
        id: 8,
        label: 'Lens Type'
    },
    BRAND: {
        id: 9,
        label: 'Brand'
    },
    BRAND_STYLE: {
        id: 10,
        label: 'Brand Style'
    },
    MANUFACTURER: {
        id: 11,
        label: 'Manufacturer'
    },
    TREND: {
        id: 12,
        label: 'Trend'
    }
};
