<template>
    <AppWrapper>
        <Header />
        <Navbar />
        <nuxt />
        <Footer />
    </AppWrapper>
</template>

<script>
import Header from '@/layouts/Header';
import Navbar from '@/layouts/Navbar';
import AppWrapper from '@/components/AppWrapper';
import Subscribe from '@/components/common/Subscribe';
import metaMixin from '@/mixins/meta-mixin';
import Footer from '@/components/Footer';

export default {
    mixins: [metaMixin],
    name: 'default-layout',
    components: {
        Header,
        Navbar,
        Footer,
        AppWrapper,
        Subscribe
    }
}
</script>
