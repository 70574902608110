<template>
    <div class="admin-sidebar-wrapper">
        <div class="admin-sidebar">
            <b-sidebar id="admin-sidebar" visible no-slide>
                <ul>
                    <li v-for="(item, index) in menu" :key="index" :class="{ active: active }">
                        <nuxt-link :to="item.link">
                            <div class="d-flex justify-content-center menu-img">
                                <img :src="formatAssetUrl('images/' + item.icon )" :alt="item.title" />
                            </div>
                            <span>{{ item.title }}</span>
                        </nuxt-link>
                    </li>
                </ul>
            </b-sidebar>
        </div>

        <MobileMenu :hideQuickMenuText="true">
            <template #menuTitleContent>
                <img :src="formatAssetUrl('images/' + currentPage.icon )" :alt="currentPage.title" />
                {{ currentPage.title }}
            </template>
            <nuxt-link v-for="(item, index) in menu" :key="index" :to="item.link">
                <img :src="formatAssetUrl('images/' + item.icon )" :alt="item.title" /> {{ item.title }}
            </nuxt-link>
        </MobileMenu>
    </div>
</template>
<script>
import MobileMenu from '@/components/admin/MobileMenu';
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    name: 'AppSidebar',
    components: {
        MobileMenu
    },
    data() {
        return {
            menu: [
                {
                    id: 1,
                    title: 'Account Snapshot',
                    icon: '01-account.png',
                    link: '/profile/account-snapshot'
                },
                {
                    id: 2,
                    title: 'Order History',
                    icon: '02-ord.png',
                    link: '/profile/order-history'
                },
                {
                    id: 3,
                    title: 'Track My Order',
                    icon: '03-tra.png',
                    link: '/profile/track-my-order'
                },
                {
                    id: 10,
                    title: 'My Subscriptions',
                    icon: 'ss-grey.svg',
                    link: '/profile/autorefills',
                    subMenu: {
                        link: '/profile/autorefill'
                    }
                },
                {
                    id: 4,
                    title: 'Gift Cards',
                    icon: '04-gif.png',
                    link: '/profile/giftcards'
                },
                {
                    id: 5,
                    title: 'Rewards',
                    icon: 'outline-star.png',
                    link: '/profile/invite-earn'
                },
                {
                    id: 6,
                    title: 'Payment Methods',
                    icon: '06-pay.png',
                    link: '/profile/payment-methods'
                },
                {
                    id: 7,
                    title: 'Prescriptions',
                    icon: '07-pre.png',
                    link: '/profile/prescriptions'
                },
                {
                    id: 8,
                    title: 'Account Settings',
                    icon: '08-set.png',
                    link: '/profile/account-settings'
                },
                {
                    id: 9,
                    title: 'Address Book',
                    icon: '09-add.png',
                    link: '/profile/address-book'
                },
                {
                    id: 12,
                    title: 'Logout',
                    icon: '11-log.png',
                    link: '/profile/logout'
                }
            ],
            active: true
        };
    },
    computed: {
        currentPage() {
            let currentPath = this.$route.path;
            for (let x = 0; x < this.menu.length; x++) {
                let menuItem = this.menu[x];
                if (menuItem.link.indexOf(currentPath) !== -1) {
                    return menuItem;
                }

                if (typeof menuItem.subMenu !== 'undefined' && currentPath.indexOf(menuItem.subMenu.link) !== -1) {
                    return menuItem;
                }
            }

            return this.menu[0];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.admin-sidebar {
    ::v-deep {
        a {
            &:hover {
                span {
                    color: #000000;
                }
            }
        }
    }
}

@media (max-width: $breakpoint5) {
    .admin-sidebar-wrapper {
        position: sticky;
        top: 0px;
        z-index: 100;

        ::v-deep {
            .mobile-profile-menu-items {
                a {
                    display: flex;
                    align-items: center;
                    img {
                        display: inline-block;
                        margin-right: 12px;
                    }
                    &.active {
                        img {
                            filter: invert(10%) sepia(90%) saturate(5268%) hue-rotate(200deg) brightness(65%)
                                contrast(155%);
                        }
                    }
                }
            }
        }
    }
}
</style>
