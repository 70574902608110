export default {
    methods: {
        onClickEnableAccessibilityOptions() {
            let accessibeTriggers = document.getElementsByClassName('acsb-trigger');
            if (!accessibeTriggers?.length) {
                return;
            }

            accessibeTriggers[0].click();
        }
    }
}
