<template>
    <AppWrapper :hideChatMobile="true">
        <DesktopHeader @showLiveChat="onShowLiveChat" />
        <div class="mobile-header">
            <a href="/" @click.prevent="onClickLogo"><img :src="websiteLogo()" alt="lensdirect logo" :class="websiteLogoCss()" /></a>
        </div>
        <div class="row lens-replacement">
            <div class="col-lg-6 col-md-12 p-0 prescription-image hidden-mobile">
                <Sample
                    v-if="baseProduct"
                />
            </div>
            <div class="col-lg-6 col-md-12 p-0 prescription-type">
                <client-only>
                    <nuxt />
                </client-only>
                <FreeLensUpgrades />
            </div>
            <client-only>
            <MobileSummary
                v-if="baseProduct"
                :product="baseProduct"
                :estimatedProduct="estimatedProduct"
                :additionalPrice="additionalPrice"
                :selectedChoices="selectedChoices"
            />
            </client-only>
        </div>
        <GlassesonScript />
        <ConfirmExitModal
            :show="showConfirmExit"
            @close="showConfirmExit = false"
            @confirm="onConfirmExit"
        />
    </AppWrapper>
</template>

<script>
import AppWrapper from '@/components/AppWrapper';
import Sample from '@/components/landing-page/select-lenses/Sample'
import MobileSummary from '@/components/landing-page/choose-lenses/MobileSummary';
import DesktopHeader from '@/components/landing-page/choose-lenses/DesktopHeader';
import FreeLensUpgrades from '@/components/landing-page/choose-lenses/FreeLensUpgrades';
import GlassesonScript from '@/components/third-party/Glasseson';
import ConfirmExitModal from '@/components/landing-page/choose-lenses/ConfirmExitModal';

import productTypes from '@/constants/product-types';
import { attentiveProductPageTracking } from '@/third-party/attentive';
import loadZipPayWidgetJs from '@/third-party/zippay';
import notificationsMixin from '@/mixins/notifications';
import eyewearFlowMixin from '@/mixins/eyewear-flow';
import metaMixin from '@/mixins/meta-mixin';
import formatImageUrlMixin from '@/mixins/image-url-formatter';

export default {
    name: 'ChooseLensesLayout',
    mixins: [eyewearFlowMixin, metaMixin, notificationsMixin, formatImageUrlMixin],
    components: {
        AppWrapper,
        Sample,
        MobileSummary,
        DesktopHeader,
        FreeLensUpgrades,
        GlassesonScript,
        ConfirmExitModal
    },
    async fetch() {
        await this.loadProduct();
    },
    mounted() {
        document.body.classList.add('hide-chat-window');
        loadZipPayWidgetJs(this.$config.zipWidgetJsUrl);

        if (this.estimatedProduct?.id) {
            attentiveProductPageTracking(this.estimatedProduct);
        } else if (this.baseProduct?.id) {
            attentiveProductPageTracking(this.baseProduct);
        }
    },
    data() {
        return {
            showConfirmExit: false
        }
    },
    computed: {
        additionalPrice() {
            return this.$store.state.eyewearFlow.additionalPrice;
        }
    },
    methods: {
        onClickLogo() {
            this.showConfirmExit = true;
        },
        onConfirmExit() {
            window.location = '/';
        },
        onShowLiveChat() {
            document.body.classList.remove('hide-chat-window');
        },
        async loadProduct() {
            try {
                let product = await this.$axios.$get('/product/' + this.$route.params.slug);
                if (
                    product.productType !== productTypes.GLASSES.id &&
                    product.productType !== productTypes.SUNGLASSES.id
                ) {
                    // Invalid product type
                    throw new Error();
                }

                if (!product.frameProduct) {
                    // No frame product
                    throw new Error();
                }

                this.$store.dispatch('eyewearFlow/setBaseProduct', product);
            } catch (e) {
                if (typeof window === 'undefined') {
                    this.$nuxt.context.error({
                        status: 404,
                        message: 'Product not found',
                    });
                }

                if (typeof this.$toast !== 'undefined') {
                    this.$toast.error('Those glasses aren\'t available!  Please select a different frame.');
                }

                this.$router.push({ path: '/glasses' });
            }
        }
    },
    watch: {
        estimatedProduct(newEstimatedProduct) {
            if (!newEstimatedProduct?.id) {
                return;
            }

            attentiveProductPageTracking(newEstimatedProduct);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.lens-replacement {
    .prescription-image {
        background-color: #ffffff;
        max-height: calc(100vh - 60px);
    }

    .prescription-type {
        background-color: $bg-light-grey;
    }
}

.mobile-header {
    display: none;
}

@media (max-width: $breakpoint2) {
    .mobile-header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 60px;
        width: 100%;
        background-color: #ffffff;
        z-index: 10;
        border-bottom: solid $color-grey-border 1px;
        img {
            height: 30px;
        }
    }
}

</style>
