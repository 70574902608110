function deepCopy(object) {
    try {
        let objectCopy = JSON.parse(JSON.stringify(object));
        return objectCopy;
    } catch(e) {
        return Object.assign({}, object);
    }
}

export default {
    image() {
        return {
            url: '',
            alt: '',
            title: ''
        };
    },
    faq() {
        return {
            question: {
                content: ''
            },
            answer: {
                content: ''
            }
        };
    },
    productDetails() {
        return {
            name: {
                content: ''
            },
            value: {
                content: ''
            }
        }
    },
    technicalDetails() {
        return {
            name: {
                content: ''
            },
            value: {
                content: ''
            }
        }
    },
    uniqueFeatures() {
        return {
            content: ''
        }
    },
    benefits() {
        return {
            content: ''
        };
    },
    recommendedFor() {
        return {
            content: ''
        };
    },
    link() {
        return {
            text: '',
            href: ''
        };
    },
    video() {
        return {
            type: 'video/mp4',
            url: ''
        };
    },
    instructions() {
        return {
            title: '',
            list: []
        };
    },
    instructionListItem() {
        return {
            text: ''
        };
    },
    prescriptionDetails() {
        return {
            power: '',
            baseCurve: '',
            diameter: '',
            cylinder: '',
            axis: '',
            addition: '',
            pd: '',
            dn: '',
            prismHorizontal: '',
            prismHorizontalDirection: '',
            prismVertical: '',
            prismVerticalDirection: ''
        };
    },
    frameSize() {
        return {
            a: '',
            b: '',
            ed: '',
            dbl: '',
            bc: '',
            sh: '',
            temple: '',
            width: '',
            height: ''
        };
    },
    lensPackageAttributes() {
        return {
            frameType: '',
            lensMaterial: '',
            lensType: '',
            rxType: '',
            lensCoating: '',
            rxTypeVariation: '',
            lensTypeVariation: ''
        };
    },
    metaData() {
        return {
            title: '',
            html: '',
            keywords: '',
            description: '',
            robots: ''
        }
    },
    getCopyOfArrayWithObjects(arrayWithObjects) {
        if (!arrayWithObjects) {
            return [];
        }

        return deepCopy(arrayWithObjects);
    },
    deepCopy(object) {
        return deepCopy(object);
    }
}
