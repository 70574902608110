export default function () {
    if (typeof window === 'undefined') {
        // Don't load server side
        return;
    }

    if (typeof window.acsbJS !== 'undefined') {
        // Don't run if already loaded
        return;
    }

    (function(document, tag) { var script = document.createElement(tag); var element = document.getElementsByTagName('body')[0]; script.src = 'https://acsbap.com/apps/app/assets/js/acsb.js'; script.async = true; script.defer = true; (typeof element === 'undefined' ? document.getElementsByTagName('html')[0] : element).appendChild(script); script.onload = function() { acsbJS.init({ statementLink : '', feedbackLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, language : 'en', position : 'left', leadColor : '#079aed', triggerColor : '#079aed', triggerRadius : '50%', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'default', triggerSize : 'medium', triggerOffsetX : -200, triggerOffsetY : 45, mobile : { triggerSize : 'small', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerOffsetX : -200, triggerOffsetY : 35, triggerRadius : '50%' } }); };}(document, 'script'));
}
