export const state = () => ({
    order: null,
    detail: null,
    processing: true
});

export const mutations = {
    setOrders(state, payload) {
        state.order = payload
        state.processing = false
        state.loginError = null
    },
    setDetail(state, payload) {
        state.detail = payload
        state.processing = false
        state.loginError = null
    },
    setProcessing(state, payload) {
        state.processing = payload
        state.loginError = null
    }
};

export const actions = {
    async updateOrderItems({ commit }, { orderId, payload }) {
        try {
            let response = this.$axios.$patch('/customer/order/' + orderId + '/item', payload);
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(e);
        }
    },
    async getOrders({ commit }, params) {
        try {
            let response = await this.$axios.$get('/customer/order', { params });
            commit('setOrders', response.data);
            return response;
        } catch (e) {
            console.error(e)
            // Prevent issues loading orders
        }

        return null;
    },
    getOrderDetail({ commit }, params) {
        return new Promise((resolve, reject) => {
            commit('setProcessing', true)
            this.$axios
                .get(this.$config.apiBaseURL + '/customer/order/' + params.id, params.config)
                .then(res => {
                    commit('setDetail', res.data)
                    commit('setProcessing', false)
                    return resolve(res.data)
                })
                .catch(err => {
                    commit('setProcessing', false)
                    return reject(err.response.data.message)
                })
        })
    }
};
