export const state = ({
    patient: null,
})

export default {
    actions: {
        getPatient ({ commit }, config) {
            return new Promise((resolve, reject) => {
                this.$axios
                .get(this.$config.apiBaseURL + '/customer/patient', config)
                .then(res => {
                    return resolve(res.data)
                })
                .catch(err => {
                    return reject(err.response.data.message)
                })
            })
        },
        addPatient ({ commit }, params) {
            return new Promise((resolve, reject) => {
                this.$axios
                .post(this.$config.apiBaseURL + '/customer/patient', params.requestBody, params.config)
                .then(res => {
                    commit('setProcessing', false)
                    return resolve(res.data)
                })
                .catch(err => {
                    commit('setProcessing', false)
                    return reject(err.data.message)
                })
            })
        },
        editPatient ({ commit }, params) {
            return new Promise((resolve, reject) => {
                this.$axios
                .patch(this.$config.apiBaseURL + '/customer/patient/' + params.id, params.requestBody, params.config)
                .then(res => {
                    commit('setProcessing', false)
                    return resolve(res.data)
                })
                .catch(err => {
                    commit('setProcessing', false)
                    return reject(err.data.message)
                })
            })
        },
        deletePatient ({ commit }, params) {
            return new Promise((resolve, reject) => {
                this.$axios
                .delete(this.$config.apiBaseURL + '/customer/parient/' + params.id, params.config)
                .then(res => {
                    commit('setProcessing', false)
                    return resolve(res.data)
                })
                .catch(err => {
                    commit('setProcessing', false)
                    return reject(err.data.message)
                })
            })
        },
    }
}
