<template>
    <ul class="footer-contact">
        <li>
            <a href="tel:18005367111">
                <img :src="formatAssetUrl('images/01-call.svg')" alt="call" />
                <h6>Call</h6>
            </a>
        </li>
        <li>
            <a href="mailto:info@lensdirect.com">
                <img :src="formatAssetUrl('images/footer-email.svg')" alt="email" class="email-icon" />
                <h6>Email</h6>
            </a>
        </li>
        <li>
            <a @click.prevent="onClickLiveChat" href="#">
                <img :src="formatAssetUrl('images/footer-chat.svg')" alt="chat" />
                <h6>Chat</h6>
            </a>
        </li>
        <li>
            <a href="sms:+15164004534">
                <img :src="formatAssetUrl('images/04-chat.svg')" alt="sms" class="sms-icon" />
                <h6>Text</h6>
            </a>
        </li>
    </ul>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
export default {
    mixins: [formatImageMixin],
    methods: {
        onClickLiveChat() {
            if (typeof Gladly === undefined) {
                return;
            }

            Gladly.show();
        }
    }
}
</script>

<style lang="scss" scoped>
    .footer-contact {
        li {
            padding: 0px;
            h6 {
                font-size: 13px !important;
            }
            &:hover {
                box-shadow: 0px 12px 36px #00000025;
            }
        }

        a {
            display: flex;
            flex-direction: column;
            color: #000000;
            height: 100% !important;
            width: 100%;
            justify-content: center;
            gap: 10px;
        }

        h6 {
            margin-top: 0px;
            margin-bottom: 0px !important;
            line-height: 1.1em;
        }

        img {
            align-self: center;
            max-height: 20px;
            max-width: 24px;
        }
    }
</style>
