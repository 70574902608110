export const actions = {
    async redeemGiftCard ({ commit }, params) {
        try {
            let response = this.$axios.$post('/customer/redeem-giftcard', params);
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(e);
        }
    }
};
