export default {
    SINGLE_VISION: {
        id: 1,
        name: 'Single Vision'
    },
    PROGRESSIVE: {
        id: 2,
        name: 'Progressive'
    },
    BIFOCAL: {
        id: 3,
        name: 'Bifocal'
    },
    READERS: {
        id: 4,
        name: 'Readers'
    },
    NON_PRESCRIPTION: {
        id: 5,
        name: 'Non-Prescription'
    }
}
