export default {
    methods: {
        successMessage(message, timeout = 3000) {
            this.$toast.success(message, { timeout });
        },
        formatError(e, defaultErrorMessage = null) {
            let formattedErrors = [];
            if (typeof e.errors !== 'undefined' && Array.isArray(e.errors)) {
                for (let x = 0; x < e.errors.length; x++) {
                    formattedErrors.push(e.errors[x]);
                }
                return formattedErrors;
            }

            if (
                typeof e.response !== 'undefined' &&
                typeof e.response.data !== 'undefined' &&
                typeof e.response.data.errors !== 'undefined' &&
                Array.isArray(e.response.data.errors)
            ) {
                formattedErrors = e.response.data.errors;
                return formattedErrors;
            }

            if (
                typeof e.response !== 'undefined' &&
                typeof e.response.data !== 'undefined' &&
                typeof e.response.data.message !== 'undefined'
            ) {
                formattedErrors.push(e.response.data.message);
                return formattedErrors;
            }

            if (typeof e.message !== 'undefined') {
                formattedErrors.push(e.message);
                return formattedErrors;
            }

            if (defaultErrorMessage === null) {
                defaultErrorMessage = 'Unable to process your request.  Please try refreshing the page.';
            }

            formattedErrors.push(defaultErrorMessage);

            return formattedErrors;
        },
        processError(e, defaultErrorMessage = null) {
            let errors = this.formatError(e, defaultErrorMessage);
            for (let x = 0; x < errors.length; x++) {
                this.$toast.error(errors[x]);
            }
        }
    }
}
