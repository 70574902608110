import Vue from 'vue';
import CustomCheckbox from '@/components/CustomCheckbox';
import CustomRadio from '@/components/CustomRadio';
import CustomFileUpload from '@/components/CustomFileUpload';
import CustomButton from '@/components/CustomButton';
import CustomDateOfBirthField from '@/components/CustomDateOfBirthField';
import CustomInput from '@/components/CustomInput';
import CustomSelect from '@/components/CustomSelect';
import CustomTextarea from '@/components/CustomTextarea';
import CloseButton from '@/components/CloseButton';
import Breadcrumb from '@/components/Breadcrumb';
import ScrollAnchor from '@/components/ScrollAnchor';

Vue.component('CustomCheckbox', CustomCheckbox);
Vue.component('CustomRadio', CustomRadio);
Vue.component('CustomFileUpload', CustomFileUpload);
Vue.component('CustomButton', CustomButton);
Vue.component('CustomDateOfBirthField', CustomDateOfBirthField);
Vue.component('CustomInput', CustomInput);
Vue.component('CloseButton', CloseButton);
Vue.component('CustomSelect', CustomSelect);
Vue.component('CustomTextarea', CustomTextarea);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('ScrollAnchor', ScrollAnchor);
