export default async function (store, axios, productSlug, productTypes, errorMessage) {
    // Reset the base product
    store.dispatch('eyewearFlow/setBaseProduct', null);
    try {
        const product = await axios.$get('/product/' + productSlug);
        if (productTypes.indexOf(product.productType) === -1) {
            // Invalid product type
            return Promise.reject('Invalid product type');
        }

        store.dispatch('eyewearFlow/setBaseProduct', product);
    } catch (e) {
        // show 404
        if (typeof window === 'undefined') {
            return Promise.reject(404);
        }

        return Promise.reject(errorMessage);
    }

    return Promise.resolve();
}
