import handleLinkClick from '@/third-party/algolia/algolia-link-click-event';

export default function ({ html, state }) {
    const url = state.context.viewAllPath + '?productName=' + state.query;

    return html`
        <a href="${url}">
            Show All Results
        </a>
    `;
}
