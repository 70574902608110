<template>
    <b-modal
        :visible="value"
        @hidden="onClickClose"
        hide-footer
        hide-header
        centered
        dialog-class="styled-modal"
    >
        <div class="blue-stripe"></div>
        <CloseButton
            class="close-button"
            @click="onClickClose"
        />
        <div class="title-section"><slot name="title"></slot></div>
        <slot>

        </slot>
    </b-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClickClose() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/color.scss';
    @import '~/assets/scss/variables/breakpoints.scss';

    .styled-modal {
        a {
            display: inline;
        }
        h4 {
            display: block;
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
        }
        .title-section {
            padding-bottom: 10px;
            border-bottom: solid $color-grey-border 1px;
        }
    }

    ::v-deep {
        .styled-modal {
            .modal-body {
                position: relative;
                .blue-stripe {
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    top: 0px;
                    height: 10px;
                    background-color: $color-blue;
                }

                .close-button {
                    position: absolute;
                    right: 15px;
                    top: 20px;
                    font-size: 24px;
                    padding: 0px;
                    margin: 0px;
                    color: #000000;
                    &:hover {
                        color: rgba(0,0,0,.7);
                    }
                }
            }
        }
    }

    @media (max-width: $breakpoint5) {
        ::v-deep {
            .styled-modal {
                .modal-body {
                    padding: 40px 20px;
                }
            }
        }
    }
</style>
