export default function() {
    try {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            return true;
        } else {
            return false;
        }
    } catch (e) {
        // prevent issues getting device type
    }

    return false;
}

export function isIosDevice() {
    try {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        // prevent issues getting device type
    }

    return false;
}
