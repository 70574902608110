<template>
    <div v-if="isCaptchaLoaded" class="login-captcha-component">

    </div>
</template>

<script>
export default {
    async mounted() {
        try {
            await this.$recaptcha.init();
        } catch (e) {
            // Prevent issues loading the captcha
            console.error(e);
        }

        this.isCaptchaLoaded = true;
    },
    beforeDestroy() {
        try {
            this.$recaptcha.destroy();
        } catch (e) {
            // Prevent issues destroying the captcha
        }

        this.isCaptchaLoaded = false;
    },
    data() {
        return {
            isCaptchaLoaded: false
        }
    }
}
</script>

<style lang="scss" scoped>
.login-captcha-component {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    pointer-events: none;
}
</style>
