<template>
    <button
        v-if="previousStep"
        class="btn-back-button"
        @click.prevent="onClick"
    >
        <img :src="formatAssetUrl('images/arrow-left.svg')" class="blue-arrow" alt="left arrow" />
        <img :src="formatAssetUrl('images/arrow-left-b.svg')" class="black-arrow" alt="left arrow" />
        <span class="back-button-text">Back</span>
    </button>
</template>

<script>
import eyewearFlowMixin from '@/mixins/eyewear-flow';
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [eyewearFlowMixin, formatImageMixin],
    methods: {
        onClick() {
            let link = this.getPreviousStepLink();
            let queryParams = this.getPreviousStepQuery();

            this.$router.push({ path: link, query: queryParams });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.btn-back-button {
    display: flex;
    align-items: center;
    padding: 0px;
    border: 0px;
    background-color: transparent;
    color: $color-blue;
    gap: 10px;
    margin-right: auto;
    .black-arrow {
        display: none;
    }
    &:hover {
        color: #000000;
        .blue-arrow {
            display: none;
        }
        .black-arrow {
            display: block;
        }
    }
}

@media (max-width: $breakpoint2) {
    .btn-back-button {
        border: solid $color-grey-input 1px !important;
        color: #91A5B4 !important;
        font-size: 16px;
        border-radius: 5px;
        padding: 12px 25px;
        line-height: 1.2em;
        img {
            display: none !important;
        }
    }
}
</style>
