export default {
    SINGLE_VISION_DISTANCE: {
        id: 1,
        name: 'Single Vision for Distance'
    },
    SINGLE_VISION_READING: {
        id: 2,
        name: 'Single Vision for Reading'
    },
    PROGRESSIVE_STANDARD: {
        id: 3,
        name: 'Progressive Standard'
    },
    PROGRESSIVE_ENHANCED: {
        id: 4,
        name: 'Progressive Enhanced'
    },
    PROGRESSIVE_ULTRA: {
        id: 5,
        name: 'Progressive Ultra'
    },
    READER_PROGRESSIVE: {
        id: 6,
        name: 'Progressive Reader'
    }
}
