<template>
    <div class="mobile-progress-wrapper">
        <div class="steps-container">
            <div
                v-for="(headerStep, index) in headerSteps"
                :key="index"
                class="step-container"
                :style="minStepWidth"
                :class="{ active: currentStepNumber >= (index + 1), 'current-step': isCurrentStep(headerStep) }"
                @click="onClickHeaderStep(headerStep, index + 1)"
            >
                <div class="step-number">
                    {{ (index + 1) }}
                    <div class="step-name">
                        {{ formatStepNameForDisplay(headerStep.name) }}
                    </div>
                </div>
            </div>
        </div>

        <ConfirmBackButton
            :show="showConfirmModal"
            @close="showConfirmModal = false"
            @confirm="onClickConfirmGoBack"
        />
    </div>
</template>

<script>
import ConfirmBackButton from '@/components/landing-page/choose-lenses/ConfirmBackButton';

import stepsMixin from '@/mixins/eyewear-flow-v2/steps-mixin';

import objectFactory from '@/utils/object-factory';
import { buildQueryStringObjectFromSelections, getNextStepLink } from '@/utils/eyewear-flow-v2/flow-tree-utils';
import {
    formatStepName,
    isCurrentStep,
    getCurrentStepNumber,
    getPreviousStepsForStepName
} from '@/utils/eyewear-flow-v2/progress-utils';

export default {
    mixins: [stepsMixin],
    components: {
        ConfirmBackButton
    },
    data() {
        return {
            selectedHeaderStep: null,
            showConfirmModal: false
        }
    },
    computed: {
        currentStepNumber() {
            return getCurrentStepNumber(this.headerSteps, this.currentStepName);
        },
        headerSteps() {
            return this.$store.getters['eyewearFlowV2/steps'];
        },
        currentStepName() {
            return this.formatStepName(this.$store.state.eyewearFlowV2.currentStep + '');
        },
        previousSteps() {
            return this.getPreviousStepsForStepName(this.currentStepName);
        },
        minStepWidth() {
            const totalSteps = this.headerSteps.length;
            if (!totalSteps) {
                return '';
            }

            return 'min-width: calc(100vw / ' + totalSteps + ');';
        }
    },
    methods: {
        getPreviousStepsForStepName(stepName) {
            return getPreviousStepsForStepName(this.headerSteps, stepName);
        },
        formatStepNameForDisplay(stepName) {
            if (stepName.toLowerCase() === 'lens material') {
                return 'Material';
            }

            return this.formatStepName(stepName);
        },
        formatStepName(stepName) {
            return formatStepName(stepName);
        },
        onClickHeaderStep(stepClicked, stepNumberClicked) {
            if (stepNumberClicked >= this.currentStepNumber) {
                return;
            }

            this.selectedHeaderStep = stepClicked;
            this.showConfirmModal = true;
        },
        async onClickConfirmGoBack() {
            // Build query string for the step we're going to
            const previousSteps = this.getPreviousStepsForStepName(this.selectedHeaderStep.name);
            let newflowSelections = [];
            let flowSelectionCopy = objectFactory.deepCopy(this.flowSelections);
            for (let flowSelection of flowSelectionCopy) {
                let foundSelectionStep = previousSteps.find(el => el.id === flowSelection.queryStringKey);
                if (typeof foundSelectionStep === 'undefined') {
                    continue;
                }

                newflowSelections.push(flowSelection);
            }

            await this.$store.dispatch('eyewearFlowV2/setFlowSelections', newflowSelections);

            const queryString = buildQueryStringObjectFromSelections(newflowSelections);

            // Figure out path
            this.showConfirmModal = false;

            const link = getNextStepLink(
                previousSteps.length > 0 ? this.selectedHeaderStep.page : '',
                this.baseProduct.slug,
                '/choose-lenses/'
            );

            this.$router.push({ path: link, query: queryString });
        },
        isCurrentStep(step) {
            return isCurrentStep(step, this.currentStepName);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
.mobile-progress-wrapper {
    display: none;
}

@media (max-width: $breakpoint2) {
    .mobile-progress-wrapper {
        position: relative;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,.1);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 55px;
        padding-bottom: 20px;
        .steps-container {
            display: flex;
            justify-content: center;
            .step-container {
                position: relative;
                min-width: 66px;
                &:first-child {
                    &::before {
                        display: none;
                    }
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
                &::after {
                    position: absolute;
                    right: 0px;
                    top: 10px;
                    content: "";
                    height: 1px;
                    width: calc(50% - 15px);
                    background-color: #dce3e8;
                }
                &::before {
                    position: absolute;
                    left: 0px;
                    top: 10px;
                    content: "";
                    height: 1px;
                    width: calc(50% - 15px);
                    background-color: #dce3e8;
                }
                .step-number {
                    position: relative;
                    display: flex;
                    margin-left: auto;
                    margin-right: auto;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100px;
                    width: 20px;
                    height: 20px;
                    font-size: .75em;
                    border: solid #91a5b4 1px;
                    background-color: #ffffff;
                    color: #91a5b4;
                    .step-name {
                        position: absolute;
                        bottom: -20px;
                        width: 100%;
                        text-align: center;
                        font-size: .833333em;
                        line-height: 1.2em;
                        min-width: 66px;
                        color: #91a5b4;
                        text-transform: uppercase;
                    }
                }
                &.active {
                    .step-number {
                        color: #ffffff;
                        background-color: #7AA5E6;
                        border-color: #7AA5E6;
                        .step-name {
                            color: #7AA5E6;
                        }
                    }
                }
            }
        }
    }
}
</style>
