<template>
    <div class="lens-replacement-progress-bar-container" :style="cssStyle"></div>
</template>

<script>
import eyewearFlowMixin from '@/mixins/eyewear-flow';
import eyewearFlowProgressMixin from '@/mixins/eyewear-flow-progress-mixin';

export default {
    mixins: [eyewearFlowMixin, eyewearFlowProgressMixin],
    computed: {
        cssStyle() {
            let width = Math.round((this.steps.length > 0 ? this.currentStepNumber / this.steps.length : 0) * 100);

            return {
                '--progress-width': width + '%'
            };
        }
    },
    methods: {
        getStepName(step) {
            let stepConstant = this.getStepConstant(step);
            if (stepConstant) {
                return stepConstant.name;
            }

            return '';
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.lens-replacement-progress-bar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0px;
    .step-container {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        &::before {
            position: absolute;
            content: "";
            height: 1px;
            width: 50%;
            background-color: $color-grey-input;
            left: 50%;
            top: 11px;
        }
        &::after {
            position: absolute;
            content: "";
            height: 1px;
            width: 50%;
            background-color: $color-grey-input;
            left: 0;
            top: 11px;
        }
        &:first-child {
            &::after {
                display: none;
            }
        }
        &:last-child {
            &::before {
                display: none;
            }
        }
        .step-dot {
            z-index: 100;
            width: 22px;
            height: 22px;
            box-shadow: 0px 0px 0px 1px $color-grey-border;
            background-color: #ffffff;
            border: solid #ffffff 2px;
            border-radius: 100px;
        }
        .step-name {
            color: #91A5B4;
            cursor: default;
            padding-top: 10px;
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 600;
        }
        &.selected {
            .step-dot {
                background-color: $color-blue;
                box-shadow: 0px 0px 0px 1px $color-grey-input;
                border-width: 4px;
            }
            &:not(.current-step) {
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                    .step-name {
                        cursor: pointer;
                    }
                    .step-dot {
                        box-shadow: 0px 0px 0px 2px $color-blue;
                    }
                }
                &::before {
                    background-color: $color-blue;
                }
            }
            &::after {
                background-color: $color-blue;
            }
            .step-name {
                color: $color-blue;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .lens-replacement-progress-bar-container {
        display: block;
        height: 5px;
        background-color: #DCE3E8;
        width: 100%;
        padding: 0px;
        .step-container {
            display: none;
        }
        &::before {
            display: block;
            content: "";
            height: 5px;
            width: var(--progress-width);
            background-color: #153257;
        }
    }
}
</style>
