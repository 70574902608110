export default {
    RELEVANCE: {
        id: 1,
        name: 'Relevance'
    },
    POPULARITY: {
        id: 2,
        name: 'Popularity'
    },
    PRICE_LOW_TO_HIGH: {
        id: 3,
        name: 'Price: Low to High'
    },
    PRICE_HIGH_TO_LOW: {
        id: 4,
        name: 'Price: High to Low'
    },
    ALPHABETICAL: {
        id: 6,
        name: 'Name: A-Z'
    },
    ALPHABETICAL_REVERSE: {
        id: 7,
        name: 'Name: Z-A'
    }
};
