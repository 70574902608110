<template>
    <div class="steps-wrapper" v-if="!isLoading">
        <div
            v-for="(headerStep, index) in headerSteps"
            :key="index"
            class="step-container"
            :class="{ active: currentStepNumber >= (index + 1), 'current-step': isCurrentStep(headerStep) }"
            @click="onClickHeaderStep(headerStep, index + 1)"
        >
            <div class="step-number">
                {{ (index + 1) }}
            </div>
            <div class="step-name">
                {{ formatStepName(headerStep.name) }}
            </div>
        </div>

        <ConfirmBackButton
            :show="showConfirmModal"
            @close="showConfirmModal = false"
            @confirm="onClickConfirmGoBack"
        />
    </div>
    <div class="steps-wrapper is-loading" v-else>

    </div>
</template>

<script>
import ConfirmBackButton from '@/components/landing-page/choose-lenses/ConfirmBackButton';
import stepsMixin from '@/mixins/eyewear-flow-v2/steps-mixin';
import { buildQueryStringObjectFromSelections, getNextStepLink } from '@/utils/eyewear-flow-v2/flow-tree-utils';
import objectFactory from '@/utils/object-factory';
import { formatStepName, isCurrentStep, getCurrentStepNumber, getPreviousStepsForStepName } from '@/utils/eyewear-flow-v2/progress-utils';

export default {
    mixins: [stepsMixin],
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ConfirmBackButton
    },
    data() {
        return {
            showConfirmModal: false,
            selectedHeaderStep: null
        }
    },
    computed: {
        isLensReplacement() {
            return this.$store.state.eyewearFlow?.baseProduct?.lensReplacement ? true : false;
        },
        currentStepNumber() {
            return getCurrentStepNumber(this.headerSteps, this.currentStepName);
        },
        headerSteps() {
            return this.$store.getters['eyewearFlowV2/steps'];
        },
        currentStepName() {
            return this.formatStepName(this.$store.state.eyewearFlowV2.currentStep + '');
        },
        previousSteps() {
            return this.getPreviousStepsForStepName(this.currentStepName);
        }
    },
    methods: {
        getPreviousStepsForStepName(stepName) {
            return getPreviousStepsForStepName(this.headerSteps, stepName);
        },
        formatStepName(stepName) {
            return formatStepName(stepName);
        },
        onClickHeaderStep(stepClicked, stepNumberClicked) {
            if (stepNumberClicked >= this.currentStepNumber) {
                return;
            }

            this.selectedHeaderStep = stepClicked;
            this.showConfirmModal = true;
        },
        async onClickConfirmGoBack() {
            // Build query string for the step we're going to
            const previousSteps = this.getPreviousStepsForStepName(this.selectedHeaderStep.name);
            let newflowSelections = [];
            let flowSelectionCopy = objectFactory.deepCopy(this.flowSelections);
            for (let flowSelection of flowSelectionCopy) {
                let foundSelectionStep = previousSteps.find(el => el.id === flowSelection.queryStringKey);
                if (typeof foundSelectionStep === 'undefined') {
                    continue;
                }

                newflowSelections.push(flowSelection);
            }

            await this.$store.dispatch('eyewearFlowV2/setFlowSelections', newflowSelections);

            const queryString = buildQueryStringObjectFromSelections(newflowSelections);

            // Figure out path
            this.showConfirmModal = false;

            const link = getNextStepLink(
                previousSteps.length > 0 ? this.selectedHeaderStep.page : '',
                this.baseProduct.slug,
                this.isLensReplacement ? '/lens-replacement/' : '/choose-lenses/'
            );

            this.$router.push({ path: link, query: queryString });
        },
        isCurrentStep(step) {
            return isCurrentStep(step, this.currentStepName);
        }
    }

}
</script>


<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

.steps-wrapper {
    display: flex;
    gap: 10px;
    text-transform: uppercase;
    &.is-loading {
        min-height: 68px;
    }
    .step-container {
        display: flex;
        align-items: center;
        color: #91A5B4;
        &::after {
            content: "";
            background-color: #DCE3E8;
            width: 50px;
            height: 1px;
            margin-left: 10px;
            cursor: default;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        .step-number {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #91A5B4;
            font-size: 15px;
            border: solid #91A5B4 1px;
            background-color: transparent;
            width: 26px;
            height: 26px;
            border-radius: 100px;
            margin-right: 5px;
        }
        &.active {
            color: #000000;
            .step-number {
                color: #ffffff;
                border-color: $color-blue;
                background-color: $color-blue;
            }
            &:not(.current-step) {
                .step-name {
                    &:hover {
                        cursor: pointer;;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &.is-glasses-progress {
        .step-container {
            font-size: .8571em;
            .step-number {
                width: 20px;
                height: 20px;
                font-size: 1em;
            }
            &.active {
                color: #78A4E7;
                .step-number {
                    color: #ffffff;
                    border-color: #78A4E7;
                    background-color: #78A4E7;
                }
            }
        }
    }
}

@media (max-width: $desktopBreakpoint1) {
    .steps-wrapper {
        .step-container {
            .step-number {
                font-size: 12px;
                width: 22px;
                height: 22px;
            }
            &::after {
                width: 30px;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint3) {
    .steps-wrapper {
        gap: 5px;
        .step-container {
            .step-number {
                font-size: 12px;
                width: 20px;
                height: 20px;
            }
            &::after {
                margin-left: 5px;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint4) {
    .steps-wrapper {
        gap: 5px;
        .step-container {
            .step-name {
                font-size: 11px;
            }
            .step-number {
                font-size: 11px;
                width: 20px;
                height: 20px;
            }
            &::after {
                width: 20px;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .steps-wrapper {
        display: none;
    }
}
</style>
