import productTypes from '@/constants/product-types';
import getThemeName from '@/utils/theme-name';

const GOOGLE_ANALYTICS_V4 = 'G-P14LVMQFX0';
const GOOGLE_ANALYTICS_ADS_V4 = 'AW-1060444446';
const GOOGLE_ANALYTICS_UNIVERSAL = 'UA-22813198-1';
const GOOGLE_ADWORDS_ID = '1060444446';
const GOOGLE_ADWORDS_CONVERSION_LABEL = 'KBTQCOrTtwIQnrLU-QM';

function getContentGroupForPage(route) {
    if (!route?.meta?.productType || !route?.meta?.type) {
        return null;
    }

    if (route.meta.type === 'category') {
        return {
            name: 'contentGroup4',
            value: '<Category Page>'
        };
    }

    if (route.meta.type !== 'product') {
        return null;
    }

    if (route.meta.productType === productTypes.CONTACT_LENSES.id) {
        return {
            name: 'contentGroup1',
            value: '<Contact Lens Product Page>'
        };
    }

    if (route.meta.productType === productTypes.GLASSES.id) {
        return {
            name: 'contentGroup2',
            value: '<Glasses Product Page>'
        };
    }

    if (route.meta.productType === productTypes.ACCESSORIES.id) {
        return {
            name: 'contentGroup3',
            value: '<Accessory Product Page>'
        };
    }

    return null;
}

function getProductTypeName(productType) {
    for (let x in productTypes) {
        if (productType === productTypes[x].id) {
            return productTypes[x].name;
        }
    }

    return 'Unknown';
}

export function googleAdsConversionTracking(order) {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof gtag === 'undefined') {
        googleAnalyticsV4Tracking();
    }

    gtag('event', 'conversion', {
        'send_to': 'AW-1060444446/KBTQCOrTtwIQnrLU-QM',
        'value': order.total,
        'currency': 'USD',
        'transaction_id': order.id
    });
}

export function googleAnalyticsUniversalTracking(route) {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof ga === 'undefined') {
        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date(); a = s.createElement(o),
                m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

        ga('create', GOOGLE_ANALYTICS_UNIVERSAL, 'auto');
    }

    let contentGroup = getContentGroupForPage(route);
    if (contentGroup) {
        ga('set', contentGroup.name, contentGroup.value);
    }

    let theme = getThemeName();

    ga('send', 'pageview', { 'dimension1': theme });
}

export function googleAnalyticsUniversalConversion(order) {
    try {
        if (typeof window === 'undefined') {
            return;
        }

        if (typeof ga === 'undefined') {
            (function (i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments)
                }, i[r].l = 1 * new Date(); a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
            })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

            ga('create', GOOGLE_ANALYTICS_UNIVERSAL, 'auto');
        }

        ga('require', 'ecommerce', 'ecommerce.js');
        ga('ecommerce:addTransaction', {
            id: order.id,
            affiliation: 'LensDirect LLC',
            revenue: order?.total,
            shipping: order?.shippingCost,
            txt: order?.tax
        });

        for (let orderItem of order.items) {
            ga('ecommerce:addItem', {
                id: order.id,
                category: getProductTypeName(orderItem.product?.productType),
                name: orderItem.product?.name,
                price: orderItem.price,
                quantity: orderItem.quantity,
                sku: orderItem.product.id
            });
        }

        ga('ecommerce:send');
    } catch (e) {
        // Prevent issues sending conversion
    }
}

export function googleAnalyticsV4Tracking() {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof gtag === 'undefined') {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://www.googletagmanager.com/gtag/js?id=" + GOOGLE_ANALYTICS_V4;
            js.type = "text/javascript"
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-analytics-v4-script'));

        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            dataLayer.push(arguments);
        }
    }

    gtag('js', new Date());
    gtag('config', GOOGLE_ANALYTICS_V4);
    gtag('config', GOOGLE_ANALYTICS_ADS_V4, { 'allow_enhanced_conversions': true });
}

export function googleAdwordsRemarketingTracking(pageType, productId = null, totalValue = 0) {
    if (typeof window === 'undefined') {
        return;
    }

    if (productId && Array.isArray(productId)) {
        let products = [];
        for (let x = 0; x < productId.length; x++) {
            products.push(productId[x].id);
        }
        productId = products;
    }

    window.google_tag_params = {
        ecomm_pagetype: pageType,
        ecomm_totalvalue: totalValue
    };

    if (productId) {
        window.google_tag_params.ecomm_prodid = productId;
    }

    /* <![CDATA[ */
    window.google_conversion_id = 1060444446;
    window.google_custom_params = window.google_tag_params;
    window.google_remarketing_only = true;
    /* ]]> */

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//www.googleadservices.com/pagead/conversion.js";
        js.type = "text/javascript"
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-adwords-conversion'));
}

export function googleAdwordsRemarketingPurchaseTracking(order, pageType) {
    if (typeof window === 'undefined') {
        return;
    }

    if (!order?.items || !Array.isArray(order?.items)) {
        return;
    }

    let products = [];
    let productIds = [];
    for (let x = 0; x < order.items.length; x++) {
        let product = order?.items[x]?.product;
        if (!product) {
            continue;
        }

        if (productIds.indexOf(product.id) !== -1) {
            continue;
        }

        productIds.push(product.id);
        products.push(product);
    }

    googleAdwordsRemarketingTracking(pageType, products, order?.total);
}

export function googleAdwordsConversionTracking(order) {
    if (typeof window === 'undefined') {
        return;
    }

    /* <![CDATA[ */
    window.google_conversion_id = 1060444446;
    window.google_conversion_label = GOOGLE_ADWORDS_CONVERSION_LABEL;
    window.google_conversion_value = order.total;
    window.google_conversion_language = 'en';
    window.google_conversion_format = '1';
    window.google_conversion_color = 'ffffff';
    window.google_conversion_currency = 'USD';
    window.google_remarketing_only = false;
    /* ]]> */

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//www.googleadservices.com/pagead/conversion.js";
        js.type = "text/javascript"
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-adwords-conversion'));
}

export function googleTrackAddToCart() {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.gtag === 'undefined') {
        googleAnalyticsV4Tracking();
    }

    gtag('event', 'Add Item', {
        event_category: 'Cart',
    });
}

export function googleInitiateCheckout(cart) {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.gtag === 'undefined') {
        googleAnalyticsV4Tracking();
    }

    gtag("event", "begin_checkout", {
        currency: "USD",
        value: cart.total,
        coupon: cart.coupon ? cart.coupon.code : '',
        items: cart.items.map((item, index) => {
            return {
                item_id: item.product?.variantId,
                item_name: item.product.name,
                affiliation: "LensDirect.com Website",
                coupon: cart.coupon ? cart.coupon.code : '',
                discount: item.couponDiscount,
                index,
                item_category: getProductTypeName(item.product?.productType),
                item_variant: item.product?.variantName,
                price: item.price,
                quantity: item.quantity
            }
        })
    });
}

export function googleEnhancedConversionTrackingObject(order) {
    window.google_enhanced_conversion_data = {
        "email": order.customer?.email,
        "phone_number": '+1' + order?.shippingAddress?.phone,
        "first_name": order?.shippingAddress?.firstName,
        "last_name": order?.shippingAddress?.lastName,
        "street": order?.shippingAddress?.line1,
        "city": order?.shippingAddress?.city,
        "region": order?.shippingAddress?.stateCode,
        "postal_code": order?.shippingAddress?.zipCode,
        "country": order?.shippingAddress?.countryCode
    }
}

export function googleEnhancedConversionTracking(order) {
    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.gtag === 'undefined') {
        googleAnalyticsV4Tracking();
    }

    gtag('set', 'user_data', {
        "email": order.customer?.email,
        "phone_number": '+1' + order?.shippingAddress?.phone,
        "address": {
            "first_name": order?.shippingAddress?.firstName,
            "last_name": order?.shippingAddress?.lastName,
            "street": order?.shippingAddress?.line1,
            "city": order?.shippingAddress?.city,
            "region": order?.shippingAddress?.stateCode,
            "postal_code": order?.shippingAddress?.zipCode,
            "country": order?.shippingAddress?.countryCode
        }
    });

    gtag("event", "purchase", {
        transaction_id: order.id,
        value: order.total,
        tax: order.tax,
        shipping: order.shippingCost,
        currency: "USD",
        coupon: order.coupon ? order.coupon.code : '',
        items: order.items.map((item, index) => {
            return {
                item_id: item.id,
                item_name: item.product.name,
                affiliation: "LensDirect.com Website",
                coupon: order.coupon ? order.coupon.code : '',
                discount: item.couponDiscount,
                index,
                // item_brand: "Google",
                item_category: getProductTypeName(item.product?.productType),
                // item_category2: "Adult",
                // item_category3: "Shirts",
                // item_category4: "Crew",
                // item_category5: "Short sleeve",
                // item_list_id: "related_products",
                // item_list_name: "Related Products",
                item_variant: item.product?.variantName,
                // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                price: item.price,
                quantity: item.quantity
            }
        })
    });
    window.setTimeout(() => {
        gtag('event', 'conversion', {
            'send_to': 'AW-1060444446/KBTQCOrTtwIQnrLU-QM',
            'value': order.total,
            'currency': 'USD',
            'transaction_id': order.id
        });
    }, 1000);
}
