import storage from '@/utils/storage';

const CJ_NEW_ACTION_ID = 384095;
const CJ_RETURNING_ACTION_ID = 384096;
const CJ_NEW_CONTAINER_ID = 13250;
const CJ_RETURNING_CONTAINER_ID = 13251;
const CJ_ENTERPRISE_ID = 1519025;

export function getCjEventFromRoute(route) {
    if (typeof window === 'undefined') {
        return;
    }

    for (let queryString in route.query) {
        if (queryString.toLowerCase() === 'cjevent') {
            return route.query[queryString];
        }
    }

    return null;
}

export function cjCookieTracking(axios, route) {
    if (typeof window === 'undefined') {
        return;
    }

    let cjEvent = getCjEventFromRoute(route);

    if (!cjEvent) {
        return;
    }

    storage.set('cje', cjEvent);

    axios.$get('/page-tracking/cj?cjevent=' + cjEvent);
}

export function cjConversionTracking(order, user) {
    if (typeof window === 'undefined') {
        return;
    }

    let actionId = order?.customer?.isNewCustomer ? CJ_NEW_ACTION_ID : CJ_RETURNING_ACTION_ID;
    let containerId = order?.customer?.isNewCustomer ? CJ_NEW_CONTAINER_ID : CJ_RETURNING_CONTAINER_ID;
    let url = 'https://www.emjcd.com/tags/c?containerTagId=' +
        containerId + '&CID=' + CJ_ENTERPRISE_ID + '&TYPE=' +
        actionId + '&CURRENCY=USD';

    url += '&OID=' + order.id;

    if (order.coupon) {
        url += '&COUPON=' + order.coupon.code;
        url += '&DISCOUNT=' + order.couponDiscount;
    }

    for (let x = 0; x < order?.items?.length; x++) {
        let orderItem = order.items[x];
        let itemNumber = x + 1;

        url += '&ITEM' + itemNumber + '=' + orderItem?.product?.variantId;
        url += '&AMT' + itemNumber + '=' + orderItem?.price;
        url += '&QTY' + itemNumber + '=' + orderItem?.quantity;
    }

    let cjEvent = user?.tracking?.cj || storage.get('cje');
    if (cjEvent) {
        url += '&CJEVENT=' + cjEvent;
    }

    if (document.getElementById('cj_conversion')?.length > 0) {
        document.getElementById('cj_conversion').src = url;
    } else {
        let element = document.createElement('iframe');
        element.height = 1;
        element.width = 1;
        element.id = 'cj_conversion';
        element.src = url;
        document.body.append(element);
    }
}
