<template>
    <div class="mobile-header-wrapper">
        <div class="logo-wrapper">
            <a href="/" @click.prevent="onClickLogo"><img :src="websiteLogo()" alt="lensdirect logo" :class="websiteLogoCss()" /></a>
        </div>
        <div class="exit-button-wrapper">
            <a :href="'/' + baseProduct.slug" @click.prevent="onClickReturnToFrame"><img :src="formatAssetUrl('images/times-line.svg')" alt="return to frame" /></a>
        </div>

        <ConfirmExitModal
            :show="showConfirmExit || showConfirmReturnToFrame"
            @close="showConfirmExit = false; showConfirmReturnToFrame = false"
            @confirm="onConfirmExit"
        />
    </div>
</template>

<script>
import ConfirmExitModal from '@/components/landing-page/choose-lenses/ConfirmExitModal';

import formatImageUrlMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageUrlMixin],
    props: {
        baseProduct: {
            type: Object,
            required: true
        }
    },
    components: {
        ConfirmExitModal
    },
    data() {
        return {
            showConfirmExit: false,
            showConfirmReturnToFrame: false
        }
    },
    methods: {
        onClickLogo() {
            this.showConfirmExit = true;
        },
        onConfirmExit() {
            if (this.showConfirmExit) {
                window.location = '/';
                return;
            }

            window.location = '/' + this.baseProduct.slug;
        },
        onClickReturnToFrame() {
            this.showConfirmReturnToFrame = true;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.mobile-header-wrapper {
    display: none;
}

@media (max-width: $breakpoint2) {
    .mobile-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        padding: 15px 20px;
        border-bottom: solid #DCE3E8 1px;
        .logo-wrapper {
            img {
                max-width: 100%;
                height: 30px;
                &.xmas-logo {
                    width: auto;
                    min-width: auto;
                }
            }
        }
        .exit-button-wrapper {
            img {
                width: 16px;
                height: 16px;
            }
        }
    }
}
</style>
