<template>
    <AppWrapper>
        <div class="vision-contents">
            <Header />
            <nuxt />
            <Footer />
        </div>
    </AppWrapper>
</template>

<script>
import AppWrapper from '@/components/AppWrapper';
import Header from '@/components/landing-page/vision-test/flow/Header.vue';
import Footer from '@/components/landing-page/vision-test/flow/Footer.vue';

export default {
    components: {
        AppWrapper,
        Header,
        Footer
    }
}
</script>
