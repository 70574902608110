import eyewearFlowSteps from '@/constants/eyewear-flow-steps'
import objectFactory from '@/utils/object-factory';
import storage from '@/utils/storage';

export default {
    computed: {
        baseProduct() {
            return this.$store.state.eyewearFlow.baseProduct;
        },
        nextStep() {
            if (typeof this.steps === 'undefined') {
                return null;
            }

            // Find the next step
            for (let x = 0; x < this.steps.length; x++) {
                let step = this.steps[x];
                if (step === this.currentStep.id) {
                    let nextStep = typeof this.steps[x + 1] !== 'undefined' ? this.steps[x + 1] : null;
                    return this.getStepConstant(nextStep);
                }
            }

            return null;
        },
        previousStep() {
            if (typeof this.steps === 'undefined') {
                return null;
            }

            // Find the previous step
            for (let x = 0; x < this.steps.length; x++) {
                if (x === 0) {
                    continue;
                }

                let step = this.steps[x];
                if (step === this.currentStep?.id) {
                    let previousStep = typeof this.steps[x - 1] !== 'undefined' ? this.steps[x - 1] : null;
                    return this.getStepConstant(previousStep);
                }
            }

            return null;
        },
        steps() {
            return this.$store.state.eyewearFlow.steps;
        },
        selectedChoices() {
            return this.$store.state.eyewearFlow.selectedChoices;
        },
        estimatedProduct() {
            return this.$store.state.eyewearFlow.estimatedProduct;
        },
        currentStep() {
            return this.$store.state.eyewearFlow.currentStep;
        },
        currentStepNumber() {
            if (!this.currentStep || this.steps.length === 0) {
                return 1;
            }

            for (let x = 0; x < this.steps.length; x++) {
                if (this.steps[x] === this.currentStep.id) {
                    return x + 1;
                }
            }

            return 1;
        },
        choices() {
            let unfilteredChoices = this.$store.state.eyewearFlow.choices;
            let choices = [];
            for (let x = 0; x < unfilteredChoices.length; x++) {
                if (!unfilteredChoices[x].stepEntity) {
                    continue;
                }

                choices.push(unfilteredChoices[x]);
            }
            return choices;
        },
        isLoadingChoices() {
            return this.$store.state.eyewearFlow.isLoadingChoices;
        }
    },
    methods: {
        scrollToMobileStartAnchor() {
            if (typeof this.$refs['mobile-start-anchor'] === 'undefined') {
                return;
            }

            if (!this.$refs['mobile-start-anchor']) {
                return;
            }

            if (this.$refs['mobile-start-anchor'].$el.offsetParent === null) {
                return;
            }

            this.$refs['mobile-start-anchor'].$el.scrollIntoView();
        },
        removeSelectedChoice(flowStep) {
            let selectedChoices = objectFactory.deepCopy(this.selectedChoices);
            let newSelectedChoices = [];
            for (let x = 0; x < selectedChoices.length; x++) {
                if (selectedChoices[x]?.choice?.stepTypeProperty === flowStep.id) {
                    continue;
                }

                newSelectedChoices.push(selectedChoices[x]);
            }

            this.$store.dispatch('eyewearFlow/setSelectedChoices', newSelectedChoices);
        },
        setSelectedChoice(choice, flowStep) {
            let selectedChoices = objectFactory.deepCopy(this.selectedChoices);
            let newSelectedChoices = [];
            for (let x = 0; x < selectedChoices.length; x++) {
                if (selectedChoices[x]?.choice?.stepTypeProperty === flowStep.id) {
                    continue;
                }

                newSelectedChoices.push(selectedChoices[x]);
            }

            newSelectedChoices.push({
                choice: choice?.stepEntity,
                name: flowStep.name,
                value: choice?.stepEntity?.name
            });

            this.$store.dispatch('eyewearFlow/setSelectedChoices', newSelectedChoices);
        },
        clearFutureChoiceProductStorage() {
            if (this.steps.legnth === 0) {
                return;
            }

            let existingStorage = storage.get('eyewearFlowCachedProductChoices');
            if (!existingStorage) {
                return;
            }

            let clearAddons = true;
            let newExistingStorage = {};
            for (let x = 0; x < this.steps.length; x++) {
                let step = this.steps[x];

                if (typeof existingStorage[step] === 'undefined') {
                    continue;
                }

                newExistingStorage[step] = existingStorage[step];

                if (step === 'lensCoating') {
                    clearAddons = false;
                }

                if (this.currentStep?.id === step) {
                    break;
                }
            }

            if (Object.keys(newExistingStorage).length > 0) {
                storage.set('eyewearFlowCachedProductChoices', newExistingStorage);
            }

            if (clearAddons) {
                storage.set('selectedAddons', null);
                storage.set('engravingText', '');
                storage.set('engravingFontFamily', '');
            }
        },
        clearChoiceProductStorage() {
            storage.set('eyewearFlowCachedProductChoices', null);
            storage.set('selectedAddons', null);
            storage.set('engravingText', '');
            storage.set('engravingFontFamily', '');
        },
        saveChoiceProductToStorage(flowStep, productId) {
            let existingStorage = storage.get('eyewearFlowCachedProductChoices');
            if (existingStorage === null) {
                existingStorage = {};
            }

            existingStorage[flowStep.id] = productId;
            storage.set('eyewearFlowCachedProductChoices', existingStorage);
        },
        getPreviousSavedChoice(flowStep) {
            let existingStorage = storage.get('eyewearFlowCachedProductChoices');
            if (!existingStorage) {
                return null;
            }

            if (typeof existingStorage[flowStep.id] === 'undefined') {
                return null;
            }

            if (this.choices.length === 0) {
                return null;
            }

            for (let x = 0; x < this.choices.length; x++) {
                let choice = this.choices[x];
                if (choice?.product.id === existingStorage[flowStep.id]) {
                    return choice;
                }

                let foundSubChoice = choice.subChoices.length > 0
                    ? this.getPreviouslySavedSubChoice(choice.subChoices, flowStep)
                    : null;

                if (foundSubChoice) {
                    return foundSubChoice;
                }
            }

            return null;
        },
        getPreviouslySavedSubChoice(subChoices, flowStep) {
            let existingStorage = storage.get('eyewearFlowCachedProductChoices');
            if (!existingStorage) {
                return null;
            }

            for (let x = 0; x < subChoices.length; x++) {
                let subChoice = subChoices[x];
                if (subChoice?.product.id === existingStorage[flowStep.id]) {
                    return subChoice;
                }
            }

            return null;
        },
        getStepConstant(stepId) {
            for (let stepKey in eyewearFlowSteps) {
                let stepConstant = eyewearFlowSteps[stepKey];
                if (stepConstant.id === stepId) {
                    return stepConstant;
                }
            }

            return null;
        },
        isFirstStep(stepConstant) {
            if (this.steps.length === 0) {
                return false;
            }

            if (this.steps[0] === stepConstant.id) {
                return true;
            }

            return false;
        },
        formatStepLink(stepConstant) {
            if (!this.baseProduct) {
                return '';
            }

            let isFirstStep = this.isFirstStep(stepConstant);

            if (this.baseProduct.lensReplacement) {
                return '/lens-replacement/' + this.baseProduct.slug + (!isFirstStep ? ('/' + stepConstant.page) : '');
            }

            return '/choose-lenses/' + this.baseProduct.slug + (!isFirstStep ? ('/' + stepConstant.page) : '');
        },
        getNextStepLink() {
            if (!this.nextStep) {
                return '';
            }

            return this.formatStepLink(this.nextStep);
        },
        getNextStepQuery(choice, selectedChoiceParam) {
            if (!this.nextStep) {
                return {};
            }

            let queryParams = objectFactory.deepCopy(this.$route.query);
            queryParams[selectedChoiceParam] = choice.stepEntity.stepTypeValue;
            return queryParams;
        },
        isStepAfterLensColor(checkStep) {
            let foundLensColorStep = false;
            for (let step of this.steps) {
                if (step === checkStep?.id) {
                    break;
                }

                if (step === 'lensType' || step === 'lensTypeVariation') {
                    foundLensColorStep = true;
                }
            }

            return foundLensColorStep;
        },
        getPreviousStepQuery() {
            if (this.previousStep === null) {
                return null;
            }

            let queryParams = objectFactory.deepCopy(this.$route.query);

            let previousStepQueryParam = this.previousStep.id;
            if (typeof queryParams[previousStepQueryParam] !== 'undefined') {
                delete queryParams[previousStepQueryParam];
            }

            if (
                !this.isStepAfterLensColor(this.previousStep) &&
                typeof queryParams.lensColor !== 'undefined'
            ) {
                delete queryParams.lensColor;
            }

            if (
                this.previousStep.id === eyewearFlowSteps.LENS_TYPE.id &&
                typeof queryParams.lensTypeVariation !== 'undefined'
            ) {
                delete queryParams.lensTypeVariation;
            }

            if (
                this.previousStep.id === eyewearFlowSteps.RX_TYPE.id &&
                typeof queryParams.rxTypeVariation !== 'undefined'
            ) {
                delete queryParams.rxTypeVariation;
            }

            return queryParams;
        },
        getPreviousStepLink() {
            if (this.previousStep === null) {
                return null;
            }

            return this.formatStepLink(this.previousStep);
        }
    }
}
