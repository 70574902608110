<template>
    <b-modal
        centered
        dialog-class="login-modal-component"
        :visible="show"
        hide-footer
        hide-header
        size="sm"
        :no-close-on-backdrop="noCloseOnBackdrop"
    >
        <div class="close-button-wrapper">
            <CloseButton @click="onClose" />
        </div>
        <RegisterForm
            v-if="showRegisterForm"
            @accountCreated="onLoggedIn"
            @signIn="onSignIn"
            @forgotPassword="onForgotPassword"
        />
        <ForgotPasswordForm
            v-else-if="showForgotPasswordForm"
            @signIn="onSignIn"
        />
        <LoginForm
            v-else
            @loggedIn="onLoggedIn"
            @createAccount="onCreateAccount"
            @forgotPassword="onForgotPassword"
            :redirectPath="redirectPath"
        >
            <template #title><slot name="logInTitle">Welcome back!</slot></template>
        </LoginForm>
    </b-modal>
</template>

<script>
import LoginForm from '@/components/auth-form/LoginForm';
import RegisterForm from '@/components/auth-form/RegisterForm';
import ForgotPasswordForm from '@/components/auth-form/ForgotPasswordForm';

export default {
    components: {
        LoginForm,
        RegisterForm,
        ForgotPasswordForm
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        noCloseOnBackdrop: {
            type: Boolean,
            default: false
        },
        redirectPath: {
            type: String,
            default: '/profile'
        }
    },
    mounted() {
        this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
            this.$emit('closeModal');
        });
    },
    data() {
        return {
            showLoginForm: false,
            showRegisterForm: false,
            showForgotPasswordForm: false
        };
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
        onLoggedIn() {
            this.$emit('loggedIn');
        },
        onCreateAccount() {
            this.showRegisterForm = true;
            this.showLoginForm = false;
            this.showForgotPasswordForm = false;
        },
        onForgotPassword() {
            this.showForgotPasswordForm = true;
            this.showLoginForm = false;
            this.showRegisterForm = false;
        },
        onSignIn() {
            this.showLoginForm = true;
            this.showForgotPasswordForm = false;
            this.showRegisterForm = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep {
        .login-modal-component {
            border-radius: 10px;
            .modal-content {
                border-radius: 10px;
            }
            .close-button-wrapper {
                position: absolute;
                top: 10px;
                right: 15px;
            }
        }
    }
</style>
