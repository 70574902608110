<template>
    <div :style="cssStyle">
        <transition
            name="expand"
            @enter="onEnter"
            @after-enter="onAfterEnter"
            @leave="onLeave"
        >

            <slot />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'TransitionExpand',
    props: {
        speed: {
            type: Number,
            default: .4
        }
    },
    computed: {
        cssStyle() {
            return {
                '--transition': 'height ' + this.speed + 's ease-in-out'
            };
        }
    },
    methods: {
        onEnter(element) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const height = getComputedStyle(element).height;

            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            // Trigger the animation.
            // We use `requestAnimationFrame` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },
        onAfterEnter(element) {
            element.style.height = 'auto';
        },
        onLeave(element) {
            const height = getComputedStyle(element).height;

            element.style.height = height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.expand-enter-active,
.expand-leave-active {
    transition: var(--transition);
    overflow: hidden;
}

.expand-enter,
.expand-leave-to {
    height: 0;
}

@media (max-width: $breakpoint2) {
    .mobile-zero-transition {
        .expand-enter-active,
        .expand-leave-active {
            transition: height 0s ease-in-out !important;
            overflow: hidden;
        }
    }
}
</style>
