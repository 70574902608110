export const state = () => ({
    payment: null,
    authKey: ""
});

export const mutations = {
    setPayments (state, payload) {
        state.payment = payload
        state.processing = false
        state.loginError = null
    },
    setAuthKey (state, payload){
        state.authKey = payload
    }
};

export const actions = {
    async getPayments ({ commit }, search = {}) {
        try {
            let response = await this.$axios.$get('/customer/payment-method', { params: search });
            return response.data;
        } catch (e) {
            // Prevent issues loading payment methods
        }

        return null;
    },
    async getPaymentDetail ({ commit }, params) {

        try {
            let response = await this.$axios.$get('/customer/payment-method/' + params.id);
            return response;
        } catch (e) {
            // Prevent issues loading payment method
        }

        return null;
    },
    addPayment ({ commit }, params) {
        return new Promise((resolve, reject) => {
            this.$axios
            .post(this.$config.apiBaseURL + '/customer/payment-method', params.requestBody, params.config)
            .then(res => {
                commit('setProcessing', false)
                return resolve(res.data)
            })
            .catch(err => {
                commit('setProcessing', false)
            })
        })
    },
    editPayment ({ commit }, params) {
        return new Promise((resolve, reject) => {
            this.$axios
            .patch(this.$config.apiBaseURL + '/customer/payment-method/' + params.id, params.requestBody, params.config)
            .then(res => {
                commit('setProcessing', false)
                return resolve(res.data)
            })
            .catch(err => {
                commit('setProcessing', false)
            })
        })
    },
    deletePayment ({ commit }, params) {
        return new Promise((resolve, reject) => {
            this.$axios
            .delete(this.$config.apiBaseURL + '/customer/payment-method/' + params.id, params.config)
            .then(res => {
                commit('setProcessing', false)
                return resolve(res.data)
            })
            .catch(err => {
                commit('setProcessing', false)
            })
        })
    },
    getPaymentOption ({ commit }, config) {
        return new Promise((resolve, reject) => {
            this.$axios
            .get(this.$config.apiBaseURL + '/customer/cart/checkout/payment-options', config)
            .then(res => {
                res.data.forEach(element => {
                    if(element.name == "PayPal"){
                        commit('setAuthKey', element.extra.authKey);
                        return resolve(element.extra.authKey)
                    }
                });
            })
            .catch(err => {
                commit('setProcessing', false)
            })
        })
    }
};

export const getters = {
    authKey: state => state.authKey
}
