<template>
    <b-modal @hide="onClose" :visible="show" centered title="Are you sure?" size="sm" @close="onClose">
        <template v-slot:modal-header-close>
            <img :src="formatAssetUrl('images/times-line.svg')" />
        </template>

        <p class="text-center">
            Are you sure you want to go back? Any progress will not be saved.
        </p>

        <template v-slot:modal-footer>
            <button class="btn btn-outline" type="button" @click="onClose">
                Cancel
            </button>

            <CustomButton class="btn btn-primary" type="button" @click="onClickConfirm">
                Yes
            </CustomButton>
        </template>
    </b-modal>
</template>

<script>
import formatImageUrlMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageUrlMixin],
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClickConfirm() {
            this.$emit('confirm');
        },
        onClose() {
            this.$emit('close');
        }
    }
};
</script>
