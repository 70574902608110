export default {
    methods: {
        loadTrustpilotWidget(elementId) {
            window.setTimeout(() => {
                if (typeof window.Trustpilot === 'undefined') {
                    return;
                }

                try {
                    const trustbox = document.getElementById(elementId);
                    if (!trustbox) {
                        return;
                    }

                    window.Trustpilot.loadFromElement(trustbox);
                } catch (e) {
                    // Prevent issues loading trust pilot
                }
            }, 200);
        }
    }
}
