export default {
    BLU_DEFEND: {
        id: 1,
        name: 'BluDefend'
    },
    TRANSITIONS_SIGNATURE: {
        id: 2,
        name: 'Signature'
    },
    TRANSITIONS_XTRACTIVE: {
        id: 3,
        name: 'XTRActive'
    },
    TINT: {
        id: 4,
        name: 'Tint'
    },
    GRADIENT: {
        id: 5,
        name: 'Gradient'
    },
    POLARIZED: {
        id: 6,
        name: 'Polarized'
    },
    MIRROR: {
        id: 7,
        name: 'Mirror'
    },
    POLARIZED_MIRROR: {
        id: 8,
        name: 'Polarized Mirror'
    }
}
