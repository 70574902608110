<template>
    <div class="mobile-search-panel" ref="mobileSearchPanel">
        <div class="search-form">
            <AlgoliaMobileAutoComplete @results="onAlgoliaResults" />
            <button class="btn-search-back" @click="onClickCloseSearch" type="button">
                <img :src="formatAssetUrl('images/arrow-small-left.svg')" alt="back button" />
            </button>
        </div>
        <div class="contact-lens-brands-wrapper">
            <Brand
                :overrideLoop="false"
                :overrideSlidesPerPage="0"
                :overrideResponsiveConfiguration="brandsConfig"
                :overrideGutter="5"
            />
        </div>
        <div class="search-results-container" v-if="searchResults.length > 0">
            <div class="search-header">
                <strong> Search results ({{ totalResults }}) </strong>
                <a
                    class="btn btn-link"
                    :href="showAllPath + '?productName=' + searchTerm"
                    v-if="totalResults > 5"
                >
                    See all
                </a>
            </div>

            <div class="search-results">
                <nuxt-link
                    v-for="(searchResult, index) in searchResults"
                    :key="index"
                    class="search-result-item"
                    :to="'/' + searchResult.slug"
                >
                    <img v-if="searchResult.image" :src="searchResult.image" />
                    {{ searchResult.name }}
                </nuxt-link>
            </div>
        </div>
        <div class="no-results-loading-container" v-else>
            <div class="search-results-container" v-if="isLoading">
                <div class="search-header">
                    <NavbarSearchResultsCountSkeleton />
                    <nuxt-link class="btn btn-link" to="/contact-lenses">
                        See all
                    </nuxt-link>
                </div>
                <div class="search-results">
                    <NavbarSearchSkeleton v-for="x in 5" :key="x" class="search-result-item"> </NavbarSearchSkeleton>
                </div>
            </div>
            <div v-if="totalResults === 0" class="text-center no-results-container">
                <div class="alert alert-primary">
                    No search results
                </div>
            </div>
            <div class="search-history" v-if="!isLoading">
                <div class="search-header">
                    <strong>Top Searches</strong>
                </div>
                <div class="search-history-items">
                    <div
                        v-for="(topSearchItem, index) in topSearches"
                        :key="index"
                        class="search-history-item"
                        @click.prevent="onClickSearchHistoryItem(topSearchItem)"
                    >
                        <img :src="formatAssetUrl('images/search.svg')" alt="magnifying glass" class="search-icon" />
                        <a :href="topSearchItem.path" @click.prevent="">{{ topSearchItem.text }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop" @click="onClickCloseSearch"></div>
    </div>
</template>

<script>
import imageUrlFormatMixin from '@/mixins/image-url-formatter';
import freezeMobileBackdrop from '@/utils/freeze-mobile-backdrop';
import NavbarSearchSkeleton from '@/components/NavbarSearchSkeleton';
import NavbarSearchResultsCountSkeleton from '@/components/NavbarSearchResultsCountSkeleton';
import productTypes from '@/constants/product-types';
import hideMobileChatWindow from '@/utils/hide-mobile-chat-window';
import Brand from '@/components/landing-page/contact-lenses/Brand';
import AlgoliaMobileAutoComplete from '@/components/third-party/AlgoliaMobileAutoComplete';

export default {
    mixins: [imageUrlFormatMixin],
    components: {
        NavbarSearchSkeleton,
        NavbarSearchResultsCountSkeleton,
        Brand,
        AlgoliaMobileAutoComplete
    },
    mounted() {
        hideMobileChatWindow(true);
        this.onScroll();
        window.addEventListener('scroll', this.onScroll, true);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll, true);
    },
    data() {
        return {
            searchTerm: '',
            searchResults: [],
            totalResults: null,
            isLoading: false,
            searchTimeout: null,
            topSearches: [
                {
                    text: 'Reordering my contacts',
                    path: '/profile/order-history'
                },
                {
                    text: 'Acuvue contacts',
                    path: '/acuvue-contacts'
                },
                {
                    text: 'Progressive lenses',
                    path: '/lens-replacement/prescription-lens-replacement'
                },
                {
                    text: 'Daily contacts',
                    path: '/daily-contacts'
                },
                {
                    text: 'Blue light glasses',
                    path: '/blue-light-glasses'
                },
                {
                    text: 'Sunglasses',
                    path: '/sunglasses'
                },
                {
                    text: 'Replacement lenses',
                    path: '/lens-replacement/prescription-lens-replacement'
                }
            ],
            brandsConfig: [
                {
                    maxWidth: 1060,
                    peekRight: 20
                }
            ]
        };
    },
    computed: {
        isSearchVisible() {
            return this.$store.state.search.isVisible;
        },
        brandLinks() {
            return [
                { name: 'Bausch & Lomb', alt: 'Bausch & Lomb', image: 'bausch.png', slug: 'bausch-and-lomb' },
                { name: 'Acuvue', alt: 'Acuvue', image: 'brand1.png', slug: 'acuvue-contacts' },
                { name: 'Air Optix', alt: 'Air Optix', image: 'brand2.png', slug: 'air-optix' },
                { name: 'Dailies', alt: 'Dailies', image: 'brand3.png', slug: 'dailies' },
                { name: 'Clariti', alt: 'Clariti', image: 'brand4.png', slug: 'clariti' },
                { name: 'Biofinity', alt: 'Biofinity', image: '05-biofinity.svg', slug: 'biofinity' }
            ];
        },
        searchHistory() {
            return this.$store.state.search.searchHistory;
        },
        showAllPath() {
            let counts = {};

            for (let x = 0; x < this.searchResults.length; x++) {
                let product = this.searchResults[x];
                let productType = product.productType;

                if (typeof counts[productType] === 'undefined') {
                    counts[productType] = 0;
                }

                counts[productType]++;
            }

            let maxProductType = null;
            let maxCount = null;
            for (let productType in counts) {
                if (maxProductType === null) {
                    maxProductType = parseInt(productType);
                    maxCount = counts[productType];
                    continue;
                }

                if (maxCount < counts[productType]) {
                    maxProductType = parseInt(productType);
                    maxCount = counts[productType];
                }
            }

            switch (maxProductType) {
                case productTypes.SUNGLASSES.id:
                    return '/sunglasses';
                case productTypes.GLASSES.id:
                    return '/glasses';
                case productTypes.ACCESSORIES.id:
                    return '/accessories';
                case productTypes.VISION_TEST.id:
                    return '/vision-test';
                case productTypes.LENS_REPLACEMENT.id:
                    return '/choose-your-lenses';
                case productTypes.DIY_LENSES.id:
                    return '/diy-lens-replacement';
                case productTypes.GIFT_CARD.id:
                    return '/giftcards';
            }

            return '/contact-lenses';
        }
    },
    methods: {
        onAlgoliaResults(results) {
            this.searchResults = results.hits;
            this.totalResults = results.nbHits;
            this.searchTerm = results.query;
        },
        onScroll(e) {
            const searchMenu = document.getElementsByClassName('mobile-search-panel');

            if (window.innerWidth > 1060) {
                // Reset top just in case going from mobile to desktop
                searchMenu[0].style.top = '90px';
                return;
            }

            if (window.top.scrollY > 42) {
                searchMenu[0].style.top = '56px';
            } else {
                searchMenu[0].style.top = 95 - window.top.scrollY + `px`;
            }
        },
        onClickSearchHistoryItem(searchHistoryItem) {
            this.$router.push(searchHistoryItem.path);
            this.onClickCloseSearch();
        },
        onClickClear() {
            this.$store.dispatch('search/clearSearchHistory');
        },
        onClickRemoveSearchItem(index) {
            this.$store.dispatch('search/removeSearchHistory', index);
        },
        onClickClearSearch() {
            this.searchTerm = '';
            this.searchResults = [];
            this.totalResults = null;
        },
        onClickCloseSearch() {
            this.$store.dispatch('search/setIsVisibleStatus', false);

            if (this.$store.getters['search/openedFromNavbar']) {
                this.$store.dispatch('setMobileMenuOpen', false);
            }

            this.searchTerm = '';
            this.searchResults = [];
            this.totalResults = null;
            freezeMobileBackdrop(false);
            hideMobileChatWindow(false);
        },
        scrollToTop() {
            if (!this.$refs?.mobileSearchPanel) {
                return;
            }

            this.$refs.mobileSearchPanel.scrollTop = 0;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/base-url.scss';

@media (max-width: $breakpoint2) {
    .mobile-search-panel {
        position: fixed;
        z-index: 110000;
        top: 90px;
        left: 0px;
        right: 0px;
        overflow: auto;
        overscroll-behavior: contain;
        height: auto;
        background-color: $bg-light-grey;
        transition: left 0.4s, right 0.4s;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        max-width: 100vw;

        .search-form {
            position: relative;
            z-index: 200;
        }

        .btn-clear-search-term {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            right: 20px;
            top: 18px;
            border: solid $color-grey-border 1px;
            border-radius: 100px;
            width: 26px;
            height: 26px;
        }

        .btn-search-back {
            position: absolute;
            left: 0px;
            top: 0px;
            border: 0px;
            background-color: transparent;
            padding: 10px 18px;
            z-index: 1000;
        }

        input[type='search'] {
            border: 0px;
            width: 100%;
            border-radius: 0px;
            padding: 20px 30px;
            background-color: #ffffff;
            border-top: solid $color-grey-border 1px;
            border-bottom: solid $color-grey-border 1px;
            padding-left: 50px;
            padding-right: 55px;
            font-size: 16px;
            outline: none !important;
        }

        ::placeholder {
            color: $color-grey-input;
        }

        .brand-listing {
            display: flex;
            flex-wrap: wrap;
            padding: 20px;
            gap: 12px;
            a {
                display: inline-flex;
                border-radius: 10px;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                width: calc(50% - 12px);
                box-shadow: 0px 12px 36px #00000014;
                padding: 20px;
                img {
                    max-height: 24px;
                }
            }
        }

        .shop-all-container {
            padding: 15px 0px;
            text-align: center;
            a {
                display: inline-block;
                color: $color-blue;
                font-size: 16px;
            }
        }

        .modal-backdrop {
            z-index: 100;
        }
    }

    .search-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        padding-bottom: 10px;
        .btn {
            font-size: 16px;
            padding: 10px 10px;
        }
        ::v-deep {
            .navbar-search-results-count-skeleton {
                width: 150px;
                margin-bottom: 0px;
                padding: 0px;
                .b-skeleton-text {
                    width: 100%;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .search-history {
        .search-history-items {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding-bottom: 10px;
            .search-history-item {
                display: flex;
                justify-content: flex-start;
                gap: 10px;
                align-items: center;
                border-radius: 15px;
                padding: 13px 20px;
                background-color: #ffffff;
                .search-icon {
                    width: 14px;
                    height: 14px;
                }
                a {
                    display: inline-block;
                    max-width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #000000;
                    text-decoration: none;
                }
            }

            .btn-remove-search-item {
                font-size: 14px;
                color: $color-grey-input;
            }
        }
    }

    .no-results-container {
        padding: 20px;
    }

    .search-results-container {
        position: relative;
        z-index: 200;
        background-color: #f4f7f9;
        padding: 20px;
        padding-top: 10px;
        overflow: auto;
        max-height: calc(100vh - 250px);
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .search-results {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-top: 0px;
        padding-bottom: 10px;
        .search-result-item {
            display: flex;
            align-items: center;
            text-align: left;
            color: #000000;
            padding: 15px 20px;
            background-color: #ffffff;
            border-radius: 10px;
            img {
                max-width: 60px;
                margin-right: 10px;
            }
        }
    }

    .loading-container {
        padding: 20px;
    }

    [type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
    }

    .no-results-loading-container {
        position: relative;
        z-index: 200;
        padding: 20px;
        background-color: #f4f7f9;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .search-results-container {
            padding: 0px;
        }
    }

    .contact-lens-brands-wrapper {
        position: relative;
        overflow: hidden;
        background-color: #ffffff;
        border-bottom: solid $color-grey-border 1px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 16px;
        z-index: 200;
        ::v-deep {
            .slide {
                max-width: 80px;
            }
        }
    }
}
</style>
