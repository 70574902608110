import productTypes from '@/constants/product-types';

export default {
    methods: {
        async loadProduct() {
            this.isLoading = true;

            // Reset the base product
            this.$store.dispatch('eyewearFlow/setBaseProduct', null);
            try {
                let product = await this.$axios.$get('/product/' + this.$route.params.slug);
                if (product.productType !== productTypes.LENS_REPLACEMENT.id) {
                    // Invalid product type
                    throw new Error();
                }

                this.$store.dispatch('eyewearFlow/setBaseProduct', product);
            } catch (e) {
                // show 404
                if (typeof window === 'undefined') {
                    this.$nuxt.context.error({
                        status: 404,
                        message: 'Product not found',
                    });
                }

                if (typeof this.$toast !== 'undefined') {
                    this.$toast.error('That lens replacement service is unavailable!  Please select a different choice.');
                }

                this.$router.push({ path: '/choose-your-lenses' });
            }

            this.isLoading = false;
        }
    }
}
