<template>
    <ol class="breadcrumb inline-links-section" v-if="!isLoading">
        <li class="breadcrumb-item"><nuxt-link to="/">Home</nuxt-link></li>
        <li
            v-for="(parentBreadcrumb, index) in parentBreadcrumbs"
            :key="index"
            class="breadcrumb-item"
        >
            <nuxt-link :to="parentBreadcrumb.url">
                {{ parentBreadcrumb.text }}
            </nuxt-link>
        </li>
        <li class="breadcrumb-item active"><slot></slot></li>
    </ol>
    <ol class="breadcrumb is-loading inline-links-section" v-else>
        <li class="breadcrumb-item">
            <b-skeleton />
        </li>
    </ol>
</template>

<script>
export default {
    props: {
        parentBreadcrumbs: {
            type: Array,
            default: () => ([])
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
    background-color: transparent;
    font-size: 12px;
    padding-left: 0px;
    &.is-loading {
        min-height: 42px;
    }
    .breadcrumb-item {
        color: #000000;
    }
    .breadcrumb-item + .breadcrumb-item::before {
        content: '>';
    }
    a {
        color: #000000;
        text-decoration: underline;
    }
    .b-skeleton {
        height: 12px;
        width: 100px;
        margin-top: 3px;
        margin-bottom: 0px;
        border-radius: 10px;
    }
}
</style>
