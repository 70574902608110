<template>
    <AppWrapper :hideChatMobile="true">
        <Header />
        <Navbar />
        <div class="content-section" v-if="!isCheckingLogin && !user && isMounted">
            <nuxt />
        </div>
        <FullPageLoader v-else />
    </AppWrapper>
</template>

<script>
import AppWrapper from '@/components/AppWrapper';
import FullPageLoader from '@/components/FullPageLoader';
import formatImageMixin from '@/mixins/image-url-formatter';
import Header from '@/layouts/Header';
import Navbar from '@/layouts/Navbar';
import freezeMobileBackdrop from '@/utils/freeze-mobile-backdrop';

export default {
    mixins: [formatImageMixin],
    components: {
        AppWrapper,
        FullPageLoader,
        Header,
        Navbar
    },
    mounted() {
        if (this.user) {
            this.$router.push({ path: '/profile' });
            return;
        }

        this.isMounted = true;

        this.initPage();
    },
    data() {
        return {
            isSticky: false,
            isMounted: false
        };
    },
    computed: {
        user() {
            return this.$store.state.auth?.currentUser?.customer;
        },
        isCheckingLogin() {
            return this.$store.state.auth.processing;
        }
    },
    methods: {
        initPage() {
            // Hide the menu if landing on the login page
            this.$store.dispatch('setMobileMenuOpen', false);
            this.$store.dispatch('search/setIsVisibleStatus', false);

            freezeMobileBackdrop(false);
        }
    },
    watch: {
        user(newUser) {
            if (!newUser) {
                return;
            }

            if (this.$store.state.auth.loginRedirect) {
                this.$store.dispatch('auth/triggerLoginRedirect', this.$router);
                return;
            }

            this.$router.push({ path: '/profile/account-snapshot' });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/base-url.scss';

.navbar-area {
    .comero-nav {
        .navbar {
            padding: 24px 0px;
            max-height: 80px;
        }

        .navbar-expand-md {
            justify-content: center;
        }
    }
}

.content-section {
    display: flex;
    width: 100%;
    height: calc(100vh - 81px);
}

::v-deep {
    .login-form-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bg-wrapper {
        background-image: url($baseAssetUrl+'images/login/login-bg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    @media (max-width: $breakpoint2) {
        .bg-wrapper {
            display: none;
        }

        .login-form-wrapper {
            width: 100% !important;
            padding: 0px 20px !important;
        }

        .content-section {
            height: auto;
            min-height: calc(100vh - 120px);
            margin-top: 10px;
            padding-bottom: 30px;
        }
    }
}
</style>
